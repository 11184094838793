import { useEffect, useState } from 'react';
import { Card, Row } from 'reactstrap';
import PostPagination from '@src/views/posts/components/PostPagination';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import ChildrenState from '../types/ChildrenState';
import { getFilesByChildId } from '../store';
import NewFileCard from './NewFileCard';
import ChildrenFileCard from './ChildrenFileCard';
import ChildrenFile from '../types/ChildrenFile';

const ChildrenFiles = () => {
  const dispatch = useDispatch<AppDispatch>();
  const childrenStore = useSelector((store: ChildrenState) => store.children);

  const [files, setFiles] = useState<ChildrenFile[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [loadOnce, setLoadOnce] = useState(false);
  const postPerPage = 6;

  useEffect(() => {
    setFiles(childrenStore?.files.slice(((activePage - 1) * postPerPage),
      postPerPage * activePage));
    if ((activePage - 1) * postPerPage === childrenStore?.files.length) {
      setActivePage(activePage === 1 ? 1 : activePage - 1);
    } else if (loadOnce) {
      setActivePage(1);
    }
    if (childrenStore?.files.length) {
      setLoadOnce(true);
    }
  }, [childrenStore?.files]);

  useEffect(() => {
    setFiles(childrenStore?.files.slice(((activePage - 1) * postPerPage),
      postPerPage * activePage));
  }, [activePage]);

  useEffect(() => {
    if (childrenStore.currentChild.id) dispatch(getFilesByChildId(childrenStore.currentChild.id));
  }, [childrenStore.currentChild.id]);

  return (
    <>
      <Card>
        <NewFileCard />
      </Card>
      <Row>
        {files.map((item) => <ChildrenFileCard key={item.id} details={item} />)}
      </Row>
      {childrenStore?.files?.length > 0
        && (
          <PostPagination
            totalPages={Number(Math.ceil(childrenStore?.files?.length / postPerPage) || 1)}
            activePage={activePage}
            setActive={setActivePage}
          />
        )}
    </>
  );
};

export default ChildrenFiles;
