import { useDispatch, useSelector } from 'react-redux';
import {
  Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import { closeModal, resetModalState } from '@store/modal';
import ModalWrapperProps from '@src/components/wrappers/ModalWrapperProps';

interface ModalState {
  modal: {
    modals: ModalWrapperProps[]
  }
}

const ModalWrapper = () => {
  const modalState = useSelector((state: ModalState) => state.modal.modals);
  const dispatch = useDispatch();

  return (
    <>
      {
        modalState.map((modal) => (
          <Modal
            key={modal.title}
            isOpen={modal.open}
            toggle={() => dispatch(closeModal())}
            onClosed={() => dispatch(resetModalState())}
            className="modal-dialog-centered"
            {...modal.modalProps}
          >
            <ModalHeader
              className="bg-transparent"
              toggle={() => dispatch(closeModal())}
              {...modal.modalHeaderProps}
            />
            <ModalBody className="px-sm-5 mx-50 pb-5" {...modal.modalBodyProps}>
              <div>
                <h1 className="text-center mb-1">{modal.title}</h1>
                {modal.subtitle && <p className="text-center">{modal.subtitle}</p>}
                {modal.textWithInsertedData?.show
                  && (
                    <p className="text-center">
                      {modal.textWithInsertedData.startText}
                      <span className="text-primary">
                        &nbsp;
                        {modal.textWithInsertedData.insertedText}
                        &nbsp;
                      </span> {modal.textWithInsertedData.endText}
                    </p>
                  )}
              </div>

              {modal.Component && <modal.Component {...modal.componentProps} />}
            </ModalBody>
          </Modal>
        ))
      }
    </>
  );
};

export default ModalWrapper;
