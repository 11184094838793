import { AppDispatch } from '@store/store';
import {
  Button, Card, CardBody, CardHeader, CardTitle,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ChevronLeft } from 'react-feather';
import CustomLabel from '@src/components/forms/CustomLabel';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import RequestStatus from '@src/types/RequestStatus';
import { successToast } from '@src/components/wrappers/ToastMessages';
import Switcher from '@src/components/switchers/Switcher';
import { yupResolver } from '@hookform/resolvers/yup';
import FaqState from '../types/FaqState';
import {
  addNewFaqCategory, clearCurrentFaqCategory, editFaqCategory, getFaqCategoriesById,
} from '../store';
import FaqCategorySubmitData from '../types/FaqCategorySubmitData';
import FaqCategories from '../types/FaqCategories';
import FaqList from '../components/FaqList';
import { faqCategoryValidationSchema } from '../validation';

const FaqCategoriesManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const faqStore = useSelector((store: FaqState) => store.faq);
  const { t } = useTranslation();
  const { faqCategoryId } = useParams();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FaqCategorySubmitData>({
    defaultValues: {
      name: '',
      description: '',
      nameEng: '',
      descriptionEng: '',
      active: '1',
    },
    resolver: yupResolver(faqCategoryValidationSchema()),
  });

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(clearCurrentFaqCategory());
    };
  }, []);

  useEffect(() => {
    if (faqCategoryId) {
      dispatch(getFaqCategoriesById(faqCategoryId));
    }
  }, []);

  useEffect(() => {
    if (faqStore.currentFaqCategories.id) {
      reset({
        name: faqStore.currentFaqCategories.name,
        description: faqStore.currentFaqCategories.description,
        nameEng: faqStore.currentFaqCategories.nameEng,
        descriptionEng: faqStore.currentFaqCategories.descriptionEng,
        active: String(faqStore.currentFaqCategories.active) as '1' | '0',
      });
    }
  }, [faqStore.currentFaqCategories]);

  const handleSuccessfulSubmit = async (data: any) => {
    if (faqStore.currentFaqCategories.id) {
      dispatch(editFaqCategory(
        { id: faqStore.currentFaqCategories.id, data },
      )).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successToast(t('FAQ category successfully edited'));
          dispatch(getFaqCategoriesById(String(faqCategoryId)));
        }
      });
    } else {
      dispatch(addNewFaqCategory(data)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successToast(t('FAQ category successfully added'));
          const { data: resData } = res.payload as { data: FaqCategories };
          navigate(`/faq-categories/edit/${resData.id}`);
        }
      });
    }
  };

  return (
    <Card>
      <CardHeader>
        <div className="mt-2" style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            color="outline"
            outline
            style={{
              margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
            }}
            onClick={() => navigate(-1)}
          >
            <ChevronLeft size={21} />
          </Button>
          <CardTitle>{faqCategoryId ? t('Edit FAQ category') : t('Add new FAQ category')}</CardTitle>
        </div>
      </CardHeader>
      <CardBody style={{ paddingTop: '1rem' }}>
        <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
          <Row>
            <Col md={6}>
              <CustomLabel name={`${t('Name')} rs`} required />
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Input invalid={!!errors.name} id="name" {...field} />
                )}
              />
              <CustomFormFeedback message={errors?.name?.message} />
            </Col>
            <Col md={6}>
              <CustomLabel name={`${t('Name')} en`} required />
              <Controller
                control={control}
                name="nameEng"
                render={({ field }) => (
                  <Input invalid={!!errors.nameEng} id="nameEng" {...field} />
                )}
              />
              <CustomFormFeedback message={errors?.nameEng?.message} />
            </Col>
            <Col md={6}>
              <CustomLabel name={`${t('Description')} rs`} />
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Input id="description" type="textarea" invalid={!!errors.description} {...field} />
                )}
              />
              <CustomFormFeedback message={errors?.description?.message} />
            </Col>
            <Col md={6}>
              <CustomLabel name={`${t('Description')} en`} />
              <Controller
                name="descriptionEng"
                control={control}
                render={({ field }) => (
                  <Input id="descriptionEng" type="textarea" invalid={!!errors.descriptionEng} {...field} />
                )}
              />
              <CustomFormFeedback message={errors?.descriptionEng?.message} />
            </Col>
            <Col md={4}>
              <CustomLabel name="" style={{ opacity: 0 }} />
              {faqCategoryId ? (
                <div>
                  <Controller
                    name="active"
                    control={control}
                    render={({ field }) => (
                      <Switcher id="active" {...field} checked={field.value === '1'} onChange={(e) => setValue('active', e.target.checked ? '1' : '0')} />
                    )}
                  />
                  {t('Active')}
                </div>
              ) : <></>}
            </Col>
            <Col md={4}>
              <CustomLabel style={{ opacity: 0 }} name={t('Submit')} />
              <Button
                type="submit"
                color="primary"
                disabled={false}
                style={{ minWidth: '100%' }}
              >
                {faqCategoryId ? t('Save') : t('Add')}
              </Button>
            </Col>
          </Row>
        </Form>
        {faqStore.currentFaqCategories.faqs ? (
          <>
            <Col md={12} style={{ paddingTop: '16px' }}><h6>{t('FAQ')}</h6></Col>
            <FaqList
              items={faqStore.currentFaqCategories.faqs}
              categoryId={String(faqStore.currentFaqCategories.id)}
            />
          </>
        ) : <></>}
      </CardBody>
    </Card>
  );
};

export default FaqCategoriesManagement;
