/* eslint-disable no-restricted-syntax */
import { AppDispatch } from '@store/store';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { getFinanceChartData, getYears, selectedYears } from '../store';
import InvoiceState from '../types/InvoiceState';

const FinanceChart = ({ childId }: { childId: number }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const invoiceStore = useSelector((store: InvoiceState) => store.invoice);
  const [selectedYear, setSelectedYear] = useState<number>();
  const [invoicesAmount, setInvoicesAmount] = useState<number[]>([]);
  const [paymentsAmount, setPaymentsAmount] = useState<number[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    dispatch(getYears());
  }, []);

  useEffect(() => {
    if (selectedYear) dispatch(getFinanceChartData({ childId: +childId, year: selectedYear }));
  }, [selectedYear]);

  useEffect(() => {
    dispatch(selectedYears(invoiceStore.years.map(
      (year) => ({ label: String(year.value), value: year.value }),
    )));
    if (invoiceStore.years.length) setSelectedYear(Number(invoiceStore.years[0].value));
  }, [invoiceStore.years]);

  useEffect(() => {
    const expenses = new Array(12).fill(0);
    const payments = new Array(12).fill(0);
    let newTotal = 0;

    for (const data of invoiceStore.financeChartData) {
      expenses[data.month - 1] = Number(data.totalAmountInvoice);
      payments[data.month - 1] = Number(data.totalAmountPayment);
      newTotal -= Number(data.totalAmountInvoice);
      newTotal += Number(data.totalAmountPayment);
    }
    setInvoicesAmount(expenses);
    setPaymentsAmount(payments);
    setTotal(newTotal);
  }, [invoiceStore.financeChartData]);

  return (
    <div>
      <div style={{ maxHeight: '550px', maxWidth: '880px', margin: '0 auto' }}>
        <Row md={12}>
          <Col md={3}>
            <h4>{t('Finance chart')}</h4>
          </Col>
          <Col md={7} />
          <Col md={2} style={{ zIndex: '1000', maxWidth: '' }}>
            <Select
              name="year"
              className="react-select"
              classNamePrefix="select"
              options={invoiceStore.selectedYears}
              value={invoiceStore.selectedYears.find((year) => Number(year.value) === selectedYear)}
              onChange={(val) => setSelectedYear(Number(val?.value))}
            />
          </Col>
        </Row>
      </div>
      <Col md={12}>
        <Chart
          style={{ maxHeight: '550px', maxWidth: '880px', margin: '0 auto' }}
          options={{
            chart: {
              id: 'basic-bar',
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '100%',
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: [t('January'), t('February'), t('March'), t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')],
              axisTicks: {
                color: '#000',
              },
            },
            title: {
              text: `${t('Total')}: ${total >= 0 ? '+' : ''}${total}`,
              align: 'center' as unknown as 'center',
              floating: true,
              style: {
                color: total >= 0 ? '#28c76f' : '#ea5455',
              },
            },
          }}
          type="bar"
          series={[
            {
              name: t('Invoiced'),
              data: invoicesAmount,
              color: '#FF9F43D9',
            },
            {
              name: t('Payed'),
              data: paymentsAmount,
            },
          ]}
        />
      </Col>
    </div>
  );
};

export default FinanceChart;
