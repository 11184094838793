import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import SelectedOptions from '@src/types/SelectedOptions';
import { MultiValue, SingleValue } from 'react-select';
import FileType from '@src/components/file-uploader/types/FileType';

const AddPostValidationSchema = (
  fileArray: FileType[],
  tag: SingleValue<SelectedOptions>,
  selectedTagOpstions: MultiValue<SelectedOptions>,
) => {
  const { t } = useTranslation();
  return yup.object().shape({
    text: yup
      .string()
      .required(t('Text is required')),
    files: yup
      .mixed()
      .test('required', t('At least one file should be added'),
        () => fileArray?.length > 0)
      .test('numberLimit', t('Maximum number of allowed files per post is five (5)'),
        () => fileArray.length <= 5),
    tagIds: yup.mixed().test('required', t('Select at least one tag'),
      () => (tag !== null && (selectedTagOpstions?.length ?? 0) !== 0)
          || (tag === null && (selectedTagOpstions?.length ?? 0) === 0)),
  });
};
export default AddPostValidationSchema;
