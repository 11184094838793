import StoreState from '@store/StoreState';
import { AppDispatch } from '@store/store';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import {
  getAllPosts, getAllTags, getChildrenByTenant, selectedChildren,
} from './store';
import PostsCard from './components/PostsCard';
import NewPostCard from './components/NewPostCard';
import Post from './types/Post';
import PostPagination from './components/PostPagination';
import {
  getAllGroups, getAllObjects, selectedGroups, selectedObjects,
} from '../polls/store';
import { PollsState } from '../polls/types';
import SelectedChildOptions from './types/SelectedChildOptions';

const PostPreview = () => {
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: StoreState) => state.posts);
  const pollStore = useSelector((state: PollsState) => state.polls);
  const postPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [posts, setPosts] = useState<Post[]>([]);
  const [activePage, setActive] = useState(1);
  const [loadOnce, setLoadOnce] = useState(false);
  const postPerPage = 6;

  const compareChildObjectsAndGroups = (a: SelectedChildOptions, b: SelectedChildOptions) => {
    const groupA = pollStore.groupsByTenant.find((group) => group.id === a.groupId);
    const groupB = pollStore.groupsByTenant.find((group) => group.id === b.groupId);

    const objectA = pollStore.objectsByTenant.find((obj) => obj.id === groupA?.objectId);
    const objectB = pollStore.objectsByTenant.find((obj) => obj.id === groupB?.objectId);

    if (groupA && groupB && objectA && objectB) {
      if (objectA.id === objectB.id) {
        return a.groupId !== b.groupId ? a.groupId - b.groupId : 0;
      }
      return objectA.id - objectB.id;
    }

    return 0;
  };

  useEffect(() => {
    setPosts(store?.allPosts.slice(((activePage - 1) * postPerPage), postPerPage * activePage));
    if ((activePage - 1) * postPerPage === store?.allPosts.length) {
      setActive(activePage === 1 ? 1 : activePage - 1);
    } else if (loadOnce) {
      setActive(1);
    }
    if (store?.allPosts.length) {
      setLoadOnce(true);
    }
  }, [store?.allPosts]);

  useEffect(() => {
    setPosts(store?.allPosts.slice(((activePage - 1) * postPerPage), postPerPage * activePage));
  }, [activePage]);

  useEffect(() => {
    dispatch(getAllPosts());
    dispatch(getChildrenByTenant());
    dispatch(getAllObjects());
    dispatch(getAllGroups());
    dispatch(getAllTags());
  }, []);

  useEffect(() => {
    dispatch(selectedObjects(
      pollStore?.objectsByTenant.map((object) => ({ value: object.id, label: object.name })),
    ));

    dispatch(selectedGroups(
      pollStore?.groupsByTenant
        .map((group) => ({ value: group.id, objectId: group.objectId, label: group.name }))
        .sort((a, b) => {
          if (a.objectId < b.objectId) return -1;
          if (a.objectId > b.objectId) return 1;
          return 0;
        }),
    ));

    dispatch(selectedChildren(
      store?.childrenByTenant.map((child) => ({
        value: child.id, label: child.name, groupId: child.group,
      }))
        .sort(compareChildObjectsAndGroups),
    ));
  }, [
    store?.childrenByTenant,
    pollStore?.objectsByTenant,
    pollStore?.groupsByTenant,
  ]);

  return (
    <>
      {isManagePermissionActive(postPermission, 'Posts')
        ? <NewPostCard /> : <></>}
      <Row>
        {
          posts.map((post) => (
            <PostsCard
              details={post}
              key={post.id}
              imageContent={post?.files[0]?.filepath || ''}
            />
          ))
        }
      </Row>
      {store?.allPosts?.length > 0
        && (
          <PostPagination
            totalPages={Number(Math.ceil(store?.allPosts?.length / postPerPage) || 1)}
            activePage={activePage}
            setActive={setActive}
          />
        )}
    </>
  );
};

export default PostPreview;
