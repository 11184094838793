import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import PostPagination from '@src/views/posts/components/PostPagination';
import { Card, Row } from 'reactstrap';
import NewAnnouncementCard from '../components/NewAnnouncementCard';
import AnnouncementState from '../types/AnnouncementState';
import { getAllAnnouncements } from '../store';
import AnnouncementCard from '../components/AnnouncementCard';
import Announcement from '../types/Announcement';

const Announcements = () => {
  const store = useSelector((store: AnnouncementState) => store.announcements);
  const dispatch = useDispatch<AppDispatch>();

  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [loadOnce, setLoadOnce] = useState(false);
  const postPerPage = 6;

  useEffect(() => {
    setAnnouncements(store?.allAnnouncements.slice(((activePage - 1) * postPerPage),
      postPerPage * activePage));
    if ((activePage - 1) * postPerPage === store?.allAnnouncements.length) {
      setActivePage(activePage === 1 ? 1 : activePage - 1);
    } else if (loadOnce) {
      setActivePage(1);
    }
    if (store?.allAnnouncements.length) {
      setLoadOnce(true);
    }
  }, [store?.allAnnouncements]);

  useEffect(() => {
    setAnnouncements(store?.allAnnouncements.slice(((activePage - 1) * postPerPage),
      postPerPage * activePage));
  }, [activePage]);

  useEffect(() => {
    dispatch(getAllAnnouncements());
  }, []);

  return (
    <>
      <Card>
        <NewAnnouncementCard />
      </Card>
      <Row>
        {announcements.map((item) => <AnnouncementCard key={item.id} details={item} />)}
      </Row>
      {store?.allAnnouncements?.length > 0
        && (
          <PostPagination
            totalPages={Number(Math.ceil(store?.allAnnouncements?.length / postPerPage) || 1)}
            activePage={activePage}
            setActive={setActivePage}
          />
        )}
    </>
  );
};

export default Announcements;
