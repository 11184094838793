import {
  Card, CardHeader, CardSubtitle, CardTitle,
} from 'reactstrap';

type Props = {
  title: string;
  subtitle?: string;
  button?: JSX.Element;
  className?: string;
}

const FormHeader: React.FC<Props> = ({
  title, subtitle, button, className = '',
}) => (
  <Card className={className}>
    <CardHeader className="d-flex">
      <div>
        <CardTitle tag="h4">{title}</CardTitle>
        <CardSubtitle className="mt-1">{subtitle}</CardSubtitle>
      </div>
      {button}
    </CardHeader>
  </Card>
);

export default FormHeader;
