import * as yup from 'yup';
import CustomPhoneRegex from '@src/components/forms/CustomPhoneReg';
import { useTranslation } from 'react-i18next';
import User from '../types/User';

const EditParentValidationSchema = (parents: User[], checkDefault: User) => {
  const { t } = useTranslation();
  const validationSchema = yup.object().shape({
    contractNumber: yup
      .number()
      .integer()
      .nullable()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .typeError(t('Positive number is required'))
      .min(0, t('Positive number is required')),
    phoneNumber: yup
      .string()
      .nullable()
      .matches(
        CustomPhoneRegex,
        t('Please enter contact number in valid format'),
      ),
    embg: yup
      .string()
      .test('unique', t('Value must be unique'), (value) => !parents.some((p) => value && (p.embg === value && checkDefault.embg !== value)))
      .nullable(),
    bankAccountNumber: yup
      .string()
      .test('unique', t('Value must be unique'), (value) => !parents.some((p) => value && p.bankAccountNumber === value && checkDefault.bankAccountNumber !== value))
      .nullable(),
  });
  return validationSchema;
};

export default EditParentValidationSchema;
