import {
  AccordionBody, AccordionHeader, AccordionItem, Button,
} from 'reactstrap';
import { useSkin } from '@src/utility/hooks/useSkin';
import {
  Delete, Edit, Eye, File,
} from 'react-feather';
import { formatDate } from '@src/views/polls/components/PollsForm';
import { extractFileName, getUserAccessTokenData } from '@src/utility/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { closeModal, openModal } from '@store/modal';
import ChildrenState from '@src/views/children/types/ChildrenState';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { useTranslation } from 'react-i18next';
import Note from '../types/Note';
import NotesForm from './NotesForm';
import { deleteNote, getAllNotes } from '../store';

type Props = {
  note: Note;
}
const NotesAccordionItem:React.FC<Props> = ({ note }) => {
  const { t } = useTranslation();
  const { skin } = useSkin();
  const store = useSelector((state: ChildrenState) => state.children);
  const dispatch = useDispatch<AppDispatch>();

  const userTokenData = getUserAccessTokenData();

  const formatNoteTitle = (text: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const cleanTitle = doc.body.textContent ?? '';

    if (cleanTitle.length > 100) {
      return ` ${cleanTitle.slice(0, 60)}...`;
    }
    return cleanTitle;
  };

  const handleDeleteClick = (noteForDeletion: Note) => {
    dispatch(
      openModal({
        Component: ConfirmationModalWrapper,
        title: t('Are you sure?'),
        subtitle: t('Note will be deleted'),
        open: true,
        componentProps: {
          handleConfirm: async () => {
            try {
              await dispatch(deleteNote(noteForDeletion.id));
            } finally {
              successToast(t('Note successfully deleted'));
              dispatch(getAllNotes(`childId=${store.currentChild.id}`));
              dispatch(closeModal());
            }
          },
        },
      }),
    );
  };

  return (
    <AccordionItem key={note.id} style={{ height: '100%' }}>
      <AccordionHeader targetId={note.id.toString()}>
        <div className="custom-accordion-header" style={{ width: '100%' }}>
          <div>
            <span style={{ color: '#6e6b7b' }}>{`${t(note.noteCategory.name)}: `}</span>
            <span style={{ color: skin === 'dark' ? '' : '#444' }}>{formatNoteTitle(note.note)}</span>
          </div>
          <div className="d-flex align-items-center md-flex-direction-row-reverse" style={{ color: '#6e6b7b', gap: '10px' }}>
            {note.visible && <Eye width={15} height={15} style={{ color: skin === 'dark' ? '#b4b7bd' : '#444' }} />}
            {formatDate(note.date.toString())}
          </div>
        </div>
      </AccordionHeader>
      <AccordionBody accordionId={note.id.toString()}>
        <div className="d-flex justify-content-between align-items-center w-100">
          {(userTokenData.role === 'superAdmin' || userTokenData.sub === note.author.id) ? (
            <Button
              color="outline m-0 p-0"
              onClick={() => {
                dispatch(openModal({
                  Component: NotesForm,
                  componentProps: { child: store.currentChild, note },
                  title: t('Edit note'),
                  open: true,
                  modalProps: { size: 'lg' },
                }));
              }}
            ><Edit />
            </Button>
          ) : <div />}
          <span>{t('Note')}</span>
          {(userTokenData.role === 'superAdmin' || userTokenData.sub === note.author.id) ? (
            <Button color="outline m-0 p-0" onClick={() => handleDeleteClick(note)}><Delete /></Button>
          ) : <div />}
        </div>
        <hr />
        <div dangerouslySetInnerHTML={{ __html: note.note }} />
        <div className={`d-flex justify-content-between ${note.file ? '' : 'flex-row-reverse'} sm-flex-direction-column-start`} style={{ width: '100%' }}>
          {note.file && (
            <a
              href={note.file}
              download
              target="_blank"
              style={{ color: skin === 'dark' ? '#b4b7bd' : '', gap: '10px', width: 'fit-content' }}
              className="d-flex align-items-center"
              rel="noreferrer"
            >
              <File width={15} height={15} /> {extractFileName(note.file)}
            </a>
          )}
          <span><strong>{t('Author')}: </strong> {`${note.author.name} ${note.author.surname}`}</span>
        </div>
      </AccordionBody>
    </AccordionItem>
  );
};

export default NotesAccordionItem;
