import { useEffect, useState } from 'react';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import ShowModalButton from '@src/components/buttons/ShowModalButton';
import { AppDispatch } from '@store/store';
import toast from 'react-hot-toast';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import PackageState from './types/PackageState';
import Package from './types/Package';
import { deletePackage, getAllCurrencies, getAllPackages } from './store';
import PackagesColumns from './components/PackagesColumns';
import PackagesForm from './components/PackagesForm';

const Packages: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const packagePermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const { t } = useTranslation();
  const tenantObjects = useSelector(
    (state: PackageState) => state.packages.allPackages,
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleDeleteClick = (pack: Package) => {
    dispatch(
      openModal({
        Component: ConfirmationModalWrapper,
        title: t('Are you sure?'),
        subtitle: `${t('Package')} "${pack.name}" ${t('will be deleted.')}`,
        open: true,
        componentProps: {
          handleConfirm: async () => {
            setLoading(true);
            try {
              await dispatch(deletePackage(pack.id));
            } finally {
              setLoading(false);
              dispatch(closeModal());
            }
          },
        },
      }),
    );
  };

  useEffect(() => {
    setLoading(false);
    dispatch(getAllPackages())
      .catch(() => { toast.error('Error while fetching objects'); })
      .finally(() => { setLoading(false); });
    dispatch(getAllCurrencies());
  }, []);

  return (
    <DataTableWrapper
      dataSource={tenantObjects}
      title={t('Packages')}
      subtitle={t('See, manage and filter packages table')}
      columns={PackagesColumns({ handleDeleteClick })}
      dataLoaded={!loading}
      functionButton={(
        isManagePermissionActive(packagePermission, 'AdministrationPackages')
          ? (
            <ShowModalButton
              modalProps={{
                Component: PackagesForm,
                title: t('Add new package'),
                open: true,
              }}
              title={t('Add Package')}
            />
          ) : <></>
      )}
    />
  );
};

export default Packages;
