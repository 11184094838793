import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { Button } from 'reactstrap';
import { successToast } from '@src/components/wrappers/ToastMessages';
import RequestStatus from '@src/types/RequestStatus';
import { useTranslation } from 'react-i18next';
import { deleteEvaluationGrade, getAllEvaluationGrades, setAllEvaluationGrades } from '../store';
import EvaluationGradeItem from './EvaluationGradeItem';
import EvaluationGradeForm from './EvaluationGradeForm';
import EvaluationState from '../types/EvaluationState';

const EvaluationGradeItems = ({ evaluationGroupId } : { evaluationGroupId: string }) => {
  const dispatch = useDispatch<AppDispatch>();
  const evaluationsStore = useSelector((store: EvaluationState) => store.evaluations);
  const [isAdding, setIsAdding] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllEvaluationGrades(`evaluationGroupIds=${evaluationGroupId}`));
  }, []);

  const handleDelete = async (id: number) => {
    dispatch(deleteEvaluationGrade(id)).then((res) => {
      if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
        successToast(t('Grade successfully deleted'));
        const filteredEvaluaitonGrades = evaluationsStore
          .allEvaluationGrades.filter((item) => item.id !== id);
        dispatch(setAllEvaluationGrades(filteredEvaluaitonGrades));
      }
    });
  };

  return (
    <div>
      <div style={{ margin: '14px 0' }}>
        {evaluationsStore.allEvaluationGrades.map((item) => (
          <EvaluationGradeItem
            key={item.id}
            item={item}
            handleDelete={handleDelete}
          />
        ))}
        {
          (evaluationsStore.allEvaluationGrades.length === 0 && !isAdding)
            ? <p style={{ margin: '14px 0' }}>{t('Grades are not defined')}</p> : <></>
        }
      </div>
      {isAdding ? (
        <EvaluationGradeForm evaluationGroupId={evaluationGroupId} setIsEditing={setIsAdding} />
      ) : <></>}
      {(!evaluationsStore.allEvaluationGrades.length && !isAdding)
        ? <Button color="primary" onClick={() => setIsAdding(true)}>{t('Add grade')}</Button> : <></>}
    </div>
  );
};

export default EvaluationGradeItems;
