import { useDispatch } from 'react-redux';
import {
  Button,
  Col, Form, Input, Spinner,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { useState } from 'react';
import { AppDispatch } from '@store/store';
import { closeModal } from '@store/modal';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomPhoneTooltip from '@src/components/forms/CustomPhoneTooltip';
import SubmitData from '../types/SubmitData';
import objectValidationSchema from '../validation';
import { addNewTenantObject, editTenantObject } from '../store';
import TenantObjectFormProps from '../types/TenantObjectFormProps';

const TenantObjectForm = ({ edit, tenantObject }: TenantObjectFormProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SubmitData>({
    defaultValues: {
      name: tenantObject?.name ?? '',
      address: tenantObject?.address ?? '',
      phoneNumber: tenantObject?.phoneNumber ?? '',
    },
    resolver: yupResolver(objectValidationSchema()),
  });

  const handleSuccessfulSubmit = async (data: SubmitData) => {
    setLoading(true);

    if (!edit) {
      dispatch(addNewTenantObject(data))
        .finally(() => {
          setLoading(false);
          dispatch(closeModal());
        });
    } else if (tenantObject) {
      dispatch(editTenantObject({
        ...data, tenantId: tenantObject.tenantId, id: tenantObject.id,
      }))
        .finally(() => {
          setLoading(false);
          dispatch(closeModal());
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Col xs={12}>
        <CustomLabel name={t('Name')} required htmlFor="name" />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input id="name" invalid={!!errors.name} {...field} />
          )}
        />
      </Col>
      <CustomFormFeedback message={errors?.name?.message} />
      <Col xs={12}>
        <CustomLabel name={t('Address')} required htmlFor="address" />
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <Input id="address" invalid={!!errors.name} {...field} />
          )}
        />
      </Col>
      <CustomFormFeedback message={errors?.address?.message} />
      <Col xs={12}>
        <CustomPhoneTooltip />
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <Input id="phoneNumber" invalid={!!errors.phoneNumber} {...field} />
          )}
        />
      </Col>
      <CustomFormFeedback message={errors?.phoneNumber?.message} />
      <Col xs={12} className="text-center mt-2 pt-50">
        <Button
          disabled={loading}
          type="submit"
          className="me-1"
          color="primary"
        >
          {loading && <Spinner size="sm" className="mr-1" />} {t('Save changes')}
        </Button>
        <Button
          type="reset"
          color="secondary"
          outline
          onClick={() => dispatch(closeModal())}
        >
          {t('Close')}
        </Button>
      </Col>
    </Form>
  );
};

export default TenantObjectForm;
