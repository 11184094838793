import { errorToast } from '@src/components/wrappers/ToastMessages';
import axios from 'axios';

const useBufferDownload = () => {
  const download = async ({ downloadUrl, downloadQuery, fileName } 
    : { downloadUrl: string, downloadQuery?: string, fileName: string }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/${downloadUrl}${downloadQuery ? '?' : ''}${downloadQuery ?? ''}`, { responseType: 'arraybuffer' });

      const el = document.createElement('a');
      const blobFile = new Blob([response.data]);
      el.href = window.URL.createObjectURL(blobFile);
      el.download = fileName;
      el.click();
    } catch (error) {
      errorToast();
    }
  };

  return { download };
};

export default useBufferDownload;