import { Label } from 'reactstrap';
import MandatoryIndicator from './MandatoryIndicator';
import CustomLabelProps from './CustomLabelProps';

const CustomLabel = ({
  name, classes = '', required, ...rest
}:CustomLabelProps) => (
  <Label className={`form-label ${classes}`} {...rest}>
    {name}
    {required && <MandatoryIndicator />}
  </Label>
);

export default CustomLabel;
