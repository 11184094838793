import FilterValue from '@src/types/FilterValue';
import SelectedOptions from '@src/types/SelectedOptions';
import { selectThemeColors } from '@src/utility/Utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { MultiValue, SingleValue } from 'react-select';

type Props = {
  options: SelectedOptions[];
  name: string;
  onChange: (
    name: string,
    value: FilterValue
  ) => void;
  isMulti?: boolean;
  defaultValue?: SelectedOptions,
}

const CustomFilter: React.FC<Props> = ({
  options, name, onChange, isMulti, defaultValue,
}) => {
  const [selectedValue, setSelectedValue] = useState<
    SingleValue<SelectedOptions> | MultiValue<SelectedOptions>>(defaultValue || []);

  const { t } = useTranslation();

  const onFilterChange = (
    value: SingleValue<SelectedOptions> | MultiValue<SelectedOptions>,
  ) => {
    setSelectedValue(value);
    onChange(name, value);
  };

  return (
    <Select
      name={name}
      className="react-select"
      placeholder={t('Select')}
      classNamePrefix="select"
      theme={selectThemeColors}
      isMulti={isMulti}
      options={options}
      onChange={(val) => onFilterChange(val)}
      value={selectedValue}
      isClearable
    />
  );
};

export default CustomFilter;
