import { useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col, Form, Row,
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import StoreState from '@store/StoreState';
import { AppDispatch } from '@store/store';
import RequestStatus from '@src/types/RequestStatus';
import FormHeader from '@src/components/forms/FormHeader';
import { useTranslation } from 'react-i18next';
import Switcher from '@src/components/switchers/Switcher';
import AddParentValidationSchema from '../validation/AddParentValidationSchema';
import {
  addParent, editParentInfo, getParentById,
} from '../store';

import EditParentValidationSchema from '../validation/EditParentValidationSchema';
import EditableFields from './form/EditableFields';
import ParentSubmitData from '../types/ParentSubmitData';
import Parent from '../types/Parent';

const ParentManagement = () => {
  const store = useSelector((state: StoreState) => state.parents);
  const { parentId } = useParams();
  const navigate = useNavigate();
  const defaultValues = {
    embg: '',
    contractNumber: '',
    bankAccountNumber: '',
    address: '',
    city: '',
    phoneNumber: '',
    name: '',
    surname: '',
    email: '',
    password: '',
    userAccount: '0',
  } as ParentSubmitData;

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(
      store?.currentParent
        ? EditParentValidationSchema(store?.allParents, store?.currentParent)
        : AddParentValidationSchema(store?.allParents),
    ),
  });

  const handleSuccessfulSubmit: SubmitHandler<Parent> = (values) => {
    if (parentId) {
      const {
        id,
        name,
        surname,
        email,
        embg,
        idNumber,
        externalId,
        bankAccountNumber,
        contractNumber,
        address,
        city,
        phoneNumber,
        userAccount,
      } = { ...values };
      dispatch(editParentInfo({
        id,
        name,
        surname,
        email,
        embg,
        idNumber,
        externalId,
        bankAccountNumber,
        contractNumber,
        address,
        city,
        phoneNumber,
        userAccount,
      })).then(
        (res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            navigate(-1);
          }
        },
      );
    } else {
      dispatch(addParent(values)).then(
        (res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            navigate(-1);
          }
        },
      );
    }
  };

  useEffect(() => {
    if (parentId) {
      dispatch(getParentById(parentId));
    }
  }, []);

  useEffect(() => {
    if (store.currentParent.id) reset({ ...store.currentParent, userAccount: store.currentParent.userId ? '1' : '0' });
  }, [store.currentParent]);

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <FormHeader
        title={parentId ? t('Update parent information') : t('Add parent')}
        subtitle={parentId ? t('Check and edit parent details') : t('Please enter parent details')}
      />
      <Card>
        <CardTitle className="p-1 mb-0">
          {t('Basic information')}
        </CardTitle>
        <CardBody>
          <Row>
            <EditableFields control={control} errors={errors} />
            <Controller
              name="userAccount"
              control={control}
              render={({ field }) => (
                <div>
                  <Switcher
                    id="userAccount"
                    checked={field.value === '1'}
                    onChange={(val) => setValue('userAccount', val.target.checked ? '1' : '0', { shouldDirty: true })}
                  />
                  {t('Parent account')}
                </div>
              )}
            />
          </Row>
          <p className="text-muted">
            {t('Fields marked with')}&nbsp;<span style={{ color: 'red' }}>*</span>&nbsp;{t('are required')}
          </p>
          <Row className="gy-1 gx-2 mt-75">
            <Col className="text-center mt-1" xs={12}>
              <Button type="submit" className="me-1" color="primary" disabled={!isDirty}>
                {parentId ? t('Save changes') : t('Add')}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
  );
};

export default ParentManagement;
