// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
import Tenant from '@tenants/types/Tenant';
import { getMessagesForCurrentLanguage, successToast } from '@src/components/wrappers/ToastMessages';
import { handleErrorMessage } from '@src/utility/Utils';
import toast from 'react-hot-toast';

export const getAllTenants = createAsyncThunk('goKinder/getTenants', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/tenants`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const getTenantById = createAsyncThunk('goKinder/getTenantById', async (tenantId: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/tenants/${tenantId}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const getTenantInfo = createAsyncThunk('goKinder/getTenantInfo', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/tenants/dashboard/info`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const synchronizeDatabase = async (tenantId: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/synchronize-users/${tenantId}`);
    successToast(getMessagesForCurrentLanguage()['Database synchronized successfully!']);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
};

export const editTenantInfo = createAsyncThunk('goKinder/editTenantInfo', async (tenant: Tenant) => {
  try {
    const id = tenant?.id;
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/tenants/${id}`, tenant);
    toast.success(getMessagesForCurrentLanguage()['Tenant successfully updated!'], { position: 'top-right', duration: 3000 });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const editGeneralTenantInfo = createAsyncThunk('goKinder/editGeneralTenantInfo', async (tenant: Tenant) => {
  try {
    const id = tenant?.id;
    const response = await axios.patch(`${process.env.REACT_APP_URL_ENV}/tenants/${id}`, tenant);
    toast.success(getMessagesForCurrentLanguage()['Tenant successfully updated!'], { position: 'top-right', duration: 3000 });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const getInvoiceOptions = createAsyncThunk('goKinder/getInvoiceOptions', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/tenants/invoice-options`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const addNewTenant = async (tenant: Tenant) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/tenants`, tenant);
    successToast(getMessagesForCurrentLanguage()['New tenant added!']);
    return {
      data: response.data,
    };
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const deleteTenant = async (tenantId: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/tenants/${tenantId}`);
    successToast(getMessagesForCurrentLanguage()['Tenant deleted successfully!']);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
};

export const tenantsSlice = createSlice({
  name: 'tenants',
  initialState: {
    allTenants: [] as Tenant[],
    currentTenant: {} as Tenant,
    invoiceOptions: [] as string[],
  },
  reducers: {
    setCurrentTenant: (state, action) => {
      state.currentTenant = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTenants.fulfilled, (state, action) => {
      state.allTenants = action.payload.data;
    });
    builder.addCase(getTenantById.fulfilled, (state, action) => {
      state.currentTenant = action.payload.data;
      if (action.payload.data.connection) {
        state.currentTenant.databaseName = action.payload.data.connection.name;
        state.currentTenant.databaseUsername = action.payload.data.connection.username;
        state.currentTenant.databasePassword = action.payload.data.connection.decryptedPassword;
        state.currentTenant.databasePort = action.payload.data.connection.port;
        state.currentTenant.databaseHost = action.payload.data.connection.host;
      }
      if (action.payload.data.privacyPolicy) {
        state.currentTenant.privacyPolicyText = action.payload.data.privacyPolicy.text;
      }
      if (action.payload.data.welcomeEmailTemplate) {
        state.currentTenant.welcomeEmailSubject = action.payload.data.welcomeEmailTemplate.subject;
        state.currentTenant.welcomeEmailText = action.payload.data
          .welcomeEmailTemplate.text;
        state.currentTenant.welcomeEmailAppLoginPath = action.payload.data
          .welcomeEmailTemplate.appLoginPath;
        state.currentTenant.welcomeHelpEmail = action.payload.data.welcomeEmailTemplate.helpEmail;
      }
    });
    builder.addCase(getTenantInfo.fulfilled, (state, action) => {
      state.currentTenant = action.payload.data;
    });
    builder.addCase(editTenantInfo.fulfilled, (state, action) => {
      const tenantIndex = state.allTenants.findIndex((t) => t?.id === action?.payload?.data.id);
      if (tenantIndex >= 0) {
        state.allTenants[tenantIndex] = action?.payload?.data;
      }
    });
    builder.addCase(editGeneralTenantInfo.fulfilled, (state, action) => {
      const tenantIndex = state.allTenants.findIndex((t) => t?.id === action?.payload?.data.id);
      if (tenantIndex >= 0) {
        state.allTenants[tenantIndex] = action?.payload?.data;
      }
    });
    builder.addCase(getInvoiceOptions.fulfilled, (state, action) => {
      state.invoiceOptions = action.payload.data.invoiceOptions;
    });
  },

});

export const {
  setCurrentTenant,
} = tenantsSlice.actions;

export default tenantsSlice.reducer;
