import { useState } from 'react';
import { HelpCircle } from 'react-feather';
import { Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CustomLabel from './CustomLabel';

const CustomPhoneTooltip: React.FC<{ isRequired?: boolean }> = ({ isRequired = true }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const { t } = useTranslation();
  const args = {
    autohide: true,
    flip: true,
  };
  return (
    <div className="d-flex">
      <CustomLabel name={t('Phone number')} for="phoneNumber" required={isRequired} />
      <div className="tooltip-wrapper d-flex justify-content-center">
        <HelpCircle
          id="helpCircle"
          size={16}
        />
        <Tooltip
          {...args}
          isOpen={tooltipOpen}
          target="helpCircle"
          toggle={toggle}
        >
          {t('Enter phone number in valid formats: +3816xxxxxxx(x) for mobile and +38111xxxxxx(x) for landline number in  Serbia, +3876xxxxxx(x) for mobile number in BiH')}
        </Tooltip>
      </div>
    </div>
  );
};

export default CustomPhoneTooltip;
