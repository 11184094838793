/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash, Eye } from 'tabler-icons-react';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Invoice from '../types/Invoice';

export const formatDateLocalDateString = (date: string | Date) => {
  const dateToFormat = new Date(date);
  return dateToFormat.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '.');
};

enum PAID_STATUS {
  Unpaid= 'NEPLAĆENA',
  Paid= 'PLAĆENA',
  PartiallyPaid= 'DELIMIČNO PLAĆENA',
}

const InvoiceColumns = ({ handleDeleteClick }:
  { handleDeleteClick: (invoiceId: number) => void }) => {
  const { t } = useTranslation();
  const invoicePermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const navigate = useNavigate();

  const columns: TableColumn<Invoice>[] = [
    {
      name: t('Invoice'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.referenceNumber}`}</p>
      ),
    },
    {
      name: t('Child'),
      minWidth: '150px',
      cell: (row) => (
        <a
          href={`${window.location.origin}/children/${row.childId}`}
          target="_blank"
          style={{ gap: '10px', width: 'fit-content' }}
          className="d-flex align-items-center"
          rel="noreferrer noopener"
        >
          {`${row.childName} ${row.childSurname}`}
        </a>
      ),
    },
    {
      name: t('Date Issued'),
      minWidth: '120px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${formatDateLocalDateString(row.dateIssued)}`}</p>
      ),
    },
    {
      name: t('Due Date'),
      minWidth: '120px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${formatDateLocalDateString(row.dueDate)}`}</p>
      ),
    },
    {
      name: t('Service Period'),
      minWidth: '250px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${formatDateLocalDateString(row.servicePeriodFrom)}`} - {`${formatDateLocalDateString(row.servicePeriodTo)}`}</p>
      ),
    },
    {
      name: t('Total amount'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.totalAmount}rsd`}</p>
      ),
    },
    {
      name: t('Invoice type'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{t(`${row.invoiceType}`)}</p>
      ),
    },
    {
      name: t('Status'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.id}>
          {PAID_STATUS.Paid === row.paidStatus && <span style={{ color: 'Green' }}>{t('Paid')}</span>}
          {PAID_STATUS.Unpaid === row.paidStatus && <span style={{ color: 'Red' }}>{t('Unpaid')}</span>}
          {PAID_STATUS.PartiallyPaid === row.paidStatus && <span style={{ color: 'Orange' }}>{t('Partially Paid')}</span>}
        </p>
      ),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          <Button
            style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
            size="sm"
            color="transparent"
            className="btn btn-icon"
            title={t('Edit object')}
            onClick={() => { navigate(`/invoice/preview/${row.id}`); }}
          >
            <Eye className="text-center" size={15} />
          </Button>
          {isManagePermissionActive(invoicePermission, 'Invoices')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                size="sm"
                color="transparent"
                className="btn btn-icon"
                title={t('Edit object')}
                onClick={() => { navigate(`/invoice/edit/${row.id}`); }}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(invoicePermission, 'Invoices')
            ? (
              <Button
                size="sm"
                color="transparent"
                className="btn btn-icon"
                onClick={() => { handleDeleteClick(row.id); }}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];

  return columns;
};

export default InvoiceColumns;
