import { useTranslation } from 'react-i18next';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { Button } from 'reactstrap';
import { Plus } from 'react-feather';
import useFilter from '@src/utility/hooks/useFilter';
import ChildrenState from '@src/views/children/types/ChildrenState';
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import RequestStatus from '@src/types/RequestStatus';
import { openModal } from '@store/modal';
import {
  allPayments,
  deletePayment,
  getPaymentStatuses,
  clearPayments,
  selectedPaymentStatuses,
  getAllPayments,
} from '../store';
import PaymentColumns from './PaymentColumns';
import PaymentState from '../types/PaymentState';
import PaymentForm from './PaymentForm';
import Payment from '../types/Payment';

const PaymentsTab = () => {
  const childStore = useSelector((state: ChildrenState) => state.children);
  const paymentStore = useSelector((state: PaymentState) => state.payments);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const invoicePermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [dataLoaded, setDataLoaded] = useState(false);
  const { onFilterChange, query } = useFilter();

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(clearPayments());
    };
  }, []);

  useEffect(() => {
    if (childStore.currentChild.id) {
      onFilterChange('childId', String(childStore.currentChild.id));
    }
  }, [childStore.currentChild.id]);

  useEffect(() => {
    dispatch(getPaymentStatuses());
    setDataLoaded(true);
  }, []);

  useEffect(() => {
    dispatch(selectedPaymentStatuses(paymentStore.paymentStatuses.map(
      (status) => ({ label: t(`${status}`), value: status }),
    )));
  }, [paymentStore.paymentStatuses]);

  const handleDeleteClick = (paymentId: number) => {
    try {
      dispatch(deletePayment(paymentId)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          const filteredPayments = paymentStore.allPayments.filter((item) => item.id !== paymentId);
          dispatch(allPayments(filteredPayments));
          toast.success(t('Payment successfully deleted'), { position: 'top-right', duration: 3000 });
        }
      });
    } catch (e) {
      toast.error('Error while deleting invoices');
    }
  };

  const handleEditClick = (data: Payment) => {
    try {
      dispatch(openModal({
        open: true,
        modalProps: {
          size: 'lg',
        },
        title: t('Edit payment'),
        Component: PaymentForm,
        componentProps: {
          children: [childStore.currentChild],
          payment: data,
        },
      }));
    } catch (e) {
      toast.error('Error while deleting payments');
    }
  };

  const handleNewClick = () => {
    try {
      dispatch(openModal({
        open: true,
        modalProps: {
          size: 'lg',
        },
        title: t('Add payment'),
        Component: PaymentForm,
        componentProps: {
          children: [childStore.currentChild],
          payment: {
            date: new Date().toISOString(),
            referenceNumber: '',
            amount: 0,
            status: '',
            note: '',
            childId: childStore.currentChild.id,
          },
        },
      }));
    } catch (e) {
      toast.error('Error while deleting payments');
    }
  };

  const onFilterClick = () => dispatch(getAllPayments(query));

  return (
    <div>
      <DataTableWrapper
        exportToExcel="payment-exports"
        dataSource={paymentStore.allPayments}
        title={t('Payments')}
        subtitle={t('See, manage and filter payments table')}
        columns={PaymentColumns({ handleDeleteClick, handleEditClick })}
        dataLoaded={dataLoaded}
        onChange={onFilterChange}
        onFilterClick={onFilterClick}
        query={query}
        showSearch={false}
        filters={[{
          label: 'Paid status', name: 'paidStatus', options: paymentStore.selectedPaymentStatuses,
        },
        {
          label: 'Date from', name: 'startDate', datePicker: 'full-date',
        },
        {
          label: 'Date to', name: 'endDate', datePicker: 'full-date',
        },
        {
          label: 'Reference number', name: 'referenceNumber',
        },
        ]}
        functionButton={(
          isManagePermissionActive(invoicePermissions, 'Payments')
            ? (
              <Button
                color="primary"
                outline
                className="text-nowrap mb-1 mt-2"
                onClick={() => handleNewClick()}
              >
                <div className="d-flex justify-content-center">
                  <Plus size={15} />
                  &nbsp;
                  <span>{t('Add payment')}</span>
                </div>
              </Button>
            ) : <></>
        )}
      />
    </div>
  );
};

export default PaymentsTab;
