import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { Button } from 'reactstrap';
import { Plus } from 'react-feather';
import useFilter from '@src/utility/hooks/useFilter';
import ChildrenState from '@src/views/children/types/ChildrenState';
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import RequestStatus from '@src/types/RequestStatus';
import {
  deleteEvaluation,
  getAllEvaluations,
  getEvaluationTypes,
  setAllEvaluations,
  setSelectedEvaluationTypes,
} from '../store';
import EvaluationColumns from './EvaluationColumns';
import EvaluationState from '../types/EvaluationState';

const EvaluationsTab = () => {
  const childStore = useSelector((state: ChildrenState) => state.children);
  const evaluationStore = useSelector((state: EvaluationState) => state.evaluations);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const evaluationPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [dataLoaded, setDataLoaded] = useState(false);
  const { onFilterChange, query } = useFilter();

  useEffect(() => {
    dispatch(getEvaluationTypes());
    setDataLoaded(true);
  }, []);

  const onFilterClick = () => {
    dispatch(getAllEvaluations(`childId=${childStore.currentChild.id}${query ? '&' : ''}${query}`));
  };

  useEffect(() => {
    dispatch(setSelectedEvaluationTypes(evaluationStore.evaluationTypes.map(
      (type) => ({ label: t(`${type}`), value: type }),
    )));
  }, [evaluationStore.evaluationTypes]);

  const handleDeleteClick = (id: number) => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('Evaluation will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            const res = await dispatch(deleteEvaluation(id));

            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              const filteredEvaluations = evaluationStore
                .allEvaluations.filter((item) => item.id !== id);
              dispatch(setAllEvaluations(filteredEvaluations));
              toast.success(t('Evaluation successfully deleted'), { position: 'top-right', duration: 3000 });
            }
          } finally {
            dispatch(closeModal());
          }
        },
      },
    }));
  };

  return (
    <div>
      <DataTableWrapper
        dataSource={evaluationStore.allEvaluations}
        title={t('Evaluations')}
        subtitle={t('See, manage and filter evaluation table')}
        columns={EvaluationColumns({ handleDeleteClick })}
        dataLoaded={dataLoaded}
        onChange={onFilterChange}
        showSearch={false}
        onFilterClick={onFilterClick}
        filters={[{
          label: 'Type', name: 'evaluationType', options: evaluationStore.selectedEvaluationTypes,
        },
        {
          label: 'Date from', name: 'dateFrom', datePicker: 'full-date',
        },
        {
          label: 'Date to', name: 'dateTo', datePicker: 'full-date',
        },
        ]}
        functionButton={(
          isManagePermissionActive(evaluationPermission, 'Evaluations')
            ? (
              <Button
                color="primary"
                className="text-nowrap mb-1 mt-2"
                outline
                onClick={() => { navigate(`/evaluations/${childStore.currentChild.id}/new`); }}
              >
                <div className="d-flex justify-content-center">
                  <Plus size={15} />
                  &nbsp;
                  <span>{t('Add new evaluation')}</span>
                </div>
              </Button>
            ) : <></>
        )}
      />
    </div>
  );
};

export default EvaluationsTab;
