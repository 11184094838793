import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const roleValidationSchema = (
  selectedPermissions: Set<string>,
) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    name: yup.string().required(t('Name is required')),
    permissions: yup.mixed().test('required', t('At least one permission is required'), () => !![...selectedPermissions].length),
  });
  return validation;
};

export default roleValidationSchema;
