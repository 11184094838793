// ** React Imports
import { Fragment } from 'react';

// ** Layouts
import BlankLayout from '@layouts/BlankLayout';
import VerticalLayout from '@src/layouts/VerticalLayout';
import LayoutWrapper from '@layouts/components/layout-wrapper';

// ** Route Components
import PublicRoute from '@components/routes/PublicRoute';
import PrivateRoute from '@components/routes/PrivateRoute';
// ** Utils
import { isObjEmpty } from '@src/utility/Utils';
import AuthenticationRoutes from './Authentication';
import PagesRoutes from './Pages';
import Route from '../Route';

interface Layout {
  blank: JSX.Element;
  vertical: JSX.Element;
  [key: string]: JSX.Element;
}
const getLayout: Layout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
};

// ** Document title
const TemplateTitle = '%s - GoKinder Admin Dashboard';

// ** Default Route
const DefaultRoute = '/parents';

// ** Merge Routes
const Routes: Route[] = [
  ...AuthenticationRoutes,
  ...PagesRoutes,
];

const getRouteMeta = (route: Route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta };
    }
    return {};
  }
  return {};
};

const isDefaultLayout = (route: Route, layout: string, defaultLayout: string) => (
  route?.meta?.layout && route?.meta?.layout === layout)
    || ((route.meta === undefined || route.meta.layout === undefined)
      && defaultLayout === layout);

const isPublicRoute = (route: Route) => (route?.meta?.publicRoute ? PublicRoute : PrivateRoute);

const getNonEmptyRouteMeta = (route: Route, isBlank: boolean) => (
  !isBlank ? getRouteMeta(route) : {});

const defineWrapper = (route: Route, isBlank: boolean) => (
  isObjEmpty(route.element.props) && isBlank === false
    ? LayoutWrapper
    : Fragment);

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout: string, defaultLayout: string) => {
  const LayoutRoutes: Route[] = [];

  if (Routes) {
    Routes.every((route) => {
      let isBlank = false;
      // ** Checks if Route layout or Default layout matches current layout
      if (isDefaultLayout(route, layout, defaultLayout)) {
        let RouteTag = PrivateRoute;

        // ** Check for public or private route
        if (route.meta) {
          isBlank = route.meta.layout === 'blank';
          RouteTag = isPublicRoute(route);
        }
        if (route.element) {
          const Wrapper = defineWrapper(route, isBlank);

          route.element = (
            <Wrapper {...(getNonEmptyRouteMeta(route, isBlank))}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          );
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route);
      }
      return LayoutRoutes;
    });
  }
  return LayoutRoutes;
};

const getRoutes = (layout: string) => {
  const defaultLayout = layout || 'vertical';
  const layouts = ['vertical', 'horizontal', 'blank'];

  const AllRoutes: Route[] = [];

  layouts.forEach((layoutItem: string) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout);

    AllRoutes.push({
      path: '/',
      element: getLayout[layoutItem as keyof Layout] || getLayout[defaultLayout as keyof Layout],
      children: LayoutRoutes,
    });
  });
  return AllRoutes;
};

export {
  DefaultRoute, TemplateTitle, Routes, getRoutes,
};
