/* eslint-disable no-restricted-syntax */
import FileUploader from '@src/components/file-uploader/FileUploader';
import FileType from '@src/components/file-uploader/types/FileType';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import RequestStatus from '@src/types/RequestStatus';
import { successToast } from '@src/components/wrappers/ToastMessages';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { File } from 'react-feather';
import { extractFileName } from '@src/utility/Utils';
import { getAllTenants } from '@src/views/tenants/store';
import Tenant from '@src/views/tenants/types/Tenant';
import { closeModal } from '@store/modal';
import SelectedOptions from '@src/types/SelectedOptions';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import {
  addNewCityForm,
  getAllCityForms,
  editCityForm,
  setSelectedTenants,
} from '../store';
import CityFormSubmitData from '../types/CityFormSubmitData';
import CityForm from '../types/CityForm';
import { addCityFormValidationSchema, editCityFormValidationSchema } from '../validation/CityFormsValidation';
import CityFormState from '../types/CityFormState';

const CityFormsForm = ({ cityForm } : { cityForm?: CityForm }) => {
  const dispatch = useDispatch<AppDispatch>();
  const tenantStore = useSelector((store: any) => store.tenants);
  const cityFormStore = useSelector((store: CityFormState) => store.cityForms);
  const [files, setFiles] = useState<FileType[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const { t } = useTranslation();
  const alphabetOptions = useMemo(() => [
    { label: t('Latin'), value: 'latin' },
    { label: t('Cyrillic'), value: 'cyrillic' },
  ], []);

  const {
    control,
    handleSubmit,
    trigger,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CityFormSubmitData>({
    defaultValues: {
      title: '',
      alphabet: '',
      tenantIds: [],
    },
    resolver: yupResolver(cityForm
      ? editCityFormValidationSchema() : addCityFormValidationSchema(newFiles)),
  });

  const successResponse = () => {
    if (!cityForm) {
      successToast(t('City form added successfully'));
    } else {
      successToast(t('City form edited successfully'));
    }

    dispatch(getAllCityForms());
    dispatch(closeModal());
  };

  const submitHandler = (data: CityFormSubmitData) => {
    data.file = newFiles?.[0];
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('alphabet', data.alphabet);
    if (data.tenantIds && data.tenantIds.length) {
      for (const tenantId of data.tenantIds) {
        formData.append('tenantIds[]', String(tenantId));
      }
    }
    if (data.file) formData.append('file', data.file);

    if (!cityForm) {
      dispatch(addNewCityForm(formData)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successResponse();
        }
      });
    } else {
      dispatch(editCityForm({
        id: cityForm.id,
        data: { ...data, tenantIds: data.tenantIds.map((tId) => String(tId)) },
      })).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successResponse();
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getAllCityForms());
    dispatch(getAllTenants());
  }, []);

  useEffect(() => {
    if (tenantStore.allTenants.length) {
      dispatch(setSelectedTenants(tenantStore.allTenants.map(
        (tenant: Tenant) => ({ label: tenant.name, value: tenant.id }),
      )));
    }
  }, [tenantStore.allTenants]);

  useEffect(() => {
    reset({
      title: cityForm?.title,
      alphabet: cityForm?.alphabet,
      tenantIds: cityForm?.tenants.map((tenant) => tenant.id),
    });
  }, []);

  useEffect(() => {
    if (newFiles?.length) {
      trigger(['file']);
    }
  }, [newFiles]);

  const acceptFiles: {
    [key: string]: string[];
  } = useMemo(() => ({
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/msword': ['.doc'],
  }), []);

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Row>
        <Col md={6}>
          <CustomLabel name={t('Title')} htmlFor="title" required />
          <Controller
            name="title"
            render={({ field }) => (
              <Input id="title" invalid={!!errors.title} {...field} />
            )}
            control={control}
          />
          <CustomFormFeedback message={errors?.title?.message} />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Alphabet')} required />
          <Select
            name="alphabet"
            className="react-select"
            classNamePrefix="select"
            options={alphabetOptions}
            value={alphabetOptions.find((opt) => opt.value === watch('alphabet'))}
            onChange={(val) => setValue('alphabet', val?.value || '')}
          />
          {errors?.alphabet?.message
          && <CustomFormFeedback message={errors.alphabet.message} />}
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Tenant')} />
          <Select
            isClearable
            name="tenantIds"
            placeholder={t('Select')}
            className="react-select"
            classNamePrefix="select"
            options={cityFormStore.selectedTenants}
            value={cityFormStore.selectedTenants?.filter((opt: SelectedOptions) => watch('tenantIds')?.find((vals: number) => vals === opt.value))}
            onChange={(val) => setValue('tenantIds', val.map((vl: SelectedOptions) => Number(vl.value)) || [])}
            isMulti
          />
          {errors?.tenantIds?.message
          && <CustomFormFeedback message={errors.tenantIds.message} />}
        </Col>
        <Col md={12}>
          <CustomLabel name={t('Media')} required />
          {cityForm ? (
            <small className="d-flex" style={{ gap: '10px', color: '#ff9f43', marginTop: '5px' }}>
              <a
                href={cityForm.filePath}
                className="d-flex align-items-center"
                download
                style={{ width: 'fit-content', gap: '5px' }}
              ><File width="15" height="15" />
                {`${extractFileName(cityForm.filePath)}`}
              </a>
            </small>
          )
            : (
              <Controller
                name="file"
                control={control}
                render={() => (
                  <FileUploader
                    files={files}
                    acceptFiles={acceptFiles}
                    maxFiles={1}
                    setFiles={setFiles}
                    newFiles={newFiles}
                    setNewFiles={setNewFiles}
                  />
                )}
              />
            )}
          {errors.file
            && <p style={{ fontSize: '0.9rem' }} className="text-center text-danger">{errors.file.message}</p>}
        </Col>
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            color="primary"
            type="submit"
            className="me-1"
          >
            {cityForm ? t('Save changes') : t('Add') }
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CityFormsForm;
