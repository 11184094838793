import { createSlice } from '@reduxjs/toolkit';
import ModalWrapperProps from '@src/components/wrappers/ModalWrapperProps';

const initialState: { modals: ModalWrapperProps[] } = { modals: [] };

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      state.modals = [...state.modals, payload];
    },
    closeModal: (state) => {
      state.modals.pop();
    },
    resetModalState: () => initialState,
  },
});

export const {
  openModal,
  closeModal,
  resetModalState,
} = modalSlice.actions;

export default modalSlice.reducer;
