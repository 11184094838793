import axios from 'axios';
import SelectedOptions from '@src/types/SelectedOptions';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleErrorMessage } from '@src/utility/Utils';
import Announcement from '../types/Announcement';
import AnnouncementType from '../types/AnnouncementType';
import AnnouncementSubmitData from '../types/AnnouncementSubmitData';

export const getAllAnnouncements = createAsyncThunk('goKinder/getAllAnnouncement', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/announcements/dashboard${query ? '?' : ''}${query ?? ''}`,
      { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getAnnouncementsById = createAsyncThunk('goKinder/getAnnouncementsById', async (id: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/announcements/${id}`,
      { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);

    return Promise.reject(new Error(err));
  }
});

export const getAnnouncementTypes = createAsyncThunk('goKinder/getAnnouncementTypes', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/announcement-types`,
      { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);

    return Promise.reject(new Error(err));
  }
});

export const createNewAnnouncement = createAsyncThunk('goKinder/createNewAnnouncement', async (data: FormData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/announcements`, data,
      { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);

    return Promise.reject(new Error(err));
  }
});

export const editAnnouncement = createAsyncThunk('goKinder/editAnnouncement', async (data: AnnouncementSubmitData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/announcements/${data.id}`, data,
      { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);

    return Promise.reject(new Error(err));
  }
});

export const deleteAnnouncement = createAsyncThunk('goKinder/deleteAnnouncement', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/announcements/${id}`,
      { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);

    return Promise.reject(new Error(err));
  }
});

export const announcementsSlice = createSlice({
  name: 'announcements',
  initialState: {
    allAnnouncements: [] as Announcement[],
    announcementTypes: [] as AnnouncementType[],
    selectedAnnouncementTypes: [] as SelectedOptions[],
    selectedTenantObjects: [] as SelectedOptions[],
    selectedTenantGroups: [] as SelectedOptions[],
  },
  reducers: {
    selectedAnnouncementTypes: (state, action) => {
      state.selectedAnnouncementTypes = action.payload;
    },
    selectedTenantObjects: (state, action) => {
      state.selectedTenantObjects = action.payload;
    },
    selectedTenantGroups: (state, action) => {
      state.selectedTenantGroups = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAnnouncements.fulfilled, (state, action) => {
      state.allAnnouncements = action.payload.data;
    });
    builder.addCase(getAnnouncementTypes.fulfilled, (state, action) => {
      state.announcementTypes = action.payload.data;
    });
  },
});

export const {
  selectedAnnouncementTypes,
  selectedTenantObjects,
  selectedTenantGroups,
} = announcementsSlice.actions;

export default announcementsSlice.reducer;
