export enum Action {
 VIEW='view',
 MANAGE='manage',
 DELETE='delete'
}

export enum Status {
  CAN='can',
  CANNOT='cannot'
}

export interface Permission {
  action: Action,
  name: string,
  status: Status
}
