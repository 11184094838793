import { useSkin } from '@src/utility/hooks/useSkin';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  items: {title: string, className: string}[]
}

const Legend:React.FC<Props> = ({ items }) => {
  const { t } = useTranslation();
  const { skin } = useSkin();
  const [legendItems, setLegendItems] = useState<{title: string, className: string}[][]>();

  function splitArrayIntoMaxLengthSubarrays<T>(arr: T[]): T[][] {
    const result: T[][] = [];
    let currentIndex = 0;

    while (currentIndex < arr.length) {
      result.push(arr.slice(currentIndex, currentIndex + 3));
      currentIndex += 3;
    }

    return result;
  }

  useEffect(() => {
    setLegendItems(splitArrayIntoMaxLengthSubarrays(items));
  }, []);

  return (
    <div className={`legend-${skin}`}>
      {legendItems?.map((legendItem, index) => (
        // eslint-disable-next-line
        <div key={index}>
          {legendItem.map((item) => (
            <div className="legend-item" key={item.title}>
              <div className={`color-box ${item.className}`} /> <span>{t(item.title)}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Legend;
