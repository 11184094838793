import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import ShowModalButton from '@src/components/buttons/ShowModalButton';
import { AppDispatch } from '@store/store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { openModal, closeModal } from '@store/modal';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import PollsColumns from '../components/PollsColumns';
import PollsForm from '../components/PollsForm';
import { deletePoll, getPolls } from '../store';
import { Poll } from '../types';

const Polls = () => {
  const [loading, setLoading] = useState(false);
  const pollPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const { t } = useTranslation();
  const polls = useSelector(
    (state: {
      polls: {
        polls: Poll[];
      };
    }) => state.polls.polls,
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleDeleteClick = (poll: Poll) => {
    dispatch(
      openModal({
        Component: ConfirmationModalWrapper,
        title: t('Are you sure?'),
        subtitle: `${t('Poll with question')} "${poll.question}" ${t('will be deleted.')}`,
        open: true,
        componentProps: {
          handleConfirm: async () => {
            setLoading(true);
            try {
              await dispatch(deletePoll(poll.id));
            } finally {
              setLoading(false);
              dispatch(closeModal());
            }
          },
        },
      }),
    );
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getPolls())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        toast.error('Error while fetching polls');
        setLoading(false);
      });
  }, []);

  return (
    <DataTableWrapper
      dataSource={polls}
      title={t('Polls')}
      subtitle={t('See, manage and filter polls table')}
      columns={PollsColumns({ handleDeleteClick })}
      dataLoaded={!loading}
      functionButton={(
        isManagePermissionActive(pollPermission, 'Polls')
          ? (
            <ShowModalButton
              modalProps={{
                Component: PollsForm,
                title: t('Add new poll'),
                open: true,
              }}
              title={t('Add Poll')}
            />
          ) : <></>
      )}
    />
  );
};

export default Polls;
