import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const announcementValidationSchema = (
  note: string,
) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    content: yup.mixed().test('required', t('Content is required'), () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(note, 'text/html');
      const cleanTitle = doc.body.textContent ?? '';
      return cleanTitle.trim().length !== 0;
    }),
    date: yup.string().required(t('Date is required')),
    announcementTypeId: yup.number().required(t('Announcement type is required')),
  });
  return validation;
};

export default announcementValidationSchema;
