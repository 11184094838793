import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const faqValidationSchema = (answer: string, answerEng: string) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    question: yup.string().required(t('Question (rs) is required')),
    answer: yup.mixed().test('required', t('Answer (rs) is required'), () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(answer, 'text/html');
      const cleanTitle = doc.body.textContent ?? '';
      return cleanTitle.trim().length !== 0;
    }),
    questionEng: yup.string().required(t('Question (en) is required')),
    answerEng: yup.mixed().test('required', t('Answer (en) is required'), () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(answerEng, 'text/html');
      const cleanTitle = doc.body.textContent ?? '';
      return cleanTitle.trim().length !== 0;
    }),
  });
  return validation;
};

const faqCategoryValidationSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    name: yup.string().required(t('Name (rs) is required')),
    description: yup.string().required(t('Description (rs) is required')),
    nameEng: yup.string().required(t('Name (en) is required')),
    descriptionEng: yup.string().required(t('Description (en) is required')),
  });
  return validation;
};

export {
  faqValidationSchema,
  faqCategoryValidationSchema,
};
