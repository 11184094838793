import { useState } from 'react';
import { HelpCircle } from 'react-feather';
import { Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CustomLabel from '@src/components/forms/CustomLabel';

const CustomTransactionInfoTooltip: React.FC<{
  isRequired?: boolean;
  name: string;
  htmlFor: string;
  data: string[];
}> = ({
  isRequired = true, name, htmlFor, data,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const { t } = useTranslation();
  const args = {
    autohide: true,
    flip: true,
  };
  return (
    <div className="d-flex">
      <CustomLabel name={t(`${name}`)} for={htmlFor} required={isRequired} />
      <div className="tooltip-wrapper d-flex justify-content-center">
        <HelpCircle
          id={htmlFor}
          size={16}
        />
        <Tooltip
          {...args}
          isOpen={tooltipOpen}
          target={htmlFor}
          toggle={toggle}
        >
          <p>{t('Ukoliko želite da menjate platilaca, primalaca ili svrhu plaćanja, pored dodatnog teksta možete koristiti i vrednosti ispod. Svaka vrednost je u formatu {{ vrednost }}')}</p>
          <p>
            {data.map((item: string) => <p style={{ fontSize: '10px' }} key={item}> {item} </p>)}
          </p>
        </Tooltip>
      </div>
    </div>
  );
};

export default CustomTransactionInfoTooltip;
