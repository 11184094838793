import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { openModal } from '@store/modal';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import TenantObjectForm from './TenantObjectForm';
import TenantObject from '../types/TenantObject';

const TenantObjectsColumns = ({ handleDeleteClick }:
  { handleDeleteClick: (tenantObject: TenantObject) => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const pollPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const columns: TableColumn<TenantObject>[] = [
    {
      name: t('Name'),
      minWidth: '150px',
      selector: ({ name }) => name,
    },
    {
      name: t('Address'),
      minWidth: '150px',
      center: true,
      selector: ({ address }) => address,
    },
    {
      name: t('Phone number'),
      minWidth: '150px',
      center: true,
      selector: ({ phoneNumber }) => phoneNumber,
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(pollPermission, 'AdministrationObjects')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                size="sm"
                color="transparent"
                className="btn btn-icon"
                title={t('Edit object')}
                onClick={() => {
                  dispatch(openModal({
                    Component: TenantObjectForm,
                    title: t('Edit object'),
                    open: true,
                    componentProps: {
                      tenantObject: row,
                      edit: true,
                    },
                  }));
                }}
              >
                <Edit size={15} className="text-center" />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(pollPermission, 'AdministrationObjects')
            ? (
              <Button
                size="sm"
                onClick={() => { handleDeleteClick(row); }}
                color="transparent"
                className="btn btn-icon"
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];
  return columns;
};

export default TenantObjectsColumns;
