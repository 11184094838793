/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { useEffect, useMemo, useState } from 'react';
import {
  EditorState, ContentState, convertToRaw,
} from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { closeModal } from '@store/modal';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import {
  Button,
  Col,
  Form,
  Row,
} from 'reactstrap';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import Select, { MultiValue } from 'react-select';
import FileUploader from '@src/components/file-uploader/FileUploader';
import FileType from '@src/components/file-uploader/types/FileType';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';
import { extractFileName, formatDateToShortDate } from '@src/utility/Utils';
import { File } from 'react-feather';
import CustomLabel from '@src/components/forms/CustomLabel';
import RequestStatus from '@src/types/RequestStatus';
import { Controller, useForm } from 'react-hook-form';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { getAllTenantGroups } from '@src/views/tenantGroups/store';
import { getAllTenantObjects } from '@src/views/tenantObjects/store';
import { useTranslation } from 'react-i18next';
import TenantGroupState from '@src/views/tenantGroups/types/TenantGroupState';
import TenantObjectState from '@src/views/tenantObjects/types/TenantObjectState';
import SelectedOptions from '@src/types/SelectedOptions';
import { useSkin } from '@src/utility/hooks/useSkin';
import { yupResolver } from '@hookform/resolvers/yup';
import AnnouncementSubmitData from '../types/AnnouncementSubmitData';
import {
  createNewAnnouncement,
  editAnnouncement,
  getAllAnnouncements,
  getAnnouncementTypes,
  selectedAnnouncementTypes,
  selectedTenantGroups,
  selectedTenantObjects,
} from '../store';
import Announcement from '../types/Announcement';
import AnnouncementState from '../types/AnnouncementState';
import announcementValidationSchema from '../validation';

const AnnouncementForm = ({ announcement } : { announcement?: Announcement }) => {
  const store = useSelector((state: AnnouncementState) => state.announcements);
  const [files, setFiles] = useState<FileType[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { skin } = useSkin();

  const handleGetHtml = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);

    return htmlContent.toString();
  };

  const allTenantObjects = useSelector(
    (state: TenantObjectState) => state.tenantObjects.allTenantObjects,
  );
  const allTenantGroups = useSelector(
    (state: TenantGroupState) => state.tenantGroups.allTenantGroups,
  );
  const [selectedGroups, setSelectedGroups] = useState<MultiValue<SelectedOptions>>([]);
  const [selectedObjects, setSelectedObjects] = useState<MultiValue<SelectedOptions>>([]);

  const successResponse = () => {
    dispatch(getAllAnnouncements());

    if (!announcement) {
      successToast(t('Announcement added successfully'));
    } else {
      successToast(t('Announcement edited successfully'));
    }

    dispatch(getAllAnnouncements());
    dispatch(closeModal());
  };

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    reset,
    watch,
    formState: { errors },
  } = useForm<AnnouncementSubmitData>({
    defaultValues: {
      content: '',
      date: new Date().toISOString(),
      groupIds: [],
      objectIds: [],
    },
    resolver: yupResolver(announcementValidationSchema(handleGetHtml())),
  });

  const submitHandler = (data: AnnouncementSubmitData) => {
    const formattedData = data;
    formattedData.file = newFiles?.[0];
    formattedData.groupIds = selectedGroups.map((group) => String(group.value));
    formattedData.objectIds = selectedObjects.map((group) => String(group.value));
    formattedData.content = handleGetHtml();
    formattedData.date = formatDateToShortDate(data.date);
    if (announcement) formattedData.id = announcement.id;

    const formData = new FormData();
    formData.append('content', formattedData.content);
    formData.append('date', formattedData.date);
    formData.append('announcementTypeId', String(data.announcementTypeId));

    for (const objectId of formattedData.objectIds) {
      formData.append('objectIds[]', objectId);
    }
    for (const groupId of formattedData.groupIds) {
      formData.append('groupIds[]', groupId);
    }
    if (formattedData.file) formData.append('file', formattedData.file);

    if (!announcement) {
      dispatch(createNewAnnouncement(formData)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successResponse();
        }
      });
    } else {
      dispatch(editAnnouncement(formattedData)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successResponse();
          dispatch(getAllAnnouncements());
        }
      });
    }
  };

  useEffect(() => {
    async function init() {
      dispatch(getAnnouncementTypes());
      dispatch(getAllTenantGroups());
      dispatch(getAllTenantObjects());
      if (announcement) {
        reset({
          date: announcement.date,
          announcementTypeId: +announcement.announcementTypeId,
          content: announcement.content,
        });
        setSelectedGroups(announcement.groups.map((group) => (
          { label: group.name, value: group.id })));
        setSelectedObjects(announcement.objects.map((object) => (
          { label: object.name, value: object.id ?? 1 })));
      }
    }

    init();
  }, []);

  useEffect(() => {
    if (announcement) {
      const contentState = htmlToDraft(announcement.content);
      const newEditorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(contentState.contentBlocks),
      );

      setEditorState(newEditorState);
    }
  }, []);

  useEffect(() => {
    dispatch(selectedAnnouncementTypes(store.announcementTypes.map(
      (type) => ({ label: t(type.name), value: type.id }),
    )));
  }, [store.announcementTypes]);

  useEffect(() => {
    dispatch(selectedTenantObjects(allTenantObjects.map(
      (type) => ({ label: t(type.name), value: type.id }),
    )));
  }, [allTenantObjects]);

  useEffect(() => {
    dispatch(selectedTenantGroups(allTenantGroups.map(
      (type) => ({ label: t(type.name), value: type.id }),
    )));
  }, [allTenantGroups]);

  useEffect(() => {
    if (newFiles?.length) {
      trigger(['file']);
    }
  }, [newFiles]);

  const acceptFiles: {
    [key: string]: string[];
  } = useMemo(() => ({
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    'video/mp4': ['.mp4'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/pdf': ['.pdf'],
  }), []);
  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Row>
        <Col md={6}>
          <CustomDatePicker
            label={t('Date')}
            name="date"
            control={control}
            setValue={setValue}
            error={errors?.date?.message}
            required
            options={{ minDate: undefined }}
            defaultValue={watch('date')}
          />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Announcement type')} required htmlFor="announcementTypeId" />
          <Select
            name="announcementTypeId"
            className="react-select"
            inputId="announcementTypeId"
            classNamePrefix="select"
            placeholder={t('Select')}
            options={store.selectedAnnouncementTypes}
            value={store.selectedAnnouncementTypes
              .find((opt) => opt.value === watch('announcementTypeId'))}
            onChange={(val) => setValue('announcementTypeId', val?.value ? +val?.value : 0)}
          />
          {errors?.announcementTypeId?.message
          && <CustomFormFeedback message={errors.announcementTypeId.message} />}
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Objects')} htmlFor="object" />
          <Select
            name="object"
            inputId="object"
            className="react-select"
            classNamePrefix="select"
            placeholder={t('Select')}
            options={store.selectedTenantObjects}
            isMulti
            value={selectedObjects}
            onChange={(val) => setSelectedObjects(val)}
          />
          {errors?.objectIds?.message
          && <CustomFormFeedback message={errors.objectIds.message} />}
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Groups')} htmlFor="groups" />
          <Select
            name="groups"
            inputId="groups"
            className="react-select"
            classNamePrefix="select"
            placeholder={t('Select')}
            options={store.selectedTenantGroups}
            isMulti
            value={selectedGroups}
            onChange={(val) => setSelectedGroups(val)}
          />
          {errors?.groupIds?.message
          && <CustomFormFeedback message={errors.groupIds.message} />}
        </Col>
        <Col md={12} className="mt-1">
          <CustomLabel name={t('Content')} required />
          <Controller
            name="content"
            control={control}
            render={() => (
              <Editor
                wrapperStyle={{ borderRadius: '10px', border: '1px solid #3b4253' }}
                editorClassName="p-1"
                editorStyle={{
                  minHeight: '350px',
                }}
                toolbarStyle={{ color: '#000', borderRadius: '10px' }}
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbarClassName={skin === 'dark' ? 'toolbar-dark' : ''}
              />
            )}
          />
          <CustomFormFeedback message={errors?.content?.message} />
        </Col>
        <Col md={12}>
          <CustomLabel name={t('Media')} />
          {announcement ? (
            <small className="d-flex" style={{ gap: '10px', color: '#ff9f43', marginTop: '5px' }}>
              {announcement.attachment ? (
                <a
                  href={announcement.attachment}
                  className="d-flex align-items-center"
                  download
                  style={{ width: 'fit-content', gap: '5px' }}
                ><File width="15" height="15" />
                  {announcement.attachment ? `${extractFileName(announcement.attachment)}` : ''}
                </a>
              ) : (
                `${t('No files')}`
              )}
            </small>
          )
            : (
              <Controller
                name="file"
                control={control}
                render={() => (
                  <FileUploader
                    files={files}
                    setFiles={setFiles}
                    newFiles={newFiles}
                    setNewFiles={setNewFiles}
                    maxFiles={1}
                    acceptFiles={acceptFiles}
                  />
                )}
              />
            )}
          {errors.file
            && <p className="text-center text-danger" style={{ fontSize: '0.9rem' }}>{errors.file.message}</p>}
        </Col>
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            type="submit"
            className="me-1"
            color="primary"
          >
            {announcement ? t('Save changes') : t('Add') }
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AnnouncementForm;
