// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
import { errorToast, getMessagesForCurrentLanguage, successToast } from '@src/components/wrappers/ToastMessages';
import SelectedOptions from '@src/types/SelectedOptions';
import SubmitData from '../types/SubmitData';
import Package from '../types/Package';
import Currency from '../types/Currency';

export const getAllPackages = createAsyncThunk('goKinder/getPackages', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/packages`);
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const deletePackage = createAsyncThunk('goKinder/deletePackage', async (packageId: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/packages/${packageId}`);
    successToast(getMessagesForCurrentLanguage()['Package successfully deleted!']);
    return {
      data: { packageId, ...response.data },
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const addNewPakage = createAsyncThunk('goKinder/addNewPackage', async (data: SubmitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/packages`, { ...data });
    successToast(getMessagesForCurrentLanguage()['Package successfully added!']);
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const editPackage = createAsyncThunk('goKinder/editPackage', async (data: SubmitData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/packages/${data.id}`, data);
    successToast(getMessagesForCurrentLanguage()['Package successfully updated!']);
    return {
      data: { ...data, ...response.data },
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const getAllCurrencies = createAsyncThunk('goKinder/getAllCurrencies', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/currencies`);
    return { data: response.data };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const packagesSlice = createSlice({
  name: 'tenantGroups',
  initialState: {
    allPackages: [] as Package[],
    allCurrencies: [] as Currency[],
    selectedCurrencies: [] as SelectedOptions[],
  },
  reducers: {
    selectedCurrencies: (state, action) => {
      state.selectedCurrencies = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPackages.fulfilled, (state, action) => {
      state.allPackages = action.payload.data;
    });
    builder.addCase(deletePackage.fulfilled, (state, action) => {
      state.allPackages = state.allPackages.filter(
        (pack) => pack.id !== action.payload.data.packageId,
      );
    });
    builder.addCase(addNewPakage.fulfilled, (state, action) => {
      state.allPackages = [...state.allPackages, action.payload.data];
    });
    builder.addCase(editPackage.fulfilled, (state, action) => {
      const packageIndex = state.allPackages.findIndex(
        (pack) => pack?.id === action?.payload?.data.id,
      );
      if (packageIndex >= 0) {
        state.allPackages[packageIndex] = action?.payload?.data;
      }
    });
    builder.addCase(getAllCurrencies.fulfilled, (state, action) => {
      state.allCurrencies = action.payload.data;
    });
  },
});

export const {
  selectedCurrencies,
} = packagesSlice.actions;

export default packagesSlice.reducer;
