import FileType from '@src/components/file-uploader/types/FileType';
import SelectedOptions from '@src/types/SelectedOptions';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import * as yup from 'yup';

const albumValidation = (
  files: FileType[],
  groups: MultiValue<SelectedOptions>,
) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    title: yup.string().required(t('Title is required')),
    date: yup.string().required(t('Date is required')),
    files: yup.mixed().test('required', t('Files are required'), () => files.length > 0),
    groupIds: yup.mixed().test('required', t('Groups are required'), () => !!groups && groups.length > 0),
  });
  return validation;
};
export default albumValidation;
