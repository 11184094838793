import { AppDispatch } from '@store/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Percentage, BrandGoogleAnalytics } from 'tabler-icons-react';
import {
  Row, Col, Card,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import {
  getPollById,
  getTotalVotes,
  getVotesPerAnswerPercentage,
  getVotingPercentage,
} from '../store';
import InfoCard from '../components/InfoCard';
import { PollsState } from '../types';
import PollStatisticsColumns from '../components/PollStatisticsColumns';

const PollStatistics = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const totalVotes = useSelector((state: PollsState) => state.polls.totalVotes);

  const votingPercentage = useSelector(
    (state: PollsState) => state.polls.votingPercentage,
  );

  const votesPerAnswerPercentage = useSelector(
    (state: PollsState) => state.polls.votesPerAnswerPercentage,
  );

  const question = useSelector(
    (state: PollsState) => state.polls.currentPoll?.question,
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      Promise.all([
        dispatch(getVotingPercentage(id)),
        dispatch(getTotalVotes(id)),
        dispatch(getVotesPerAnswerPercentage(id)),
        dispatch(getPollById(id)),
      ]);
    }
  }, [id, dispatch]);

  return (
    <div>
      <Card>
        <div className="mx-2 py-1">
          <h5 className="mb-1">{t('Question')}:</h5>
          <h5>{question}</h5>
        </div>
      </Card>
      <Row>
        <Col>
          <InfoCard
            icon={BrandGoogleAnalytics}
            title={t('Total number of votes')}
            value={totalVotes}
          />
        </Col>
        <Col>
          <InfoCard
            icon={Percentage}
            title={t('Participation percentage')}
            value={votingPercentage}
          />
        </Col>
      </Row>
      <div className="react-dataTable">
        <DataTable
          noHeader
          className="react-dataTable"
          columns={PollStatisticsColumns()}
          data={votesPerAnswerPercentage}
        />
      </div>
    </div>
  );
};

export default PollStatistics;
