import { AppDispatch } from '@store/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import FormHeader from '@src/components/forms/FormHeader';
import { useTranslation } from 'react-i18next';
import { Plus } from 'react-feather';
import { Button } from 'reactstrap';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { clearCurrentAlbum, getAlbumById } from '../store';
import AlbumState from '../types/AlbumState';
import ImageThumbnail from '../components/ImageThumbnail';

const AlbumPreview = () => {
  const store = useSelector((state: AlbumState) => state.albums);
  const dispatch = useDispatch<AppDispatch>();
  const { albumId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const galleryPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  useEffect(() => {
    dispatch(clearCurrentAlbum());
  }, []);

  useEffect(() => {
    if (albumId && !store.currentAlbum) {
      dispatch(getAlbumById(+albumId));
    }
  }, [store.currentAlbum]);

  return (
    <>
      <FormHeader
        title={t('Album preview')}
        subtitle={t('View, add, and manage your album files')}
        button={(
          isManagePermissionActive(galleryPermission, 'Gallery')
            ? (
              <Button
                color="primary"
                className="text-nowrap mb-1 mt-2"
                outline
                onClick={() => { navigate(`/gallery/album/${albumId}/add-files`); }}
              >
                <div className="d-flex justify-content-center">
                  <Plus size={15} />
                  &nbsp;
                  <span>{t('Add new files')}</span>
                </div>
              </Button>
            ) : <></>)}
      />
      <div className="d-flex" style={{ gap: '20px', flexWrap: 'wrap' }}>
        {store.currentAlbum?.albumFiles.map((file) => (
          <ImageThumbnail file={file} key={file.id} />
        ))}
      </div>
    </>
  );
};

export default AlbumPreview;
