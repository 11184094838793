import { useSkin } from '@src/utility/hooks/useSkin';
import { useEffect, useState } from 'react';

const SpinnerComponent = () => {
  const { skin } = useSkin();
  const [illustration, setIlustration] = useState(
    skin === 'dark' ? 'GoKinder-logo-dark.svg' : 'GoKinder-logo-light.svg');
  const [source, setSource] = useState(require(`@src/assets/images/logo/${illustration}`).default);
  const [currentSkin, setCurrentSkin] = useState(skin);

  useEffect(() => {
  if(currentSkin !== skin) {
    setIlustration(skin === 'dark' ? 'GoKinder-logo-dark.svg' : 'GoKinder-logo-light.svg');
  }
  },[skin]);
  
  useEffect(() => {
    setSource(require(`@src/assets/images/logo/${illustration}`).default)
    setCurrentSkin(skin)
  }, [illustration]);

  return (
  <div className="fallback-spinner app-loader">
    <img className="fallback-logo" src={source} alt="logo" width="100px" height="100px"/>
    <div className="loading">
      <div className="effect-1 effects" />
      <div className="effect-2 effects" />
      <div className="effect-3 effects" />
    </div>
  </div>
  );
};

export default SpinnerComponent;
