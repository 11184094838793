import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { successToast } from '@src/components/wrappers/ToastMessages';
import RequestStatus from '@src/types/RequestStatus';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import EvaluationMetricSubmitData from '../types/EvaluationMetricSubmitData';
import { createNewEvaluationMetric, editEvaluationMetrics, getAllEvaluationMetrics } from '../store';
import EvaluationMetric from '../types/EvaluationMetric';
import { evaluationMetricValidaitonSchema } from '../validation';

const EvaluationMetricForm = ({ evaluationGroupId, evaluationMetricItem, setIsEditing } : {
   evaluationGroupId: string, evaluationMetricItem?: EvaluationMetric,
   setIsEditing: (editing: boolean) => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EvaluationMetricSubmitData>({
    defaultValues: {
      name: evaluationMetricItem?.name ?? '',
      sort: evaluationMetricItem?.sort ?? 0,
      evaluationGroupId: Number(evaluationGroupId),
    },
    resolver: yupResolver(
      evaluationMetricValidaitonSchema(),
    ),
  });

  const handleSuccess = (hasItem: boolean) => {
    if (hasItem) {
      successToast(t('Metric successfully edited'));
    } else {
      successToast(t('Metric successfully added'));
    }

    dispatch(getAllEvaluationMetrics(`evaluationGroupIds=${evaluationGroupId}`));
    setIsEditing(false);
  };

  const handleSuccessfulSubmit = async (data: any) => {
    if (evaluationMetricItem) {
      dispatch(editEvaluationMetrics({ id: evaluationMetricItem.id, data })).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          handleSuccess(true);
        }
      });
    } else {
      dispatch(createNewEvaluationMetric(data)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          handleSuccess(false);
        }
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={4}>
          <CustomLabel name={t('Name')} required />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input id="name" invalid={!!errors.name} {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.name?.message} />
        </Col>
        <Col md={4}>
          <CustomLabel name={t('Sort')} required />
          <Controller
            name="sort"
            control={control}
            render={({ field }) => (
              <Input id="sort" type="number" invalid={!!errors.sort} {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.sort?.message} />
        </Col>
        <Col md={2} />
        <Col md={2}>
          <CustomLabel style={{ opacity: 0 }} name={t('')} />
          <Col
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Button
              type="submit"
              color="primary"
              disabled={false}
              outline
            >
              {evaluationMetricItem ? t('Save') : t('Add')}
            </Button>
            <Button color="red" onClick={() => setIsEditing(false)}>{t('Close')}</Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};

export default EvaluationMetricForm;
