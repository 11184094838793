import { useState, useEffect } from 'react';

// ** Third Party Components
import { Sun, Moon, Menu2 } from 'tabler-icons-react';

// ** Reactstrap Imports
import { NavItem, NavLink } from 'reactstrap';
import UserDropdown from './UserDropdown';
import IntlDropdown from './IntlDropdown';

 // ** Function to toggle Theme (Light/Dark)
 const ThemeToggler = ({skin, setSkin}) => {
  if (skin === 'dark') {
    return <Sun className='ficon' size={25} onClick={() => setSkin('light')}  />;
  }
  return <Moon className='ficon' size={25} onClick={() => setSkin('dark')} />;
};

const NavbarUser = (props) => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props;
  const [menuButtonVisible, setMenuButtonVisible] = useState(false);

  const toggleMenuVisibility = () => {
    setMenuVisibility((prevState) => !prevState);
  };

  useEffect(() => {
    const breakpoint = 1200;

    if (window.innerWidth < breakpoint) {
      setMenuButtonVisible(true);
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth < breakpoint) {
        setMenuButtonVisible(true);
      } else {
        setMenuButtonVisible(false);
      }
    });
  }, []);

  return (
    <ul className="nav navbar-nav align-items-center justify-content-end w-100">
      { menuButtonVisible &&
        <NavItem className='cursor-pointer' style={{ marginRight: 'auto' }}>
          <Menu2 onClick={toggleMenuVisibility} />
        </NavItem> 
      }
      <IntlDropdown />
      <NavItem>
        <NavLink className="nav-link-style">
          <ThemeToggler skin={skin} setSkin={setSkin}/>
        </NavLink>
      </NavItem>
      <UserDropdown />
    </ul>
  );
};
export default NavbarUser;