import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import Child from '@src/views/children/types/Child';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col, Form, Input, Row,
} from 'reactstrap';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';
import Select, { SingleValue } from 'react-select';
import { extractFileName, selectThemeColors } from '@src/utility/Utils';
import { useEffect, useRef, useState } from 'react';
import SelectedOptions from '@src/types/SelectedOptions';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import ChildrenState from '@src/views/children/types/ChildrenState';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { getAllChildren } from '@src/views/children/store';
import CustomLabel from '@src/components/forms/CustomLabel';
import { closeModal } from '@store/modal';
import {
  EditorState, convertToRaw, ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { yupResolver } from '@hookform/resolvers/yup';
import RequestStatus from '@src/types/RequestStatus';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { formatDate } from '@src/views/polls/components/PollsForm';
import { File } from 'react-feather';
import { useSkin } from '@src/utility/hooks/useSkin';
import NotesState from '../types/NotesState';
import {
  addNewNote,
  editNote,
  getAllNotes,
  getNoteCategories,
  setSelectedChildren,
  setSelectedNoteCategories,
} from '../store';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import NoteSubmitData from '../types/NoteSubmitData';
import noteValidationSchema from '../validation';
import Note from '../types/Note';

type Props = {
  child?: Child;
  note?: Note;
}

const NotesForm: React.FC<Props> = ({ child, note }) => {
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const store = useSelector((state: NotesState) => state.notes);
  const [category, setCategory] = useState<SingleValue<SelectedOptions>>();
  const [selectedChild, setSelectedChild] = useState<SingleValue<SelectedOptions>>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const childrenStore = useSelector((state: ChildrenState) => state.children);
  const visibleRef = useRef<HTMLInputElement>(null);
  const [showNotifyOptions, setShowNotifyOptions] = useState(note?.visible);
  const dispatch = useDispatch<AppDispatch>();
  const { skin } = useSkin();

  const handleGetHtml = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);

    return htmlContent.toString();
  };

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<NoteSubmitData>({
    defaultValues: {
      visible: note?.visible ? '1' : '0',
      notifyByEmail: note?.notifyByEmail ? '1' : '0',
      notifyByPush: note?.notifyByPush ? '1' : '0',
    },
    resolver: yupResolver(noteValidationSchema(selectedChild, category, handleGetHtml(), child)),
  });

  const getFormattedData = (data: NoteSubmitData) => {
    const formattedData = data;
    if (child) {
      formattedData.childId = child.id;
    }
    if (selectedChild?.value) {
      formattedData.childId = +selectedChild.value;
    }

    formattedData.note = handleGetHtml();
    formattedData.file = selectedFile;
    formattedData.date = formatDate(data.date);
    formattedData.visible = (data.visible && data.visible !== '0') ? '1' : '0';

    if (category?.value) {
      formattedData.noteCategoryId = +category.value;
    }

    if (formattedData.visible === '0') {
      delete formattedData.notifyByEmail;
      delete formattedData.notifyByPush;
    } else {
      formattedData.notifyByEmail = (data.notifyByEmail && data.notifyByEmail !== '0') ? '1' : '0';
      formattedData.notifyByPush = (data.notifyByPush && data.notifyByPush !== '0') ? '1' : '0';
    }

    return formattedData;
  };

  const getFormattedDataForEdit = (formattedData: NoteSubmitData) => {
    if (note) {
      formattedData.id = note.id;

      if (note.visible) {
        delete formattedData.visible;
        delete formattedData.notifyByEmail;
        delete formattedData.notifyByEmail;
      }
    }

    return formattedData;
  };

  const successResponse = (isEdit = false) => {
    if (childrenStore.currentChild.id) {
      dispatch(getAllNotes(`childId=${childrenStore.currentChild.id}`));
    } else {
      dispatch(getAllNotes());
    }

    if (isEdit) {
      successToast(t('Note edited successfully'));
    } else {
      successToast(t('Note added successfully'));
    }

    dispatch(closeModal());
  };

  const submitHandler = (data: NoteSubmitData) => {
    let formattedData = getFormattedData(data);

    if (!note) {
      dispatch(addNewNote(formattedData)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successResponse();
        }
      });
    } else {
      formattedData = getFormattedDataForEdit(formattedData);

      dispatch(editNote(formattedData)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successResponse(true);
        }
      });
    }
  };

  useEffect(() => {
    if (note) {
      const contentState = htmlToDraft(note.note);
      const newEditorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(contentState.contentBlocks),
      );

      setEditorState(newEditorState);
    }
  }, []);

  const onFileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;

    if (file) {
      if (['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file?.type ?? '')) {
        clearErrors('file');
        setSelectedFile(file);
      } else {
        setSelectedFile(null);
        setError('file', { type: 'value', message: t('Uploaded file is in unsuported type. Please upload .pdf or .docx file') });
      }
    } else {
      clearErrors('file');
      setSelectedFile(null);
    }
  };

  useEffect(() => {
    if (store.noteCategories.length === 0) {
      dispatch(getNoteCategories());
    }
    if (!child && childrenStore.allChildren.length < 1) {
      dispatch(getAllChildren());
    }
  }, []);

  useEffect(() => {
    dispatch(setSelectedNoteCategories(
      store.noteCategories.map(
        (noteCategory) => ({ label: t(noteCategory.name), value: noteCategory.id }),
      ),
    ));
  }, [store.noteCategories]);

  useEffect(() => {
    if (!child) {
      dispatch(setSelectedChildren(childrenStore.allChildren.map((c) => ({ label: `${c.name} ${c.surname}`, value: c.id }))));
    }
    handleGetHtml();
  }, [childrenStore.allChildren]);

  useEffect(() => {
    if (note) {
      const noteCategory = store.noteCategories.find(
        (noteCat) => noteCat.id === note.noteCategory.id,
      );
      if (noteCategory) {
        setCategory(({ label: t(noteCategory.name), value: noteCategory.id }));
      }
    } else {
      setCategory(store.selectedNoteCategories[0]);
    }
  }, [store.selectedNoteCategories]);

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Row>
        {!child && (
        <Col md={12} className="my-1">
          <CustomLabel name={t('Child')} required />
          <Select
            name="childId"
            className="react-select"
            classNamePrefix="select"
            theme={selectThemeColors}
            placeholder={t('Select')}
            options={store.selectedChildren}
            onChange={(val) => setSelectedChild(val)}
            value={selectedChild}
          />
          <CustomFormFeedback message={errors?.childId?.message} />
        </Col>
        )}
        <Col md={6}>
          <CustomDatePicker
            label={t('Date')}
            name="date"
            control={control}
            setValue={setValue}
            error={errors?.date?.message}
            options={{ minDate: undefined, maxDate: 'today' }}
            required
            defaultValue={note ? (note.date?.toString() ?? '') : new Date(new Date().setHours(0, 0, 0, 0)).toISOString()}
          />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Note category')} required />
          <Select
            name="noteCategoryId"
            className="react-select"
            classNamePrefix="select"
            theme={selectThemeColors}
            placeholder={t('Select')}
            options={store.selectedNoteCategories}
            onChange={(val) => setCategory(val)}
            value={category}
            defaultValue={category}
          />
          <CustomFormFeedback message={errors?.noteCategoryId?.message} />
        </Col>
        <Col md={12}>
          <Controller
            name="note"
            control={control}
            render={() => (
              <Editor
                wrapperStyle={{ borderRadius: '10px', border: '1px solid #3b4253' }}
                editorClassName="px-1"
                editorStyle={{
                  minHeight: '350px',
                }}
                toolbarStyle={{ color: '#000', borderRadius: '10px' }}
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbarClassName={skin === 'dark' ? 'toolbar-dark' : ''}
              />
            )}
          />
          <CustomFormFeedback message={errors?.note?.message} />
        </Col>
        <Col md={12} className="mb-1">
          <CustomLabel name={t('File')} />
          <Input
            id="file"
            type="file"
            invalid={!!errors.file}
            onChange={onFileChangeHandler}
            accept=".pdf, .docx"
          />
          {errors.file && <CustomFormFeedback message={errors.file?.message} />}
          {note?.file && (
          <small className="d-flex" style={{ gap: '10px', color: '#ff9f43', marginTop: '5px' }}>
            {t('Adding a new file will override the old file')}
            <a
              href={note.file}
              className="d-flex align-items-center"
              download
              style={{ width: 'fit-content', gap: '5px' }}
            ><File width="15" height="15" /> {extractFileName(note.file)}
            </a>
          </small>
          )}
        </Col>
        <Col md={12} className="d-flex align-items-center">
          <Controller
            name="visible"
            control={control}
            render={({ field }) => (
              /* eslint-disable-next-line */
                /* @ts-ignore */
              <Input id="visible" type="checkbox" disabled={note?.visible} checked={field.value === true || field.value === '1'} {...field} innerRef={visibleRef} onClick={() => { setShowNotifyOptions(visibleRef.current?.checked ?? false); }} />
            )}
          />
          <CustomLabel name={t('Add note to official reports delivered to parents?')} htmlFor="visible" className="mx-50 my-0" />
        </Col>
        {showNotifyOptions
        && (
        <Row className="mt-1">
          <Col md={6}>
            <Controller
              name="notifyByEmail"
              control={control}
              render={({ field }) => (
                /* eslint-disable-next-line */
                /* @ts-ignore */
                <Input id="notifyByEmail" disabled={note?.visible} checked={field.value === true || field.value === '1'} type="checkbox" {...field} />
              )}
            />
            <CustomLabel name={t('Notify by email')} htmlFor="notifyByEmail" className="mx-50" />
          </Col>

          <Col md={6}>
            <Controller
              name="notifyByPush"
              control={control}
              render={({ field }) => (
                /* eslint-disable-next-line */
                /* @ts-ignore */
                <Input id="notifyByPush" disabled={note?.visible} checked={field.value === true || field.value === '1'} type="checkbox" {...field} />
              )}
            />
            <CustomLabel name={t('Notify by push')} htmlFor="notifyByPush" className="mx-50" />
          </Col>
        </Row>
        )}
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            type="submit"
            className="me-1"
            color="primary"
          >
            {note ? t('Save changes') : t('Add') }
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default NotesForm;
