// ** React Imports
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// ** Icons Imports
import { Disc, X, Circle } from 'react-feather';

// ** Config
import themeConfig from '@configs/themeConfig';

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from '@utils';

import { useSkin } from '@src/utility/hooks/useSkin';

const Toggler = ({menuCollapsed, setMenuCollapsed}) => {
  if (!menuCollapsed) {
    return (
      <Disc
        size={20}
        data-tour="toggle-icon"
        className="text-primary toggle-icon d-none d-xl-block"
        onClick={() => setMenuCollapsed(true)}
      />
    );
  }
  return (
    <Circle
      size={20}
      data-tour="toggle-icon"
      className="text-primary toggle-icon d-none d-xl-block"
      onClick={() => setMenuCollapsed(false)}
    />
  );
};

const VerticalMenuHeader = (props) => {
  // ** Props
  const {
    menuCollapsed, setMenuCollapsed, setMenuVisibility, setGroupOpen, menuHover,
  } = props;

  // ** Vars
  const { skin } = useSkin()
  const user = getUserData();

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([]);
  }, [menuHover, menuCollapsed]);

  // ** Menu toggler component
 

  const [illustration, setIlustration] = useState(
    skin === 'dark' ? 'GoKinder-logo-dark.svg' : 'GoKinder-logo-light.svg')
  const [sourceimg, setSource] = useState(require(`@src/assets/images/logo/${illustration}`).default)
  const [currentSkin, setCurrentSkin] = useState(skin);

  useEffect(() => {
    if (skin !== currentSkin) {
    setIlustration(skin === 'dark' ? 'GoKinder-logo-dark.svg' : 'GoKinder-logo-light.svg');
    }
  }, [skin]);

  useEffect(() => {
    setSource(require(`@src/assets/images/logo/${illustration}`).default)
    setCurrentSkin(skin)
  }, [illustration]);

  return (
    <div className="navbar-header">
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item me-auto">
          <NavLink to={user ? getHomeRouteForLoggedInUser(user.role) : '/'} className="navbar-brand">
            <span className="brand-logo">
              <img src={sourceimg} alt='logo' style={{display: !menuCollapsed ? "flex" : "none"}} />
            </span>
            <h2 className="brand-text mb-0">{themeConfig.app.appName}</h2>
          </NavLink>
        </li>
        <li className="nav-item nav-toggle">
          <div className="nav-link modern-nav-toggle cursor-pointer">
            <Toggler menuCollapsed={menuCollapsed} setMenuCollapsed={setMenuCollapsed} />
            <X onClick={() => setMenuVisibility(false)} className="toggle-icon icon-x d-block d-xl-none" size={20} />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default VerticalMenuHeader;
