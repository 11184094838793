import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const evaluationGroupValidationSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    name: yup.string().required(t('Name is required')),
    evaluationType: yup.object().nullable().required('Type is required').shape({
      value: yup.string().required(t('Type is required')),
    }),
    groupIds: yup.array().min(1, 'At least one group is required'),
  });
  return validation;
};

const evaluationGradeValidaitonSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    one: yup.string().required(t('Field is required')),
    two: yup.string().required(t('Field is required')),
    three: yup.string().required(t('Field is required')),
    four: yup.string().required(t('Field is required')),
    five: yup.string().required(t('Field is required')),
  });
  return validation;
};

const evaluationMetricValidaitonSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    name: yup.string().required(t('Name is required')),
    sort: yup.number().nullable().transform((value, originalValue) => (originalValue === '' ? null : value))
      .min(1, t('Minimum value is 1'))
      .required(t('Field is required')),
  });
  return validation;
};

export {
  evaluationGroupValidationSchema,
  evaluationGradeValidaitonSchema,
  evaluationMetricValidaitonSchema,
};
