/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import User from '../types/User';

const UserColumns = ({ handleEditClick, handleDeleteClick }:
  { handleEditClick: (user: User) => void, handleDeleteClick: (userId: number) => void }) => {
  const { t } = useTranslation();
  const userPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const systemRoles = new Set(['teacher', 'tenantAdmin']);

  const columns: TableColumn<User>[] = [
    {
      name: t('Name'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.name} ${row.surname}`}</p>
      ),
    },
    {
      name: t('Email'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${t(row.email)}`}</p>
      ),
    },
    {
      name: t('Role'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{systemRoles.has(row.role.name) ? t(row.role.name) : row.role.name}</p>
      ),
    },
    {
      name: t('Objects'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{row.objects.map((object) => object.name).join(', ')}</p>
      ),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(userPermission, 'AdministrationUsers')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                color="transparent"
                className="btn btn-icon"
                title={t('Edit user')}
                size="sm"
                onClick={() => handleEditClick(row)}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(userPermission, 'AdministrationUsers')
            ? (

              <Button
                size="sm"
                className="btn btn-icon"
                color="transparent"
                onClick={() => { handleDeleteClick(row.id); }}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];

  return columns;
};

export default UserColumns;
