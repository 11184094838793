import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  Row,
} from 'reactstrap';
import useFilter from '@src/utility/hooks/useFilter';

import TenantGroupState from '@src/views/tenantGroups/types/TenantGroupState';
import TenantObjectState from '@src/views/tenantObjects/types/TenantObjectState';
import ChildrenState from '@src/views/children/types/ChildrenState';
import {
  CalendarMinus,
  Percentage,
  User,
  UserMinus,
} from 'tabler-icons-react';
import { useForm } from 'react-hook-form';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import CustomLabel from '@src/components/forms/CustomLabel';
import CustomFilter from '@src/components/forms/CustomFilter';
import { formatDateToShortDate, getNumberOfWorkingDays } from '@src/utility/Utils';
import { getAllChildren } from '@src/views/children/store';
import { getAllTenantGroups } from '@src/views/tenantGroups/store';
import { getAllTenantObjects } from '@src/views/tenantObjects/store';
import NonWorkingDaysState from '@src/views/nonNonWorkingDays/types/NonWorkingDaysState';
import RecurringNonWorkingDaysState from '@src/views/nonNonWorkingDays/types/RecurringNonWorkingDaysState';
import { getAllNonWorkingDays } from '@src/views/nonNonWorkingDays/store';
import { getAllRecurringNonWorkingDays } from '@src/views/nonNonWorkingDays/store/RecurringNonWorkingDaysStore';
import { AbsenceState } from '../types/AbsenceState';
import { getAllAbsences, setSelectedGroups, setSelectedObjects } from '../store';

interface AbsentChildrenState {
  [key: number]: {
    id: number;
    name: string;
    surname: string;
    count: number;
  };
}

type SubmitData = {
  dateFrom: Date,
  dateTo: Date,
}

const Absences = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const store = useSelector((state: AbsenceState) => state.absences);
  const nonWorkingDaysStore = useSelector(((state: NonWorkingDaysState) => state.nonWorkingDays));
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const recurringNonWorkingDaysStore = useSelector((
    (state: RecurringNonWorkingDaysState) => state.recurringNonWorkingDays));
  const [attendence, setAttendence] = useState<number | undefined>();
  const navigate = useNavigate();
  const [absentChildren, setAbsentChildren] = useState<AbsentChildrenState>();
  const allTenantObjects = useSelector(
    (state: TenantObjectState) => state.tenantObjects.allTenantObjects,
  );
  const allTenantGroups = useSelector(
    (state: TenantGroupState) => state.tenantGroups.allTenantGroups,
  );
  const AllChildren = useSelector(
    (state: ChildrenState) => state.children.allChildren,
  );
  const today = new Date();
  const [dataLoaded, setDataLoaded] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm<SubmitData>();

  const { onFilterChange, query } = useFilter();

  useEffect(() => {
    const currQueryUrl = new URLSearchParams(query);
    const filterObjects = currQueryUrl.get('objectIds')?.split(',');

    if (filterObjects) {
      const filterGroupsOptions = allTenantGroups.filter(
        (group) => filterObjects?.includes(group.object.id.toString()),
      );
      dispatch(setSelectedGroups(filterGroupsOptions.map(
        (group) => ({ label: group.name, value: group.id }),
      )));
    } else {
      dispatch(setSelectedGroups([]));
      onFilterChange('groupIds', []);
    }
  }, [query]);

  const transformObjToSelectObject = (objects: { name: string, id: number }[],
    translate = false) => objects.map(
    (obj) => ({ label: translate ? t(obj.name) : obj.name, value: obj.id }),
  );

  useEffect(() => {
    dispatch(getAllTenantObjects());
    dispatch(getAllTenantGroups());
    dispatch(getAllChildren());
    dispatch(getAllNonWorkingDays());
    dispatch(getAllRecurringNonWorkingDays());
    setDataLoaded(true);
  }, []);

  useEffect(() => {
    dispatch(setSelectedObjects(transformObjToSelectObject(allTenantObjects)));
  }, [allTenantObjects]);

  useEffect(() => {
    function calcAttendance() {
      if (!AllChildren.length) {
        setAttendence(0);
        return;
      }
      const queryUrl = new URLSearchParams(query);
      const dateFromString = queryUrl.get('dateFrom')?.split(',')[0];
      const dateToString = queryUrl.get('dateTo')?.split(',')[0];
      if (!dateFromString) {
        setAttendence(undefined);
        return;
      }

      const dateFrom = new Date(dateFromString);
      const dateTo = dateToString ? new Date(dateToString) : today;
      const recurringNonWorkingDays = recurringNonWorkingDaysStore
        .allRecurringNonWorkingDays.map((val) => val.day);
      const nonWorkingDays = nonWorkingDaysStore.allNonWorkingDays.map((val) => new Date(val.date));
      recurringNonWorkingDays.push(0);
      recurringNonWorkingDays.push(6);

      const days = getNumberOfWorkingDays(dateFrom,
        dateTo, recurringNonWorkingDays, nonWorkingDays) + 1;

      const attendenceDecimal = (1 - (store.allAbsences.length
        / (AllChildren.length * days))) * 100;

      setAttendence(Math.floor(attendenceDecimal));
    }

    function getAbsentChildren() {
      const childredHash: AbsentChildrenState = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const storeAbsence of store.allAbsences) {
        if (childredHash[storeAbsence.child.id]) {
          childredHash[storeAbsence.child.id].count += 1;
        } else {
          childredHash[storeAbsence.child.id] = {
            id: storeAbsence.child.id,
            name: storeAbsence.child.name,
            surname: storeAbsence.child.surname,
            count: 1,
          };
        }
      }

      setAbsentChildren(childredHash);
    }
    if (dataLoaded) {
      calcAttendance();
      getAbsentChildren();
    }
  }, [AllChildren, store.allAbsences]);

  const handleSuccessfulSubmit = async () => {
    dispatch(getAllAbsences(query));
    dispatch(getAllChildren(query));
    setShow(true);
  };

  useEffect(() => {
    const data = getValues();
    const dataFrom = new Date(data.dateFrom);
    if (loaded) {
      onFilterChange('dateFrom', formatDateToShortDate(dataFrom));
    }
    setLoaded(true);
  }, [watch('dateFrom')]);

  useEffect(() => {
    const data = getValues();
    const dataTo = new Date(data.dateTo);
    if (loaded) {
      onFilterChange('dateTo', formatDateToShortDate(dataTo));
    }
    setLoaded(true);
  }, [watch('dateTo')]);

  useEffect(() => {
    onFilterChange('dateFrom', formatDateToShortDate(today));
    setTimeout(() => {
      onFilterChange('dateTo', formatDateToShortDate(today));
    }, 300);
  }, []);

  return (
    <div>
      <Card>
        <CardHeader className="border-bottom d-flex">
          <div className="mt-2">
            <CardTitle tag="h4">{t('Absence report')}</CardTitle>
            <CardSubtitle className="mt-1">{t('See absence report by object, group and date')}</CardSubtitle>
          </div>
        </CardHeader>
        <CardBody style={{ paddingTop: '1rem' }}>
          <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
            <Row>
              <Col md={4} key="objectIds" className="pb-50">
                <CustomLabel name={t('Object')} />
                <CustomFilter
                  options={store.selectedObjects ?? []}
                  name="objectIds"
                  onChange={onFilterChange}
                />
              </Col>
              { store.selectedGroups.length ? (
                <Col md={4} key="groupIds" className="pb-50">
                  <CustomLabel name={t('Group')} />
                  <CustomFilter
                    options={store.selectedGroups ?? []}
                    name="groupIds"
                    onChange={onFilterChange}
                  />
                </Col>
              ) : ''}
              <Col md={4}>
                <CustomDatePicker<SubmitData>
                  control={control}
                  error={errors?.dateFrom?.message}
                  setValue={setValue}
                  label={t('Date from')}
                  name="dateFrom"
                  options={{ minDate: undefined, maxDate: watch('dateTo') }}
                  defaultValue={today}
                />
                <CustomFormFeedback message={errors?.dateFrom?.message} />
              </Col>
              <Col md={4}>
                <CustomDatePicker<SubmitData>
                  control={control}
                  error={errors?.dateTo?.message}
                  setValue={setValue}
                  label={t('Date to')}
                  name="dateTo"
                  options={{ minDate: watch('dateFrom'), maxDate: today }}
                  defaultValue={today}
                />
                <CustomFormFeedback message={errors?.dateTo?.message} />
              </Col>
              <Col md={4} style={{ flexDirection: 'column' }} className="d-flex">
                <CustomLabel style={{ opacity: '0' }} name="show" />
                <Button
                  type="submit"
                  color="primary"
                  style={{ width: '100%' }}
                >
                  {t('Show')}
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      {show ? (
        <Card>
          <CardBody>
            <div>
              <p className="detail-wrapper">
                <span style={{ color: '#7DCBEC' }}><User size={20} />&nbsp;</span>
                {t('Total number of children')}:&nbsp;
                {AllChildren.length}
              </p>
              <p className="detail-wrapper">
                <span style={{ color: '#7DCBEC' }}><CalendarMinus size={20} />&nbsp;</span>
                {t('Total number of absences')}:&nbsp;
                {store.allAbsences.length}
              </p>
              <p className="detail-wrapper">
                <span style={{ color: '#7DCBEC' }}><Percentage size={20} />&nbsp;</span>
                {t('Atendance')}:&nbsp;
                {attendence}%
              </p>
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p className="detail-wrapper">
                  <span style={{ color: '#7DCBEC' }}><UserMinus size={20} />&nbsp;</span>
                  {t('Absent')}:
                </p>
                {
                  absentChildren && Object.keys(absentChildren)
                    .map((childId) => {
                      const child = absentChildren[parseInt(childId, 10)];
                      return (
                        <Button
                          key={child.id}
                          outline
                          color="outline"
                          onClick={() => navigate(`/children/${child.id}`)}
                          style={{
                            margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
                          }}
                        >
                          <p
                            className="detail-wrapper cursor-pointer"
                            style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                          >
                            <span style={{ textDecoration: 'underline' }}>{`${child.name} ${child.surname}`}</span> &nbsp; {`${t('Total absences').toLowerCase()}: ${child.count}`}
                          </p>
                        </Button>
                      );
                    })
                }
              </div>
            </div>
          </CardBody>
        </Card>
      ) : (
        <div />
      )}
    </div>
  );
};

export default Absences;
