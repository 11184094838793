import {
  Card, CardBody, Col, Button,
  Row,
  CardSubtitle,
} from 'reactstrap';
import {
  Trash,
} from 'react-feather';
import defaultAvatar from '@src/assets/images/avatars/avatar-blank.png';
import { getUserAccessTokenData } from '@src/utility/Utils';
import { useSkin } from '@src/utility/hooks/useSkin';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { AvatarGroup, Avatar, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';

import AnnouncementModal from '@src/views/announcements/components/AnnouncementModal';
import { deleteRole, getAllRoles } from '../store';
import RoleForm from './RoleForm';
import Role from '../types/Role';
import UsersModal from './UsersModal';

const RoleCard = ({ role }: { role: Role }) => {
  const dispatch = useDispatch<AppDispatch>();
  const userTokenData = getUserAccessTokenData();
  const { t } = useTranslation();
  const { skin } = useSkin();
  const navigate = useNavigate();
  const rolePermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const formatRoleDescription = (text: string) => {
    // eslint-disable-next-line no-restricted-globals
    const { width } = screen;

    if (text.length > 40 && width >= 1400) {
      return ` ${text.slice(0, 40)}...`;
    }
    if (text.length > 22 && width < 1400) {
      return ` ${text.slice(0, 22)}...`;
    }
    return text;
  };

  const handleDeleteClick = (roleId: number) => {
    dispatch(
      openModal({
        Component: ConfirmationModalWrapper,
        title: t('Are you sure?'),
        subtitle: t('Role will be deleted'),
        open: true,
        componentProps: {
          handleConfirm: async () => {
            try {
              await dispatch(deleteRole(roleId));
            } finally {
              successToast(t('Role successfully deleted'));
              dispatch(getAllRoles());
              dispatch(closeModal());
            }
          },
        },
      }),
    );
  };

  const handleUsersClick = () => {
    dispatch(
      openModal({
        Component: UsersModal,
        title: `${t('Users')} - ${role.name}`,
        open: true,
        componentProps: {
          users: role.users,
        },
      }),
    );
  };

  const handleContentClick = (content: string) => {
    dispatch(openModal({
      Component: AnnouncementModal,
      componentProps: { content },
      title: t('Content'),
      open: true,
    }));
  };

  const disabledColor = skin === 'dark' ? '#444' : '#cccccc';
  const canEdit = userTokenData.role === 'superAdmin' || userTokenData.sub === role.createdById;

  return (
    <Col md={4}>
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <h6>{t('Users')}: {role.users.length}</h6>
              </div>
            </Col>
            <Col md={6}>
              <AvatarGroup spacing="small" slotProps={{ additionalAvatar: { onClick: () => handleUsersClick(), style: { cursor: 'pointer' } } }}>
                {
                  role.users.map((user) => (
                    <Tooltip title={`${user.name} ${user.surname}`} onClick={() => navigate(`/users/${user.id}`)} key={user.id} style={{ cursor: 'pointer' }}>
                      <Avatar src={user.profileImage ? user.profileImage : defaultAvatar} />
                    </Tooltip>
                  ))
                }
                {
                  !role.users.length && (
                    <Avatar style={{ visibility: 'hidden' }} />
                  )
                }
              </AvatarGroup>
            </Col>
          </Row>
          <Col>
            <h4>{role.name}</h4>
            <Button
              outline
              color="outline"
              onClick={() => handleContentClick(role.description ?? '')}
              style={{
                margin: 0, padding: 0, color: 'blue', fontWeight: 'inherit',
              }}
            >
              <CardSubtitle>
                {formatRoleDescription(role.description ?? '')}
              </CardSubtitle>
            </Button>
          </Col>
          <div className="w-full d-flex justify-content-between">
            <div>
              {isManagePermissionActive(rolePermission, 'AdministrationRoles')
                ? (
                  <Button
                    color="outline m-0 p-0"
                    style={{
                      border: 'none', color: !canEdit ? disabledColor : '#7DCBEC', fontSize: '18px', fontWeight: 'bold',
                    }}
                    disabled={!canEdit}
                    onClick={() => {
                      dispatch(openModal({
                        Component: RoleForm,
                        componentProps: { role },
                        title: t('Edit role'),
                        open: true,
                        modalProps: { size: 'lg' },
                      }));
                    }}
                  >
                    {t('Edit')}
                  </Button>
                ) : <></>}
            </div>
            {isDeletePermissionActive(rolePermission, 'AdministrationRoles')
              ? (
                <Button
                  style={{ border: 'none', color: !canEdit ? disabledColor : '' }}
                  disabled={!canEdit}
                  color="outline m-0 p-0"
                  onClick={() => handleDeleteClick(role.id)}
                ><Trash />
                </Button>
              ) : <></>}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default RoleCard;
