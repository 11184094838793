import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { Button } from 'reactstrap';
import { Plus } from 'react-feather';
import useFilter from '@src/utility/hooks/useFilter';
import ChildrenState from '@src/views/children/types/ChildrenState';
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import RequestStatus from '@src/types/RequestStatus';
import {
  allInvoices,
  clearInvoices,
  deleteInvoice,
  getAllInvoices,
  getInvoiceStatuses,
  getInvoiceTypes,
  selectedInvoiceStatuses,
  selectedInvoiceType,
} from '../store';
import InvoiceColumns from './InvoiceColumns';
import InvoiceState from '../types/InvoiceState';

const FinancesTab = () => {
  const childStore = useSelector((state: ChildrenState) => state.children);
  const invoiceStore = useSelector((state: InvoiceState) => state.invoice);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const invoicePermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [dataLoaded, setDataLoaded] = useState(false);
  const { onFilterChange, query } = useFilter();

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(clearInvoices());
    };
  }, []);

  useEffect(() => {
    if (childStore.currentChild.id) {
      onFilterChange('childId', String(childStore.currentChild.id));
    }
  }, [childStore.currentChild.id]);

  useEffect(() => {
    dispatch(getInvoiceTypes());
    dispatch(getInvoiceStatuses());
    setDataLoaded(true);
  }, []);

  useEffect(() => {
    dispatch(selectedInvoiceType(invoiceStore.invoiceTypes.map(
      (type) => ({ label: t(`${type}`), value: type }),
    )));
  }, [invoiceStore.invoiceTypes]);

  useEffect(() => {
    dispatch(selectedInvoiceStatuses(invoiceStore.invoiceStatuses.map(
      (status) => ({ label: t(`${status}`), value: status }),
    )));
  }, [invoiceStore.invoiceStatuses]);

  const handleDeleteClick = (id: number) => {
    try {
      dispatch(deleteInvoice(id)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          const filteredInvoices = invoiceStore.allInvoices.filter((item) => item.id !== id);
          dispatch(allInvoices(filteredInvoices));
          toast.success(t('Invoice successfully deleted'), { position: 'top-right', duration: 3000 });
        }
      });
    } catch (e) {
      toast.error('Error while deleting invoices');
    }
  };

  const onFilterClick = () => dispatch(getAllInvoices(query));

  return (
    <div>
      <DataTableWrapper
        exportToExcel="invoice-exports"
        dataSource={invoiceStore.allInvoices}
        title={t('Invoices')}
        subtitle={t('See, manage and filter invoice table')}
        columns={InvoiceColumns({ handleDeleteClick })}
        dataLoaded={dataLoaded}
        onChange={onFilterChange}
        onFilterClick={onFilterClick}
        query={query}
        showSearch={false}
        filters={[{
          label: 'Invoice type', name: 'invoiceType', options: invoiceStore.selectedInvoiceType,
        },
        {
          label: 'Paid status', name: 'paidStatus', options: invoiceStore.selectedInvoiceStatuses,
        },
        {
          label: 'Date from', name: 'startDate', datePicker: 'full-date',
        },
        {
          label: 'Date to', name: 'endDate', datePicker: 'full-date',
        },
        ]}
        functionButton={(
          isManagePermissionActive(invoicePermissions, 'Invoices')
            ? (
              <Button
                color="primary"
                className="text-nowrap mb-1 mt-2"
                outline
                onClick={() => { navigate(`/invoice/${childStore.currentChild.id}/new`); }}
              >
                <div className="d-flex justify-content-center">
                  <Plus size={15} />
                  &nbsp;
                  <span>{t('Add new invoice')}</span>
                </div>
              </Button>
            ) : <></>
        )}
      />
    </div>
  );
};

export default FinancesTab;
