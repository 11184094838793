import FileUploader from '@src/components/file-uploader/FileUploader';
import FileType from '@src/components/file-uploader/types/FileType';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import RequestStatus from '@src/types/RequestStatus';
import { successToast } from '@src/components/wrappers/ToastMessages';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { File } from 'react-feather';
import { extractFileName } from '@src/utility/Utils';
import { closeModal } from '@store/modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { Controller, useForm } from 'react-hook-form';
import { addNewFile, getFilesByChildId, editFile } from '../store';
import ChildrenFileSubmitData from '../types/ChildrenFileSubmitData';
import ChildrenState from '../types/ChildrenState';
import ChildrenFile from '../types/ChildrenFile';
import { childrenFileValidationSchema } from '../validation';

const ChildrenFileForm = ({ file } : { file?: ChildrenFile }) => {
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: ChildrenState) => state.children);
  const [files, setFiles] = useState<FileType[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm<ChildrenFileSubmitData>({
    defaultValues: {
      title: '',
    },
    resolver: yupResolver(childrenFileValidationSchema(newFiles, !!file)),
  });

  const acceptFiles: {
    [key: string]: string[];
  } = useMemo(() => ({
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    'video/mp4': ['.mp4'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/pdf': ['.pdf'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  }), []);

  const successResponse = () => {
    if (!file) {
      successToast(t('File added successfully'));
    } else {
      successToast(t('File edited successfully'));
    }

    dispatch(getFilesByChildId(store.currentChild.id));
    dispatch(closeModal());
  };

  const submitHandler = (data: ChildrenFileSubmitData) => {
    data.file = newFiles?.[0];
    const formData = new FormData();
    formData.append('title', data.title);
    if (data.file) formData.append('file', data.file);

    if (!file) {
      dispatch(addNewFile({ id: store.currentChild.id, data: formData })).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successResponse();
        }
      });
    } else {
      dispatch(editFile({ id: file.id, data })).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successResponse();
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getFilesByChildId(store.currentChild.id));
  }, []);

  useEffect(() => {
    reset({
      title: file?.title,
    });
  }, []);

  useEffect(() => {
    if (newFiles?.length) {
      trigger(['file']);
    }
  }, [newFiles]);

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Row>
        <Col md={6}>
          <CustomLabel name={t('Title')} htmlFor="title" required />
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <Input id="title" invalid={!!errors.title} {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.title?.message} />
        </Col>
        <Col md={12}>
          <CustomLabel name={t('Media')} required />
          {file ? (
            <small className="d-flex" style={{ gap: '10px', color: '#ff9f43', marginTop: '5px' }}>
              <a
                href={file.filePath}
                className="d-flex align-items-center"
                download
                style={{ width: 'fit-content', gap: '5px' }}
              ><File width="15" height="15" />
                {`${extractFileName(file.filePath)}`}
              </a>
            </small>
          )
            : (
              <Controller
                name="file"
                control={control}
                render={() => (
                  <FileUploader
                    acceptFiles={acceptFiles}
                    newFiles={newFiles}
                    setNewFiles={setNewFiles}
                    maxFiles={1}
                    files={files}
                    setFiles={setFiles}
                  />
                )}
              />
            )}
          {errors.file
            && <p style={{ fontSize: '0.9rem' }} className="text-center text-danger">{errors.file.message}</p>}
        </Col>
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            className="me-1"
            color="primary"
            type="submit"
          >
            {file ? t('Save changes') : t('Add') }
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ChildrenFileForm;
