import {
  Button, CardHeader, CardSubtitle, CardTitle,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Plus } from 'tabler-icons-react';
import { AppDispatch } from '@store/store';
import { openModal } from '@store/modal';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import AnnouncementForm from './AnnouncementForm';

const NewAnnouncementCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const announcementPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  return (
    <CardHeader className="border-bottom d-flex">
      <div className="mt-2">
        <CardTitle tag="h4">{t('Announcement overview')}</CardTitle>
        <CardSubtitle className="mt-1">{t('View, add and edit announcement')}</CardSubtitle>
      </div>
      {isManagePermissionActive(announcementPermission, 'Announcements')
        ? (
          <Button
            color="primary"
            className="text-nowrap mb-1 mt-2"
            outline
            onClick={() => {
              dispatch(openModal({
                Component: AnnouncementForm,
                title: t('Add new announcement'),
                open: true,
                modalProps: { size: 'lg' },
              }));
            }}
          >
            <div className="d-flex justify-content-center">
              <Plus size={15} />
              &nbsp;
              <span>{t('Add Announcement')}</span>
            </div>
          </Button>
        ) : <></>}
    </CardHeader>
  );
};

export default NewAnnouncementCard;
