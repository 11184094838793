import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { closeModal, openModal } from '@store/modal';
import { useTranslation } from 'react-i18next';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import RequestStatus from '@src/types/RequestStatus';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { Button } from 'reactstrap';
import { Edit, RotateCw, Trash } from 'react-feather';
import { useEffect, useState } from 'react';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { deleteImage, getAlbumById, rotateImage } from '../store';
import EditPhotoForm from './EditPhotoForm';
import AlbumFile from '../types/AlbumFile';
import { ModalState } from './AlbumThumbnail';

type Props = {
  file: AlbumFile;
}

const ImageThumbnail:React.FC<Props> = ({ file }) => {
  const [isHovered, setIsHovered] = useState(false);
  const modalState = useSelector((state: ModalState) => state.modal.modals);
  const [filePath, setFilePath] = useState(file.path);
  const galleryPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const onEditClickHandler = (albumFile: AlbumFile) => {
    dispatch(
      openModal({
        Component: EditPhotoForm,
        title: t('Edit photo'),
        open: true,
        componentProps: {
          file: albumFile,
        },
      }),
    );
  };

  const onDeleteClickHandler = (albumFile: AlbumFile) => {
    dispatch(
      openModal({
        Component: ConfirmationModalWrapper,
        title: t('Are you sure?'),
        subtitle: `${t('File')}: "${albumFile.path.split('/').pop()?.split('-').slice(1)
          .join('-') ?? ''}" ${t('will be deleted.')}`,
        open: true,
        componentProps: {
          handleConfirm: async () => {
            dispatch(deleteImage(albumFile.id)).then((res) => {
              if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
                successToast(t('File successfully deleted'));
                dispatch(getAlbumById(albumFile.albumId));
                dispatch(closeModal());
              }
            });
          },
        },
      }),
    );
  };

  const onRotateClickHandler = (albumFile: AlbumFile) => {
    dispatch(rotateImage(albumFile.id)).then((res) => {
      if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
        setFilePath(`${albumFile.path}?${new Date().getTime()}`);
        successToast(t('Successfully rotated image'));
      }
    });
  };

  const onMouseLeaveHandler = () => {
    if (modalState.length === 0) {
      setIsHovered(false);
    }
  };

  useEffect(() => {
    if (modalState.length === 0) {
      setIsHovered(false);
    }
  }, [modalState.length]);

  return (
    <div
      key={file.id}
      className={`album-preview-wrapper ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => onMouseLeaveHandler()}
      role="group"
    >
      <img
        alt="img in album"
        className="background-image"
        src={filePath}
        style={{ objectFit: 'cover' }}
      />
      <div className="item">
        {isManagePermissionActive(galleryPermission, 'Gallery')
          ? (
            <>
              <Button color="outline" onClick={() => onEditClickHandler(file)} className="m-0 px-0">
                <Edit width="35" height="35" color="#fff" strokeWidth="1.5px" />
              </Button>
              <Button color="outline" onClick={() => onRotateClickHandler(file)} className="m-0 px-0">
                <RotateCw width="35" height="35" color="#fff" strokeWidth="1.5px" />
              </Button>
            </>
          ) : <></>}
        {isDeletePermissionActive(galleryPermission, 'Gallery')
          ? (
            <Button color="outline" onClick={() => onDeleteClickHandler(file)} className="m-0 px-0">
              <Trash width="35" height="35" color="#fff" strokeWidth="1.5px" />
            </Button>
          ) : <></>}

      </div>
    </div>
  );
};

export default ImageThumbnail;
