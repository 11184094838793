import {
  Button,
  Col, Form, Input, Row,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Controller, useForm } from 'react-hook-form';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { useTranslation } from 'react-i18next';
import { closeModal } from '@store/modal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { yupResolver } from '@hookform/resolvers/yup';
import RequestStatus from '@src/types/RequestStatus';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';
import * as yup from 'yup';
import { formatDateToShortDate } from '@src/utility/Utils';
import { addMultipleAbsences } from '../store';

const MultipleAbsencesModal = ({ childIds = [] } : { childIds: number[] }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ reason: string, date: Date, justified: string }>({
    defaultValues: {
      reason: '',
      date: new Date(),
      justified: '1',
    },
    resolver: yupResolver(
      yup.object().shape({
      }),
    ),
  });

  const submitHandler = async (data: {
    reason: string, date: Date, justified: string
   }) => {
    const formattedData: any = {
      reason: data.reason,
      childIds,
      date: formatDateToShortDate(data.date),
      justified: data.justified,
    };

    dispatch(addMultipleAbsences(formattedData)).then((res) => {
      if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
        dispatch(closeModal());
        successToast(t('Absences successfully added'));
      }
    });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(submitHandler)}>
        <Row>
          <Col md={4} className="mb-1">
            <CustomDatePicker
              control={control}
              required
              label={t('Date')}
              error={errors?.date?.message}
              setValue={setValue}
              name="date"
              defaultValue={new Date()}
              options={{ minDate: undefined }}
            />
            {errors?.date?.message
            && <CustomFormFeedback message={errors.date.message} />}
          </Col>
          <Col md={12}>
            <CustomLabel name={t('Reason')} htmlFor="reason" />
            <Controller
              name="reason"
              control={control}
              render={({ field }) => (
                <Input id="reason" type="textarea" invalid={!!errors.reason} {...field} />
              )}
            />
            <CustomFormFeedback message={errors?.reason?.message} />
          </Col>
          <Col md={12} className="d-flex align-items-center mt-1">
            <Controller
              name="justified"
              control={control}
              render={({ field }) => (
                /* eslint-disable-next-line */
                    /* @ts-ignore */
                <Input id="visible" type="checkbox" checked={field.value === true || field.value === '1'} {...field} />
              )}
            />
            <CustomLabel name={t('Justified?')} htmlFor="visible" className="mx-50 my-0" />
          </Col>
          <Col xs={12} className="text-center mt-2 pt-50">
            <Button
              type="submit"
              className="me-1"
              color="primary"
            >
              {t('Add') }
            </Button>
            <Button
              type="reset"
              color="secondary"
              outline
              onClick={() => dispatch(closeModal())}
            >
              {t('Close')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MultipleAbsencesModal;
