import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Button, Col, Row } from 'reactstrap';
import EvaluationGrade from '../types/EvaluationGrade';
import EvaluationGradeForm from './EvaluationGradeForm';

const EvaluationGradeItem = ({ item, handleDelete } : {
  item: EvaluationGrade, handleDelete: (id: number) => void }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      {isEditing ? (
        <EvaluationGradeForm
          evaluationGroupId={String(item.evaluationGroupId)}
          item={item}
          setIsEditing={setIsEditing}
        />
      ) : (
        <Row>
          <Col md={2}>
            <CustomLabel name={t('One')} />
            <p>{item.one}</p>
          </Col>
          <Col md={2}>
            <CustomLabel name={t('Two')} />
            <p>{item.two}</p>
          </Col>
          <Col md={2}>
            <CustomLabel name={t('Three')} />
            <p>{item.three}</p>
          </Col>
          <Col md={2}>
            <CustomLabel name={t('Four')} />
            <p>{item.four}</p>
          </Col>
          <Col md={2}>
            <CustomLabel name={t('Five')} />
            <p>{item.five}</p>
          </Col>
          <Col md={2}>
            <CustomLabel style={{ opacity: 0 }} name={t('')} />
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Button color="primary" onClick={() => setIsEditing(true)} style={{ marginRight: '4px' }}>{t('Edit')}</Button>
              <Button color="red" onClick={() => handleDelete(item.id)}>{t('Delete')}</Button>
            </Col>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default EvaluationGradeItem;
