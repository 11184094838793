import ReactSelect, { MultiValue, PropsValue, SingleValue } from 'react-select';
import { Col, Label } from 'reactstrap';
import { selectThemeColors } from '@src/utility/Utils';
import SelectedOptions from '@src/types/SelectedOptions';
import { Dispatch, SetStateAction } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Option from './Option';

type Props = {
  tag: SingleValue<SelectedOptions>;
  tagByOptions: SelectedOptions[];
  onChangeTagByHandler: (newTag: SingleValue<SelectedOptions>) => void;
  defaultValue?: PropsValue<SelectedOptions>;
  tagLabel: string;
  tagOptions: SelectedOptions[];
  setSelectedValues: Dispatch<SetStateAction<MultiValue<SelectedOptions> | undefined>>;
  selectedValues: MultiValue<SelectedOptions> | undefined;
  errors: FieldError | undefined;
}

const TagsSelect: React.FC<Props> = ({
  tagLabel,
  tagOptions,
  setSelectedValues,
  selectedValues,
  errors,
  tag,
  tagByOptions,
  onChangeTagByHandler,
  defaultValue,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Col md={12} className="mt-1">
        <Label>{t('Tag by')}</Label>
        <ReactSelect
          name="tags"
          className="react-select"
          classNamePrefix="select"
          theme={selectThemeColors}
          options={tagByOptions}
          onChange={onChangeTagByHandler}
          value={tag}
          isClearable
          defaultValue={defaultValue}
        />
      </Col>
      {tag
        && (
          <Col md={12} className="mt-1">
            <Label>{tagLabel}</Label>
            <ReactSelect
              name="tagIds"
              className="react-select"
              classNamePrefix="select"
              theme={selectThemeColors}
              isMulti
              closeMenuOnSelect={false}
              options={tagOptions}
              onChange={(val) => setSelectedValues(val as MultiValue<SelectedOptions>)}
              value={selectedValues}
              components={{ Option }}
            />
            {errors
              && <p className="text-center text-danger" style={{ fontSize: '0.9rem' }}>{errors.message}</p>}
          </Col>
        )}
    </>
  );
};

export default TagsSelect;
