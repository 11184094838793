/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import Payment from '../types/Payment';

export const formatDateLocalDateString = (date: string | Date) => {
  const dateToFrormat = new Date(date);
  return dateToFrormat.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
};

enum PAID_STATUS {
  Unrecorded= 'NEPROKNJIŽENO',
  Recorded= 'PROKNJIŽENO',
}

const InvoiceColumns = ({ handleDeleteClick, handleEditClick }:
  { handleDeleteClick: (paymentId: number) => void,
     handleEditClick: (payment: Payment) => void }) => {
  const { t } = useTranslation();
  const paymentPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const columns: TableColumn<Payment>[] = [
    {
      name: t('Invoice'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.referenceNumber}`}</p>
      ),
    },
    {
      name: t('Child'),
      minWidth: '150px',
      cell: (row) => (
        <a
          style={{ gap: '10px', width: 'fit-content' }}
          className="d-flex align-items-center"
          href={`${window.location.origin}/children/${row.childId}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {`${row.child.name} ${row.child.surname}`}
        </a>
      ),
    },
    {
      name: t('Payment date'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${formatDateLocalDateString(row.date)}`}</p>
      ),
    },
    {
      name: t('Amount'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.amount}rsd`}</p>
      ),
    },
    {
      name: t('Note'),
      minWidth: '250px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{t(`${row.note}`)}</p>
      ),
    },
    {
      name: t('Status'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>
          {PAID_STATUS.Recorded === row.status && <span style={{ color: 'Green' }}>{t('PROKNJIŽENO')}</span>}
          {PAID_STATUS.Unrecorded === row.status && <span style={{ color: 'Red' }}>{t('NEPROKNJIŽENO')}</span>}
        </p>
      ),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(paymentPermission, 'Payments')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                size="sm"
                color="transparent"
                className="btn btn-icon"
                title={t('Edit object')}
                onClick={() => { handleEditClick(row); }}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(paymentPermission, 'Payments')
            ? (
              <Button
                size="sm"
                color="transparent"
                className="btn btn-icon"
                onClick={() => { handleDeleteClick(row.id); }}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];

  return columns;
};

export default InvoiceColumns;
