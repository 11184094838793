import { Plus } from 'tabler-icons-react';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { openModal } from '@store/modal';
import { updateCurrentParent } from '@src/views/parents/store';
import ModalWrapperProps from '../wrappers/ModalWrapperProps';

export interface ShowModalProps{
  modalProps: ModalWrapperProps
  title: string;
}

const ShowModalButton = ({ modalProps, title }: ShowModalProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const onClickHandler = () => {
    dispatch(updateCurrentParent(undefined));
    dispatch(openModal(modalProps));
  };

  return (
    <div className="card-wrapper">
      <Button
        outline
        color="primary"
        onClick={() => onClickHandler()}
      >
        <div className="d-flex justify-content-center">
          <Plus size={15} />&nbsp;
          <span>{title}</span>
        </div>
      </Button>
    </div>
  );
};

export default ShowModalButton;
