import { Col, Input, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { Action } from '@src/types/Permission';
import { useTranslation } from 'react-i18next';
import Permission from '../types/Permission';

interface PermissionSelectProps {
  permissionName: string,
  permissions: Permission[],
  selectedPermissions: Set<string>,
}

const PermissionSelect = (
  { permissionName, permissions, selectedPermissions } : PermissionSelectProps,
) => {
  const [toogle, setToogle] = useState(false);
  const viewPermission = permissions.find((permission) => permission.action === Action.VIEW);
  const managePermission = permissions.find((permission) => permission.action === Action.MANAGE);
  const deletePermission = permissions.find((permission) => permission.action === Action.DELETE);
  const [viewName, setViewName] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (viewPermission) {
      setViewName(viewPermission.viewName);
    } else if (managePermission) {
      setViewName(managePermission.viewName);
    } else if (deletePermission) {
      setViewName(deletePermission.viewName);
    }
  }, []);

  const handleSelectedPermissions = (id: string) => {
    if (selectedPermissions.has(id)) {
      selectedPermissions.delete(id);
      if (viewPermission && (String(viewPermission.id) === id)) {
        if (deletePermission) selectedPermissions.delete(String(deletePermission.id));
        if (managePermission) selectedPermissions.delete(String(managePermission.id));
      }
    } else {
      selectedPermissions.add(id);
      if (viewPermission && (String(viewPermission.id) !== id)) {
        selectedPermissions.add(String(viewPermission.id));
      }
    }

    setToogle(!toogle);
  };

  return (
    <>
      <Row key={permissionName} md={12} style={{ marginBottom: '16px' }}>
        <Col md={6}>
          <h5>{t(viewName)}</h5>
        </Col>
        <Col md={6}>
          <Row md={12}>
            <Col md={4}>
              {
                viewPermission ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Input type="checkbox" onChange={() => handleSelectedPermissions(String(viewPermission.id))} checked={selectedPermissions.has(String(viewPermission.id))} />
                  </div>
                ) : <></>
              }
            </Col>
            <Col md={4}>
              {
                managePermission ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Input type="checkbox" onChange={() => handleSelectedPermissions(String(managePermission.id))} checked={selectedPermissions.has(String(managePermission.id))} />
                  </div>
                ) : <></>
              }
            </Col>
            <Col md={4}>
              {
                deletePermission ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Input type="checkbox" onChange={() => handleSelectedPermissions(String(deletePermission.id))} checked={selectedPermissions.has(String(deletePermission.id))} />
                  </div>
                ) : <></>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      <hr key={`${permissionName}-hr`} />
    </>
  );
};

export default PermissionSelect;
