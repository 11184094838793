import {
  Button,
  Col, Form, Row,
} from 'reactstrap';
import { useEffect } from 'react';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Controller, useForm } from 'react-hook-form';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { useTranslation } from 'react-i18next';
import { closeModal } from '@store/modal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { yupResolver } from '@hookform/resolvers/yup';
import RequestStatus from '@src/types/RequestStatus';
import * as yup from 'yup';
import SelectedOptions from '@src/types/SelectedOptions';
import Select from 'react-select';
import TenantGroupState from '@src/views/tenantGroups/types/TenantGroupState';
import { getAllTenantGroups } from '@src/views/tenantGroups/store';
import { getAllChildren, massGroupChange, setSelectedGroups } from '../store';
import ChildrenState from '../types/ChildrenState';

const MassGroupChangeModal = ({ childIds = [] } : {
   childIds: string[] }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const store = useSelector((state: ChildrenState) => state.children);
  const allTenantGroups = useSelector(
    (state: TenantGroupState) => state.tenantGroups.allTenantGroups,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ groupId: SelectedOptions }>({
    resolver: yupResolver(
      yup.object().shape({
      }),
    ),
  });

  useEffect(() => {
    if (!childIds?.length) dispatch(closeModal());
    dispatch(getAllTenantGroups());
  }, []);

  useEffect(() => {
    dispatch(setSelectedGroups(allTenantGroups.map(
      (group) => ({ label: group.name, value: group.id }),
    )));
  }, [allTenantGroups]);

  const submitHandler = async (data: { groupId: SelectedOptions }) => {
    const formattedData: any = {
      childIds: childIds.map((val) => Number(val)),
      groupId: Number(data.groupId?.value),
    };

    dispatch(massGroupChange(formattedData)).then((res) => {
      if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
        dispatch(closeModal());
        successToast(t('Group successfully changed'));
        dispatch(getAllChildren());
      }
    });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(submitHandler)}>
        <Row>
          <Col md={12}>
            <CustomLabel name={t('Group')} htmlFor="groupId" />
            <Controller
              name="groupId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={store.selectedGroups}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                />
              )}
            />
            <CustomFormFeedback message={errors?.groupId?.message} />
          </Col>
          <Col xs={12} className="text-center mt-2 pt-50">
            <Button
              type="submit"
              className="me-1"
              color="primary"
            >
              {t('Add') }
            </Button>
            <Button
              type="reset"
              color="secondary"
              outline
              onClick={() => dispatch(closeModal())}
            >
              {t('Close')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MassGroupChangeModal;
