import { useTranslation } from 'react-i18next';

const NoTableData = ({ searched } : { searched: boolean }) => {
  const { t } = useTranslation();
  return (
    <p className="p-1">{searched ? t('There are no records to display') : ''}</p>
  );
};

export default NoTableData;
