import { useEffect, useState } from 'react';
import {
  Button, Col, Form, Input, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import RequestStatus from '@src/types/RequestStatus';
import toast from 'react-hot-toast';
import { closeModal } from '@store/modal';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import CustomLabel from '@src/components/forms/CustomLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import Role from '../types/Role';
import RoleState from '../types/RoleState';
import {
  createNewRole, editRole, getAllPermissions, getAllRoles,
} from '../store';
import Permission from '../types/Permission';
import PermissionSelect from './PermissionSelect';
import roleValidationSchema from '../validation';

const RoleForm = ({ role } : { role?: Role }) => {
  const roleStore = useSelector((store: RoleState) => store.roles);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const initialPermissions = role
    ? role.permissions.map((permission) => String(permission.id)) : [];
  const selectedPermissions = new Set(initialPermissions);
  const [permissions, setPermissions] = useState<{[key: string]: Permission[]}>({});

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { name: role ? role.name : '', permissions: selectedPermissions },
    resolver: yupResolver(roleValidationSchema(selectedPermissions)),
  });

  useEffect(() => {
    const permissionsIterator: {[key: string]: Permission[]} = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const permission of roleStore.allPermissions) {
      if (permissionsIterator[permission.name]) {
        permissionsIterator[permission.name].push(permission);
      } else {
        permissionsIterator[permission.name] = [permission];
      }
    }
    setPermissions(permissionsIterator);
  }, [roleStore.allPermissions]);

  useEffect(() => {
    dispatch(getAllPermissions());
  }, []);

  const handleSuccessfulSubmit = async (data: { name: string }) => {
    const submitData: any = {};
    submitData.name = data.name;
    submitData.permissionIds = [...selectedPermissions];
    if (role) {
      const res = await dispatch(editRole({ id: role.id, data: submitData }));
      if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
        toast.success(t('Role successfully edited'), { position: 'top-right', duration: 3000 });
        dispatch(getAllRoles());
        dispatch(closeModal());
      }
    } else {
      const res = await dispatch(createNewRole(submitData));
      if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
        toast.success(t('Role successfully added'), { position: 'top-right', duration: 3000 });
        dispatch(getAllRoles());
        dispatch(closeModal());
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)} style={{ paddingTop: '16px' }}>
      <Col md={6}>
        <CustomLabel name={t('Name')} required />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input id="name" invalid={!!errors.name} {...field} />
          )}
        />
        <CustomFormFeedback message={errors?.name?.message} />
      </Col>
      <Row md={12}>
        <Col md={6}>
          <h3>{t('Role Permissions')}</h3>
        </Col>
        <Col>
          <Row md={12} style={{ textAlign: 'center' }}>
            <Col md={4}>
              {t('View')}
            </Col>
            <Col md={4}>
              {t('Edit')}
            </Col>
            <Col md={4}>
              {t('Delete')}
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      {Object.keys(permissions).map((permission) => (
        <PermissionSelect
          key={permission}
          permissionName={permission}
          permissions={permissions[permission]}
          selectedPermissions={selectedPermissions}
        />
      ))}
      <CustomFormFeedback message={errors?.permissions?.message} />
      <Row md={12} style={{ marginTop: '32px' }}>
        <Col md={4} />
        <Col md={4}>
          <Button
            type="submit"
            color="primary"
            style={{ minWidth: '100%' }}
          >
            {role ? t('Save') : t('Add')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RoleForm;
