import { Controller, useForm } from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import {
  Button, Col, FormFeedback, Input, Label, Row,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import CustomLabel from '@src/components/forms/CustomLabel';
import { closeModal } from '@store/modal';
import { useTranslation } from 'react-i18next';
import { successToast } from '@src/components/wrappers/ToastMessages';
import RecurringNonWorkingDay from '../types/RecurringNonWorkingDay';
import NonWorkingDay from '../types/NonWorkingDay';
import EditNonWorkingDaySubmitData from '../types/EditNonWorkingDaySubmitData';
import { getAllNonWorkingDays, updateNonWorkingDay } from '../store';
import { getAllRecurringNonWorkingDays, updateRecurringNonWorkingDay } from '../store/RecurringNonWorkingDaysStore';

type Props = {
  nonWorkingDay?: NonWorkingDay,
  recurringNonWorkingDay?: RecurringNonWorkingDay,
}

const EditNonWorkingDayForm:React.FC<Props> = ({ nonWorkingDay, recurringNonWorkingDay }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EditNonWorkingDaySubmitData>({
    defaultValues: {
      note: nonWorkingDay?.note ? nonWorkingDay.note : (recurringNonWorkingDay?.note ?? ''),
    },
  });

  const submitHandler = (data: EditNonWorkingDaySubmitData) => {
    if (nonWorkingDay) {
      dispatch(updateNonWorkingDay(
        { id: nonWorkingDay?.id.toString(), note: data.note },
      )).finally(() => {
        successToast(t('Successfully edited non-working day'));
        dispatch(getAllNonWorkingDays());
        dispatch(closeModal());
      });
    } else if (recurringNonWorkingDay) {
      dispatch(updateRecurringNonWorkingDay(
        { id: recurringNonWorkingDay?.id.toString(), note: data.note },
      )).finally(() => {
        successToast(t('Successfully edited recurring non-working day'));
        dispatch(getAllRecurringNonWorkingDays());
        dispatch(closeModal());
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Row>
        {nonWorkingDay
          && (
            <Col md={6}>
              <Label>{t('Date')}</Label>
              <Flatpickr
                readOnly
                disabled
                className="form-control full-opacity"
                value={nonWorkingDay?.date}
              />
            </Col>
          )}
      </Row>
      <Col md={12} className="mt-1">
        <CustomLabel name={t('Note')} />
        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <Input
              type="textarea"
              rows="4"
              id="description"
              maxLength={250}
              invalid={errors.note !== undefined}
              {...field}
            />
          )}
        />
        {errors.note && <FormFeedback className="text-center">{errors.note.message}</FormFeedback>}
      </Col>
      <Col md={12} className="text-center mt-2 pt-50">
        <Button
          className="me-1"
          color="primary"
          type="submit"
        >
          {t('Save changes')}
        </Button>
        <Button
          type="reset"
          color="secondary"
          outline
          onClick={() => dispatch(closeModal())}
        >
          {t('Close')}
        </Button>
      </Col>
    </form>
  );
};

export default EditNonWorkingDayForm;
