import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { successToast } from '@src/components/wrappers/ToastMessages';
import RequestStatus from '@src/types/RequestStatus';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { createNewEvaluationGrades, editEvaluationGrade, getAllEvaluationGrades } from '../store';
import EvaluationGrade from '../types/EvaluationGrade';
import EvaluationGradeSubmitData from '../types/EvaluationGradeSubmitData';
import { evaluationGradeValidaitonSchema } from '../validation';

const EvaluationGradeForm = ({ evaluationGroupId, item, setIsEditing } : {
   evaluationGroupId: string, item?: EvaluationGrade,
    setIsEditing: (editing: boolean) => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EvaluationGradeSubmitData>({
    defaultValues: {
      one: item?.one ?? '',
      two: item?.two ?? '',
      three: item?.three ?? '',
      four: item?.four ?? '',
      five: item?.five ?? '',
      evaluationGroupId: Number(evaluationGroupId),
    },
    resolver: yupResolver(
      evaluationGradeValidaitonSchema(),
    ),
  });

  const handleSuccess = (hasItem: boolean) => {
    if (hasItem) {
      successToast(t('Grade successfully edited'));
    } else {
      successToast(t('Grade successfully added'));
    }

    dispatch(getAllEvaluationGrades(`evaluationGroupIds=${evaluationGroupId}`));
    setIsEditing(false);
  };

  const handleSuccessfulSubmit = async (data: any) => {
    if (item) {
      dispatch(editEvaluationGrade({ id: item.id, data })).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          handleSuccess(true);
        }
      });
    } else {
      dispatch(createNewEvaluationGrades(data)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          handleSuccess(false);
        }
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={2}>
          <CustomLabel name={t('One')} required />
          <Controller
            name="one"
            control={control}
            render={({ field }) => (
              <Input id="one" invalid={!!errors.one} {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.one?.message} />
        </Col>
        <Col md={2}>
          <CustomLabel name={t('Two')} required />
          <Controller
            name="two"
            control={control}
            render={({ field }) => (
              <Input id="two" invalid={!!errors.two} {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.two?.message} />
        </Col>
        <Col md={2}>
          <CustomLabel name={t('Three')} required />
          <Controller
            name="three"
            control={control}
            render={({ field }) => (
              <Input id="three" invalid={!!errors.three} {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.three?.message} />
        </Col>
        <Col md={2}>
          <CustomLabel name={t('Four')} required />
          <Controller
            name="four"
            control={control}
            render={({ field }) => (
              <Input id="four" invalid={!!errors.four} {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.four?.message} />
        </Col>
        <Col md={2}>
          <CustomLabel name={t('Five')} required />
          <Controller
            name="five"
            control={control}
            render={({ field }) => (
              <Input id="five" invalid={!!errors.five} {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.five?.message} />
        </Col>
        <Col md={2}>
          <CustomLabel style={{ opacity: 0 }} name={t('')} />
          <Col
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Button
              type="submit"
              outline
              color="primary"
              disabled={false}
            >
              {item ? t('Save') : t('Add')}
            </Button>
            <Button color="red" onClick={() => setIsEditing(false)}>{t('Close')}</Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};

export default EvaluationGradeForm;
