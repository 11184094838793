import {
  Row, Col, Input, Button, Form, Spinner, Label,
} from 'reactstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Plus, Trash } from 'react-feather';
import { AppDispatch } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '@store/modal';
import CustomLabel from '@src/components/forms/CustomLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDatePicker, { todayDate } from '@src/components/wrappers/CustomDatePicker';
import Select, { MultiValue } from 'react-select';
import '@styles/react/libs/react-select/_react-select.scss';
import { selectThemeColors } from '@src/utility/Utils';
import SelectedOptions from '@src/types/SelectedOptions';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import {
  addNewPoll, editPoll, getPolls, getAllObjects, selectedObjects, getAllGroups, selectedGroups,
} from '../store';
import {
  PollsFormProps,
  SubmitData,
  NewPollPayload,
  Answer,
  NewAnswers,
  PollsState,
  SelectedGroupOptions,
} from '../types';
import validationSchema from '../validation';
// helpers
const defaultAnswers = [{ answer: '' }, { answer: '' }];
export const pollDateFormat = 'YYYY-MM-DD';
export const formatDate = (date: string) => moment(date).format(pollDateFormat).toString();
const calculateDefaultDate = (date: string | undefined, fallback: string) => (
  date ? formatDate(date) : fallback);

const PollsForm = ({ edit, poll }: PollsFormProps) => {
  const store = useSelector((state: PollsState) => state.polls);
  const { startDate: pollStartDate, endDate: pollEndDate } = poll ?? {};
  const [loading, setIsLoading] = useState(false);
  const [objects, setObjects] = useState<MultiValue<SelectedOptions>>();
  const [groups, setGroups] = useState<MultiValue<SelectedGroupOptions>>();
  const { t } = useTranslation();

  const defaultStartDate = calculateDefaultDate(pollStartDate, todayDate);
  const defaultEndDate = calculateDefaultDate(
    pollEndDate,
    moment(new Date())
      .add({
        day: 1,
      })
      .format(pollDateFormat)
      .toString(),
  );

  // form setup
  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<SubmitData>({
    defaultValues: {
      question: poll?.question ?? '',
      answers: poll?.answers ?? defaultAnswers,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    },
    resolver: yupResolver(validationSchema()),
  });

  const { fields, append, remove } = useFieldArray({
    name: 'answers',
    control,
  });

  const handleAddNewButtonCLick = () => append({ answer: '' });

  const dispatch = useDispatch<AppDispatch>();

  const onSuccess = async () => {
    try {
      await dispatch(getPolls());
    } catch (error) {
      toast.error('Error while fetching polls');
    } finally {
      setIsLoading(false);
      dispatch(closeModal());
    }
  };

  const handleSuccessfulSubmit = async (data: SubmitData) => {
    setIsLoading(true);
    const {
      question, startDate, endDate, answers,
    } = data;

    const values = {
      question,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      objects: objects?.map((obj) => ({
        objectId: obj.value,
        groups: groups?.filter((group) => group.objectId === obj.value)
          .map((group) => group.value) ?? [],
      })) ?? [],
    };

    try {
      if (edit && poll?.id !== undefined) {
        const currentAnswers: Array<Answer> = answers.filter(
          (answer) => 'id' in answer,
        ) as Array<Answer>;

        const newAnswers: NewAnswers = answers
          .filter((answer) => !('id' in answer))
          .map((answer) => ({
            answer: answer.answer,
          })) as unknown as NewAnswers;

        await dispatch(
          editPoll({
            ...values,
            id: poll.id,
            currentAnswers,
            newAnswers: newAnswers as unknown as NewAnswers,
          }),
        );
        onSuccess();
      } else {
        const newPollPayload: NewPollPayload = {
          ...values,
          answers: answers?.map((answer) => answer.answer),
        };
        await dispatch(addNewPoll(newPollPayload));
        onSuccess();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const confirmHandler = async () => {
    trigger().then((res) => {
      if (res && (!objects || objects.length === 0)
        && (!poll || (poll && poll.objects.length > 0))) {
        dispatch(openModal({
          Component: ConfirmationModalWrapper,
          componentProps: {
            handleConfirm: () => {
              handleSubmit(handleSuccessfulSubmit)();
              dispatch(closeModal());
            },
            confirmButtonText: poll ? 'Save changes' : 'Add',
            closeButtonText: 'Cancel',
          },
          open: true,
          title: t('Warning'),
          subtitle: t('You have not selected a specific object nor a group. Please note, this poll will be visible to all parents. Are you sure you want to create this poll?'),
          modalProps: {
            size: 'm',
          },
        }));
      } else {
        handleSubmit(handleSuccessfulSubmit)();
      }
    });
  };

  useEffect(() => {
    dispatch(getAllObjects());
    dispatch(getAllGroups());
  }, []);

  useEffect(() => {
    dispatch(selectedObjects(
      store?.objectsByTenant.map((object) => ({ value: object.id, label: object.name })),
    ));
  }, [store?.objectsByTenant]);

  useEffect(() => {
    // Filter groups to show only for selected object
    dispatch(selectedGroups(
      store?.groupsByTenant
        .filter((group) => objects?.some((obj) => obj.value === group.objectId))
        .map((group) => ({ value: group.id, objectId: group.objectId, label: group.name })),
    ));

    // Remove selected groups if object containing selected groups is removed
    if (groups && groups.length !== 0) {
      setGroups(
        groups.filter((group) => objects?.some((object) => object.value === group.objectId)),
      );
    }
  }, [objects]);

  // Set inital object and group values when editing poll
  useEffect(() => {
    if (poll?.objects) {
      setObjects(store?.objectsByTenant
        .filter((object) => poll.objects.some((p) => p.objectId === object.id))
        .map((object) => ({ value: object.id, label: object.name })));

      setGroups(
        store?.groupsByTenant
          .filter((group) => poll.objects
            .some((obj) => obj.groups.some((g) => g.groupId === group.id)))
          .map((group) => ({ value: group.id, objectId: group.objectId, label: group.name })),
      );
    }
  }, [store?.objectsByTenant, store?.groupsByTenant, poll?.objects]);

  return (
    <Form>
      <Row className="d-flex align-items-center gy-1">
        <Col xs={12}>
          <CustomLabel name={t('Question')} required htmlFor="question" />
          <Controller
            name="question"
            control={control}
            render={({ field }) => (
              <Input id="question" invalid={!!errors.question} {...field} />
            )}
          />
        </Col>
        <CustomFormFeedback message={errors?.question?.message} />
        {fields.map((item, i: number) => (
          <Row key={item.id} className="pe-0">
            <CustomLabel
              name={`${t('Answer')} #${i + 1}`}
              required
              htmlFor={`answers.${i}.answer`}
              className="mb-0.5 mt-1"
            />
            <Col xs={9} sm="10" className="pe-0">
              <Controller
                name={`answers.${i}.answer`}
                control={control}
                render={({ field }) => (
                  <Input
                    id={`answers.${i}.answer`}
                    invalid={!!errors?.answers?.[i]?.answer}
                    {...field}
                  />
                )}
              />
              <CustomFormFeedback
                message={errors?.answers?.[i]?.answer?.message}
              />
            </Col>
            <Col xs={2} sm={1} className="d-flex align-items-center mb-2">
              <Button
                size="sm"
                color="transparent"
                className="btn btn-buttonType d-flex mx-1"
                onClick={() => remove(i)}
              >
                <Trash size={20} />
              </Button>
            </Col>
          </Row>
        ))}
        {errors?.answers?.message && (
          <CustomFormFeedback message={errors?.answers?.message} />
        )}
        <div className="mb-1">
          <Button
            color="link"
            className="px-0 outline-none"
            onClick={handleAddNewButtonCLick}
          >
            <div className="d-flex justify-text-center">
              <Plus size={15} />
              &nbsp;
              <span>{t('Add answer')}</span>
            </div>
          </Button>
        </div>
        <Col>
          <CustomDatePicker
            label={t('Start Date')}
            name="startDate"
            control={control}
            error={errors?.startDate?.message}
            setValue={setValue}
            defaultValue={defaultStartDate}
            required
          />
          <div className="my-1">
            <CustomDatePicker
              defaultValue={defaultEndDate}
              label={t('End Date')}
              name="endDate"
              control={control}
              error={errors?.endDate?.message}
              setValue={setValue}
              required
            />
          </div>
        </Col>
        <Col md={12} className="mt-1">
          <Label>{t('Objects')}</Label>
          <Select
            name="objects"
            className="react-select"
            classNamePrefix="select"
            theme={selectThemeColors}
            placeholder={t('Select')}
            isMulti
            options={store.selectedObjects}
            onChange={(val) => setObjects(val)}
            value={objects}
          />
        </Col>
        {!!objects?.length
          && (
            <Col md={12} className="mt-1">
              <Label>{t('Groups')}</Label>
              <Select
                name="groups"
                className="react-select"
                classNamePrefix="select"
                theme={selectThemeColors}
                placeholder={t('Select')}
                isMulti
                options={store.selectedGroups}
                onChange={(val) => setGroups(val)}
                value={groups}
              />
            </Col>
          )}
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            disabled={loading}
            className="me-1"
            color="primary"
            onClick={confirmHandler}
          >
            {loading && <Spinner size="sm" className="mr-1" />} {t('Save changes')}
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default PollsForm;
