import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col, Form, Input, Spinner,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { closeModal } from '@store/modal';
import { yupResolver } from '@hookform/resolvers/yup';
import Select, { SingleValue } from 'react-select';
import { selectThemeColors } from '@src/utility/Utils';
import SelectedOptions from '@src/types/SelectedOptions';
import PackageFormProps from '../types/PackageFormProps';
import PackageState from '../types/PackageState';
import SubmitData from '../types/SubmitData';
import packageValidationSchema from '../validation';
import { addNewPakage, editPackage, selectedCurrencies } from '../store';

const PackagesForm = ({ edit, pack }: PackageFormProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedCurrency, setSelectedCurrency] = useState<SingleValue<SelectedOptions>>();

  const store = useSelector((state: PackageState) => state.packages);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SubmitData>({
    defaultValues: {
      name: pack?.name ?? '',
      price: pack?.price,
      cityReport: pack?.cityReport ?? 0,
    },
    resolver: yupResolver(packageValidationSchema(
      selectedCurrency as SingleValue<SelectedOptions>,
    )),
  });

  const handleSuccessfulSubmit = async (data: SubmitData) => {
    setLoading(true);

    if (!selectedCurrency) {
      return;
    }

    const formattedData = {
      name: data.name,
      price: (+data.price).toFixed(2),
      currencyId: +selectedCurrency.value,
      cityReport: (+data.cityReport).toString(),
    };

    if (!edit) {
      dispatch(addNewPakage(formattedData))
        .finally(() => {
          setLoading(false);
          dispatch(closeModal());
        });
    } else if (pack) {
      dispatch(editPackage({ id: pack.id, ...formattedData }))
        .finally(() => {
          setLoading(false);
          dispatch(closeModal());
        });
    }
  };

  useEffect(() => {
    dispatch(selectedCurrencies(
      store.allCurrencies.map((currency) => ({ label: currency.cc, value: currency.id })),
    ));
  }, [store.allCurrencies]);

  useEffect(() => {
    if (!edit) {
      setSelectedCurrency(store.selectedCurrencies[0]);
    }
  }, [store.selectedCurrencies]);

  useEffect(() => {
    if (pack) {
      const currentCurrency = store.allCurrencies.find(
        (currency) => currency.id === pack.currency.id,
      );

      if (currentCurrency) {
        setSelectedCurrency({ value: currentCurrency.id, label: currentCurrency.cc });
      }
    } else {
      setSelectedCurrency({ value: store.allCurrencies[0].id, label: store.allCurrencies[0].cc });
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Col xs={12}>
        <CustomLabel required htmlFor="name" name={t('Name')} />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input id="name" invalid={!!errors.name} {...field} />
          )}
        />
      </Col>
      <CustomFormFeedback message={errors?.name?.message} />
      <Col xs={12}>
        <CustomLabel name={t('Price')} required htmlFor="price" />
        <Controller
          name="price"
          control={control}
          render={({ field }) => (
            <Input id="price" type="number" invalid={!!errors.price?.message} {...field} />
          )}
        />
      </Col>
      <CustomFormFeedback message={errors?.price?.message} />
      <Col xs={12}>
        <CustomLabel name={t('Currency')} required htmlFor="currency" />
        <Select
          name="currency"
          className="react-select"
          classNamePrefix="select"
          theme={selectThemeColors}
          placeholder={t('Select')}
          options={store.selectedCurrencies}
          onChange={(val) => setSelectedCurrency(val)}
          value={selectedCurrency}
        />
      </Col>
      <CustomFormFeedback message={errors?.currencyId?.message} />
      <Col xs={12}>
        <Controller
          name="cityReport"
          control={control}
          render={({ field }) => (
            <Input id="cityReport" type="checkbox" checked={!!field.value} {...field} />
          )}
        />
        <CustomLabel name={t('City report')} htmlFor="cityReport" className="mx-50" />
      </Col>
      <Col xs={12} className="text-center mt-2 pt-50">
        <Button
          disabled={loading}
          type="submit"
          className="me-1"
          color="primary"
        >
          {loading && <Spinner size="sm" className="mr-1" />} {t('Save changes')}
        </Button>
        <Button
          type="reset"
          color="secondary"
          outline
          onClick={() => dispatch(closeModal())}
        >
          {t('Close')}
        </Button>
      </Col>
    </Form>
  );
};

export default PackagesForm;
