import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { Button } from 'reactstrap';
import { successToast } from '@src/components/wrappers/ToastMessages';
import RequestStatus from '@src/types/RequestStatus';
import { useTranslation } from 'react-i18next';
import { deleteEvaluationMetric, getAllEvaluationMetrics, setAllEvaluationMetrics } from '../store';
import EvaluationMetricItem from './EvaluationMetricItem';
import EvaluationMetricForm from './EvaluationMetricForm';
import EvaluationState from '../types/EvaluationState';

const EvaluationMetricItems = ({ evaluationGroupId } : { evaluationGroupId: string }) => {
  const dispatch = useDispatch<AppDispatch>();
  const evaluationsStore = useSelector((store: EvaluationState) => store.evaluations);
  const [isAdding, setIsAdding] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllEvaluationMetrics(`evaluationGroupIds=${evaluationGroupId}`));
  }, []);

  const handleDelete = async (id: number) => {
    dispatch(deleteEvaluationMetric(id)).then((res) => {
      if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
        successToast(t('Metric successfully deleted'));
        const filteredEvaluaitonMetrics = evaluationsStore
          .allEvaluationMetrics.filter((item) => item.id !== id);
        dispatch(setAllEvaluationMetrics(filteredEvaluaitonMetrics));
      }
    });
  };

  return (
    <div>
      <div style={{ margin: '14px 0' }}>
        {evaluationsStore.allEvaluationMetrics.map((item) => (
          <EvaluationMetricItem
            key={item.id}
            evaluationMetricItem={item}
            handleDelete={handleDelete}
          />
        ))}
        {
          (evaluationsStore.allEvaluationMetrics.length === 0 && !isAdding)
            ? <p>{t('Metrics are not defined')}</p> : <></>
        }
      </div>
      {isAdding ? (
        <EvaluationMetricForm evaluationGroupId={evaluationGroupId} setIsEditing={setIsAdding} />
      ) : <Button color="primary" onClick={() => setIsAdding(true)}>{t('Add metric')}</Button>}
    </div>
  );
};

export default EvaluationMetricItems;
