import { useEffect, useState } from 'react';
import { Card, Row } from 'reactstrap';
import PostPagination from '@src/views/posts/components/PostPagination';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { getAllContracts } from '../store';
import ContractState from '../types/ContractState';
import Contract from '../types/Contract';
import NewContractCard from '../components/NewContractCard';
import ContractCard from '../components/ContractCard';

const Contracts = () => {
  const dispatch = useDispatch<AppDispatch>();
  const contractStore = useSelector((store: ContractState) => store.contract);

  const [contracts, setContracts] = useState<Contract[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [loadOnce, setLoadOnce] = useState(false);
  const postPerPage = 6;

  useEffect(() => {
    setContracts(contractStore?.allContracts.slice(((activePage - 1) * postPerPage),
      postPerPage * activePage));
    if ((activePage - 1) * postPerPage === contractStore?.allContracts.length) {
      setActivePage(activePage === 1 ? 1 : activePage - 1);
    } else if (loadOnce) {
      setActivePage(1);
    }
    if (contractStore?.allContracts.length) {
      setLoadOnce(true);
    }
  }, [contractStore?.allContracts]);

  useEffect(() => {
    setContracts(contractStore?.allContracts.slice(((activePage - 1) * postPerPage),
      postPerPage * activePage));
  }, [activePage]);

  useEffect(() => {
    dispatch(getAllContracts());
  }, []);

  return (
    <>
      <Card>
        <NewContractCard />
      </Card>
      <Row>
        {contracts.map((item) => <ContractCard key={item.id} details={item} />)}
      </Row>
      {contractStore?.allContracts?.length > 0
        && (
          <PostPagination
            totalPages={Number(Math.ceil(contractStore?.allContracts?.length / postPerPage) || 1)}
            activePage={activePage}
            setActive={setActivePage}
          />
        )}
    </>
  );
};

export default Contracts;
