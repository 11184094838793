import { DateSelectArg } from '@fullcalendar/react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col, FormFeedback, Input, Label, Row,
} from 'reactstrap';
import { closeModal } from '@store/modal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import CustomLabel from '@src/components/forms/CustomLabel';
import Flatpickr from 'react-flatpickr';
import { formatDate } from '@src/views/polls/components/PollsForm';
import { useMemo } from 'react';
import { getDatesInRange, getOneDayEarlierFormatted } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import { successToast } from '@src/components/wrappers/ToastMessages';
import ChildrenState from '@src/views/children/types/ChildrenState';
import { AbsenceSubmitData } from '../types/AbsenceSubmitData';
import { Absence } from '../types/Absence';
import { createMultipleAbsences, getAbsencesByChildId, updateAbsence } from '../store';

type Props = {
  selectedDates?: DateSelectArg,
  absence?: Absence;
}

const AbsenceForm: React.FC<Props> = ({ selectedDates, absence }) => {
  const { t } = useTranslation();
  const currentChild = useSelector((state: ChildrenState) => state.children.currentChild);
  const dispatch = useDispatch<AppDispatch>();
  const startDate = useMemo(() => {
    if (selectedDates) {
      return selectedDates.start;
    }
    if (absence) {
      return absence.date;
    }

    return new Date();
  }, []);

  const defaultValues = {
    reason: absence ? (absence.reason ?? '') : '',
    justified: '1',
  };

  if (absence) {
    defaultValues.justified = absence?.justified ? '1' : '0';
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AbsenceSubmitData>({
    defaultValues,
  });

  const submitHandler = (data: AbsenceSubmitData) => {
    data.justified = (data.justified && data.justified !== '0') ? '1' : '0';

    if (absence) {
      data.id = absence.id;
      data.date = formatDate(new Date(absence.date).toISOString());
      dispatch(updateAbsence(data)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successToast(t('Successfully updated absence'));
          dispatch(getAbsencesByChildId(+absence.childId));
          dispatch(closeModal());
        }
      });
    } else if (selectedDates) {
      const datesArray = getDatesInRange(
        selectedDates.start, new Date(getOneDayEarlierFormatted(selectedDates.end)),
      );

      data.dates = datesArray.map((date) => formatDate(date.toISOString()));
      data.childId = currentChild.id;
      dispatch(createMultipleAbsences(data)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successToast(t('Successfully added absences'));
          dispatch(getAbsencesByChildId(currentChild.id));
          dispatch(closeModal());
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Row>
        <Col md={6}>
          <Label>{selectedDates?.endStr ? t('From') : t('Date')} </Label>
          <Flatpickr
            readOnly
            disabled
            className="form-control full-opacity"
            value={startDate}
          />
        </Col>
        {selectedDates?.endStr
        && (
        <Col md={6}>
          <CustomLabel name={t('To')} />
          <Flatpickr
            readOnly
            disabled
            className="form-control full-opacity"
            value={new Date(getOneDayEarlierFormatted(selectedDates.end))}
          />
        </Col>
        )}
      </Row>
      <Col md={12} className="mt-1">
        <CustomLabel name={t('Reason')} />
        <Controller
          name="reason"
          control={control}
          render={({ field }) => (
            <Input
              type="textarea"
              rows="4"
              id="description"
              maxLength={250}
              invalid={errors.reason !== undefined}
              {...field}
            />
          )}
        />
        {errors.reason && <FormFeedback className="text-center">{errors.reason.message}</FormFeedback>}
      </Col>
      <Col md={12} className="d-flex align-items-center mt-1">
        <Controller
          name="justified"
          control={control}
          render={({ field }) => (
            /* eslint-disable-next-line */
                /* @ts-ignore */
            <Input id="visible" type="checkbox" checked={field.value === true || field.value === '1'} {...field} />
          )}
        />
        <CustomLabel name={t('Justified?')} htmlFor="visible" className="mx-50 my-0" />
      </Col>
      <Col md={12} className="text-center mt-2 pt-50">
        <Button
          className="me-1"
          color="primary"
          type="submit"
        >
          {absence ? t('Save changes') : t('Add')}
        </Button>
        <Button
          type="reset"
          color="secondary"
          outline
          onClick={() => dispatch(closeModal())}
        >
          {t('Close')}
        </Button>
      </Col>
    </form>
  );
};

export default AbsenceForm;
