// ** React Imports
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'

// ** Custom Components
import Avatar from '@components/avatar';

// ** Utils
import { isUserLoggedIn } from '@utils';

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux';
import { handleLogout } from '@store/authentication';

// ** Third Party Components
import { Power,
} from 'tabler-icons-react';

// ** Reactstrap Imports
import {
  UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem,
} from 'reactstrap';

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/avatars/avatar-blank.png';
import useJwt from '@src/auth/jwt/useJwt';
import { openModal } from '@store/modal';
import ChangePassword from '@src/views/authentication/ChangePassword';
import { IconLock } from '@tabler/icons-react';

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch();
  const authStore = useSelector(state => state.auth)

  // ** State
  const [userData, setUserData] = useState(null);

  // ** Hooks
  const { t } = useTranslation();

  //* * ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(authStore.userData);
    }
  }, []);

  //* * Vars
  const userAvatar = (userData && userData.avatar) || defaultAvatar;

  const handleLogoutClick = () => {
    useJwt
      .logout()
      .then(() => {
        dispatch(handleLogout());
      })
      .catch(() => {
        dispatch(handleLogout());
      });
  };

  const handleChangePasswordClick = () => dispatch(openModal({
    Component: ChangePassword,
    title: t('Change password'),
    open: true,
    modalProps: { size: 'md' },
  }));

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle href="/" tag="a" className="nav-link dropdown-user-link" onClick={(e) => e.preventDefault()}>
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">{(userData && userData.name) || ' '}</span>
        </div>
        <Avatar img={userAvatar} imgHeight="40" imgWidth="40" status="online" />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem onClick={handleChangePasswordClick}>
          <IconLock size={16} className="me-75" />
          <span className="align-middle">{t('Change password')}</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/login" onClick={handleLogoutClick}>
          <Power size={16} className="me-75" />
          <span className="align-middle">{t('Logout')}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserDropdown;
