import { Avatar } from '@mui/material';
import defaultAvatar from '@src/assets/images/avatars/avatar-blank.png';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

interface UsersModalProps {
  users: {
    name: string;
    surname: string;
    id: string;
    profileImage?: string;
  }[]
}

const UsersModal = ({ users } : UsersModalProps) => {
  const navigate = useNavigate();

  return (
    <Row>
      {
        users.map((user) => (
          <Col md={12} key={user.id}>
            <Button
              outline
              color="outline"
              onClick={() => navigate(`$/users/${user.id}`)}
              style={{
                margin: '0 0 14px',
                padding: 0,
                color: 'inherit',
                fontWeight: 'inherit',
                textAlign: 'center',
                cursor: 'pointer',
                borderColor: 'white',
                display: 'flex',
                alignItems: 'center',
                columnGap: '14px',
              }}
            >
              <Avatar src={user.profileImage ? user.profileImage : defaultAvatar} />
              <div>
                <p style={{ overflow: 'hidden', margin: 0 }}>{`${user.name} ${user.surname}`}</p>
              </div>
            </Button>
          </Col>
        ))
      }
    </Row>
  );
};

export default UsersModal;
