import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import CustomLabel from '@src/components/forms/CustomLabel';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { Button, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { closeModal } from '@store/modal';
import RequestStatus from '@src/types/RequestStatus';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { editImage, getAlbumById } from '../store';
import AlbumFile from '../types/AlbumFile';

type Props = {
  file: AlbumFile;
}

const EditPhotoForm: React.FC<Props> = ({ file }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{description: string}>({
    defaultValues: { description: file.description ?? '' },
  });

  const submitHandler = ({ description }: {description: string}) => {
    dispatch(editImage({ id: file.id, description })).then((res) => {
      if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
        successToast(t('Successfully edited image'));
        dispatch(getAlbumById(file.albumId));
        dispatch(closeModal());
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <CustomLabel name={t('Description')} />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <Input
            type="textarea"
            rows="4"
            id="description"
            maxLength={500}
            invalid={errors.description !== undefined}
            {...field}
          />
        )}
      />
      <CustomFormFeedback message={errors.description?.message} />
      <div className="text-center mt-2">
        <Button
          type="submit"
          className="me-1"
          color="primary"
        >
          {t('Save changes')}
        </Button>
        <Button color="secondary" outline onClick={() => dispatch(closeModal())}>
          {t('Close')}
        </Button>
      </div>
    </form>
  );
};

export default EditPhotoForm;
