import * as yup from 'yup';
import CustomPhoneRegex from '@src/components/forms/CustomPhoneReg';
import { useTranslation } from 'react-i18next';
import User from '../types/User';

const AddParentValidationSchema = (parents: User[]) => {
  const { t } = useTranslation();
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(t('Name is required')),
    surname: yup
      .string()
      .required(t('Surname is required')),
    contractNumber: yup
      .number()
      .integer()
      .nullable()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .min(0, t('Positive number is required'))
      .typeError(t('Positive number is required')),
    email: yup
      .string()
      .email(t('Enter email in valid format'))
      .required(t('Email is required'))
      .test('unique', t('Value must be unique'), (value) => !parents.some((p) => p.email === value)),
    phoneNumber: yup
      .string()
      .nullable()
      .matches(
        CustomPhoneRegex,
        t('Please enter contact number in valid format'),
      )
      .nullable(),
    embg: yup
      .string()
      .test('unique', t('Value must be unique'), (value) => !parents.some((p) => value && p.embg === value))
      .nullable(),
    bankAccountNumber: yup
      .string()
      .test('unique', t('Value must be unique'), (value) => !parents.some((p) => value && p.bankAccountNumber === value))
      .nullable(),
  });
  return validationSchema;
};
export default AddParentValidationSchema;
