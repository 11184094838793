import moment from 'moment';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import {
  Edit, Delete,
  File,
} from 'react-feather';
import {
  BuildingCommunity,
  CalendarTime, Notes, User,
} from 'tabler-icons-react';
import { IconListDetails, IconUsersGroup } from '@tabler/icons-react';
import { extractFileName, getUserAccessTokenData } from '@src/utility/Utils';
import { useSkin } from '@src/utility/hooks/useSkin';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';

import Announcement from '../types/Announcement';
import AnnouncementModal from './AnnouncementModal';
import { deleteAnnouncement, getAllAnnouncements } from '../store';
import AnnouncementForm from './AnnouncementForm';

const AnnouncementCard = ({ details }: { details: Announcement }) => {
  const dispatch = useDispatch<AppDispatch>();
  const userTokenData = getUserAccessTokenData();
  const { t } = useTranslation();
  const { skin } = useSkin();
  const announcementPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const formatAnnouncementTitle = (text: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const cleanTitle = doc.body.textContent ?? '';

    return cleanTitle;
  };

  const handleDeleteClick = (announcementsId: number) => {
    dispatch(
      openModal({
        Component: ConfirmationModalWrapper,
        title: t('Are you sure?'),
        subtitle: t('Announcement will be deleted'),
        open: true,
        componentProps: {
          handleConfirm: async () => {
            try {
              await dispatch(deleteAnnouncement(announcementsId));
            } finally {
              successToast(t('Announcement successfully deleted'));
              dispatch(getAllAnnouncements());
              dispatch(closeModal());
            }
          },
        },
      }),
    );
  };

  const handleContentClick = () => {
    dispatch(openModal({
      Component: AnnouncementModal,
      componentProps: { content: details.content },
      title: t('Content'),
      open: true,
    }));
  };

  const canEdit = userTokenData.role === 'superAdmin' || userTokenData.sub === details.createdById;
  const disabledColor = skin === 'dark' ? '#444' : '#cccccc';

  return (
    <Col md={4}>
      <Card>
        <CardBody>
          <div>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><User size={20} />&nbsp;</span>
              {t('Created by')}:&nbsp;
              {`${details.createdBy.name} ${details.createdBy.surname}`}
            </p>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><CalendarTime size={20} />&nbsp;</span>
              {t('Sent at')}:&nbsp;
              {moment(new Date(details.date)).format('DD. MMM Y. HH:mm')}
            </p>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><IconListDetails size={20} />&nbsp;</span>
              {t('Announcement type')}:&nbsp;
              {t(details.announcementType.name)}
            </p>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><BuildingCommunity size={20} />&nbsp;</span>
              {t('Objects')}:&nbsp;
              {details.objects.map((group) => group.name).join(', ')}
            </p>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><IconUsersGroup size={20} />&nbsp;</span>
              {t('Groups')}:&nbsp;
              {details.groups.map((group) => group.name).join(', ')}
            </p>
            <Button
              outline
              color="outline"
              onClick={handleContentClick}
              block
              style={{
                margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
              }}
            >
              <p
                className="cursor-pointer"
                style={{
                  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: '0.5rem', textAlign: 'start',
                }}
              >
                <span style={{ color: '#7DCBEC' }}><Notes size={20} /></span>
                {t('Content')}:&nbsp;{formatAnnouncementTitle(details.content)}
              </p>
            </Button>
          </div>
          {details.attachment ? (
            <>
              <hr />
              <a
                href={details.attachment}
                download
                target="_blank"
                style={{ gap: '10px', width: 'fit-content' }}
                className="d-flex align-items-center"
                rel="noreferrer"
              >
                <File width={15} height={15} />{extractFileName(details.attachment)}
              </a>
            </>
          ) : (
            <>
              <hr />
              <p
                className="d-flex align-items-center my-0"
                style={{ color: skin === 'dark' ? '#b4b7bd' : '', gap: '10px', width: 'fit-content' }}
              >
                <File width={15} height={15} /> <span>{t('No content')}</span>
              </p>
            </>
          )}
          <>
            <hr />
            <div className="w-full d-flex justify-content-between">
              <div>
                {isManagePermissionActive(announcementPermission, 'Announcements')
                  ? (
                    <Button
                      color="outline m-0 p-0"
                      style={{ border: 'none', color: !canEdit ? disabledColor : '' }}
                      disabled={!canEdit}
                      onClick={() => {
                        dispatch(openModal({
                          Component: AnnouncementForm,
                          componentProps: { announcement: details },
                          title: t('Edit announcement'),
                          open: true,
                          modalProps: { size: 'lg' },
                        }));
                      }}
                    ><Edit />
                    </Button>
                  ) : <></>}
              </div>
              {isDeletePermissionActive(announcementPermission, 'Announcements')
                ? (
                  <Button
                    style={{ border: 'none', color: !canEdit ? disabledColor : '' }}
                    disabled={!canEdit}
                    color="outline m-0 p-0"
                    onClick={() => handleDeleteClick(details.id)}
                  ><Delete color="red" />
                  </Button>
                ) : <></>}
            </div>
          </>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AnnouncementCard;
