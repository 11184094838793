import { useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import '@styles/react/libs/flatpickr/flatpickr.scss';
import {
  Control,
  Controller,
  FieldValues,
  FieldPath,
  UseFormSetValue,
  PathValue,
} from 'react-hook-form';
import moment from 'moment';
import { BaseOptions, Plugin } from 'flatpickr/dist/types/options';
import CustomLabel from '@src/components/forms/CustomLabel';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import weekSelect from 'flatpickr/dist/plugins/weekSelect/weekSelect';

interface DatePickerProps<T extends FieldValues> {
  control: Control<T>;
  error?: string;
  setValue: UseFormSetValue<T>;
  label: string;
  startName: FieldPath<T>;
  endName: FieldPath<T>;
  defaultStartValue?: PathValue<T, FieldPath<T>>;
  defaultEndValue?: PathValue<T, FieldPath<T>>;
  options?: Partial<BaseOptions>;
  required?: boolean;
  dateFormat?: string;
  disabled?: boolean;
}

function getFirstDateOfWeek(date: Date, firstDayOfWeek = 1) {
  const dayOfWeek = date.getDay();
  const diff = (dayOfWeek < firstDayOfWeek ? 7 : 0) + dayOfWeek - firstDayOfWeek;
  const firstDateOfWeek = new Date(date);
  firstDateOfWeek.setDate(date.getDate() - diff);
  return firstDateOfWeek;
}

const getLastDateOfWeekFromMonday = (mondayDate: Date): Date => {
  const lastDateOfWeek = new Date(mondayDate);
  lastDateOfWeek.setDate(mondayDate.getDate() + 6); // Add 6 days to get to Sunday
  return lastDateOfWeek;
};

export const firstDay = getFirstDateOfWeek(new Date()).toString();

const CustomWeekPicker = <T extends FieldValues>({
  control,
  error,
  setValue,
  label,
  startName,
  endName,
  defaultStartValue = firstDay as unknown as PathValue<T, FieldPath<T>>,
  defaultEndValue = getLastDateOfWeekFromMonday(
    new Date(firstDay),
  ) as unknown as PathValue<T, FieldPath<T>>,
  options = {
    allowInput: false,
    dateFormat: 'Y-m-j',
    altInput: true,
    altFormat: 'F j, Y',
    minDate: 'today',
  },
  required = false,
  dateFormat = 'YYYY-MM-DD',
  disabled = false,
}: DatePickerProps<T>) => {
  const handleChange = (date: Date[]) => {
    const newStartDate = getFirstDateOfWeek(date[0]);
    const newEndDate = getLastDateOfWeekFromMonday(newStartDate);
    const newStartValue = moment(newStartDate).format(dateFormat);
    const newEndValue = moment(newEndDate).format(dateFormat);

    setValue(
      startName,
      newStartValue as PathValue<T, FieldPath<T>>,
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );
    setValue(
      endName,
      newEndValue as PathValue<T, FieldPath<T>>,
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );
  };

  useEffect(() => {
    setValue(startName, defaultStartValue);
    setValue(endName, defaultEndValue);
  }, []);

  return (
    <>
      <CustomLabel name={label} required={required} />
      <Controller
        name={startName}
        control={control}
        render={({ field }) => (
          <>
            <Flatpickr
              readOnly={false}
              disabled={disabled}
              className={`form-control ${
                error ? 'is-invalid' : ''
              } full-opacity`}
              options={{
                ...options,
                weekNumbers: true,
                locale: {
                  firstDayOfWeek: 1,
                },
                plugins: [weekSelect() as Plugin],
                formatDate(date, format) {
                  return `${moment(new Date(date)).format(format)} - ${moment(getLastDateOfWeekFromMonday(new Date(date))).format(format)}`;
                },
              }}
              value={field.value ? moment(field.value).toDate() : undefined}
              onChange={handleChange}
            />
            {error && <CustomFormFeedback message={error} />}
          </>
        )}
      />
    </>
  );
};

export default CustomWeekPicker;
