import FormHeader from '@src/components/forms/FormHeader';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col, Row,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import '../../../assets/scss/album.scss';
import { Plus } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { useEffect, useState } from 'react';
import PageFilters from '@src/views/misc/PageFilters';
import useFilter from '@src/utility/hooks/useFilter';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import AlbumState from '../types/AlbumState';
import { getAllAlbums } from '../store';
import AlbumThumbnail from '../components/AlbumThumbnail';

const Albums = () => {
  const { t } = useTranslation();
  const store = useSelector((state: AlbumState) => state.albums);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { onFilterChange, filters } = useFilter();
  const [albums, setAlbums] = useState(store.allAlbums);
  const galleryPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  useEffect(() => {
    dispatch(getAllAlbums());
  }, []);

  useEffect(() => {
    setAlbums(store.allAlbums.filter((album) => {
      if (filters[0]?.value) {
        return new Date(album.date).getFullYear().toString() === filters[0].value;
      }
      return true;
    }));
  }, [store.allAlbums, filters]);

  return (
    <>
      <FormHeader
        title={t('Gallery')}
        subtitle={t('View, add, and manage your albums')}
        button={(
            isManagePermissionActive(galleryPermission, 'Gallery')
              ? (
                <Button
                  color="primary"
                  className="text-nowrap mb-1 mt-2"
                  outline
                  onClick={() => { navigate('/gallery/album/edit/new'); }}
                >
                  <div className="d-flex justify-content-center">
                    <Plus size={15} />
                    &nbsp;
                    <span>{t('Add new album')}</span>
                  </div>
                </Button>
              ) : <></>
          )}
      />
      <PageFilters
        filters={[{
          label: 'Year', name: 'year', datePicker: 'year',
        }]}
        onChange={onFilterChange}
      />
      <Row className="mb-2">
        {albums.map((album) => (
          <Col md={3} key={album.id}>
            <AlbumThumbnail album={album} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Albums;
