import { useEffect, useMemo } from 'react';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { AppDispatch } from '@store/store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { getAllPackages } from '@src/views/packages/store';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';
import { formatDateToShortDate, handleApiErrorResponse } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import { closeModal } from '@store/modal';
import CustomLabel from '@src/components/forms/CustomLabel';
import Child from '@src/views/children/types/Child';
import { yupResolver } from '@hookform/resolvers/yup';
import ChildrenState from '@src/views/children/types/ChildrenState';
import {
  addNewPayment,
  editPayment,
  getAllPayments,
  getPaymentStatuses,
  selectedChildren,
  selectedInvoiceType,
} from '../store';
import PaymentState from '../types/PaymentState';
import paymentValidationSchema from '../validation/index';
import Payment from '../types/Payment';
import PaymentSubmitData from '../types/PaymentSubmitData';

const PaymentForm = ({ payment, children }: { payment?: Payment, children: Child[] }) => {
  const currentDate = useMemo(() => new Date(new Date().toDateString()), []);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const paymentStore = useSelector((store: PaymentState) => store.payments);
  const childId = useSelector((store: ChildrenState) => store.children.currentChild.id);

  const defaultValues: PaymentSubmitData = {
    date: currentDate.toISOString(),
    referenceNumber: '',
    amount: 0,
    status: '',
    note: '',
    childId: 0,
  } as PaymentSubmitData;

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<PaymentSubmitData>({
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(
      paymentValidationSchema(),
    ),
  });

  useEffect(() => {
    dispatch(getAllPackages());
    dispatch(getPaymentStatuses());
    dispatch(selectedChildren(children.map(
      (child) => ({ label: `${child.name} ${child.surname}`, value: child.id }),
    )));
  }, []);

  useEffect(() => {
    if (payment) {
      reset({
        note: payment.note,
        date: payment.date,
        referenceNumber: payment.referenceNumber,
        amount: payment.amount,
        status: payment.status,
        childId: payment.childId,
      });
    }
  }, []);

  const handleSuccessfulSubmit: SubmitHandler<any> = async (values: PaymentSubmitData) => {
    try {
      const newPayment = {
        ...values,
        date: formatDateToShortDate(values.date),
        amount: Number(values.amount),
      };

      if (payment?.id) {
        await dispatch(editPayment({ id: +payment.id, data: newPayment })).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Payment successfully updated'), { position: 'top-right', duration: 3000 });
            dispatch(getAllPayments(childId ? `childId=${childId}` : ''));
            dispatch(closeModal());
          }
        });
      } else {
        await dispatch(addNewPayment(newPayment)).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Payment successfully added'), { position: 'top-right', duration: 3000 });
            dispatch(getAllPayments(childId ? `childId=${childId}` : ''));
            dispatch(closeModal());
            dispatch(getAllPayments());
          }
        });
      }
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  useEffect(() => {
    dispatch(selectedInvoiceType(paymentStore.paymentStatuses.map(
      (type) => ({ label: t(`${type}`), value: type }),
    )));
  }, [paymentStore.paymentStatuses]);

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={6}>
          <CustomLabel name={t('Reference number')} for="referenceNumber" required />
          <Controller
            name="referenceNumber"
            control={control}
            render={({ field }) => (
              <Input id="referenceNumber" invalid={!!errors.referenceNumber} {...field} />
            )}
          />
          {errors?.referenceNumber?.message
          && <CustomFormFeedback message={errors.referenceNumber.message} />}
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Amount')} for="amount" required />
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <Input id="amount" type="number" invalid={!!errors.amount} {...field} />
            )}
          />
          {errors?.amount?.message
          && <CustomFormFeedback message={errors.amount.message} />}
        </Col>
        <Col md={6}>
          <CustomDatePicker
            label={t('Date')}
            name="date"
            control={control}
            setValue={setValue}
            error={errors?.date?.message}
            required
            options={{ minDate: undefined }}
            defaultValue={watch('date')}
          />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Status')} required htmlFor="status" />
          <Select
            name="status"
            className="react-select"
            inputId="status"
            classNamePrefix="select"
            placeholder={t('Select')}
            options={paymentStore.selectedPaymentStatuses}
            value={paymentStore.selectedPaymentStatuses
              .find((opt) => opt.value === watch('status'))}
            onChange={(val) => setValue('status', String(val?.value) || '')}
          />
          {errors?.status?.message
          && <CustomFormFeedback message={errors.status.message} />}
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Child')} required htmlFor="childId" />
          <Select
            name="childId"
            className="react-select"
            inputId="childId"
            classNamePrefix="select"
            placeholder={t('Select')}
            options={paymentStore.selectedChildren}
            value={paymentStore.selectedChildren
              .find((opt) => opt.value === watch('childId'))}
            onChange={(val) => setValue('childId', Number(val?.value) || 0)}
          />
          {errors?.childId?.message
          && <CustomFormFeedback message={errors.childId.message} />}
        </Col>
        <Col md={12}>
          <CustomLabel name={t('Note')} for="note" />
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <Input id="note" type="textarea" invalid={!!errors.note} {...field} />
            )}
          />
          {errors?.note?.message
          && <CustomFormFeedback message={errors.note.message} />}
        </Col>
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            type="submit"
            className="me-1"
            color="primary"
          >
            {payment?.id ? t('Save changes') : t('Add') }
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default PaymentForm;
