import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import CustomLabel from '@src/components/forms/CustomLabel';
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SpinnerComponent from '@components/spinner/Fallback-spinner';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import { handleApiErrorResponse } from '@src/utility/Utils';
import { getAllChildren } from '@src/views/children/store';
import ChildrenState from '@src/views/children/types/ChildrenState';
import UserState from '@src/views/users/types/UserState';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAllUsers } from '@src/views/users/store';
import {
  editGeneralTenantInfo, getTenantInfo, getInvoiceOptions, getAllTenants,
} from '../store';
import { UserEditTenantValidationSchema } from '../validation/TenantValidation';
import PdvRate from '../components/PdvRate';
import CustomTransactionInfoTooltip from '../components/CustomTransactionInfoTooltip';
import TenantFormHeader from '../components/TenantFormHeader';

type SubmitData = {
  name: string;
  address:string;
  city: string;
  director: string;
  identificationNumber: string;
  taxId: string;
  invoiceDueDateByNumber: string;
  website?: string;
  email: string;
  phoneNumber: string;
  accountNumber: string;
  id: string;
  bank: string;
  invoiceNote: string;
  vat: number;
  payer: string;
  payee: string;
  purposeOfPayment: string;
}

function General() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: any) => state.tenants);
  const [loading, setLoading] = useState(true);
  const AllChildren = useSelector((state: ChildrenState) => state.children.allChildren);
  const AllUsers = useSelector((state: UserState) => state.users.allUsers);

  const defaultValues: SubmitData = {
    name: '',
    address: '',
    city: '',
    director: '',
    identificationNumber: '',
    taxId: '',
    invoiceDueDateByNumber: '',
    website: '',
    email: '',
    phoneNumber: '',
    accountNumber: '',
    id: '',
    bank: '',
    invoiceNote: '',
    vat: 0,
    payer: '',
    payee: '',
    purposeOfPayment: '',
  } as SubmitData;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<SubmitData>({
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(
      UserEditTenantValidationSchema(
        store?.allTenants, store?.currentTenant,
      ),
    ),
  });

  useEffect(() => {
    dispatch(getTenantInfo());
    dispatch(getAllTenants());
    dispatch(getInvoiceOptions());
    dispatch(getAllChildren('childStatus=Aktivan'));
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    if (store?.currentTenant) {
      reset({
        name: store.currentTenant.name || '',
        address: store.currentTenant.address || '',
        city: store.currentTenant.city || '',
        director: store.currentTenant.director || '',
        identificationNumber: store.currentTenant.identificationNumber || '',
        taxId: store.currentTenant.taxId || '',
        website: store.currentTenant.website || '',
        email: store.currentTenant.email || '',
        phoneNumber: store.currentTenant.phoneNumber || '',
        id: store.currentTenant.id,
        invoiceDueDateByNumber: store.currentTenant.invoiceDueDateByNumber || '',
        accountNumber: store.currentTenant.accountNumber || '',
        bank: store.currentTenant.bank || '',
        invoiceNote: store.currentTenant.invoiceNote || '',
        vat: store.currentTenant.vat || 0,
        payer: store.currentTenant.payer,
        payee: store.currentTenant.payee,
        purposeOfPayment: store.currentTenant.purposeOfPayment,
      });
    }
    setLoading(false);
  }, [store.currentTenant]);

  const handleSuccessfulSubmit: SubmitHandler<any> = async (values) => {
    try {
      const updatedTenant = {
        ...values,
        invoiceDueDateByNumber:
         values.invoiceDueDateByNumber > 0 ? Number(values.invoiceDueDateByNumber) : null,
      };
      await dispatch(editGeneralTenantInfo(updatedTenant));
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  if (loading) {
    return <SpinnerComponent />;
  }

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <TenantFormHeader
        title={t('General tenant information')}
        subtitle={t('Check and edit tenant details')}
        maxNumberOfChildren={store.currentTenant?.maxNumberOfChildProfiles}
        activeChildren={AllChildren.length}
        maxNumberOfUsers={store.currentTenant?.maxNumberOfUsers}
        activeUsers={AllUsers.length}
      />
      <Col md={12}>
        <Card className="m-10">
          <CardTitle className="p-1 mb-0">
            {t('Basic information')}
          </CardTitle>
          <CardBody>
            <Row>
              <Col md={4}>
                <CustomLabel name={t('Name')} required htmlFor="name" />
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input id="name" invalid={!!errors.name} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.name?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Address')} required htmlFor="address" />
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <Input id="address" invalid={!!errors.address} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.address?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('City')} required htmlFor="city" />
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <Input id="city" invalid={!!errors.city} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.city?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Director')} required htmlFor="director" />
                <Controller
                  name="director"
                  control={control}
                  render={({ field }) => (
                    <Input id="director" invalid={!!errors.director} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.director?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Identification number')} required htmlFor="identificationNumber" />
                <Controller
                  name="identificationNumber"
                  control={control}
                  render={({ field }) => (
                    <Input id="identificationNumber" invalid={!!errors.identificationNumber} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.identificationNumber?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Tax ID (PIB)')} required htmlFor="taxId" />
                <Controller
                  name="taxId"
                  control={control}
                  render={({ field }) => (
                    <Input id="taxId" invalid={!!errors.taxId} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.taxId?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Invoice due date')} htmlFor="invoiceDueDateByNumber" />
                <Controller
                  name="invoiceDueDateByNumber"
                  control={control}
                  render={({ field }) => (
                    <Input id="invoiceDueDateByNumber" invalid={!!errors.invoiceDueDateByNumber} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.invoiceDueDateByNumber?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Website')} htmlFor="website" />
                <Controller
                  name="website"
                  control={control}
                  render={({ field }) => (
                    <Input id="website" invalid={!!errors.website} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.website?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Email')} required htmlFor="email" />
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input id="email" invalid={!!errors.email} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.email?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Phone number')} required htmlFor="phoneNumber" />
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <Input id="phoneNumber" invalid={!!errors.phoneNumber} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.phoneNumber?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Account number')} required htmlFor="accountNumber" />
                <Controller
                  name="accountNumber"
                  control={control}
                  render={({ field }) => (
                    <Input id="accountNumber" invalid={!!errors.accountNumber} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.accountNumber?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel required name={t('Bank')} htmlFor="bank" />
                <Controller
                  name="bank"
                  control={control}
                  render={({ field }) => (
                    <Input id="bank" invalid={!!errors.bank} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.bank?.message} />
              </Col>
              <CardTitle className="p-1 mb-0">
                {t('Podaci o uplati')}
              </CardTitle>
              <Col md={4}>
                <CustomTransactionInfoTooltip name={t('Payer')} htmlFor="payer" data={store.invoiceOptions} isRequired />
                <Controller
                  name="payer"
                  control={control}
                  render={({ field }) => (
                    <Input id="payer" invalid={!!errors.payer} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.payer?.message} />
              </Col>
              <Col md={4}>
                <CustomTransactionInfoTooltip name={t('Payee')} htmlFor="payee" data={store.invoiceOptions} isRequired />
                <Controller
                  name="payee"
                  control={control}
                  render={({ field }) => (
                    <Input id="payee" invalid={!!errors.payee} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.payee?.message} />
              </Col>
              <Col md={4}>
                <CustomTransactionInfoTooltip name={t('Purpose of payment')} htmlFor="purposeOfPayment" data={store.invoiceOptions} isRequired />
                <Controller
                  name="purposeOfPayment"
                  control={control}
                  render={({ field }) => (
                    <Input id="purposeOfPayment" invalid={!!errors.purposeOfPayment} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.purposeOfPayment?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Invoice note')} htmlFor="invoiceNote" />
                <Controller
                  name="invoiceNote"
                  control={control}
                  render={({ field }) => (
                    <Input id="invoiceNote" type="textarea" invalid={!!errors.invoiceNote} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.invoiceNote?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('VAT')} htmlFor="vat" />
                <Controller
                  name="vat"
                  control={control}
                  render={({ field }) => (
                    <PdvRate field={field} />
                  )}
                />
                <CustomFormFeedback message={errors?.vat?.message} />
              </Col>
            </Row>
            <Button
              type="submit"
              className="me-1"
              color="primary"
              disabled={!isDirty}
            >
              {t('Save changes')}
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Form>
  );
}

export default General;
