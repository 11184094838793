// ** React Imports
import { lazy } from 'react';

const Login = lazy(() => import('@views/authentication/Login'));
const ForgotPassword = lazy(() => import('@views/authentication/ForgotPassword'));

const AuthenticationRoutes = [
  {
    path: '/login',
    element: <Login />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true,
    },
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
    },
  },
];

export default AuthenticationRoutes;
