import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { Button } from 'reactstrap';
import { Plus } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import TenantGroupState from '@src/views/tenantGroups/types/TenantGroupState';
import { useTranslation } from 'react-i18next';
import { getAllTenantGroups } from '@src/views/tenantGroups/store';
import useFilter from '@src/utility/hooks/useFilter';
import toast from 'react-hot-toast';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import RequestStatus from '@src/types/RequestStatus';
import EvaluationGroupColumns from '../components/EvaluationGroupColumns';
import {
  deleteEvaluationGroup,
  getAllEvaluationGroups,
  getEvaluationTypes,
  setAllEvaluationGroups,
  setSelectedEvaluationTypes,
  setSelectedGroups,
} from '../store';
import EvaluationState from '../types/EvaluationState';

const EvaluationGroups = () => {
  const dispatch = useDispatch<AppDispatch>();
  const evaluationsStore = useSelector((store: EvaluationState) => store.evaluations);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const evaluationPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const allTenantGroups = useSelector(
    (state: TenantGroupState) => state.tenantGroups.allTenantGroups,
  );
  const { onFilterChange, query } = useFilter();
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    dispatch(getAllTenantGroups());
    dispatch(getEvaluationTypes());
    setDataLoaded(true);
  }, []);

  useEffect(() => {
    dispatch(setSelectedEvaluationTypes(evaluationsStore.evaluationTypes.map(
      (item) => ({ label: t(String(item)), value: item }),
    )));
  }, [evaluationsStore.evaluationTypes]);

  useEffect(() => {
    dispatch(setSelectedGroups(allTenantGroups.map(
      (group) => ({ label: group.name, value: group.id }),
    )));
  }, [allTenantGroups]);

  const handleDeleteClick = (id: number) => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('Evaluation will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            const res = await dispatch(deleteEvaluationGroup(id));

            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              const filteredEvaluationGroups = evaluationsStore
                .allEvaluationGroups.filter((item) => item.id !== id);
              dispatch(setAllEvaluationGroups(filteredEvaluationGroups));
              toast.success(t('Evaluation successfully deleted'), { position: 'top-right', duration: 3000 });
            }
          } finally {
            dispatch(closeModal());
          }
        },
      },
    }));
  };

  const onFilterClick = () => {
    dispatch(getAllEvaluationGroups(query))
      .catch(() => { toast.error('Error while fetching objects'); });
  };

  return (
    <div>
      <DataTableWrapper
        dataSource={evaluationsStore.allEvaluationGroups}
        title={t('Evaluation groups')}
        subtitle={t('See, manage and filter evaluation groups table')}
        columns={EvaluationGroupColumns({ handleDeleteClick })}
        onChange={onFilterChange}
        showSearch={false}
        dataLoaded={dataLoaded}
        onFilterClick={onFilterClick}
        filters={[{
          label: 'Groups', name: 'groupIds', options: evaluationsStore.selectedGroups, isMulti: true,
        },
        {
          label: 'Type', name: 'evaluationType', options: evaluationsStore.selectedEvaluationTypes,
        },
        ]}
        functionButton={(
          isManagePermissionActive(evaluationPermission, 'AdministrationEvaluations')
            ? (
              <Button
                color="primary"
                className="text-nowrap mb-1 mt-2"
                outline
                onClick={() => { navigate('/evaluation-groups/new'); }}
              >
                <div className="d-flex justify-content-center">
                  <Plus size={15} />
                  &nbsp;
                  <span>{t('Add new evaluation')}</span>
                </div>
              </Button>
            ) : <></>
        )}
      />
    </div>
  );
};

export default EvaluationGroups;
