import { AppDispatch } from '@store/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PollsState } from '../types';
import { getPollAnswerVotes } from '../store';
import PollVotersTable from './PollVotersTable';

const PollVoters = ({ id }: { id: number }) => {
  const dispatch = useDispatch<AppDispatch>();
  const pollsStore = useSelector((store: PollsState) => store.polls);

  useEffect(() => {
    dispatch(getPollAnswerVotes(String(id)));
  }, []);

  return (
    <div>
      <PollVotersTable votes={pollsStore.answerVotes} />
    </div>
  );
};

export default PollVoters;
