// ** Store Imports
import { handleFooterType } from '@store/layout';
import { AppDispatch } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';

export const useFooterType = () => {
  // ** Hooks
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: {layout : {footerType: string}}) => state.layout);

  const setFooterType = (type : string) => {
    dispatch(handleFooterType(type));
  };

  return { setFooterType, footerType: store.footerType };
};
