import { TableColumn } from 'react-data-table-component';
import { Eye } from 'react-feather';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { openModal } from '@store/modal';
import { VotesPerAnswer } from '../types';
import PollVoters from './PollVoters';

const PollStatisticsColumns = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const columns: TableColumn<VotesPerAnswer>[] = [
    {
      name: t('Answer'),
      selector: (row) => row.answer,
    },
    {
      name: t('Voters'),
      cell: (row) => (
        <Button
          style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
          size="sm"
          color="transparent"
          className="btn btn-icon"
          title={t('Check the list of voters')}
          onClick={() => dispatch(openModal({
            Component: PollVoters,
            title: `${t('Votes for')} ${row.answer}`,
            open: true,
            modalProps: { size: 'lg' },
            componentProps: { id: row.id },
          }))}
        >
          <Eye className="text-center" size={15} />
        </Button>
      ),
      center: true,
    },
    {
      name: t('Votes'),
      selector: (row) => row.votes,
      sortable: true,
      center: true,
    },
    {
      name: t('Percentage'),
      selector: (row) => `${row.percentage ?? 0} %`,
      sortable: true,
      center: true,
    },
  ];
  return columns;
};

export default PollStatisticsColumns;
