import { useEffect, useState } from 'react';
import { Card, Row } from 'reactstrap';
import PostPagination from '@src/views/posts/components/PostPagination';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { getAllCityForms } from '../store';
import CityFormState from '../types/CityFormState';
import Contract from '../types/CityForm';
import NewCityFormCard from '../components/NewCityFormCard';
import CityFormCard from '../components/CityFormCard';

const CityForms = () => {
  const dispatch = useDispatch<AppDispatch>();
  const cityFormStore = useSelector((store: CityFormState) => store.cityForms);

  const [cityForms, setCityForms] = useState<Contract[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [loadOnce, setLoadOnce] = useState(false);
  const postPerPage = 6;

  useEffect(() => {
    setCityForms(cityFormStore?.allCityForms.slice(((activePage - 1) * postPerPage),
      postPerPage * activePage));
    if ((activePage - 1) * postPerPage === cityFormStore?.allCityForms.length) {
      setActivePage(activePage === 1 ? 1 : activePage - 1);
    } else if (loadOnce) {
      setActivePage(1);
    }
    if (cityFormStore?.allCityForms.length) {
      setLoadOnce(true);
    }
  }, [cityFormStore?.allCityForms]);

  useEffect(() => {
    setCityForms(cityFormStore?.allCityForms.slice(((activePage - 1) * postPerPage),
      postPerPage * activePage));
  }, [activePage]);

  useEffect(() => {
    dispatch(getAllCityForms());
  }, []);

  return (
    <>
      <Card>
        <NewCityFormCard />
      </Card>
      <Row>
        {cityForms.map((item) => <CityFormCard key={item.id} details={item} />)}
      </Row>
      {cityFormStore?.allCityForms?.length > 0
        && (
          <PostPagination
            totalPages={Number(Math.ceil(cityFormStore?.allCityForms?.length / postPerPage) || 1)}
            activePage={activePage}
            setActive={setActivePage}
          />
        )}
    </>
  );
};

export default CityForms;
