import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';
import CustomWeekPicker from '@src/components/wrappers/CustomWeekPicker';
import { AppDispatch } from '@store/store';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import InvoiceState from '@src/views/invoice/types/InvoiceState';
import { getYears, selectedYears } from '@src/views/invoice/store';
import { Col } from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import Select, { SingleValue } from 'react-select';
import SelectedOptions from '@src/types/SelectedOptions';
import { useTranslation } from 'react-i18next';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { formatDateToShortDate } from '@src/utility/Utils';

const EvaluationDate = ({ evaluationType, evaluationDates, setEvaluationDates } : {
   evaluationType: string, evaluationDates: any, setEvaluationDates: (arg1: any) => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const invoiceStore = useSelector((store: InvoiceState) => store.invoice);
  const [year, setYear] = useState<SingleValue<SelectedOptions>>({ label: `${new Date().getFullYear()}`, value: new Date().getFullYear() });
  const { t } = useTranslation();
  const snapshotOptions = [{ label: String(t('First')), value: 1 }, { label: String(t('Second')), value: 2 }];

  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<{startDate: Date, endDate: Date, snapshot: SelectedOptions}>({});

  function getFirstDateOfMonth(firstDate: Date) {
    // Create a date object from the input
    const date = new Date(firstDate);
    // Get the month and year of the first date
    const month = date.getMonth();
    const year = date.getFullYear();
    // Create a new date object for the first day of the next month
    const nextMonth = new Date(year, month, 1);
    // Return the last date
    return nextMonth;
  }

  function getLastDateOfMonth(firstDate: Date) {
    // Create a date object from the input
    const date = new Date(firstDate);
    // Get the month and year of the first date
    const month = date.getMonth();
    const year = date.getFullYear();
    // Create a new date object for the first day of the next month
    const nextMonth = new Date(year, month + 1, 1);
    // Subtract one day to get the last day of the current month
    const lastDate = new Date(Number(nextMonth) - 1);
    // Return the last date
    return lastDate;
  }

  useEffect(() => {
    const dates: any = {};
    let date;
    let date2;
    if (evaluationType === 'DAILY_EVALUATIONS') {
      date = formatDateToShortDate(watch('startDate'));
      date2 = date;
    } else if (evaluationType === 'WEEKLY_EVALUATIONS') {
      date = formatDateToShortDate(watch('startDate'));
      date2 = formatDateToShortDate(watch('endDate'));
      setEvaluationDates(dates);
    } else if (evaluationType === 'MONTHLY_EVALUATIONS') {
      const startDateValue = new Date(watch('startDate'));
      date = formatDateToShortDate(startDateValue);
      date2 = formatDateToShortDate(getLastDateOfMonth(startDateValue));
      setEvaluationDates(dates);
    } else if (evaluationType === 'YEARLY_EVALUATIONS') {
      date = formatDateToShortDate(new Date(Number(year?.value), 0, 1));
      date2 = formatDateToShortDate(new Date(Number(year?.value), 11, 31));
      dates.snapshot = watch('snapshot.value');
    }
    dates.startDate = date;
    dates.endDate = date2;
    setEvaluationDates(dates);
  }, [watch('startDate'), evaluationType, watch('snapshot'), year]);

  useEffect(() => {
    setValue('startDate', evaluationDates.startDate);
  }, [evaluationDates]);

  useEffect(() => {
    const evalSnapshot = snapshotOptions.find((opt) => opt.value === evaluationDates.snapshot);
    if (evalSnapshot) setValue('snapshot', evalSnapshot);
    if (evaluationType === 'YEARLY_EVALUATIONS' && !evalSnapshot) {
      setValue('snapshot', snapshotOptions[0]);
    }
  }, [evaluationDates.snapshot, evaluationType]);

  useEffect(() => {
    dispatch(getYears());
  }, []);

  useEffect(() => {
    dispatch(selectedYears(invoiceStore.years.map(
      (item) => ({ label: String(item.value), value: item.value }),
    )));
  }, [invoiceStore.years]);

  return (
    <>
      {evaluationType === 'DAILY_EVALUATIONS' ? (
        <Col md={4}>
          <CustomDatePicker control={control} name="startDate" defaultValue={evaluationDates.dateFrom} setValue={setValue} label="Datum" options={{ minDate: new Date('2015-01-01'), dateFormat: 'd M' }} />
        </Col>
      ) : <></>}
      {evaluationType === 'WEEKLY_EVALUATIONS' ? (
        <Col md={4}>
          <CustomWeekPicker control={control} startName="startDate" defaultStartValue={evaluationDates.dateFrom} endName="endDate" label="Nedelja" setValue={setValue} options={{ minDate: new Date('2015-01-01'), dateFormat: 'D. MM.' }} />
        </Col>
      ) : <></>}
      {evaluationType === 'MONTHLY_EVALUATIONS' ? (
        <Col md={4}>
          <CustomDatePicker control={control} name="startDate" defaultValue={getFirstDateOfMonth(new Date())} setValue={setValue} label="Datum" options={{ minDate: new Date('2015-01-01'), dateFormat: 'd M' }} isMonthSelect />
        </Col>
      ) : <></>}
      {evaluationType === 'YEARLY_EVALUATIONS' ? (
        <>
          <Col md={4}>
            <CustomLabel name={t('Year')} required />
            <Select
              name="object"
              className="react-select"
              options={invoiceStore.selectedYears}
              value={year}
              onChange={(val) => setYear(val)}
            />
          </Col>
          <Col md={4}>
            <CustomLabel name={t('Snapshot')} />
            <Controller
              name="snapshot"
              control={control}
              render={({ field }) => (
                <Select
                  className="react-select"
                  classNamePrefix="select"
                  options={snapshotOptions}
                  {...field}
                  value={field.value || []}
                />
              )}
            />
            <CustomFormFeedback message={errors?.snapshot?.message} />
          </Col>
        </>
      ) : <></>}
    </>
  );
};

export default EvaluationDate;
