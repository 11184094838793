import { Check, X } from 'react-feather';
import ToastWrapper from './ToastWrapper';
import messagesEnglish from '../toast-translation-messages/messages.en.json';
import messagesSerbian from '../toast-translation-messages/messages.rs.json';

export const getMessagesForCurrentLanguage = () => (
  localStorage.getItem('language') === 'rs' ? messagesSerbian : messagesEnglish);

export const successToast = (title: string) => ToastWrapper(
  getMessagesForCurrentLanguage()['Changes are saved!'],
  <>{title}</>,
  <Check size={12} />,
  'success',
);

export const errorToast = (message?:string) => ToastWrapper(
  'Oops!',
  <>
    <b>{getMessagesForCurrentLanguage()['Something Went Wrong!']}
      <span role="img" aria-label="emoji">😖</span>
    </b>
    <br />
    <hr />
    {message ?? getMessagesForCurrentLanguage()['There seems to be an error, please try again or check back later!']}
  </>,
  <X size={12} />,
  'danger',
);
