import FullCalendar, {
  DateSelectArg, DayCellContentArg, EventClickArg, EventContentArg, EventInput,
} from '@fullcalendar/react';
import '../../../assets/scss/calendar.scss';
import ShowModalButton from '@src/components/buttons/ShowModalButton';
import FormHeader from '@src/components/forms/FormHeader';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { useSkin } from '@src/utility/hooks/useSkin';
import { isOneDayMore } from '@src/utility/Utils';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { formatDate } from '@src/views/polls/components/PollsForm';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'react-feather';
import { successToast } from '@src/components/wrappers/ToastMessages';
import Legend from '@src/components/legend/Legend';
import { DAYS, FULL_DAYS } from '@src/constants/days';
import MONTHS from '@src/constants/months';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import NonWorkingDaysState from '../types/NonWorkingDaysState';
import { deleteNonWorkingDay, getAllNonWorkingDays, setCalendarEvents } from '../store';
import AddNonWorkingDayForm from '../components/NonWorkingDayForm';
import RecurringNonWorkingDaysState from '../types/RecurringNonWorkingDaysState';
import { deleteRecurringNonWorkingDay, getAllRecurringNonWorkingDays, setGeneratedEvents } from '../store/RecurringNonWorkingDaysStore';
import EditNonWorkingDayForm from '../components/EditNonWorkingDayForm';

const NonWorkingDays = () => {
  const { t } = useTranslation();
  const calendarRef = useRef<FullCalendar>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const deleteButtonRef = useRef<HTMLButtonElement>(null);
  const nonWorkingDaysStore = useSelector((state: NonWorkingDaysState) => state.nonWorkingDays);
  const recurringNonWorkingDaysStore = useSelector(
    (state: RecurringNonWorkingDaysState) => state.recurringNonWorkingDays,
  );
  const dispatch = useDispatch<AppDispatch>();
  const { skin } = useSkin();
  const [selectedDates, setSelectedDates] = useState<DateSelectArg>();
  const legendItems = useMemo(() => [
    { title: 'Non-working day', className: 'holiday' },
    { title: 'Weekend', className: 'weekend' },
    { title: 'Today', className: 'today' },
  ], []);

  const nonWorkingDayspermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const canManage = isManagePermissionActive(nonWorkingDayspermission, 'AdministrationNonWorkingDays');
  const canDelete = isDeletePermissionActive(nonWorkingDayspermission, 'AdministrationNonWorkingDays');

  const dayCellClassNames = (arg: DayCellContentArg) => {
    const nonWorkingDay = nonWorkingDaysStore.allNonWorkingDays.find((event) => {
      const eventStart = new Date(event.date);
      return (
        eventStart.getDate() === arg.date.getDate()
          && eventStart.getMonth() === arg.date.getMonth()
          && eventStart.getFullYear() === arg.date.getFullYear()
      );
    });

    const isRecurringNonWorkingDay = recurringNonWorkingDaysStore.allRecurringNonWorkingDays.some(
      (nw) => nw.day === arg.date.getDay(),
    );

    if (nonWorkingDay || isRecurringNonWorkingDay) {
      return 'calendar-non-working-day';
    }

    return '';
  };

  const onDateSelection = (dates: DateSelectArg) => {
    if (!canManage) return;
    if (isOneDayMore(dates.start, dates.end)) {
      const calendarApi = calendarRef.current?.getApi();
      calendarApi?.unselect();
      setSelectedDates(undefined);

      dispatch(openModal({
        Component: AddNonWorkingDayForm,
        title: t('Add non working day'),
        open: true,
        componentProps: {
          selectedDates: dates,
        },
      }));
    } else {
      setSelectedDates(dates);
    }
  };

  const onDeleteClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number, isRecurringEvent: boolean,
  ) => {
    event.stopPropagation();
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: isRecurringEvent ? t('Recurring non-working day will be deleted') : t('Non-working day will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          if (!isRecurringEvent) {
            try {
              await dispatch(deleteNonWorkingDay(id));
            } finally {
              await dispatch(getAllNonWorkingDays());
              successToast(t('Successfully deleted non-working day'));
              dispatch(closeModal());
            }
          } else if (isRecurringEvent) {
            try {
              await dispatch(deleteRecurringNonWorkingDay(id));
            } finally {
              await dispatch(getAllRecurringNonWorkingDays());
              successToast(t('Successfully deleted recurring non-working day'));
              dispatch(closeModal());
            }
          }
        },
      },
    }));
  };

  const dayCellContent = (day: DayCellContentArg) => {
    let event: {id: number} | undefined;
    let isRecurringEvent = true;

    const recurringNonWorkingDay = recurringNonWorkingDaysStore.allRecurringNonWorkingDays.find(
      (e) => e.day === day.date.getDay(),
    );

    event = recurringNonWorkingDay;
    if (!event) {
      const currentEvent = nonWorkingDaysStore.calendarEvents.find(
        (e) => e.start
        && formatDate(
          new Date(e?.start.toString()).toISOString(),
        ) === formatDate(new Date(day.date.toString()).toISOString()),
      );

      if (currentEvent?.id) {
        event = { id: +currentEvent.id };
        isRecurringEvent = false;
      }
    }

    return (
      <div className="d-flex flex-column justify-content-between align-items-center mr-50-md" style={{ height: '80px' }}>
        <p
          className="m-0 text-center"
          style={{
            background: day.isToday ? '#1976d2' : 'transparent', width: '30px', height: '30px', borderRadius: '50%', padding: '5px',
          }}
        >{day.dayNumberText}
        </p>
        {event && (
          <div className="d-flex mb-1">
            {canManage
              ? (
                <Button color="outline" className="p-0 mr-50-md">
                  <Edit
                    width={15}
                    height={15}
                    color="#fff"
                    pointerEvents="fill"
                  />
                </Button>
              ) : <></>}
            {canDelete
              ? (
                <Button color="outline" className="m-0 p-0" innerRef={deleteButtonRef} onClick={(e) => event?.id && onDeleteClick(e, +event.id, isRecurringEvent)} data-isdeletebutton>
                  <Trash
                    width={15}
                    height={15}
                    data-isdeletebutton
                    color="#fff"
                    pointerEvents="fill"
                  />
                </Button>
              ) : <></>}
          </div>
        )}
      </div>
    );
  };

  const onDateClick = (date: DateClickArg) => {
    if (!canManage) return;
    const dataValue = (date.jsEvent.target as HTMLElement).getAttribute('data-isdeletebutton');

    let parentDataValue = false;
    if (!dataValue) {
      const parent = (date.jsEvent.target as HTMLElement).closest('[data-isdeletebutton]');
      parentDataValue = parent ? !!parent.getAttribute('data-isdeletebutton') : false;
    }

    let event: EventInput | undefined;
    event = nonWorkingDaysStore.calendarEvents.find(
      (e) => formatDate(e.start?.toString() ?? '') === date.dateStr,
    );

    if (event && !dataValue && !parentDataValue) {
      dispatch(openModal({
        Component: EditNonWorkingDayForm,
        title: t('Edit non-working day'),
        open: true,
        componentProps: {
          nonWorkingDay: nonWorkingDaysStore.allNonWorkingDays.find(
            (n) => event?.id && n.id === +event.id,
          ),
        },
      }));
    }

    if (!event) {
      event = recurringNonWorkingDaysStore.generatedEvents.find(
        (e) => e.daysOfWeek[0] === date.date.getDay().toString(),
      );

      if (event && !dataValue && !parentDataValue) {
        dispatch(openModal({
          Component: EditNonWorkingDayForm,
          title: `${t('Edit recurring non-working day')}: ${t(FULL_DAYS[date.date.getDay()])}`,
          open: true,
          componentProps: {
            recurringNonWorkingDay: recurringNonWorkingDaysStore.allRecurringNonWorkingDays.find(
              (n) => event?.id && n.id === +event.id,
            ),
          },
        }));
      }
    }
  };

  const onEventClick = (event: EventClickArg) => {
    if (!canManage) return;
    if (event.event._def.recurringDef) {
      const recurringNonWorkingDay = recurringNonWorkingDaysStore.allRecurringNonWorkingDays
        .find((nwd) => nwd.id.toString() === event.event.id);

      if (recurringNonWorkingDay) {
        dispatch(openModal({
          Component: EditNonWorkingDayForm,
          title: `${t('Edit recurring non-working day')}: ${t(FULL_DAYS[recurringNonWorkingDay.day])}`,
          open: true,
          componentProps: {
            recurringNonWorkingDay,
          },
        }));
      }
    } else {
      const nonWorkingDay = nonWorkingDaysStore.allNonWorkingDays
        .find((nwd) => nwd.id.toString() === event.event.id);

      if (nonWorkingDay) {
        dispatch(openModal({
          Component: EditNonWorkingDayForm,
          title: t('Edit non-working day'),
          open: true,
          componentProps: {
            nonWorkingDay,
          },
        }));
      }
    }
  };

  const eventContent = (event: EventContentArg) => (
    <div className="d-flex px-50 flex-column align-items-center" style={{ gap: '10px' }}>
      {event.event.title
      && (
      <p className="hide-md-down">
        {event.event.title.length > 30 ? `${event.event.title.slice(0, 30)}...` : event.event.title}
      </p>
      )}
    </div>
  );

  useEffect(() => {
    dispatch(getAllNonWorkingDays());
    dispatch(getAllRecurringNonWorkingDays());

    document.addEventListener('click', (event: MouseEvent) => {
      if (!buttonRef.current?.contains(event.target as Node)) {
        const calendarApi = calendarRef.current?.getApi();
        calendarApi?.unselect();
        setSelectedDates(undefined);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(setCalendarEvents(nonWorkingDaysStore.allNonWorkingDays.map(
      (nonWorkingDay) => ({
        id: nonWorkingDay.id, start: nonWorkingDay.date, title: nonWorkingDay.note ?? '', allDay: true, color: 'transparent',
      }),
    )));
  }, [nonWorkingDaysStore.allNonWorkingDays]);

  useEffect(() => {
    const events: EventInput[] = [];
    recurringNonWorkingDaysStore.allRecurringNonWorkingDays.forEach((nwd) => {
      events.push({
        title: nwd.note ?? '', id: nwd.id.toString(), daysOfWeek: [nwd.day.toString()], color: 'transparent',
      });
    });
    dispatch(setGeneratedEvents(events));
  }, [recurringNonWorkingDaysStore.allRecurringNonWorkingDays]);

  return (
    <>
      <FormHeader title={t('Non-working days')} subtitle={t('See and manage non-working days')} />

      <div className={`calendar-wrapper-${skin}`}>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={[
            ...nonWorkingDaysStore.calendarEvents,
            ...recurringNonWorkingDaysStore.generatedEvents,
          ]}
          dayCellClassNames={dayCellClassNames}
          firstDay={1}
          dateClick={onDateClick}
          eventClick={onEventClick}
          selectable
          unselectAuto={false}
          height={600}
          select={onDateSelection}
          selectOverlap={false}
          dayCellContent={dayCellContent}
          longPressDelay={300}
          eventContent={eventContent}
          locales={[{
            buttonText: {
              today: t('Today'),
            },
            code: 'rs',
          }]}
          dayHeaderContent={(arg) => t(DAYS[arg.date.getDay()])}
          locale="rs"
          titleFormat={(title) => `${t(MONTHS[title.date.month])} ${title.date.year}`}
          viewHeight={2000}
        />
        <div className="d-flex justify-content-between md-flex-direction-column-reverse">
          <Legend items={legendItems} />
          {(selectedDates && canManage)
          && (
            <div className="mt-1">
              <ShowModalButton
                modalProps={{
                  Component: AddNonWorkingDayForm,
                  title: t('Add non-working days'),
                  open: true,
                  componentProps: {
                    selectedDates,
                  },
                }}
                title={t('Add non-working days')}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NonWorkingDays;
