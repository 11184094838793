import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const paymentValidationSchema = (
) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    date: yup.string().nullable().required(t('Date is required')),
    referenceNumber: yup.string().required(t('Reference number is required')),
    amount: yup.string().required(t('Amount is required')),
    status: yup.string().required(t('Status is required')),
    childId: yup.number().min(1, t('Child is required')).required(t('Child is required')),
  });
  return validation;
};
export default paymentValidationSchema;
