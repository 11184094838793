import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { Card, Row } from 'reactstrap';
import PostPagination from '@src/views/posts/components/PostPagination';
import SpinnerComponent from '@components/spinner/Fallback-spinner';
import {
  getAllRoles,
} from '../store';
import RoleState from '../types/RoleState';
import Role from '../types/Role';
import NewRoleCard from '../components/NewRoleCard';
import RoleCard from '../components/RoleCard';

const Roles = () => {
  const dispatch = useDispatch<AppDispatch>();
  const roleStore = useSelector((store: RoleState) => store.roles);
  const [dataFiltered, setDataFiltered] = useState(false);
  const hiddenRoles = new Set([2, 4]);

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const [paginatedRoles, setPaginatedRoles] = useState<Role[]>([]);
  const [allFilteredRoles, setAllFilteredRoles] = useState<Role[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [loadOnce, setLoadOnce] = useState(false);
  const rolesPerPage = 9;

  useEffect(() => {
    const filteredRoles = roleStore.allRoles.filter((role) => !hiddenRoles.has(role.id));
    setAllFilteredRoles(filteredRoles);
    setPaginatedRoles(filteredRoles.slice(((activePage - 1) * rolesPerPage),
      rolesPerPage * activePage));
    if ((activePage - 1) * rolesPerPage === filteredRoles.length) {
      setActivePage(activePage === 1 ? 1 : activePage - 1);
    } else if (loadOnce) {
      setActivePage(1);
    }
    if (filteredRoles.length) {
      setLoadOnce(true);
    }
    setDataFiltered(true);
  }, [roleStore?.allRoles]);

  useEffect(() => {
    const filteredRoles = roleStore.allRoles.filter((role) => !hiddenRoles.has(role.id));
    setPaginatedRoles(filteredRoles.slice(((activePage - 1) * rolesPerPage),
      rolesPerPage * activePage));
  }, [activePage]);

  if (!dataFiltered) {
    return <SpinnerComponent />;
  }

  return (
    <>
      <Card>
        <NewRoleCard />
      </Card>
      <Row>
        {paginatedRoles.map((item) => <RoleCard key={item.id} role={item} />)}
      </Row>
      {allFilteredRoles?.length > 0
        && (
          <PostPagination
            totalPages={Number(Math.ceil(allFilteredRoles?.length / rolesPerPage) || 1)}
            activePage={activePage}
            setActive={setActivePage}
          />
        )}
    </>
  );
};

export default Roles;
