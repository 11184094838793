import { AppDispatch } from '@store/store';
import moment from 'moment';
import { closeModal, openModal } from '@store/modal';
import {
  useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import Lottie from 'react-lottie';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import {
  CalendarTime, Edit, LockAccess, Trash, User,
} from 'tabler-icons-react';
import { IconThumbUp } from '@tabler/icons-react';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import imagePlaceholder from '@src/assets/images/avatars/image-placeholder.json';
import videoPreview from '@src/assets/images/posts/video-preview.png';
import ViewDetailsModal from './ViewDetailsModal';
import Post from '../types/Post';
import { deletePost, getAllPosts } from '../store';

interface PostCardProps {
  imageContent: string;
  details: Post;
}

const PostsCard = ({ imageContent, details }: PostCardProps) => {
  // ** State
  const formatDate = (date: Date) => moment(date).format('DD. MM. YYYY').toString();
  const dispatch = useDispatch<AppDispatch>();
  const imageRef = useRef<HTMLImageElement>(null);
  const [post, setCurrentPost] = useState<Post>({} as Post);
  const [imageErrors, setImageErrors] = useState(false);
  const [viewDetails, toggleViewDetails] = useState(false);
  const [showGenericImage, setShowGenericImage] = useState(false);
  const postPermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const { t } = useTranslation();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: imagePlaceholder,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    if (imageContent === '') {
      setShowGenericImage(true);
      return;
    }

    if (imageRef.current && imageErrors) {
      imageRef.current.src = videoPreview;
      setImageErrors(false);
    }

    setShowGenericImage(false);
  }, [imageContent, imageErrors, videoPreview]);

  const handleConfirm = (postId: number) => {
    deletePost(postId).then(() => {
      dispatch(getAllPosts());
    }).then(() => dispatch(closeModal()));
  };

  const handleDeleteClick = () => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      componentProps: {
        handleConfirm: () => handleConfirm(details.id),
      },
      open: true,
      title: t('Delete post'),
      subtitle: t('Are you sure you want to delete post?'),
      modalProps: {
        size: 'sm',
      },
    }));
  };

  return (
    <>
      <Col md={4}>
        <Card>
          <CardBody>
            <Col md={12} className="mb-2">
              {
                showGenericImage
                  ? (
                    <Lottie
                      options={defaultOptions}
                      height={150}
                      width={150}
                    />
                  )
                  : (
                    <img
                      ref={imageRef}
                      onError={() => setImageErrors(true)}
                      className="mx-auto d-block"
                      style={{ maxWidth: '100%', borderRadius: '0.428rem' }}
                      src={imageContent}
                      alt="error"
                      height="200px"
                    />
                  )
              }
            </Col>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><CalendarTime size={20} />&nbsp;</span>
              {t('Post Created')}:&nbsp;
              {details.createdAt && formatDate(new Date(details.createdAt)) !== 'Invalid date' ? formatDate(new Date(details.createdAt)) : ''}
            </p>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><Edit size={20} />&nbsp;</span>
              {t('Post Updated')}:&nbsp;
              {details.updatedAt && formatDate(new Date(details.updatedAt)) !== 'Invalid date' ? formatDate(new Date(details.updatedAt)) : ''}
            </p>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><User size={20} />&nbsp;</span>
              {t('Published by')}:&nbsp;
              {`${details.user.name} ${details.user.surname}`}
            </p>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><IconThumbUp size={20} />&nbsp;</span>
              {t('Number of likes')}:&nbsp;
              {details.likes.length}
            </p>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><LockAccess size={20} />&nbsp;</span>
              {t('Visibility')}:&nbsp;
              {details?.tag ? t('Restricted') : t('Visible to all')}
            </p>
            <div className="d-flex flex-row justify-content-between">
              <div className="view-details__button">
                {isManagePermissionActive(postPermissions, 'Posts')
                  ? (
                    <Button
                      className="mt-1"
                      outline
                      color="primary"
                      onClick={() => { toggleViewDetails(true); setCurrentPost(details); }}
                    >
                      {t('View details')}
                    </Button>
                  ) : <></>}
              </div>
              {isDeletePermissionActive(postPermissions, 'Posts')
                ? (
                  <Button
                    size="sm"
                    color="transparent"
                    className="btn btn-icon align-items-center justify-content-center mt-1"
                    onClick={() => { handleDeleteClick(); }}
                  >
                    <Trash size={20} />
                  </Button>
                ) : <></>}
            </div>
          </CardBody>
        </Card>
      </Col>
      <ViewDetailsModal
        show={viewDetails}
        setShow={toggleViewDetails}
        currentPost={post}
      />
    </>
  );
};

export default PostsCard;
