import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { errorToast } from '@src/components/wrappers/ToastMessages';
import SelectedOptions from '@src/types/SelectedOptions';
import User from '@src/views/parents/types/User';
import NoteCategory from '../types/NoteCategory';
import NoteSubmitData from '../types/NoteSubmitData';
import Note from '../types/Note';

export const getAllNotes = createAsyncThunk('goKinder/getAllNotes', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/notes${query ? '?' : ''}${query ?? ''}`,
      { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const getAllAuthors = createAsyncThunk('goKinder/getAllAuthors', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/users/authors`);

    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const getNoteCategories = createAsyncThunk('goKinder/getAllNoteCategories', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/notes-categories`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const addNewNote = createAsyncThunk('goKinder/addNewNote', async (data: NoteSubmitData) => {
  try {
    const formData = new FormData();
    if (data.file) {
      formData.append('file', data.file, data.file?.name);
    }
    delete data.file;

    Object.entries(data).forEach((value) => {
      if (value[1]) {
        formData.append(value[0], value[1]?.toString() ?? '');
      }
    });

    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/notes`, formData, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const editNote = createAsyncThunk('goKinder/editNote', async (data: NoteSubmitData) => {
  try {
    const { id } = data;
    delete data.id;
    const formData = new FormData();
    if (data.file) {
      formData.append('file', data.file, data.file?.name);
    }
    delete data.file;

    Object.entries(data).forEach((value) => {
      if (value[1]) {
        formData.append(value[0], value[1]?.toString() ?? '');
      }
    });

    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/notes/${id}`, formData, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const deleteNote = createAsyncThunk('goKinder/deleteNote', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/notes/${id}`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const notesSlice = createSlice({
  name: 'notes',
  initialState: {
    allNotes: [] as Note[],
    noteCategories: [] as NoteCategory[],
    allAuthors: [] as unknown as User,
    selectedNoteCategories: [] as SelectedOptions[],
    selectedChildren: [] as SelectedOptions[],
    selectedObjects: [] as SelectedOptions[],
    selectedGroups: [] as SelectedOptions[],
    selectedAuthors: [] as SelectedOptions[],
  },
  reducers: {
    setSelectedNoteCategories: (state, action) => {
      state.selectedNoteCategories = action.payload;
    },
    setSelectedChildren: (state, action) => {
      state.selectedChildren = action.payload;
    },
    setSelectedObjects(state, action) {
      state.selectedObjects = action.payload;
    },
    setSelectedGroups(state, action) {
      state.selectedGroups = action.payload;
    },
    setSelectedAuthors(state, action) {
      state.selectedAuthors = action.payload;
    },
    clearAllNotes(state) {
      state.allNotes = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNoteCategories.fulfilled, (state, action) => {
      state.noteCategories = action.payload.data;
    });
    builder.addCase(getAllNotes.fulfilled, (state, action) => {
      state.allNotes = action.payload.data;
    });
    builder.addCase(getAllAuthors.fulfilled, (state, action) => {
      state.allAuthors = action.payload.data;
    });
  },
});

export const {
  setSelectedNoteCategories,
  setSelectedChildren,
  setSelectedGroups,
  setSelectedObjects,
  setSelectedAuthors,
  clearAllNotes,
} = notesSlice.actions;

export default notesSlice.reducer;
