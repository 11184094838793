import { ElementType } from 'react';
import { Card, CardBody } from 'reactstrap';

interface InfoCardProps {
  icon?: ElementType;
  iconColor?: string;
  cardClassName?: string;
  title: string;
  value: string | number;
  textColor?: string;
}

const defaultColor = '#7DCBEC';

const InfoCard: React.FC<InfoCardProps> = ({
  icon: IconComponent, cardClassName = '', title, value, textColor = defaultColor, iconColor = defaultColor,
}) => (
  <Card className={`text-center ${cardClassName}`}>
    <CardBody className="py-1">
      <div className="avatar p-50 m-0 mb-1 bg-light-success">
        <div className="avatar-content">{IconComponent && <IconComponent size={18} color={iconColor} />}</div>
      </div>
      <h6 className="fw-bolder">{title}</h6>
      <h5 style={{ color: textColor }} className="card-text line-ellipsis">
        {value}
      </h5>
    </CardBody>
  </Card>
);

export default InfoCard;
