import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import User from '../types/User';

const userValidationSchema = (
  users: User[],
) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    name: yup.string().required(t('Name is required')),
    surname: yup.string().required(t('Surname is required')),
    email: yup
      .string()
      .email(t('Enter email in valid format'))
      .required(t('Email is required'))
      .test('unique', t('Value must be unique'), (value) => !users.some((p) => p.email === value)),
    objects: yup.array().min(1, t('At least one object is required')),
    role: yup.object().nullable().required(t('Role is required')).shape({
      value: yup.number().required(t('Role is required')),
    }),
  });
  return validation;
};

export default userValidationSchema;
