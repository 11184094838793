import { DateSelectArg } from '@fullcalendar/react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col, FormFeedback, Input, Label, Row,
} from 'reactstrap';
import { closeModal } from '@store/modal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import CustomLabel from '@src/components/forms/CustomLabel';
import Flatpickr from 'react-flatpickr';
import { formatDate } from '@src/views/polls/components/PollsForm';
import { getDatesInRange, getOneDayEarlierFormatted } from '@src/utility/Utils';
import { useMemo, useRef, useState } from 'react';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { FULL_DAYS } from '@src/constants/days';
import NonWorkingDaySubmitData from '../types/NonWorkingDaySubmitData';
import { createMultipleNonWorkingDays, createNonWorkingDay, getAllNonWorkingDays } from '../store';
import { createRecurringNonWorkingDay, getAllRecurringNonWorkingDays } from '../store/RecurringNonWorkingDaysStore';

type Props = {
  selectedDates: DateSelectArg,
}

const AddNonWorkingDayForm: React.FC<Props> = ({ selectedDates }) => {
  const { t } = useTranslation();
  const isRecurringRef = useRef<HTMLInputElement>(null);
  const [isRecurring, setIsRecurring] = useState(false);
  const { startDate, endDate } = useMemo(() => ({
    startDate: formatDate(selectedDates.start.toISOString()),
    endDate: getOneDayEarlierFormatted(selectedDates.end),
  }), []);

  const dispatch = useDispatch<AppDispatch>();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<NonWorkingDaySubmitData>({
    defaultValues: {
      isRecurringNonWorkingDay: '0',
    },
  });

  const submitHandler = (data: NonWorkingDaySubmitData) => {
    if (startDate === endDate) {
      if (!data.isRecurringNonWorkingDay || data.isRecurringNonWorkingDay === '0') {
        dispatch(createNonWorkingDay({ date: startDate, note: data.note }))
          .finally(() => {
            dispatch(getAllNonWorkingDays());
            successToast(t('Successfully added non-working day'));
            dispatch(closeModal());
          });
      } else {
        dispatch(createRecurringNonWorkingDay(
          { day: selectedDates.start.getDay(), note: data.note },
        ))
          .finally(() => {
            dispatch(getAllRecurringNonWorkingDays());
            successToast(t('Successfully added recurring non-working day'));
            dispatch(closeModal());
          });
      }
    } else {
      const datesArray = getDatesInRange(
        selectedDates.start, new Date(getOneDayEarlierFormatted(selectedDates.end)),
      );

      dispatch(createMultipleNonWorkingDays(
        {
          dates: datesArray.map((date) => formatDate(date.toISOString())),
          note: data.note,
        },
      )).finally(() => {
        dispatch(getAllNonWorkingDays());
        successToast(t('Successfully added non-working days'));
        dispatch(closeModal());
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Row>
        {!isRecurring
        && (
        <Col md={6}>
          <Label>{startDate !== endDate ? t('From') : t('Date')} </Label>
          <Flatpickr
            readOnly
            disabled
            className="form-control full-opacity"
            value={selectedDates.start}
          />
        </Col>
        )}
        {startDate !== endDate
        && (
        <Col md={6}>
          <CustomLabel name={t('To')} />
          <Flatpickr
            readOnly
            disabled
            className="form-control full-opacity"
            value={new Date(getOneDayEarlierFormatted(selectedDates.end))}
          />
        </Col>
        )}
      </Row>
      <Col md={12} className="mt-1">
        <CustomLabel name={t('Note')} />
        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <Input
              rows="4"
              type="textarea"
              id="description"
              maxLength={250}
              invalid={errors.note !== undefined}
              {...field}
            />
          )}
        />
        {errors.note && <FormFeedback className="text-center">{errors.note.message}</FormFeedback>}
      </Col>
      {startDate === endDate
      && (
      <Col md={12} className="d-flex align-items-center mt-1">
        <Controller
          name="isRecurringNonWorkingDay"
          control={control}
          render={({ field }) => (
            /* eslint-disable-next-line */
                /* @ts-ignore */
            <Input id="visible" type="checkbox" checked={field.value === true || field.value === '1'} {...field} innerRef={isRecurringRef} onClick={() => { setIsRecurring(isRecurringRef.current?.checked ?? false); }} />
          )}
        />
        <CustomLabel name={`${t('Set as non-working-day')}: ${t(FULL_DAYS[selectedDates.start.getDay()])}`} htmlFor="visible" className="mx-50 my-0" />
      </Col>
      )}
      <Col md={12} className="text-center mt-2 pt-50">
        <Button
          className="me-1"
          color="primary"
          type="submit"
        >
          {t('Add')}
        </Button>
        <Button
          type="reset"
          color="secondary"
          outline
          onClick={() => dispatch(closeModal())}
        >
          {t('Close')}
        </Button>
      </Col>
    </form>
  );
};

export default AddNonWorkingDayForm;
