import { AppDispatch } from '@store/store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PageFilters from '@src/views/misc/PageFilters';
import TenantGroupState from '@src/views/tenantGroups/types/TenantGroupState';
import TenantObjectState from '@src/views/tenantObjects/types/TenantObjectState';
import useFilter from '@src/utility/hooks/useFilter';
import PostPagination from '@src/views/posts/components/PostPagination';
import { getAllTenantGroups } from '@src/views/tenantGroups/store';
import { getAllTenantObjects } from '@src/views/tenantObjects/store';
import {
  clearAllNotes,
  getAllAuthors,
  getAllNotes, getNoteCategories, setSelectedAuthors, setSelectedGroups, setSelectedNoteCategories,
  setSelectedObjects,
} from '../store';
import NewNoteCard from '../components/NewNoteCard';
import NotesState from '../types/NotesState';
import NotesCard from '../components/NotesCard';
import Note from '../types/Note';

const Notes = () => {
  const dispatch = useDispatch<AppDispatch>();
  const notesStore = useSelector((state: NotesState) => state.notes);
  const allTenantObjects = useSelector(
    (state: TenantObjectState) => state.tenantObjects.allTenantObjects,
  );
  const allTenantGroups = useSelector(
    (state: TenantGroupState) => state.tenantGroups.allTenantGroups,
  );
  const allAuthors = useSelector(
    (state: NotesState) => state.notes.allAuthors,
  );
  const [searched, setSearched] = useState(false);
  const { t } = useTranslation();

  const { onFilterChange, query } = useFilter();
  const [notes, setNotes] = useState<Note[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [loadOnce, setLoadOnce] = useState(false);
  const postPerPage = 6;

  useEffect(() => {
    setNotes(notesStore?.allNotes.slice(((activePage - 1) * postPerPage),
      postPerPage * activePage));
    if ((activePage - 1) * postPerPage === notesStore?.allNotes.length) {
      setActivePage(activePage === 1 ? 1 : activePage - 1);
    } else if (loadOnce) {
      setActivePage(1);
    }
    if (notesStore?.allNotes.length) {
      setLoadOnce(true);
    }
  }, [notesStore?.allNotes]);

  useEffect(() => {
    setNotes(notesStore?.allNotes.slice(((activePage - 1) * postPerPage),
      postPerPage * activePage));
  }, [activePage]);

  useEffect(() => {
    dispatch(clearAllNotes());
    dispatch(getAllTenantObjects());
    dispatch(getAllTenantGroups());
    dispatch(getNoteCategories());
    dispatch(getAllAuthors());
  }, []);

  useEffect(() => {
    const queryUrl = new URLSearchParams(query);
    const filterObjectIds = queryUrl.get('objectIds')?.split(',');

    if (filterObjectIds) {
      const activeGroups = allTenantGroups.filter(
        (group) => filterObjectIds?.includes(group.object.id.toString()),
      );
      dispatch(setSelectedGroups(activeGroups.map(
        (group) => ({ label: group.name, value: group.id }),
      )));
    } else {
      dispatch(setSelectedGroups([]));
      onFilterChange('groupIds', []);
    }
  }, [query]);

  const transformToSelectedObj = (objects: { name: string, id: number }[],
    translate = false) => objects.map(
    (obj) => ({ label: translate ? t(obj.name) : obj.name, value: obj.id }),
  );

  useEffect(() => {
    dispatch(setSelectedObjects(transformToSelectedObj(allTenantObjects)));
  }, [allTenantObjects]);

  useEffect(() => {
    dispatch(setSelectedAuthors(transformToSelectedObj(allAuthors)));
  }, [allAuthors]);

  useEffect(() => {
    dispatch(setSelectedNoteCategories(transformToSelectedObj(notesStore.noteCategories, true)));
  }, [notesStore.noteCategories]);

  const onFilterClick = () => {
    dispatch(getAllNotes(query));
    if (!searched) setSearched(true);
  };

  return (
    <>
      <Card>
        <NewNoteCard />
        <PageFilters
          filters={[
            {
              label: 'Name', name: 'name',
            },
            {
              label: 'Object', name: 'objectIds', options: notesStore.selectedObjects, isMulti: true,
            },
            {
              label: 'Group', name: 'groupIds', options: notesStore.selectedGroups, isMulti: true,
            },
            {
              label: 'Author', name: 'authorIds', options: notesStore.selectedAuthors, isMulti: true,
            },
            {
              label: 'Note category', name: 'categoryIds', options: notesStore.selectedNoteCategories, isMulti: true,
            },
          ]}
          onFilterClick={onFilterClick}
          onChange={onFilterChange}
        />
      </Card>
      <Row>
        {
          notes.map((note) => (
            <NotesCard
              details={note}
              key={note.id}
              pdfContent={note?.file || ''}
            />
          ))
        }
        {
          (notes.length === 0 && searched) && (
          <div style={{
            textAlign: 'center',
            width: '100%',
            fontSize: '20px',
            fontWeight: 'bold',
          }}
          >
            {t('There are no notes to display.')}
          </div>
          )
        }
      </Row>
      {notesStore?.allNotes?.length > 0
        && (
          <PostPagination
            totalPages={Number(Math.ceil(notesStore?.allNotes?.length / postPerPage) || 1)}
            activePage={activePage}
            setActive={setActivePage}
          />
        )}
    </>
  );
};

export default Notes;
