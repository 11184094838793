/* eslint-disable no-restricted-syntax */
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col, Form, Input, Label, Row, Spinner,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { useEffect, useRef, useState } from 'react';
import { AppDispatch } from '@store/store';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';
import { formatDateToShortDate, selectThemeColors } from '@src/utility/Utils';
import Select, { SingleValue } from 'react-select';
import SelectedOptions from '@src/types/SelectedOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Trash } from 'react-feather';
import SpinnerComponent from '@components/spinner/Fallback-spinner';
import { getAllParents } from '@src/views/parents/store';
import TenantGroupState from '@src/views/tenantGroups/types/TenantGroupState';
import TenantObjectState from '@src/views/tenantObjects/types/TenantObjectState';
import { getAllTenantObjects } from '@src/views/tenantObjects/store';
import Lottie from 'react-lottie';
import imagePlaceholder from '@src/assets/images/avatars/image-placeholder.json';
import { getAllTenantGroups } from '@src/views/tenantGroups/store';
import StoreState from '@store/StoreState';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import FormHeader from '@src/components/forms/FormHeader';
import { getAllPackages } from '@src/views/packages/store';
import RequestStatus from '@src/types/RequestStatus';
import PackageState from '@src/views/packages/types/PackageState';
import SubmitData from '../types/SubmitData';
import {
  addNewChild,
  deleteImage,
  editChild,
  getAllChildren,
  getChildById,
  getChildStatuses,
  getDocumentIdentifiers,
  getFamilyStatuses,
  getTypesOfService,
  setSelectedChildStatuses,
  setSelectedDocumentIdentifiers,
  setSelectedFamilyStatuses,
  setSelectedGroups,
  setSelectedObjects,
  setSelectedPackages,
  setSelectedParents,
  setSelectedTypesOfService,
  updateChildImage,
} from '../store';
import ChildrenState from '../types/ChildrenState';
import { childrenValidationSchema } from '../validation';
import discounts from '../constants/discounts';
import acceptedFiles from '../constants/accepted-files';
import Child from '../types/Child';

const ChildManagement = () => {
  const { t } = useTranslation();
  const { childId } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: ChildrenState) => state.children);
  const [selectedObject, setSelectedObject] = useState<SingleValue<SelectedOptions>>();
  const [selectedGroup, setSelectedGroup] = useState<SingleValue<SelectedOptions>>();
  const [selectedDiscount, setSelectedDiscount] = useState<SingleValue<SelectedOptions>>();
  const [selectedFamilyStatus, setSelectedFamilyStatus] = useState<SingleValue<SelectedOptions>>();
  const [selectedChildStatus, setSelectedChildStatus] = useState<SingleValue<SelectedOptions>>();
  const groupStore = useSelector((state: TenantGroupState) => state.tenantGroups);
  const objectStore = useSelector((state: TenantObjectState) => state.tenantObjects);
  const parentStore = useSelector((state: StoreState) => state.parents);
  const packageStore = useSelector((state: PackageState) => state.packages);
  const [groupOptions, setGroupOptions] = useState<SelectedOptions[]>();
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageSource, setImageSource] = useState('');
  const [isSaveAndContinue, setIsSaveAndContinue] = useState(false);
  const [
    selectedContractHolder,
    setSelectedContractHolder,
  ] = useState<SingleValue<SelectedOptions>>();
  const [
    selectedTypeOfService,
    setSelectedTypeOfService,
  ] = useState<SingleValue<SelectedOptions>>();
  const [
    selectedDocumentIdentifier,
    setSelectedDocumentIdentifier,
  ] = useState<SingleValue<SelectedOptions>>();
  const [
    selectedPredefinedPackageId,
    setSelectedPredefinedPackageId,
  ] = useState<SingleValue<SelectedOptions>>();

  const imageRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const defaultValues: SubmitData = {
    ...store?.currentChild,
    group: store.currentChild?.object?.id,
    object: store.currentChild?.group?.id,
    image: null,
  } as SubmitData;

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm<SubmitData & { imageInput?: string }>({
    reValidateMode: 'onChange',
    resolver: yupResolver(childrenValidationSchema(
      selectedObject,
      selectedGroup,
      selectedTypeOfService,
      selectedDocumentIdentifier,
      selectedFamilyStatus,
      selectedPredefinedPackageId,
    )),
  });

  const onObjectChangeHandler = (val: SingleValue<SelectedOptions>) => {
    setSelectedObject(val);
    setGroupOptions(store.selectedGroups.filter((group) => group.objectId === val?.value));
    setSelectedGroup(null);
  };

  useEffect(() => {
    const errorFields = Object.keys(errors);
    if (errorFields.length) {
      const basicInformationFields = new Set(['name', 'surname', 'familyStatus', 'embg']);
      const enrollmentDetailsFields = new Set(['object', 'group', 'typeOfService', 'predefinedPackageId', 'documentNumber']);
      let isBasicInfoError = false;
      for (const err of errorFields) {
        if (basicInformationFields.has(err)) isBasicInfoError = true;
      }
      if (isBasicInfoError) {
        const basicInfoElement = document.getElementById('basic-information');
        const basicInfoOffsetTopPosition = basicInfoElement?.offsetTop ?? 0;
        // decrease 108.5 px from app container padding to get exact value
        window.scrollTo({ top: basicInfoOffsetTopPosition - 108.5 });
      } else {
        const enrollmentDetailsElement = document.getElementById('enrollment-details');
        const enrollmentDetailsOffsetTopPosition = enrollmentDetailsElement?.offsetTop ?? 0;
        let isEnrollmentDetailsError = false;
        for (const err of errorFields) {
          if (enrollmentDetailsFields.has(err)) isEnrollmentDetailsError = true;
        }
        if (isEnrollmentDetailsError) {
          window.scrollTo({ top: enrollmentDetailsOffsetTopPosition - 108.5 });
        }
      }
    }
  }, [errors]);

  const onImageChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;

    if (file) {
      if (acceptedFiles.includes(file?.type ?? '')) {
        setSelectedImage(file);
        clearErrors('image');

        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result as string;
          setImageSource(dataUrl);
        };

        reader.readAsDataURL(file);

        if (childId) {
          dispatch(updateChildImage({ id: +childId, image: file }));
        }
      } else {
        setSelectedImage(null);
        setError('image', { type: 'value', message: t('Uploaded file is in unsuported type. Please upload .jpg, .jpeg or .png file') });
      }
    } else {
      setSelectedImage(null);
      clearErrors('image');
    }
  };

  const clearImage = () => {
    setSelectedImage(null);
    setImageSource('');
    clearErrors('image');
  };

  const imageClearHandler = () => {
    if (!childId) {
      clearImage();
    }

    if (childId && imageSource) {
      dispatch(
        openModal({
          Component: ConfirmationModalWrapper,
          title: t('Are you sure?'),
          subtitle: t('Image will be deleted'),
          open: true,
          componentProps: {
            handleConfirm: async () => {
              setLoading(true);
              try {
                await dispatch(deleteImage(+childId));
                clearImage();
              } finally {
                setLoading(false);
                dispatch(closeModal());
              }
            },
          },
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(getAllChildren());
    dispatch(getAllTenantObjects());
    dispatch(getAllTenantGroups());
    dispatch(getTypesOfService());
    dispatch(getFamilyStatuses());
    dispatch(getChildStatuses());
    dispatch(getDocumentIdentifiers());
    dispatch(getAllPackages());
    dispatch(getAllParents(childId ? `childId=${childId}` : ''));
  }, []);

  useEffect(() => {
    dispatch(setSelectedObjects(objectStore.allTenantObjects.map(
      (obj) => ({ label: obj.name, value: obj.id }),
    )));

    dispatch(setSelectedGroups(groupStore.allTenantGroups.map(
      (group) => ({ label: group.name, value: group.id, objectId: group.object.id }),
    )));

    dispatch(setSelectedTypesOfService(store.typesOfService.map(
      (service) => ({ label: t(service), value: service }),
    )));

    dispatch(setSelectedFamilyStatuses(store.familyStatuses.map(
      (status) => ({ label: t(status), value: status }),
    )));

    dispatch(setSelectedDocumentIdentifiers(store.documentIdentifiers.map(
      (identifier) => ({ label: t(identifier), value: identifier }),
    )));

    dispatch(setSelectedParents(parentStore.allParents.map((parent) => {
      const embg = parent.embg ? `(${parent.embg})` : '';
      return { label: `${parent.name} ${parent.surname} ${embg}`, value: parent.id };
    })));

    dispatch(setSelectedChildStatuses(store.childStatuses.map(
      (status) => ({ label: t(status), value: status }),
    )));

    dispatch(setSelectedPackages(packageStore.allPackages.map(
      (pkg) => ({ label: t(pkg.name), value: pkg.id }),
    )));
  }, [
    objectStore.allTenantObjects,
    groupStore.allTenantGroups,
    parentStore.allParents,
  ]);

  useEffect(() => {
    if (childId) {
      dispatch(getChildById(+childId)).finally(() => setIsPageLoading(false));
    }
  }, []);

  useEffect(() => {
    if (!childId) {
      setSelectedObject(store.selectedObjects[0] ?? '');
      setGroupOptions(store.selectedGroups.filter(
        (group) => store.selectedObjects[0]?.value === group.objectId,
      ));

      setSelectedFamilyStatus(store.selectedFamilyStatuses[0]);
      setSelectedTypeOfService(store.selectedTypesOfService[0]);

      setIsPageLoading(false);
    }
  }, [
    store.selectedObjects,
    store.selectedGroups,
    store.selectedFamilyStatuses,
    store.selectedTypesOfService,
  ]);

  useEffect(() => {
    setSelectedChildStatus(
      store.selectedChildStatuses.find(
        (childStatus) => childStatus.value === store.currentChild.status,
      ),
    );
  }, [store.selectedChildStatuses]);

  useEffect(() => {
    setSelectedFamilyStatus(
      store.selectedFamilyStatuses.find(
        (familyStatus) => familyStatus.value === store.currentChild.familyStatus,
      ),
    );
  }, [store.selectedFamilyStatuses]);

  useEffect(() => {
    if (childId) {
      reset({
        ...defaultValues,
        image: null,
      });

      if (Object.keys(store.currentChild).length > 0) {
        const childsTenantObject = store.selectedObjects.find(
          (obj) => obj.value === store.currentChild.object?.id,
        );

        setSelectedObject(childsTenantObject);

        setGroupOptions(store.selectedGroups.filter(
          (group) => childsTenantObject?.value === group.objectId,
        ));

        setSelectedGroup(
          store.selectedGroups.find((group) => group.value === store.currentChild.group.id),
        );

        setSelectedFamilyStatus(
          store.selectedFamilyStatuses.find(
            (familyStatus) => familyStatus.value === store.currentChild.familyStatus,
          ),
        );

        setSelectedChildStatus(
          store.selectedChildStatuses.find(
            (childStatus) => childStatus.value === store.currentChild.status,
          ),
        );

        setSelectedDocumentIdentifier(
          store.selectedDocumentIdentifiers.find(
            (documentIdentifier) => documentIdentifier.value
              === store.currentChild.documentIdentifier,
          ),
        );

        setSelectedContractHolder(
          store.selectedParents.find(
            (parent) => parent.value === store.currentChild.contractHolder?.id,
          ),
        );

        setSelectedTypeOfService(
          store.selectedTypesOfService.find(
            (service) => service.value === store.currentChild.typeOfService,
          ),
        );

        setSelectedPredefinedPackageId(
          store.selectedPackages.find(
            (pkg) => pkg.value === store.currentChild.predefinedPackageId,
          ),
        );

        setImageSource(store?.currentChild?.image.toString());
      }
    }
  }, [store?.currentChild]);

  useEffect(() => {
    if (selectedChildStatus?.value === store.childStatuses[0] && !!getValues('dateOfTermination')) setValue('dateOfTermination', '');
  }, [selectedChildStatus]);

  useEffect(() => {
    if (store.currentChild.typeOfService && store.selectedTypesOfService) {
      setSelectedTypeOfService(
        store.selectedTypesOfService.find(
          (service) => service.value === store.currentChild.typeOfService,
        ),
      );
    }
  }, [store.selectedTypesOfService, store.currentChild]);

  useEffect(() => {
    if (store.currentChild.contractHolder && store.selectedParents) {
      setSelectedContractHolder(
        store.selectedParents.find(
          (parent) => parent.value === store.currentChild.contractHolder?.id,
        ),
      );
    }
  }, [store.selectedParents, store.currentChild]);

  useEffect(() => {
    if (store.currentChild && packageStore.allPackages.length) {
      setSelectedPackages(
        packageStore.allPackages.find(
          (pkg) => pkg.id === store.currentChild.predefinedPackageId,
        ),
      );
    }
  }, [packageStore.allPackages, store.currentChild]);

  useEffect(() => {
    if (selectedChildStatus?.value === store.childStatuses[0] && !!getValues('dateOfTermination')) setSelectedChildStatus(store.selectedChildStatuses[store.childStatuses.length - 1]);
  }, [watch('dateOfTermination')]);

  const navigateOnSave = (id: number, isNewChild = false) => {
    setLoading(false);
    if (isSaveAndContinue) {
      navigate('/children/new');
    } else if (isNewChild) {
      navigate(`/children/${id}/parent-relationship`);
    } else {
      navigate(`/children/${id}`);
    }
  };

  const handleSuccessfulSubmit = async (data: SubmitData) => {
    setLoading(true);
    const subData = { ...data };

    if (subData.dateOfBirth) subData.dateOfBirth = formatDateToShortDate(subData.dateOfBirth);
    if (subData.dateOfEnrollment) {
      subData.dateOfEnrollment = formatDateToShortDate(subData.dateOfEnrollment);
    }
    if (subData.contractDate) subData.contractDate = formatDateToShortDate(subData.contractDate);
    if (subData.dateOfTermination) {
      subData.dateOfTermination = formatDateToShortDate(subData.dateOfTermination);
    }
    const filteredData = Object.fromEntries(
      Object.entries(subData).filter(([_, value]) => value),
    );

    filteredData.objectId = selectedObject?.value ?? null;
    filteredData.groupId = selectedGroup?.value ?? null;
    filteredData.typeOfService = selectedTypeOfService?.value.toString() ?? null;
    filteredData.familyStatus = selectedFamilyStatus?.value.toString() ?? null;
    filteredData.predefinedPackageId = selectedPredefinedPackageId?.value.toString() ?? null;
    filteredData.contractHolderId = selectedContractHolder?.value ?? null;
    filteredData.discount = selectedDiscount?.value ?? null;
    filteredData.documentIdentifier = selectedDocumentIdentifier?.value ?? null;
    filteredData.image = selectedImage;

    if (!childId) {
      dispatch(addNewChild(filteredData as SubmitData))
        .then((newChildData) => {
          if (newChildData.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            const payload = newChildData.payload as { data: Child };
            navigateOnSave(payload.data?.id, true);
          }
        });
    } else if (childId) {
      filteredData.id = +childId;
      filteredData.status = selectedChildStatus?.value.toString() ?? null;
      delete filteredData.parents;
      delete filteredData.contractHolder;
      delete filteredData.predefinedPackage;
      dispatch(editChild(filteredData as SubmitData))
        .then((editedChildData) => {
          if (editedChildData.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            navigateOnSave(+childId);
          }
        });
    } else {
      navigate('/');
    }
    setLoading(false);
  };

  if (isPageLoading) {
    return <SpinnerComponent />;
  }

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <FormHeader
        title={childId ? t('Update child information') : t('Child enrollment')}
        subtitle={childId ? t('Check and edit child details') : t('Please enter child details to complete enrolment')}
        button={childId ? (
          <Button
            color="primary"
            className="text-nowrap mb-1 mt-2"
            outline
            onClick={() => { navigate(`/children/${childId}`); }}
          >
            {t('Go to child profile')}
          </Button>
        ) : undefined}
      />
      <Col md={12} id="basic-information">
        <Card className="m-10">
          <CardTitle className="p-1 mb-0">
            {t('Basic information')}
          </CardTitle>
          <CardBody>
            <Row className="g-2">
              <Col
                md={2}
                className="d-flex"
                style={{
                  justifyContent: 'center',
                  flexShrink: 0,
                  minWidth: '160px',
                  boxSizing: 'border-box',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <Row className="d-none">
                  <Controller
                    name="imageInput"
                    control={control}
                    render={({ field }) => (
                      <Input
                        id="image"
                        type="file"
                        invalid={!!errors.image}
                        {...field}
                        innerRef={imageRef}
                        onChange={onImageChangeHandler}
                        accept=".png, .jpg, .jpeg"
                      />
                    )}
                  />
                </Row>
                <Row style={{ cursor: 'pointer', minWidth: '160px', minHeight: '160px' }} onClick={() => { imageRef.current?.click(); }}>
                  {imageSource === '' ? (
                    <Lottie
                      options={{
                        loop: false,
                        autoplay: true,
                        animationData: imagePlaceholder,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      height={150}
                      width={150}
                      style={{ margin: 0, flexShrink: 0 }}
                    />
                  ) : (
                    <img
                      src={imageSource}
                      alt={t('Child')}
                      style={{
                        borderRadius: '5px', padding: 0, width: '150px', height: '150px',
                      }}
                    />
                  )}
                </Row>
                <Row>
                  <Button
                    size="sm"
                    color="transparent"
                    className="btn btn-icon"
                    onClick={imageClearHandler}
                  >
                    <Trash className="text-center" size={15} />
                  </Button>
                </Row>
                {errors?.image
                  && (
                    <Row>
                      <CustomFormFeedback message={errors?.image?.message} />
                    </Row>
                  )}
              </Col>
              <Col md={10}>
                <Row>
                  <Col md={4}>
                    <CustomLabel name={t('Name')} required htmlFor="name" />
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <Input id="name" invalid={!!errors.name} {...field} />
                      )}
                    />
                    <CustomFormFeedback message={errors?.name?.message} />
                  </Col>
                  <Col md={4}>
                    <CustomLabel name={t('Surname')} required htmlFor="surname" />
                    <Controller
                      name="surname"
                      control={control}
                      render={({ field }) => (
                        <Input id="surname" invalid={!!errors.surname} {...field} />
                      )}
                    />
                    <CustomFormFeedback message={errors?.surname?.message} />
                  </Col>
                  <Col md={4}>
                    <CustomDatePicker<SubmitData>
                      control={control}
                      error={errors?.dateOfBirth?.message}
                      setValue={setValue}
                      label={t('Date of birth')}
                      name="dateOfBirth"
                      options={{ minDate: undefined }}
                      defaultValue={childId ? (store.currentChild?.dateOfBirth?.toString() ?? '') : ''}
                      clear
                    />
                    <CustomFormFeedback message={errors?.dateOfBirth?.message} />
                  </Col>
                  <Col md={4}>
                    <CustomLabel name={t('EMBG')} htmlFor="embg" />
                    <Controller
                      name="embg"
                      control={control}
                      render={({ field }) => (
                        <Input id="embg" type="number" invalid={!!errors.embg} {...field} />
                      )}
                    />
                    <CustomFormFeedback message={errors?.embg?.message} />
                  </Col>
                  <Col md={4}>
                    <Row>
                      <Col md={8}>
                        <CustomLabel name={t('Street')} htmlFor="street" />
                        <Controller
                          name="street"
                          control={control}
                          render={({ field }) => (
                            <Input id="street" invalid={!!errors.street} {...field} />
                          )}
                        />
                        <CustomFormFeedback message={errors?.street?.message} />
                      </Col>
                      <Col md={4}>
                        <CustomLabel name={t('Number')} htmlFor="streetNumber" />
                        <Controller
                          name="streetNumber"
                          control={control}
                          render={({ field }) => (
                            <Input id="streetNumber" invalid={!!errors.streetNumber} {...field} />
                          )}
                        />
                        <CustomFormFeedback message={errors?.streetNumber?.message} />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <CustomLabel name={t('Municipality')} htmlFor="municipality" />
                    <Controller
                      name="municipality"
                      control={control}
                      render={({ field }) => (
                        <Input id="municipality" invalid={!!errors.municipality} {...field} />
                      )}
                    />
                    <CustomFormFeedback message={errors?.municipality?.message} />
                  </Col>
                  <Col md={4}>
                    <CustomLabel name={t('City')} htmlFor="city" />
                    <Controller
                      name="city"
                      control={control}
                      render={({ field }) => (
                        <Input id="city" invalid={!!errors.city} {...field} />
                      )}
                    />
                    <CustomFormFeedback message={errors?.city?.message} />
                  </Col>
                  <Col md={4}>
                    <CustomLabel name={t('Postal code')} htmlFor="postalCode" />
                    <Controller
                      name="postalCode"
                      control={control}
                      render={({ field }) => (
                        <Input id="postalCode" type="number" invalid={!!errors.postalCode} {...field} />
                      )}
                    />
                    <CustomFormFeedback message={errors?.postalCode?.message} />
                  </Col>
                  <Col md={4}>
                    <CustomLabel name={t('Family status')} htmlFor="postalCode" required />
                    <Select
                      name="familyStatus"
                      className="react-select"
                      classNamePrefix="select"
                      theme={selectThemeColors}
                      placeholder={t('Select')}
                      options={store.selectedFamilyStatuses}
                      onChange={(val) => setSelectedFamilyStatus(val)}
                      value={selectedFamilyStatus}
                    />
                    <CustomFormFeedback message={errors?.familyStatus?.message} />
                  </Col>
                  {childId && (
                  <Col md={4}>
                    <Label>{t('Child status')}</Label>
                    <Select
                      name="status"
                      className="react-select"
                      classNamePrefix="select"
                      theme={selectThemeColors}
                      placeholder={t('Select')}
                      options={store.selectedChildStatuses}
                      onChange={(val) => setSelectedChildStatus(val)}
                      value={selectedChildStatus}
                    />
                    <CustomFormFeedback message={errors?.status?.message} />
                  </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col md={12} id="enrollment-details">
        <Card className="mt-10">
          <CardTitle className="p-1 mb-0">
            {t('Enrollment details')}
          </CardTitle>
          <CardBody>
            <Row>
              <Col md={4}>
                <CustomLabel name={t('Object')} htmlFor="object" required />
                <Select
                  name="object"
                  className="react-select"
                  classNamePrefix="select"
                  theme={selectThemeColors}
                  placeholder={t('Select')}
                  options={store.selectedObjects}
                  onChange={onObjectChangeHandler}
                  value={selectedObject}
                />
                <CustomFormFeedback message={errors?.object?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Group')} htmlFor="group" required />
                <Select
                  name="group"
                  className="react-select"
                  classNamePrefix="select"
                  theme={selectThemeColors}
                  placeholder={t('Select')}
                  options={groupOptions}
                  onChange={(val) => setSelectedGroup(val)}
                  value={selectedGroup}
                />
                <CustomFormFeedback message={errors?.group?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Package')} required />
                <Select
                  name="predefinedPackageId"
                  className="react-select"
                  classNamePrefix="select"
                  theme={selectThemeColors}
                  placeholder={t('Select')}
                  options={store.selectedPackages}
                  onChange={(val) => setSelectedPredefinedPackageId(val)}
                  value={selectedPredefinedPackageId}
                />
                <CustomFormFeedback message={errors?.predefinedPackageId?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Type of service')} required />
                <Select
                  name="typeOfService"
                  className="react-select"
                  classNamePrefix="select"
                  theme={selectThemeColors}
                  placeholder={t('Select')}
                  options={store.selectedTypesOfService}
                  onChange={(val) => setSelectedTypeOfService(val)}
                  value={selectedTypeOfService}
                />
                <CustomFormFeedback message={errors?.typeOfService?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Discount')} />
                <Select
                  name="discount"
                  className="react-select"
                  classNamePrefix="select"
                  theme={selectThemeColors}
                  placeholder={t('Select')}
                  options={discounts.map(
                    (discount) => ({ value: discount.value, label: t(discount.label) ?? '' }),
                  )}
                  onChange={(val) => setSelectedDiscount(val)}
                  defaultValue={{ value: discounts[0].value, label: t(discounts[0].label) ?? '' }}
                  value={selectedDiscount}
                />
                <CustomFormFeedback message={errors?.discount?.message} />
              </Col>
              <Col md={4}>
                <CustomLabel name={t('Contract number')} htmlFor="contractNumber" />
                <Controller
                  name="contractNumber"
                  control={control}
                  render={({ field }) => (
                    <Input id="contractNumber" invalid={!!errors.contractNumber} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.contractNumber?.message} />
              </Col>
              {store.currentChild.contractHolder ? (
                <Col md={4}>
                  <CustomLabel name={t('Contract holder')} htmlFor="contractHolderId" />
                  <Select
                    name="contractHolderId"
                    className="react-select"
                    classNamePrefix="select"
                    theme={selectThemeColors}
                    placeholder={t('Select')}
                    options={store.selectedParents}
                    onChange={(val) => setSelectedContractHolder(val)}
                    value={selectedContractHolder}
                    isClearable
                  />
                  <CustomFormFeedback message={errors?.contractHolderId?.message} />
                </Col>
              ) : (
                <></>
              )}
              <Col md={4}>
                <CustomDatePicker<SubmitData>
                  control={control}
                  error={errors?.contractDate?.message}
                  setValue={setValue}
                  label={t('Contract date')}
                  name="contractDate"
                  options={{ minDate: undefined }}
                  defaultValue={childId ? (store.currentChild?.contractDate?.toString() ?? '') : ''}
                  clear
                />
                <CustomFormFeedback message={errors?.contractDate?.message} />
              </Col>
              <Col md={4}>
                <CustomDatePicker<SubmitData>
                  control={control}
                  error={errors?.dateOfEnrollment?.message}
                  setValue={setValue}
                  label={t('Date of enrollment')}
                  name="dateOfEnrollment"
                  options={{ minDate: undefined }}
                  defaultValue={childId ? (store.currentChild?.dateOfEnrollment?.toString() ?? '') : ''}
                  clear
                />
                <CustomFormFeedback message={errors?.dateOfEnrollment?.message} />
              </Col>
              <Col md={4}>
                <CustomDatePicker<SubmitData>
                  control={control}
                  error={errors?.dateOfTermination?.message}
                  setValue={setValue}
                  label={t('Date of termination')}
                  name="dateOfTermination"
                  options={{ minDate: undefined }}
                  defaultValue={childId ? (store.currentChild?.dateOfTermination?.toString() ?? '') : ''}
                  clear
                />
                <CustomFormFeedback message={errors?.dateOfTermination?.message} />
              </Col>
              <Col md={4} className="d-flex" style={{ alignItems: 'center' }}>
                <Controller
                  name="subvention"
                  control={control}
                  render={({ field }) => (
                    <Input id="subvention" type="checkbox" checked={!!field.value} {...field} />
                  )}
                />
                <CustomLabel name={t('Subvention')} htmlFor="subvention" className="mx-50 my-0" />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col md={12}>
        <Card>
          <CardTitle className="p-1 mb-0">
            JISP
          </CardTitle>
          <CardBody>
            <Row>
              <Col md={4}>
                <CustomLabel name={t('Document identifier')} />
                <Select
                  name="documentIdentifier"
                  className="react-select"
                  classNamePrefix="select"
                  theme={selectThemeColors}
                  placeholder={t('Select')}
                  options={store.selectedDocumentIdentifiers}
                  onChange={(val) => setSelectedDocumentIdentifier(val)}
                  value={selectedDocumentIdentifier}
                  isClearable
                />
                <CustomFormFeedback message={errors?.documentIdentifier?.message} />
              </Col>
              {selectedDocumentIdentifier && (
                <Col md={4}>
                  <CustomLabel name={t('Document number')} required htmlFor="documentNumber" />
                  <Controller
                    name="documentNumber"
                    control={control}
                    render={({ field }) => (
                      <Input id="documentNumber" invalid={!!errors.documentNumber} {...field} />
                    )}
                  />
                  <CustomFormFeedback message={errors?.documentNumber?.message} />
                </Col>
              )}
              <Col md={4}>
                <CustomLabel name={t('JOB')} htmlFor="job" />
                <Controller
                  name="job"
                  control={control}
                  render={({ field }) => (
                    <Input id="job" invalid={!!errors.job} {...field} />
                  )}
                />
                <CustomFormFeedback message={errors?.job?.message} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Card className="mt-1">
        <CardTitle className="p-1 mb-0">
          {t('Additional information')}
        </CardTitle>
        <CardBody>
          <Row className="form-row-gap">
            <Col md={6}>
              <Label>{t('Previous experience from kindergarten')}</Label>
              <Controller
                name="previousExperience"
                control={control}
                render={({ field }) => (
                  <Input
                    type="textarea"
                    rows="4"
                    id="previousExperience"
                    maxLength={250}
                    invalid={errors.previousExperience !== undefined}
                    {...field}
                  />
                )}
              />
              <CustomFormFeedback message={errors?.previousExperience?.message} />
            </Col>

            <Col md={6}>
              <Label>{t('Medical details')}</Label>
              <Controller
                name="medicalDetails"
                control={control}
                render={({ field }) => (
                  <Input
                    type="textarea"
                    rows="4"
                    id="medicalDetails"
                    maxLength={250}
                    invalid={errors.medicalDetails !== undefined}
                    {...field}
                  />
                )}
              />
              <CustomFormFeedback message={errors?.healthProblems?.message} />
            </Col>

            <Col md={6}>
              <Label>{t('Health problems')}</Label>
              <Controller
                name="healthProblems"
                control={control}
                render={({ field }) => (
                  <Input
                    type="textarea"
                    rows="4"
                    id="healthProblems"
                    maxLength={250}
                    invalid={errors.healthProblems !== undefined}
                    {...field}
                  />
                )}
              />
              <CustomFormFeedback message={errors?.healthProblems?.message} />
            </Col>

            <Col md={6}>
              <Label>{t('Developmental disabilities')}</Label>
              <Controller
                name="developmentalDisabilities"
                control={control}
                render={({ field }) => (
                  <Input
                    type="textarea"
                    rows="4"
                    id="developmentalDisabilities"
                    maxLength={250}
                    invalid={errors.developmentalDisabilities !== undefined}
                    {...field}
                  />
                )}
              />
              <CustomFormFeedback message={errors?.developmentalDisabilities?.message} />
            </Col>

            <Col md={6}>
              <Label>{t('Specific needs')}</Label>
              <Controller
                name="specificNeeds"
                control={control}
                render={({ field }) => (
                  <Input
                    type="textarea"
                    rows="4"
                    id="specificNeeds"
                    maxLength={250}
                    invalid={errors.specificNeeds !== undefined}
                    {...field}
                  />
                )}
              />
              <CustomFormFeedback message={errors?.specificNeeds?.message} />
            </Col>

            <Col md={6}>
              <Label>{t('Other')}</Label>
              <Controller
                name="other"
                control={control}
                render={({ field }) => (
                  <Input
                    type="textarea"
                    rows="4"
                    id="other"
                    maxLength={250}
                    invalid={errors.other !== undefined}
                    {...field}
                  />
                )}
              />
              <CustomFormFeedback message={errors?.other?.message} />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row>
        <Col md={12} className="text-center mt-2 pt-50">
          <Button
            disabled={loading}
            type="submit"
            className="me-1"
            color="primary"
            onClick={() => setIsSaveAndContinue(false)}
          >
            {loading && <Spinner size="sm" className="mr-1" />} {childId ? t('Save changes') : t('Add')}
          </Button>
          {!childId && (
            <Button
              disabled={loading}
              type="submit"
              className="me-1"
              color="primary"
              onClick={() => setIsSaveAndContinue(true)}
            >
              {loading && <Spinner size="sm" className="mr-1" />} {t('Add and continue')}
            </Button>
          )}

        </Col>
      </Row>
    </Form>
  );
};

export default ChildManagement;
