import { useState } from 'react';
import { Accordion, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ShowModalButton from '@src/components/buttons/ShowModalButton';
import NotesForm from '@src/views/notes/components/NotesForm';
import { useSelector } from 'react-redux';
import FormHeader from '@src/components/forms/FormHeader';
import Note from '../types/Note';
import ChildrenState from '../../children/types/ChildrenState';
import NotesAccordionItem from './NotesAccordionItem';

type Props = {
  notes: Note[];
}

const NotesAccordion:React.FC<Props> = ({ notes }) => {
  const { t } = useTranslation();
  const store = useSelector((state: ChildrenState) => state.children);
  const [open, setOpen] = useState('');

  const toggle = (id: string) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  return (
    <Col md={12}>
      <FormHeader
        title={`${t('Notes of child')}: ${store.currentChild.name} ${store.currentChild.surname}`}
        subtitle={t('Add, edit or delete notes')}
        button={(
          <ShowModalButton
            modalProps={{
              Component: NotesForm,
              componentProps: { child: store.currentChild },
              title: t('Add new note'),
              open: true,
              modalProps: { size: 'lg' },
            }}
            title={t('Add Note')}
          />
      )}
      />

      {/* eslint-ignore-next-line
     @ts-ignore */}
      <Accordion open={open} toggle={toggle}>
        {notes?.map((note) => (
          <NotesAccordionItem note={note} key={note.id} />
        ))}
      </Accordion>
    </Col>
  );
};

export default NotesAccordion;
