// ** React Imports
import { Navigate } from 'react-router-dom';
import { useContext, Suspense } from 'react';

// ** Context Imports
import { AbilityContext } from '@src/utility/context/Can';
import { isViewPermissionActive } from '@src/utility/context/ActivePermissions';

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  const ability = useContext(AbilityContext);
  const user = JSON.parse(localStorage.getItem('userData'));
  const permissions = user?.permissions;

  if (route) {
    let action = null;
    let resource = null;
    let restrictedRoute = false;
    let permissionName = null;

    if (route.meta) {
      action = route.meta.action;
      resource = route.meta.resource;
      restrictedRoute = route.meta.restricted;
      permissionName = route.meta.permissionName;
    }
    if (!user) {
      return <Navigate to="/login" />;
    }
    if (user && restrictedRoute) {
      return <Navigate to="/" />;
    }
    if (user && permissionName && !isViewPermissionActive(permissions, permissionName)) {
      return <Navigate to="/404" />;
    }
    if (user && !ability.can(action || 'read', resource)) {
      return <Navigate to="/404" replace />;
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>;
};

export default PrivateRoute;
