import { useTranslation } from 'react-i18next';
import MONTHS from '@src/constants/months';
import { useEffect, useRef, useState } from 'react';
import { Edit, Image, Trash } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { closeModal, openModal } from '@store/modal';
import { successToast } from '@src/components/wrappers/ToastMessages';
import ModalWrapperProps from '@src/components/wrappers/ModalWrapperProps';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import Album from '../types/Album';
import { deleteAlbum, getAllAlbums } from '../store';

type Props = {
  album: Album;
}

export interface ModalState {
  modal: {
    modals: ModalWrapperProps[]
  }
}

const AlbumThumbnail:React.FC<Props> = ({ album }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const titleRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const modalState = useSelector((state: ModalState) => state.modal.modals);
  const galleryPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const scrollToTop = () => {
    if (titleRef.current) {
      titleRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleDeleteClick = () => {
    dispatch(
      openModal({
        Component: ConfirmationModalWrapper,
        title: t('Are you sure?'),
        subtitle: `${t('Album')}: "${album.title}" ${t('will be deleted.')}`,
        open: true,
        componentProps: {
          handleConfirm: async () => {
            try {
              await dispatch(deleteAlbum(album.id));
            } finally {
              successToast(t('Album successfully deleted'));
              dispatch(getAllAlbums());
              dispatch(closeModal());
            }
          },
        },
      }),
    );
  };

  const onMouseLeaveHandler = () => {
    if (modalState.length === 0) {
      setIsHovered(false);
    }
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>, handler: () => void) => {
    e.stopPropagation();
    handler();
  };

  useEffect(() => {
    if (modalState.length === 0) {
      setIsHovered(false);
    }
  }, [modalState.length]);

  return (
    <div
      className={`album-wrapper ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => onMouseLeaveHandler()}
      role="presentation"
      onClick={() => navigate(`album/${album.id}`)}
    >
      <div
        className="album-image"
        style={{
          background: `url("${album.albumFiles[0].path}") no-repeat center /cover`,
        }}
      >
        <div className="album-title" ref={titleRef} onMouseLeave={scrollToTop} role="presentation">
          <div className="title-holder">
            <h3>{album.title}</h3>
          </div>
          <h5>{t(MONTHS[new Date(album.date).getMonth()])}
            {' '}
            {new Date(album.date).getFullYear()}
          </h5>
          <div className="d-flex mt-1" style={{ gap: '10px' }}>
            {isManagePermissionActive(galleryPermission, 'Gallery')
              ? (
                <Button color="outline" className="p-0 mt-0" onClick={(e) => handleButtonClick(e, () => navigate(`album/edit/${album.id}`))}>
                  <Edit color="#fff" strokeWidth="1.5px" />
                </Button>
              ) : <></>}
            <Button color="outline" className="p-0 mt-0" onClick={(e) => handleButtonClick(e, () => navigate(`album/${album.id}`))}>
              <Image color="#fff" strokeWidth="1.5px" />
            </Button>
            {isDeletePermissionActive(galleryPermission, 'Gallery')
              ? (
                <Button color="outline" className="p-0 mt-0" onClick={(e) => handleButtonClick(e, () => handleDeleteClick())}>
                  <Trash color="#fff" strokeWidth="1.5px" />
                </Button>
              ) : <></>}
          </div>
          <div className="mt-1 d-flex flex-wrap">{album.groups.map((group) => <div key={group.id} className="tenant-group-item">{group.name}</div>)}</div>
          <p className="album-description">{album.description ? album.description : ''}</p>
        </div>
      </div>
    </div>
  );
};

export default AlbumThumbnail;
