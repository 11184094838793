import SelectedOptions from '@src/types/SelectedOptions';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { PollsState } from '@src/views/polls/types';
import StoreState from '@store/StoreState';
import i18n from '@src/configs/i18n';
import { AppDispatch } from '@store/store';
import Tags from '../enums/tags';
import { selectedTags } from '../store';
import Post from '../types/Post';

const useTags = () => {
  const { t } = useTranslation();
  const [tag, setTag] = useState<SingleValue<SelectedOptions> | null>(null);
  const [selectedValues, setSelectedValues] = useState<MultiValue<SelectedOptions>>();
  const [tagOptions, setTagOptions] = useState<SelectedOptions[]>([]);
  const [tagLabel, setTagLabel] = useState('');
  const dispatch = useDispatch<AppDispatch>();

  const pollStore = useSelector((state: PollsState) => state.polls);
  const store = useSelector((state: StoreState) => state.posts);

  const groupOptions = useMemo(() => pollStore.selectedGroups
    .reduce((result: SelectedOptions[], current, index, array) => {
      if (index === 0 || current.objectId !== array[index - 1].objectId) {
        const object = pollStore.objectsByTenant.find((obj) => obj.id === current.objectId);

        if (object) {
          result.push({
            value: object.name,
            label: object.name,
            title: true,
          });
        }
      }
      result.push(current);
      return result;
    }, []), [pollStore.selectedGroups]);

  const childrenOptions = useMemo(() => {
    const objectIds: number[] = [];

    return store.selectedChildren
      .reduce((result: SelectedOptions[], current, index, array) => {
        if (index === 0 || current.groupId !== array[index - 1].groupId) {
          const group = pollStore.groupsByTenant.find((g) => g.id === current.groupId);
          if (group) {
            const object = pollStore.objectsByTenant.find((obj) => obj.id === group.objectId);
            if (object) {
              if (!objectIds.includes(object?.id)) {
                objectIds.push(object.id);
                result.push({
                  value: object.name,
                  label: object.name,
                  title: true,
                });
              }
            }
            result.push({
              value: group.name,
              label: group.name,
              subtitle: true,
            });
          }
        }
        result.push(current);
        return result;
      }, []);
  }, [store.selectedChildren]);

  const setNewTagAndTagOptions = (newTag: SingleValue<SelectedOptions>) => {
    setTag(newTag);

    if (newTag?.value === Tags.objects) {
      setTagOptions(pollStore.selectedObjects);
      setTagLabel(t('Objects'));
    } else if (newTag?.value === Tags.groups) {
      setTagOptions(groupOptions);
      setTagLabel(t('Groups'));
    } else if (newTag?.value === Tags.children) {
      setTagOptions(childrenOptions);
      setTagLabel(t('Children'));
    }

    setSelectedValues([]);
  };

  const setTagOptionsByCurrentPost = (currentPost: Post) => {
    if (currentPost?.tag?.tag) {
      const currentPostTag = store.selectedTags.find((f) => f.value === currentPost.tag.tag);
      if (currentPostTag) {
        setTag(currentPostTag);
        setTagLabel(t(currentPostTag.value as Tags));
      }

      if (currentPost.tag.tag === Tags.objects) {
        setTagOptions(pollStore.selectedObjects);
        setSelectedValues(
          pollStore.selectedObjects
            .filter((obj) => currentPost.objects.some((o) => o.objectId === +obj.value)),
        );
      } else if (currentPost.tag.tag === Tags.groups) {
        setTagOptions(groupOptions);
        setSelectedValues(
          pollStore.selectedGroups
            .filter((group) => currentPost.groups.some((g) => g.groupId === group.value)),
        );
      } else if (currentPost.tag.tag === Tags.children) {
        setTagOptions(childrenOptions);
        setSelectedValues(
          store.selectedChildren
            .filter((child) => currentPost.childs.some((c) => c.childId === child.value)),
        );
      }
    }
  };

  useEffect(() => {
    dispatch(selectedTags(store.postTags.map((tg) => ({ value: tg.tag, label: t(tg.tag) }))));
  }, [store.postTags, i18n.language]);

  return {
    tag,
    tagLabel,
    setTagLabel,
    tagOptions,
    setTagOptions,
    selectedValues,
    setSelectedValues,
    setTagOptionsByCurrentPost,
    setNewTagAndTagOptions,
  };
};

export default useTags;
