import { FormFeedback } from 'reactstrap';
import CustomFormFeedbackProps from './CustomFormFeedbackProps';

const CustomFormFeedback = ({ message }:CustomFormFeedbackProps) => (
  <FormFeedback className={`d-block ${message ? 'visible' : 'hidden'}`}>
    {message}&zwnj;
  </FormFeedback>
);

export default CustomFormFeedback;
