import SelectedOptions from '@src/types/SelectedOptions';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';
import * as yup from 'yup';

const groupValidationSchema = (
  selectedObject: SingleValue<SelectedOptions>,
  selectedPersonsInCharge: MultiValue<SelectedOptions>,
) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    name: yup.string().required(t('Name is required')),
    objectId: yup.mixed().test('required', t('Object is required'), () => !!selectedObject?.value),
    personsInCharge: yup.mixed().test('required', t('Select at least one person in charge'),
      () => selectedPersonsInCharge.length > 0),
  });
  return validation;
};
export default groupValidationSchema;
