import { useEffect } from 'react';
import '@styles/react/libs/flatpickr/flatpickr.scss';
import { Input } from 'reactstrap';

interface SelectAllCheckboxProps {
  selectedColumns: Set<string>;
  currentPage: number;
}

const SelectAllCheckbox = ({ selectedColumns, currentPage } : SelectAllCheckboxProps) => {
  const handleSelectAll = (e: any) => {
    const items = (document.querySelectorAll('.select-column-checkbox'));
    const val = e.target.checked;

    items.forEach((item) => {
      const _item = (item as HTMLInputElement);

      if (!_item.checked === val) _item.click();
    });
  };

  const isAllChecked = () => {
    const items = (document.querySelectorAll('.select-column-checkbox'));
    let isChecked = true;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      if (!(item as HTMLInputElement).checked) {
        isChecked = false;
        break;
      }
    }

    return items.length ? isChecked : false;
  };

  useEffect(() => {
    if (selectedColumns) {
      const checkbox = document.getElementById('select-all-checkbox') as HTMLInputElement;
      if (checkbox) checkbox.checked = isAllChecked();
    }
  }, [currentPage]);

  return (
    <Input type="checkbox" id="select-all-checkbox" onClick={(e) => handleSelectAll(e)} />
  );
};

export default SelectAllCheckbox;
