// ** Reactstrap Imports
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface PostPaginationProps {
  totalPages: number;
  activePage: number;
  setActive: (page: number) => void;
}
const PostPagination = ({ totalPages, activePage, setActive }: PostPaginationProps) => {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1).slice(1, -1);
  const pagesLinks = pages.filter((page) => Math.abs(page - activePage) < 3);

  const isActive = (pageNumber: number) => pageNumber === activePage;
  return (
    <Pagination className="d-flex mt-3 align-items-end flex-column">
      <PaginationItem className="prev-item" disabled={activePage === 1}>
        <PaginationLink onClick={() => setActive(activePage - 1)} />
      </PaginationItem>
      <PaginationItem key={`page${1}`} active={isActive(1)}>
        <PaginationLink onClick={() => setActive(1)}>
          {1}
        </PaginationLink>
      </PaginationItem>
      {(totalPages > 3 && pagesLinks[0] !== 2) ? (
        <PaginationItem key={`page${2}`} active={isActive(2)}>
          <PaginationLink onClick={() => setActive(2)}>
            ...
          </PaginationLink>
        </PaginationItem>
      ) : <></>}
      {pagesLinks.map((page) => (
        <PaginationItem key={`page${page}`} active={isActive(page)}>
          <PaginationLink onClick={() => setActive(page)}>
            {page}
          </PaginationLink>
        </PaginationItem>
      ))}
      {(totalPages > 4 && pagesLinks[pagesLinks.length - 1] !== totalPages - 1) ? (
        <PaginationItem key={`page${totalPages - 1}`} active={isActive(totalPages - 1)}>
          <PaginationLink onClick={() => setActive(totalPages - 1)}>
            ...
          </PaginationLink>
        </PaginationItem>
      ) : <></>}
      {totalPages > 1 ? (
        <PaginationItem key={`page${totalPages}`} active={isActive(totalPages)}>
          <PaginationLink onClick={() => setActive(totalPages)}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      ) : <></>}
      <PaginationItem className="next-item" disabled={activePage === totalPages}>
        <PaginationLink onClick={() => setActive(activePage + 1)} />
      </PaginationItem>
    </Pagination>
  );
};

export default PostPagination;
