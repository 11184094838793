import FullCalendar, {
  DayCellContentArg, DateSelectArg, EventClickArg, EventContentArg, EventInput,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import '../../../assets/scss/calendar.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { AppDispatch } from '@store/store';
import ChildrenState from '@src/views/children/types/ChildrenState';
import { useSkin } from '@src/utility/hooks/useSkin';
import { Edit, Trash } from 'react-feather';
import { formatDate } from '@src/views/polls/components/PollsForm';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { useTranslation } from 'react-i18next';
import ShowModalButton from '@src/components/buttons/ShowModalButton';
import { Button } from 'reactstrap';
import { isOneDayMore } from '@src/utility/Utils';
import MONTHS from '@src/constants/months';
import NonWorkingDaysState from '@src/views/nonNonWorkingDays/types/NonWorkingDaysState';
import RecurringNonWorkingDaysState from '@src/views/nonNonWorkingDays/types/RecurringNonWorkingDaysState';
import { getAllRecurringNonWorkingDays, setGeneratedEvents } from '@src/views/nonNonWorkingDays/store/RecurringNonWorkingDaysStore';
import { getAllNonWorkingDays } from '@src/views/nonNonWorkingDays/store';
import Legend from '@src/components/legend/Legend';
import { DAYS } from '@src/constants/days';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { AbsenceState } from '../types/AbsenceState';
import {
  createAbsence, deleteAbsence, getAbsencesByChildId, setAllAbsences, setCalendarEvents,
} from '../store';
import { setCalendarEvents as setNonWorkingDayCalendarEvents } from '../../nonNonWorkingDays/store';
import AbsenceForm from './AbsenceForm';

const AbsencesTab = () => {
  const { t } = useTranslation();
  const calendarRef = useRef<FullCalendar>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const deleteButtonRef = useRef<HTMLButtonElement>(null);
  const childStore = useSelector((state: ChildrenState) => state.children);
  const nonWorkingDaysStore = useSelector((state: NonWorkingDaysState) => state.nonWorkingDays);
  const recurringNonWorkingDaysStore = useSelector(
    (state: RecurringNonWorkingDaysState) => state.recurringNonWorkingDays,
  );
  const store = useSelector((state: AbsenceState) => state.absences);
  const dispatch = useDispatch<AppDispatch>();
  const { skin } = useSkin();
  const [selectedDates, setSelectedDates] = useState<DateSelectArg>();
  const legendItems = useMemo(() => [
    { title: 'Present', className: 'present' },
    { title: 'Justified absence', className: 'justified-absence' },
    { title: 'Unjustified absence', className: 'unjustified-absence' },
    { title: 'Holiday', className: 'holiday' },
    { title: 'Weekend', className: 'weekend' },
    { title: 'Today', className: 'today' },
  ], []);
  const absencePermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const canEdit = isManagePermissionActive(absencePermissions, 'Absences');
  const canDelete = isDeletePermissionActive(absencePermissions, 'Absences');

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(setAllAbsences([]));
    };
  }, []);

  const dayCellClassNames = (arg: DayCellContentArg) => {
    const absence = store.allAbsences.find((event) => {
      const eventStart = new Date(event.date);
      return (
        eventStart.getDate() === arg.date.getDate()
          && eventStart.getMonth() === arg.date.getMonth()
          && eventStart.getFullYear() === arg.date.getFullYear()
      );
    });

    if (absence) {
      return absence.justified ? 'dark calendar-absence-justified' : 'dark calendar-absence-unjustified';
    }

    const nwd = nonWorkingDaysStore.allNonWorkingDays.find((event) => {
      const eventStart = new Date(event.date);
      return (
        eventStart.getDate() === arg.date.getDate()
          && eventStart.getMonth() === arg.date.getMonth()
          && eventStart.getFullYear() === arg.date.getFullYear()
      );
    });

    const isRnwd = recurringNonWorkingDaysStore.allRecurringNonWorkingDays.some(
      (nw) => nw.day === arg.date.getDay(),
    );

    if (nwd || isRnwd) {
      return 'calendar-non-working-day';
    }
    return '';
  };

  const onDateSelection = (dates: DateSelectArg) => {
    if (!canEdit) return;
    if (isOneDayMore(dates.start, dates.end)) {
      const calendarApi = calendarRef.current?.getApi();
      calendarApi?.unselect();
      setSelectedDates(undefined);

      dispatch(createAbsence({ childId: childStore.currentChild.id, date: dates.startStr }))
        .then(() => {
          dispatch(getAbsencesByChildId(childStore.currentChild.id));
        });
    } else {
      setSelectedDates(dates);
    }
  };

  const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    if (!canDelete) return;
    event.stopPropagation();
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('Absence will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            await dispatch(deleteAbsence(id));
          } finally {
            await dispatch(getAbsencesByChildId(childStore.currentChild.id));
            dispatch(closeModal());
          }
        },
      },
    }));
  };

  const dayCellContent = (day: DayCellContentArg) => {
    const event = store.calendarEvents.find(
      (e) => e.start
      && formatDate(
        new Date(e?.start.toString()).toISOString(),
      ) === formatDate(new Date(day.date.toString()).toISOString()),
    );

    return (
      <div className="d-flex flex-column justify-content-between align-items-center mr-50-md" style={{ height: '80px' }}>
        <p
          className="m-0 text-center"
          style={{
            background: day.isToday ? '#1976d2' : 'transparent',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            padding: '5px',
          }}
        >{day.dayNumberText}
        </p>
        {event && (
          <div className="d-flex mb-1">
            {canEdit
              ? (
                <Button color="outline" className="p-0 mr-50-md">
                  <Edit
                    width={15}
                    color="#fff"
                    height={15}
                    pointerEvents="fill"
                  />
                </Button>
              ) : <></>}
            {canDelete
              ? (
                <Button color="outline" className="m-0 p-0" innerRef={deleteButtonRef} onClick={(e) => event.id && onDeleteClick(e, +event.id)} data-isdeletebutton>
                  <Trash
                    width={15}
                    height={15}
                    data-isdeletebutton
                    color="#fff"
                    pointerEvents="fill"
                  />
                </Button>
              ) : <></>}
          </div>
        )}
      </div>
    );
  };

  const onDateClick = (date: DateClickArg) => {
    if (!canEdit) return;
    const event = store.calendarEvents.find(
      (e) => formatDate(e.start?.toString() ?? '') === date.dateStr,
    );

    const dataValue = (date.jsEvent.target as HTMLElement).getAttribute('data-isdeletebutton');

    let parentDataValue = false;
    if (!dataValue) {
      const parent = (date.jsEvent.target as HTMLElement).closest('[data-isdeletebutton]');
      parentDataValue = parent ? !!parent.getAttribute('data-isdeletebutton') : false;
    }

    if (event && !dataValue && !parentDataValue) {
      dispatch(openModal({
        Component: AbsenceForm,
        title: t('Update absence'),
        open: true,
        componentProps: {
          absence: store.allAbsences.find((a) => event.id && a.id === +event.id),
        },
      }));
    }
  };

  const onEventClick = (event: EventClickArg) => {
    if (!canEdit) return;
    if (event.event._def.interactive !== false) {
      dispatch(openModal({
        Component: AbsenceForm,
        title: t('Edit absence'),
        open: true,
        componentProps: {
          absence: store.allAbsences.find((a) => a.id === +event.event.id),
        },
      }));
    }
  };

  const eventContent = (event: EventContentArg) => (
    <div className="d-flex px-50 flex-column align-items-center" style={{ gap: '10px' }}>
      {event.event.title
      && (
      <p className="hide-md-down">
        {event.event.title.length > 30 ? `${event.event.title.slice(0, 30)}...` : event.event.title}
      </p>
      )}

    </div>
  );

  useEffect(() => {
    if (childStore.currentChild.id) dispatch(getAbsencesByChildId(childStore.currentChild.id));
  }, [childStore.currentChild.id]);

  useEffect(() => {
    dispatch(getAllNonWorkingDays());
    dispatch(getAllRecurringNonWorkingDays());

    document.addEventListener('click', (event: MouseEvent) => {
      if (!buttonRef.current?.contains(event.target as Node)) {
        const calendarApi = calendarRef.current?.getApi();
        calendarApi?.unselect();
        setSelectedDates(undefined);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(setCalendarEvents(store.allAbsences.map(
      (absence) => ({
        id: absence.id, start: absence.date, title: absence.reason ?? '', allDay: true, color: 'transparent',
      }),
    )));
  }, [store.allAbsences]);

  useEffect(() => {
    dispatch(setNonWorkingDayCalendarEvents(nonWorkingDaysStore.allNonWorkingDays.map(
      (nonWorkingDay) => ({
        id: nonWorkingDay.id,
        start: nonWorkingDay.date,
        title: nonWorkingDay.note ?? '',
        allDay: true,
        color: 'transparent',
        interactive: false,
      }),
    )));
  }, [nonWorkingDaysStore.allNonWorkingDays]);

  useEffect(() => {
    const events: EventInput[] = [];
    recurringNonWorkingDaysStore.allRecurringNonWorkingDays.forEach((nwd) => {
      events.push({
        title: nwd.note ?? '',
        id: nwd.id.toString(),
        daysOfWeek: [nwd.day.toString()],
        color: 'transparent',
        allDay: true,
        interactive: false,
      });
    });
    dispatch(setGeneratedEvents(events));
  }, [recurringNonWorkingDaysStore.allRecurringNonWorkingDays]);

  return (
    <div className={`calendar-wrapper-${skin}`}>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={[
          ...store.calendarEvents,
          ...nonWorkingDaysStore.calendarEvents,
          ...recurringNonWorkingDaysStore.generatedEvents,
          {
            daysOfWeek: [6, 0],
            color: 'transparent',
            allDay: true,
            interactive: false,
          },
        ]}
        dayCellClassNames={dayCellClassNames}
        firstDay={1}
        dateClick={onDateClick}
        eventClick={onEventClick}
        unselectAuto={false}
        selectable
        select={onDateSelection}
        height={600}
        selectOverlap={false}
        dayCellContent={dayCellContent}
        longPressDelay={300}
        eventContent={eventContent}
        locales={[{
          buttonText: {
            today: t('Today'),
          },
          code: 'rs',
        }]}
        dayHeaderContent={(arg) => t(DAYS[arg.date.getDay()])}
        locale="rs"
        titleFormat={(title) => `${t(MONTHS[title.date.month])} ${title.date.year}`}
      />
      <div className="d-flex justify-content-between md-flex-direction-column-reverse">
        <Legend items={legendItems} />
        {(selectedDates && canEdit)
          && (
            <div className="mt-1">
              <ShowModalButton
                modalProps={{
                  Component: AbsenceForm,
                  title: t('Add absences'),
                  open: true,
                  componentProps: {
                    selectedDates,
                  },
                }}
                title={t('Add absences')}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default AbsencesTab;
