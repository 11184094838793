import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col, Form, Input, Spinner,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { closeModal } from '@store/modal';
import { yupResolver } from '@hookform/resolvers/yup';
import Select, { MultiValue, SingleValue } from 'react-select';
import { selectThemeColors } from '@src/utility/Utils';
import SelectedOptions from '@src/types/SelectedOptions';
import TenantObjectState from '@src/views/tenantObjects/types/TenantObjectState';
import SubmitData from '../types/SubmitData';
import groupValidationSchema from '../validation';
import {
  addNewTenantGroup, editTenantGroup, selectedObjects, selectedPersonsInCharge,
} from '../store';
import TenantGroupFormProps from '../types/TenantGroupFormProps';
import TenantGroupState from '../types/TenantGroupState';

const TenantGroupForm = ({ edit, tenantGroup }: TenantGroupFormProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedPersons, setSelectedPersons] = useState<MultiValue<SelectedOptions>>([]);
  const [selectedObject, setSelectedObject] = useState<SingleValue<SelectedOptions>>();

  const store = useSelector((state: TenantGroupState) => state.tenantGroups);
  const objectStore = useSelector((state: TenantObjectState) => state.tenantObjects);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SubmitData>({
    defaultValues: {
      name: tenantGroup?.name ?? '',
      description: tenantGroup?.description ?? '',
    },
    resolver: yupResolver(groupValidationSchema(
      selectedObject as SingleValue<SelectedOptions>,
      selectedPersons as MultiValue<SelectedOptions>,
    )),
  });

  const handleSuccessfulSubmit = async (data: SubmitData) => {
    setLoading(true);

    if (!selectedObject) {
      return;
    }

    const formattedData = {
      name: data.name,
      description: data.description,
      objectId: +selectedObject.value,
      personsInCharge: selectedPersons.map((person) => person.value).join(','),
    };

    if (!edit) {
      dispatch(addNewTenantGroup(formattedData))
        .finally(() => {
          setLoading(false);
          dispatch(closeModal());
        });
    } else if (tenantGroup) {
      dispatch(editTenantGroup({ id: tenantGroup.id, ...formattedData }))
        .finally(() => {
          setLoading(false);
          dispatch(closeModal());
        });
    }
  };

  useEffect(() => {
    dispatch(selectedPersonsInCharge(store.allPersonsInCharge.map(
      (person) => ({ label: `${person.name} ${person.surname}`, value: person.id }),
    )));
    dispatch(selectedObjects(
      objectStore.allTenantObjects.map((obj) => ({ label: obj.name, value: obj.id })),
    ));
  }, [store.allTenantGroups, objectStore.allTenantObjects]);

  useEffect(() => {
    if (tenantGroup) {
      const object = objectStore.allTenantObjects.find((obj) => obj.id === tenantGroup.object.id);

      if (object) {
        setSelectedObject({ value: object.id, label: object.name });
      }

      setSelectedPersons(
        store.allPersonsInCharge.filter(
          (person) => tenantGroup.personsInCharge.some((pc) => pc.id === person.id),
        ).map((pc) => ({ value: pc.id, label: pc.name })),
      );
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Col xs={12}>
        <CustomLabel name={t('Name')} required htmlFor="name" />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input id="name" invalid={!!errors.name} {...field} />
          )}
        />
      </Col>
      <CustomFormFeedback message={errors?.name?.message} />
      <Col xs={12}>
        <CustomLabel name={t('Description')} htmlFor="description" />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Input id="description" invalid={!!errors.description} {...field} />
          )}
        />
      </Col>
      <CustomFormFeedback message={errors?.description?.message} />
      <Col xs={12}>
        <CustomLabel name={t('Object')} required htmlFor="object" />
        <Select
          name="object"
          className="react-select"
          classNamePrefix="select"
          theme={selectThemeColors}
          placeholder={t('Select')}
          options={store.selectedObjects}
          onChange={(val) => setSelectedObject(val)}
          value={selectedObject}
        />
      </Col>
      <CustomFormFeedback message={errors?.objectId?.message} />
      <Col xs={12}>
        <CustomLabel name={t('Persons in charge')} required htmlFor="personsInCharge" />
        <Select
          name="personsInCharge"
          className="react-select"
          classNamePrefix="select"
          placeholder={t('Select')}
          theme={selectThemeColors}
          isMulti
          options={store.selectedPersonsInCharge}
          onChange={(val) => setSelectedPersons(val)}
          value={selectedPersons}
        />
      </Col>
      <CustomFormFeedback message={errors?.personsInCharge?.message} />
      <Col xs={12} className="text-center mt-2 pt-50">
        <Button
          disabled={loading}
          type="submit"
          className="me-1"
          color="primary"
        >
          {loading && <Spinner size="sm" className="mr-1" />} {t('Save changes')}
        </Button>
        <Button
          type="reset"
          color="secondary"
          outline
          onClick={() => dispatch(closeModal())}
        >
          {t('Close')}
        </Button>
      </Col>
    </Form>
  );
};

export default TenantGroupForm;
