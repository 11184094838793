import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import SelectedOptions from '@src/types/SelectedOptions';
import { handleErrorMessage } from '@src/utility/Utils';
import Invoice from '../types/Invoice';
import SelectedPackageOptions from '../types/SelectedPackageOptions';
import CreateInvoiceData from '../types/CreateInvoiceData';
import Year from '../types/Year';
import InvoiceBulk from '../types/InvoiceBulk';
import InvoiceNumber from '../types/InvoiceNumber';
import FinanceChartData from '../types/FinanceChartData';

export const getAllInvoices = createAsyncThunk('goKinder/getAllInvoices', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/invoices${query ? '?' : ''}${query ?? ''}`, { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getInvoiceById = createAsyncThunk('goKinder/getInvoiceById', async (id: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/invoices/${id}`, { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getCreateInvoiceByChild = createAsyncThunk('goKinder/getCreateInvoiceByChild', async (id: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/invoices/create-invoice/${id}`, { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const addNewInvoice = createAsyncThunk('goKinder/addNewInvoice', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/invoices`, data, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createMassInovice = createAsyncThunk('goKinder/addNewInvoice', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/invoices/create-mass-invoice`, data, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editInvoice = createAsyncThunk('goKinder/editInvoice', async ({ id, data } : { id: number, data:any }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/invoices/${id}`, data, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteInvoice = createAsyncThunk('goKinder/deleteInvoice', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/invoices/${id}`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getMultipleInvoices = createAsyncThunk('goKinder/getMultipleInvoices', async (query: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/invoices/create-multiple-invoices${query ? '?' : ''}${query ?? ''}`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const addMultipleInvoices = createAsyncThunk('goKinder/addMultipleInvoices', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/invoices/create-multiple-invoices`, data, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getInvoiceTypes = createAsyncThunk('goKinder/getInvoiceTypes', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/invoices/invoice-types`, { headers: { Version: 'new' } });

    return {
      data: response.data?.invoiceTypes,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getInvoiceNumbers = createAsyncThunk('goKinder/getInvoiceNumbers', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/invoices/invoice-numbers`, { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getInvoiceStatuses = createAsyncThunk('goKinder/getInvoiceStatuses', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/invoices/invoice-statuses`, { headers: { Version: 'new' } });

    return {
      data: response.data?.invoiceStatuses,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getYears = createAsyncThunk('goKinder/getYears', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/years`, { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getFinanceChartData = createAsyncThunk('goKinder/getFinanceChartData', async ({ childId, year } : { childId: number, year: number }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/invoices/${childId}/finance-graph?year=${year}`, { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getInvoiceByHash = createAsyncThunk('goKinder/getInvoiceByHash', async (hash: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/invoices/preview/${hash}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    allInvoices: [] as Invoice[],
    createInvoiceData: {} as CreateInvoiceData,
    invoice: {} as Invoice,
    selectedPackages: [] as SelectedPackageOptions[],
    invoiceTypes: [] as string[],
    years: [] as Year[],
    bulkInvoices: [] as InvoiceBulk[],
    invoiceStatuses: [] as string[],
    invoiceNumbers: [] as InvoiceNumber[],
    selectedYears: [] as SelectedOptions[],
    selectedInvoiceType: [] as SelectedOptions[],
    selectedInvoiceStatuses: [] as SelectedOptions[],
    selectedChildren: [] as SelectedOptions[],
    selectedObjects: [] as SelectedOptions[],
    selectedGroups: [] as SelectedOptions[],
    selectedPackageIds: [] as SelectedOptions[],
    financeChartData: [] as FinanceChartData[],
  },
  reducers: {
    clearInvoices(state) {
      state.allInvoices = [];
    },
    selectedPackages: (state, action) => {
      state.selectedPackages = action.payload;
    },
    selectedYears: (state, action) => {
      state.selectedYears = action.payload;
    },
    selectedInvoiceType: (state, action) => {
      state.selectedInvoiceType = action.payload;
    },
    selectedInvoiceStatuses: (state, action) => {
      state.selectedInvoiceStatuses = action.payload;
    },
    selectedChildren: (state, action) => {
      state.selectedChildren = action.payload;
    },
    selectedObjects: (state, action) => {
      state.selectedObjects = action.payload;
    },
    selectedGroups: (state, action) => {
      state.selectedGroups = action.payload;
    },
    selectedPackageIds: (state, action) => {
      state.selectedPackageIds = action.payload;
    },
    allInvoices: (state, action) => {
      state.allInvoices = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllInvoices.fulfilled, (state, action) => {
      state.allInvoices = action.payload.data;
    });
    builder.addCase(getMultipleInvoices.fulfilled, (state, action) => {
      state.bulkInvoices = action.payload.data;
    });
    builder.addCase(getInvoiceById.fulfilled, (state, action) => {
      state.invoice = action.payload.data;
    });
    builder.addCase(getCreateInvoiceByChild.fulfilled, (state, action) => {
      state.createInvoiceData = action.payload.data;
    });
    builder.addCase(addNewInvoice.fulfilled, (state, action) => {
      state.allInvoices = [...state.allInvoices, action.payload.data];
    });
    builder.addCase(getInvoiceTypes.fulfilled, (state, action) => {
      state.invoiceTypes = action.payload.data;
    });
    builder.addCase(getInvoiceStatuses.fulfilled, (state, action) => {
      state.invoiceStatuses = action.payload.data;
    });
    builder.addCase(getInvoiceNumbers.fulfilled, (state, action) => {
      state.invoiceNumbers = action.payload.data;
    });
    builder.addCase(getYears.fulfilled, (state, action) => {
      state.years = action.payload.data;
    });
    builder.addCase(getFinanceChartData.fulfilled, (state, action) => {
      state.financeChartData = action.payload.data;
    });
    builder.addCase(getInvoiceByHash.fulfilled, (state, action) => {
      state.invoice = action.payload.data;
    });
  },
});

export const {
  clearInvoices,
  selectedPackages,
  selectedInvoiceType,
  selectedYears,
  allInvoices,
  selectedInvoiceStatuses,
  selectedChildren,
  selectedGroups,
  selectedObjects,
  selectedPackageIds,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
