import { Button } from 'reactstrap';
import { Icon, IconProps } from 'tabler-icons-react';

interface Props {
  handleClick: () => void;
  IconComponent: Icon;
  iconProps: IconProps;
  text: string;
  color?: string;
  block?: boolean;
}

const ButtonWithIcon = ({
  handleClick,
  color = 'primary',
  iconProps,
  IconComponent,
  text,
  block,
  ...otherProps
}: Props) => (
  <Button outline color={color} onClick={handleClick} {...otherProps} block={block}>
    <div className="d-flex justify-content-center">
      <IconComponent {...iconProps} />
      &nbsp;
      <span>{text}</span>
    </div>
  </Button>
);

export default ButtonWithIcon;
