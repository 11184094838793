import {
  Button,
} from 'reactstrap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from 'tabler-icons-react';
import FormHeader from '@src/components/forms/FormHeader';
import AddPost from './AddPost';

const NewPostCard = () => {
  const [newPost, toggleNewPost] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <FormHeader
        title={t('Posts overview')}
        subtitle={t('See, edit and add new posts')}
        button={(
          <Button
            color="primary"
            className="text-nowrap mb-1 mt-2"
            outline
            onClick={() => toggleNewPost(true)}
          >
            <div className="d-flex justify-content-center">
              <Plus size={15} />
              &nbsp;
              <span>{t('Add post')}</span>
            </div>
          </Button>
        )}
      />
      <AddPost
        showModal={newPost}
        setShowModal={toggleNewPost}
      />
    </>
  );
};

export default NewPostCard;
