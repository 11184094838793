import { TFunction } from 'react-i18next';
import * as yup from 'yup';

const databaseValidationSchema = (t: TFunction<'translation', undefined>) => {
  const validation = {
    databaseName: yup
      .string()
      .required(t('Database name is required')),
    databaseUsername: yup
      .string()
      .required(t('Database username is required')),
    databasePassword: yup
      .string()
      .required(t('Database password is required')),
    databaseHost: yup
      .string()
      .required(t('Database host is required')),
    databasePort: yup
      .number()
      .typeError(t('You can only enter positive numbers'))
      .integer()
      .required(t('Database port is required')),
  };
  return validation;
};

export default databaseValidationSchema;
