import SelectedOptions from '@src/types/SelectedOptions';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import * as yup from 'yup';

const childrenValidationSchema = (
  selectedObject: SingleValue<SelectedOptions> | undefined,
  selectedGroup: SingleValue<SelectedOptions> | undefined,
  selectedTypeOfService: SingleValue<SelectedOptions> | undefined,
  selectedDocumentIdentifier: SingleValue<SelectedOptions> | undefined,
  selectedFamilyStatus: SingleValue<SelectedOptions> | undefined,
  selectedPredefinedPackageId: SingleValue<SelectedOptions> | undefined,

) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    name: yup.string().required(t('Name is required')),
    surname: yup.string().required(t('Surname is required')),
    object: yup.mixed().test('required', t('Object is required'), () => !!selectedObject?.value),
    group: yup.mixed().test('required', t('Group is required'), () => !!selectedGroup?.value),
    typeOfService: yup.mixed().test('required', t('Type of service is required'), () => !!selectedTypeOfService?.value),
    familyStatus: yup.mixed().test('required', t('Family status is required'), () => !!selectedFamilyStatus?.value),
    predefinedPackageId: yup.mixed().test('required', t('Package is required'), () => !!selectedPredefinedPackageId?.value),
    embg: yup.string().nullable().matches(/^.{0}$|^\d{13}$/, t('JMBG must be exactly 13 numbers long')),
    documentNumber: yup.mixed().test('required', t('Document number is requierd'), (value) => {
      if (!!selectedDocumentIdentifier?.value && (value === '' || value === undefined || value === null)) {
        return false;
      }

      return true;
    }),
  });
  return validation;
};

const childrenFileValidationSchema = (
  files: File[],
  isEdit: boolean,
) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    title: yup.string().required(t('Name is required')),
    file: yup.mixed().test('required', t('File is required'), () => isEdit || files.length === 1),
  });
  return validation;
};

export { childrenValidationSchema, childrenFileValidationSchema };
