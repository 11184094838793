import SpinnerComponent from '@components/spinner/Fallback-spinner';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { errorToast } from '@src/components/wrappers/ToastMessages';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { handleLogin } from '@store/authentication';
import { AbilityContext } from '@src/utility/context/Can';
import { getHomeRouteForLoggedInUser } from '@src/utility/Utils';
import ToastWrapper from '@src/components/wrappers/ToastWrapper';
import { Check } from 'tabler-icons-react';
import { callSso } from './store';

const Sso = () => {
  const { token } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const ability = useContext(AbilityContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      callSso(token)
        .then((data) => {
          const userData = {
            accessToken: data.data.accessToken,
            permissions: data.data.permissions,
            ability: [
              {
                action: 'manage',
                subject: 'all',
              },
            ],
          };
          dispatch(handleLogin(userData));
          // eslint-disable-next-line react/destructuring-assignment
          ability.update(userData.ability);
          navigate(getHomeRouteForLoggedInUser());
          ToastWrapper(t('Success'), t('Successfully logged in'), <Check />, 'primary');
        })
        .catch(() => {
          errorToast(t('An error occured while logging in'));
          navigate('/login');
        });
    } else {
      errorToast(t('An error occured while logging in'));
      navigate('/login');
    }
  }, []);

  return (
    <SpinnerComponent />
  );
};

export default Sso;
