import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CreateInvoicePackage from '../types/CreateInvoicePackage';

const InvoiceItemTable = (
  { packages, total } : { packages: CreateInvoicePackage[], total: string },
) => {
  const { t } = useTranslation();

  return (
    <div>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>{t('Item num')}.</th>
            <th>{t('Item')}</th>
            <th>{t('Price')}</th>
            <th>{t('VAT')} (%)</th>
            <th>{t('Disc')} (%)</th>
            <th>{t('Total')}</th>
          </tr>
        </thead>
        <tbody>
          {
            packages.map((row, id) => (
              <>
                <tr>
                  <td key={`${row.id}-id`}>{id + 1}</td>
                  <td key={`${row.id}-packageName`}>{`${row.packageName}`}</td>
                  <td key={`${row.id}-price`}>{`${row.price}`}</td>
                  <td key={`${row.id}-vat`}>{`${row.vat}%`}</td>
                  <td key={`${row.id}-discount`}>{`${row.discount ?? 0}%`}</td>
                  <td key={`${row.id}-finalPrice`}>{`${row.finalPrice}`}</td>
                </tr>
                {row.packageNote && (
                <tr>
                  <td key={`${row.id}-note`}>{t('Note')}:</td>
                  <td key={`${row.id}-packageNote`} colSpan={5}>{row.packageNote}</td>
                </tr>
                )}
              </>
            ))
          }
          <tr>
            <td colSpan={4} aria-label="empty-space" />
            <td style={{ fontWeight: 'bold' }}>{t('Total')}:</td>
            <td>{total}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default InvoiceItemTable;
