//* * React Imports
import { useEffect } from 'react';

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux';
import { handleLayout, handleLastLayout } from '@store/layout';
import { AppDispatch } from '@store/store';

interface Layouts {
  layout: string;
  lastLayout: string;
}

export const useLayout = () => {
  // ** Hooks
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: {layout: Layouts}) => state.layout);

  const setLayout = (value: string) => {
    dispatch(handleLayout(value));
  };

  const setLastLayout = (value: string) => {
    dispatch(handleLastLayout(value));
  };

  if (window) {
    const breakpoint = 1200;

    useEffect(() => {
      if (window.innerWidth < breakpoint) {
        setLayout('vertical');
      }

      window.addEventListener('resize', () => {
        if (window.innerWidth <= breakpoint && store.lastLayout !== 'vertical' && store.layout !== 'vertical') {
          setLayout('vertical');
        }
        if (window.innerWidth >= breakpoint && store.lastLayout !== store.layout) {
          setLayout(store.lastLayout);
        }
      });
    }, [store.layout]);
  }

  return {
    layout: store.layout, setLayout, lastLayout: store.lastLayout, setLastLayout,
  };
};
