/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Evaluation from '../types/Evaluation';

export const formatDateLocalDateString = (date: string | Date) => {
  const dateToFrormat = new Date(date);
  return dateToFrormat.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
};

export const formatDateMonthYear = (date: string | Date) => {
  const dateToFrormat = new Date(date);
  return dateToFrormat.toLocaleDateString('en-GB', { month: 'short', year: 'numeric' });
};

const EvaluationColumns = ({ handleDeleteClick }:
  { handleDeleteClick: (invoiceId: number) => void }) => {
  const { t } = useTranslation();
  const evaluaitonPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const navigate = useNavigate();

  const columns: TableColumn<Evaluation>[] = [
    {
      name: t('Title'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.createdBy.name} ${row.createdBy.surname}`}</p>
      ),
    },
    {
      name: t('Date from'),
      minWidth: '300px',
      cell: (row) => (
        <p className="m-0" key={row.id}>
          {row.evaluationType === 'DAILY_EVALUATIONS' ? (
            <span>{`${formatDateLocalDateString(row.dateFrom)}`}</span>
          ) : <></>}
          {row.evaluationType === 'WEEKLY_EVALUATIONS' ? (
            <span>{`${formatDateLocalDateString(row.dateFrom)} - ${formatDateLocalDateString(row.dateTo)}`}</span>
          ) : <></>}
          {row.evaluationType === 'MONTHLY_EVALUATIONS' ? (
            <span>{`${formatDateMonthYear(row.dateFrom)}`}</span>
          ) : <></>}
          {row.evaluationType === 'YEARLY_EVALUATIONS' ? (
            <span>{`${new Date(row.dateFrom).getFullYear()}`}</span>
          ) : <></>}
        </p>
      ),
    },
    {
      name: t('Type'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{t(`${row.evaluationType}`)}</p>
      ),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(evaluaitonPermission, 'Evaluations')
            ? (
              <Button
                size="sm"
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                className="btn btn-icon"
                title={t('Edit object')}
                color="transparent"
                onClick={() => { navigate(`/evaluations/edit/${row.id}`); }}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(evaluaitonPermission, 'Evaluations')
            ? (
              <Button
                size="sm"
                className="btn btn-icon"
                onClick={() => { handleDeleteClick(row.id); }}
                color="transparent"
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];

  return columns;
};

export default EvaluationColumns;
