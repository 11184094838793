import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorToast } from '@src/components/wrappers/ToastMessages';
import axios from 'axios';
import { EventInput } from '@fullcalendar/react';
import RecurringNonWorkingDay from '../types/RecurringNonWorkingDay';
import NonWorkingDaySubmitData from '../types/NonWorkingDaySubmitData';
import EditNonWorkingDaySubmitData from '../types/EditNonWorkingDaySubmitData';

export const getAllRecurringNonWorkingDays = createAsyncThunk('goKinder/getRecurringAllNonWorkingDays', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/recurring-non-working-days`);
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const createRecurringNonWorkingDay = createAsyncThunk('goKinder/createRecurringNonWorkingDay', async (data: NonWorkingDaySubmitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/recurring-non-working-days/`, { ...data });
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const deleteRecurringNonWorkingDay = createAsyncThunk('goKinder/deleteRecurringNonWorkingDay', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/recurring-non-working-days/${id}`);
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const updateRecurringNonWorkingDay = createAsyncThunk('goKinder/updateRecurringNonWorkingDay', async (data: EditNonWorkingDaySubmitData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/recurring-non-working-days/${data.id}`, { ...data });
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const recurringNonWorkingDaysSlice = createSlice({
  name: 'recurringNonWorkingDays',
  initialState: {
    allRecurringNonWorkingDays: [] as RecurringNonWorkingDay[],
    generatedEvents: [] as EventInput[],
  },
  reducers: {
    setGeneratedEvents: (state, action) => {
      state.generatedEvents = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRecurringNonWorkingDays.fulfilled, (state, action) => {
      state.allRecurringNonWorkingDays = action.payload.data;
    });
  },
});

export const { setGeneratedEvents } = recurringNonWorkingDaysSlice.actions;

export default recurringNonWorkingDaysSlice.reducer;
