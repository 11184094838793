// ** Reactstrap Imports
import {
  Card, CardBody, Button, ListGroup, ListGroupItem,
} from 'reactstrap';

// ** Third Party Imports
import toast from 'react-hot-toast';
import { useDropzone } from 'react-dropzone';
import { X, CloudUpload } from 'tabler-icons-react';

import '@styles/react/libs/file-uploader/file-uploader.scss';
import { useTranslation } from 'react-i18next';
import { SyntheticEvent } from 'react';
import videoThumbnail from '@src/assets/images/posts/video-thumbnail-icon.png';
import FileType from './types/FileType';

interface FileUploaderProps {
  files: FileType[],
  newFiles: File[],
  setNewFiles: (_arg1: File[]) => void;
  setFiles: (_arg1: FileType[]) => void;
  onDelete?: (fileId: number) => void;
  maxFileSize?: number;
  maxFiles?: number;
  acceptFiles?: {
    [key: string]: string[]
  }
}

const FileUploader = ({
  files,
  setFiles,
  newFiles,
  setNewFiles,
  onDelete,
  maxFiles = 5,
  maxFileSize = 100_000_000,
  acceptFiles = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    'video/mp4': ['.mp4'],
  },
}: FileUploaderProps) => {
  const { t } = useTranslation();

  const fileConstraints = {
    disabled: files.length >= maxFiles,
    maxFiles,
    maxSize: maxFileSize,
    accept: acceptFiles,
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...fileConstraints,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        if (rejectedFiles.length > fileConstraints.maxFiles) {
          toast.error(
            `${t('You can upload a maxiumum of')} ${maxFiles} ${t('files in supported formats')} (${Object.values(acceptFiles).join(', ')})`,
          );
        }
        if (rejectedFiles.some((f) => f.file.size > fileConstraints.maxSize)) {
          toast.error(
            `${t('File cannot be uploaded! File size cannot be larger than')} ${maxFileSize / 1_000_000} MB`,
          );
        }
      } else {
        const newPostFiles: FileType[] = [];
        acceptedFiles.forEach((file: File) => {
          newPostFiles.push(
            {
              name: file.name,
              path: URL.createObjectURL(file),
              size: file.size,
            } as FileType,
          );
        });
        setFiles([...files, ...newPostFiles]);
        setNewFiles([...newFiles, ...acceptedFiles.map((file) => Object.assign(file))]);
      }
    },
  });

  const handleRemoveFile = (file: FileType) => {
    if (file.id) {
      if (onDelete) {
        onDelete(file.id);
      }
    }
    const index = files.indexOf(file);
    setFiles(files.filter((f) => files.indexOf(f) !== index));
    setNewFiles(newFiles.filter((f) => f.name !== file.name));
  };

  const renderFileSize = (size: number) => {
    if (Math.round(size / 100) / 10 > 1000) {
      return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`;
    }
    return `${(Math.round(size / 100) / 10).toFixed(1)} kb`;
  };

  const fileList = files.map((file, i) => {
    const lastDotIndex = file.name.lastIndexOf('.');

    const fileNameWithoutExtension = lastDotIndex !== -1
      ? file.name.substring(0, lastDotIndex)
      : file.name;

    function handleImageIcon(e: SyntheticEvent<HTMLImageElement, Event>): void {
      e.currentTarget.src = videoThumbnail;
    }

    return (
      <ListGroupItem key={`file.name${i + 1}`} className="d-flex align-items-center justify-content-between">
        <div className="file-details d-flex align-items-center">
          <div className="file-preview me-1">
            <img
              className="rounded"
              alt="no thumbnail"
              src={file.path}
              height="28"
              width="28"
              onError={(e) => handleImageIcon(e)}
            />
          </div>
          <div>
            <p className="file-name mb-0">{fileNameWithoutExtension}</p>
            <p className="file-size mb-0">{renderFileSize(file.size ?? 0)}</p>
          </div>
        </div>
        <Button
          color="danger"
          outline
          size="sm"
          className="btn-icon"
          onClick={() => handleRemoveFile(file)}
        >
          <X size={14} />
        </Button>
      </ListGroupItem>
    );
  });

  return (
    <Card style={{ boxShadow: 'none' }}>
      <CardBody className="p-0">
        <div
          {...getRootProps({ className: 'dropzone' })}
          style={files.length >= fileConstraints.maxFiles ? { borderColor: 'red' } : { borderColor: '#7DCBEC' }}
        >
          <input {...getInputProps()} />
          <div className="d-flex align-items-center justify-content-center flex-column">
            <CloudUpload size={64} />
            <h5>{t('Drop Files here or click to upload')}</h5>
            <p className="text-secondary mb-0">
              {t('Drop files here or click')}{' '}
              <a href="/" onClick={(e) => e.preventDefault()}>
                {t('browse')}
              </a>{' '}
              {t('thorough your machine')}
            </p>
            <p className="text-secondary text-center">
              {`${t('Supported formats')} ${Object.values(acceptFiles).join(', ')}. ${t('Max')} ${maxFiles} ${t('files')}.`}
              <br />
              {`${t('Max size per file')} ${maxFileSize / 1_000_000} MB`}
            </p>
          </div>
        </div>
        {files.length >= fileConstraints.maxFiles && <p className="text-danger text-center mt-1">{t('File limit reached')}</p>}
        {files.length > 0 && <ListGroup className="my-2">{fileList}</ListGroup>}
      </CardBody>
    </Card>
  );
};

export default FileUploader;
