import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useTranslation } from 'react-i18next';
import useFilter from '@src/utility/hooks/useFilter';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import { getAllParents } from '@src/views/parents/store';
import { getAllUsers } from '@src/views/users/store';
import UserState from '@src/views/users/types/UserState';
import Parent from '@src/views/parents/types/Parent';
import SmsColumns from '../components/SmsColumns';
import {
  getAllSmses,
  setSelectedParents,
  setSelectedUsers,
} from '../store';
import SmsState from '../types/SmsState';

const Smses = () => {
  const dispatch = useDispatch<AppDispatch>();
  const smsStore = useSelector((store: SmsState) => store.smses);
  const AllParents = useSelector(
    (store: any) => store.parents.allParents,
  );
  const AllUsers = useSelector(
    (store: UserState) => store.users.allUsers,
  );
  const { t } = useTranslation();
  const { onFilterChange, query } = useFilter();
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    dispatch(getAllParents());
    dispatch(getAllUsers());
    setDataLoaded(true);
  }, []);

  useEffect(() => {
    dispatch(setSelectedParents(AllParents.map(
      (item: Parent) => ({ label: t(`${item.name} ${item.surname}`), value: item.id }),
    )));
  }, [AllParents]);

  useEffect(() => {
    dispatch(setSelectedUsers(AllUsers.map(
      (item) => ({ label: t(`${item.name} ${item.surname}`), value: item.id }),
    )));
  }, [AllUsers]);

  const onFilterClick = () => dispatch(getAllSmses(query));

  return (
    <div>
      <DataTableWrapper
        dataSource={smsStore.allSmses}
        filters={[{
          label: 'Sender', name: 'createdByIds', options: smsStore.selectedUsers, isMulti: true,
        },
        {
          label: 'Receiver', name: 'parentIds', options: smsStore.selectedParents, isMulti: true,
        }]}
        onFilterClick={onFilterClick}
        title={t('Smses')}
        subtitle={t('View sent smses')}
        columns={SmsColumns()}
        onChange={onFilterChange}
        showSearch={false}
        dataLoaded={dataLoaded}
      />
    </div>
  );
};

export default Smses;
