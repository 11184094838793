import moment from 'moment';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import {
  File, Edit, Delete, Eye,
} from 'react-feather';
import {
  CalendarTime, Notes, User,
} from 'tabler-icons-react';
import { IconListDetails } from '@tabler/icons-react';
import { extractFileName, getUserAccessTokenData } from '@src/utility/Utils';
import { useSkin } from '@src/utility/hooks/useSkin';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';

import Note from '../types/Note';
import NotesForm from './NotesForm';
import { deleteNote, getAllNotes } from '../store';
import NoteModal from './NoteModal';

interface PostCardProps {
  pdfContent: string;
  details: Note;
}

const NotesCard = ({ pdfContent, details }: PostCardProps) => {
  const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD').toString();
  const dispatch = useDispatch<AppDispatch>();
  const userTokenData = getUserAccessTokenData();
  const { t } = useTranslation();
  const { skin } = useSkin();
  const notesPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const formatNoteTitle = (text: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const cleanTitle = doc.body.textContent ?? '';

    return cleanTitle;
  };

  const handleDeleteClick = (noteForDeletion: Note) => {
    dispatch(
      openModal({
        Component: ConfirmationModalWrapper,
        title: t('Are you sure?'),
        subtitle: t('Note will be deleted'),
        open: true,
        componentProps: {
          handleConfirm: async () => {
            try {
              await dispatch(deleteNote(noteForDeletion.id));
            } finally {
              successToast(t('Note successfully deleted'));
              dispatch(getAllNotes());
              dispatch(closeModal());
            }
          },
        },
      }),
    );
  };

  const handleNoteClick = () => {
    dispatch(openModal({
      Component: NoteModal,
      componentProps: { note: details },
      title: t('Note'),
      open: true,
    }));
  };

  const canEdit = userTokenData.role === 'superAdmin' || userTokenData.sub === details.author.id;
  const disabledColor = skin === 'dark' ? '#444' : '#cccccc';

  return (
    <Col md={4}>
      <Card>
        <CardBody>
          <div>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><User size={20} />&nbsp;</span>
              {t('Child name')}:&nbsp;
              {`${details.child.name} ${details.child.surname}`}
            </p>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><CalendarTime size={20} />&nbsp;</span>
              {t('Date')}:&nbsp;
              {details.date && formatDate(new Date(details.date)) !== 'Invalid date' ? formatDate(new Date(details.date)) : ''}
            </p>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><IconListDetails size={20} />&nbsp;</span>
              {t('Note category')}:&nbsp;
              {t(details.noteCategory.name)}
            </p>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><User size={20} />&nbsp;</span>
              {t('Written by')}:&nbsp;
              {details.author.name}
            </p>
            <Button
              outline
              color="outline"
              onClick={handleNoteClick}
              block
              style={{
                margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
              }}
            >
              <p
                className="cursor-pointer"
                style={{
                  whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'start', marginBottom: '0.5rem',
                }}
              >
                <span style={{ color: '#7DCBEC' }}><Notes size={20} />&nbsp;</span>
                {t('Note')}:{formatNoteTitle(details.note)}
              </p>
            </Button>

            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><Eye size={20} />&nbsp;</span>
              {t('Visible to parents')}:&nbsp;
              {t(details.visible ? 'Yes' : 'No')}
            </p>
          </div>
          {pdfContent ? (
            <>
              <hr />
              <a
                href={details.file}
                download
                target="_blank"
                style={{ gap: '10px', width: 'fit-content' }}
                className="d-flex align-items-center"
                rel="noreferrer"
              >
                <File width={15} height={15} /> {extractFileName(pdfContent)}
              </a>
            </>
          ) : (
            <>
              <hr />
              <p
                style={{ color: skin === 'dark' ? '#b4b7bd' : '', gap: '10px', width: 'fit-content' }}
                className="d-flex align-items-center my-0"
              >
                <File width={15} height={15} /> <span>{t('No content')}</span>
              </p>
            </>
          )}
          <>
            <hr />
            <div className="d-flex justify-content-between w-full">
              {isManagePermissionActive(notesPermission, 'Notes')
                ? (
                  <Button
                    style={{ border: 'none', color: !canEdit ? disabledColor : '' }}
                    color="outline m-0 p-0"
                    disabled={!canEdit}
                    onClick={() => {
                      dispatch(openModal({
                        Component: NotesForm,
                        componentProps: { child: details.child, note: details },
                        title: t('Edit note'),
                        open: true,
                        modalProps: { size: 'lg' },
                      }));
                    }}
                  ><Edit />
                  </Button>
                ) : <></>}
              {isDeletePermissionActive(notesPermission, 'Notes')
                ? (
                  <Button
                    style={{ border: 'none', color: !canEdit ? disabledColor : '' }}
                    disabled={!canEdit}
                    color="outline m-0 p-0"
                    onClick={() => handleDeleteClick(details)}
                  ><Delete />
                  </Button>
                ) : <></>}
            </div>
          </>
        </CardBody>
      </Card>
    </Col>
  );
};

export default NotesCard;
