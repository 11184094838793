import moment from 'moment';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import {
  Edit, Delete,
  File,
} from 'react-feather';
import {
  BuildingBank,
  CalendarTime, Heading,
} from 'tabler-icons-react';
import { extractFileName, getUserAccessTokenData } from '@src/utility/Utils';
import { useSkin } from '@src/utility/hooks/useSkin';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { successToast } from '@src/components/wrappers/ToastMessages';
import AnnouncementModal from '@src/views/announcements/components/AnnouncementModal';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { deleteCityForm, getAllCityForms } from '../store';
import ContractForm from './CityFormsForm';
import CityForm from '../types/CityForm';

const CityFormCard = ({ details }: { details: CityForm }) => {
  const dispatch = useDispatch<AppDispatch>();
  const userTokenData = getUserAccessTokenData();
  const { t } = useTranslation();
  const { skin } = useSkin();
  const cityFormPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const handleDeleteClick = (cityFormId: number) => {
    dispatch(
      openModal({
        Component: ConfirmationModalWrapper,
        title: t('Are you sure?'),
        subtitle: t('City form will be deleted'),
        open: true,
        componentProps: {
          handleConfirm: async () => {
            try {
              await dispatch(deleteCityForm(cityFormId));
            } finally {
              successToast(t('City form successfully deleted'));
              dispatch(getAllCityForms());
              dispatch(closeModal());
            }
          },
        },
      }),
    );
  };

  const handleTenantsClick = () => {
    dispatch(openModal({
      Component: AnnouncementModal,
      componentProps: { content: details.tenants.length ? details.tenants.map((tenant) => tenant.name).join(', ') : t('All') },
      title: t('Message'),
      open: true,
    }));
  };

  const disabledColor = skin === 'dark' ? '#444' : '#cccccc';
  const canEdit = userTokenData.role === 'superAdmin';

  return (
    <Col md={4}>
      <Card>
        <CardBody>
          <div>
            <p
              className="detail-wrapper"
              style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
            >
              <span style={{ color: '#7DCBEC' }}><Heading size={20} /></span>
              {t('Title')}:&nbsp;{details.title}
            </p>
          </div>
          <div>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><CalendarTime size={20} />&nbsp;</span>
              {t('Created at')}:&nbsp;
              {moment(new Date(details.createdAt)).format('DD. MMM Y. HH:mm')}
            </p>
          </div>
          <Button
            outline
            color="outline"
            block
            style={{
              margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
            }}
            onClick={handleTenantsClick}
          >
            <p
              className="cursor-pointer"
              style={{
                whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'start', marginBottom: '0.5rem',
              }}
            >
              <span style={{ color: '#7DCBEC' }}><BuildingBank size={20} />&nbsp;</span>
              {t('Tenants')}:&nbsp;
              {details.tenants.length ? details.tenants.map((tenant) => tenant.name).join(', ') : t('All')}
            </p>
          </Button>
          <hr />
          <a
            href={details.filePath}
            download
            target="_blank"
            style={{ gap: '10px', width: 'fit-content' }}
            className="d-flex align-items-center"
            rel="noreferrer"
          >
            <File width={15} height={15} />{extractFileName(details.filePath)}
          </a>
          <hr />
          <div className="w-full d-flex justify-content-between">
            <div>
              {isManagePermissionActive(cityFormPermission, 'AdministrationCityForms')
                ? (
                  <Button
                    color="outline m-0 p-0"
                    style={{ border: 'none', color: !canEdit ? disabledColor : '' }}
                    disabled={!canEdit}
                    onClick={() => {
                      dispatch(openModal({
                        Component: ContractForm,
                        componentProps: { cityForm: details },
                        title: t('Edit city form'),
                        open: true,
                        modalProps: { size: 'lg' },
                      }));
                    }}
                  ><Edit />
                  </Button>
                ) : <></>}
            </div>
            {isDeletePermissionActive(cityFormPermission, 'AdministrationCityForms')
              ? (
                <Button
                  style={{ border: 'none', color: !canEdit ? disabledColor : '' }}
                  disabled={!canEdit}
                  color="outline m-0 p-0"
                  onClick={() => handleDeleteClick(details.id)}
                ><Delete color="red" />
                </Button>
              ) : <></>}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CityFormCard;
