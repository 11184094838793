/* eslint-disable camelcase */
import InputPasswordToggle from '@components/input-password-toggle';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { closeModal } from '@store/modal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import {
  Button, Col, Form, Row,
} from 'reactstrap';
import { changePassword } from './store';
import { ChangePasswordValidationSchema } from './validation';

type ChangePasswordSubmitData = {
  old_password: string;
  password: string;
  confirm_password: string;
}

const ChangePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordSubmitData>({
    defaultValues: {
      old_password: '',
      password: '',
      confirm_password: '',
    },
    resolver: yupResolver(ChangePasswordValidationSchema()),
  });

  const handleSuccessfulSubmit = async (data: ChangePasswordSubmitData) => {
    const { old_password, password, confirm_password } = data;
    const res = await changePassword(old_password, password, confirm_password);

    if (res) {
      toast.success(t('Password changed successfully'));
      dispatch(closeModal());
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={12}>
          <CustomLabel name={t('Old password')} />
          <Controller
            name="old_password"
            control={control}
            render={({ field }) => (
              <InputPasswordToggle
                className="input-group-merge"
                invalid={errors.old_password}
                {...field}
              />
            )}
          />
          {errors?.old_password?.message
          && <CustomFormFeedback message={errors.old_password.message} />}
        </Col>
        <Col md={12}>
          <CustomLabel name={t('New password')} />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <InputPasswordToggle
                className="input-group-merge"
                invalid={errors.password}
                {...field}
              />
            )}
          />
          {errors?.password?.message
          && <CustomFormFeedback message={errors.password.message} />}
        </Col>
        <Col md={12}>
          <CustomLabel name={t('Confirm new password')} />
          <Controller
            name="confirm_password"
            control={control}
            render={({ field }) => (
              <InputPasswordToggle
                className="input-group-merge"
                invalid={errors.confirm_password}
                {...field}
              />
            )}
          />
          {errors?.confirm_password?.message
          && <CustomFormFeedback message={errors.confirm_password.message} />}
        </Col>
        <Col md={12}>
          <Button type="submit" data-testid="confirm-delete-feedback" className="me-1" color="primary" style={{ marginTop: '16px' }} block>
            {t('Save')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ChangePassword;
