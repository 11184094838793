import { AppDispatch } from '@store/store';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card, CardBody, CardHeader, CardTitle, Col, Row,
} from 'reactstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TabContext from '@mui/lab/TabContext';
import ButtonWithIcon from '@src/components/buttons/ButtonWithIcon';
import { SyntheticEvent } from 'react-draft-wysiwyg';
import TabList from '@mui/lab/TabList';
import { Tab, Theme } from '@mui/material';
import { Info } from 'react-feather';
import { FileDownload } from 'tabler-icons-react';
import { getAllNotes } from '@src/views/notes/store';
import NotesState from '@src/views/notes/types/NotesState';
import TabPanel from '@mui/lab/TabPanel';
import AbsencesTab from '@src/views/absences/components/AbsencesTab';
import EvaluationsTab from '@src/views/evaluations/components/EvaluationsTab';
import ContractState from '@src/views/contracts/types/ContractState';
import useBufferDownload from '@src/utility/hooks/useBufferDownload';
import { extractFileExtension } from '@src/utility/Utils';
import { getAllContracts } from '@src/views/contracts/store';
import { isManagePermissionActive, isViewPermissionActive } from '@src/utility/context/ActivePermissions';
import SendEmailModal from '@src/components/message-senders/SendEmailModal';
import SendSmsModal from '@src/components/message-senders/SendSmsModal';
import { openModal } from '@store/modal';
import FinanceChart from '@src/views/invoice/components/FinanceChart';
import PaymentsTab from '@src/views/payments/components/PaymentsTab';
import ChildrenState from '../types/ChildrenState';
import { clearCurrentChild, getChildById } from '../store';
import RelatedParents from '../components/RelatedParents';
import NotesAccordion from '../../notes/components/NotesAccordion';
import FinancesTab from '../../invoice/components/FinancesTab';
import ChildrenFiles from '../components/ChildrenFiles';

const ChildProfile = () => {
  const store = useSelector((state: ChildrenState) => state.children);
  const notesStore = useSelector((state: NotesState) => state.notes);
  const contractStore = useSelector((state: ContractState) => state.contract);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { childId } = useParams();
  const { t } = useTranslation();
  const { download } = useBufferDownload();
  const childPermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab');
  const parentSelectOptions = useMemo(() => store.currentChild.parents?.map(
    (parent) => ({ label: `${parent.name} ${parent.surname}`, value: parent.id }),
  ), [store.currentChild.parents]);

  const handleTabsChange = (event: SyntheticEvent, newValue: string) => {
    setSearchParams({ tab: newValue });
  };

  useEffect(() => {
    if (childId) {
      dispatch(getChildById(+childId)).then((data) => {
        if (data.meta.requestStatus === 'rejected') {
          navigate('/404');
        }
      });

      dispatch(getAllNotes(`childId=${childId}`));
      dispatch(getAllContracts());
    }

    if (!activeTab) setSearchParams({ tab: 'child-information' });

    return () => {
      dispatch(clearCurrentChild());
    };
  }, []);

  const sendEmail = () => {
    dispatch(openModal({
      Component: SendEmailModal,
      title: t('Send email'),
      open: true,
      modalProps: { size: 'lg' },
      componentProps: {
        route: '/emails/parents',
        options: parentSelectOptions,
        idName: 'parent',
      },
    }));
  };

  const sendSms = () => {
    dispatch(openModal({
      Component: SendSmsModal,
      title: t('Send sms'),
      open: true,
      modalProps: { size: 'lg' },
      componentProps: {
        route: '/sms/parents',
        options: parentSelectOptions,
        idName: 'parent',
      },
    }));
  };

  return (
    <TabContext value={activeTab ?? 'child-information'}>
      <TabList
        variant="scrollable"
        scrollButtons={false}
        onChange={handleTabsChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#7DCBEC',
          },
        }}
        sx={{ borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`, '& .MuiTab-root': { py: 3.5 } }}
      >
        <Tab className="tenant_navigation" value="child-information" label={t('Child information')} />
        {
          isViewPermissionActive(childPermissions, 'Notes')
            ? (
              <Tab className="tenant_navigation" value="child-notes" label={t('Notes')} />
            ) : <></>
        }
        {
          isViewPermissionActive(childPermissions, 'Absences')
            ? (
              <Tab className="tenant_navigation" value="child-absences" label={t('Absences')} />
            ) : <></>
        }
        {
          isViewPermissionActive(childPermissions, 'Invoices')
            ? (
              <Tab className="tenant_navigation" value="child-finances" label={t('Finances')} />
            ) : <></>
        }
        {
          isViewPermissionActive(childPermissions, 'Payments')
            ? (
              <Tab className="tenant_navigation" value="child-payments" label={t('Payments')} />
            ) : <></>
        }
        {
          isViewPermissionActive(childPermissions, 'Evaluations')
            ? (
              <Tab className="tenant_navigation" value="child-evaluations" label={t('Evaluations')} />
            ) : <></>
        }
        {
          isViewPermissionActive(childPermissions, 'ChildFiles')
            ? (
              <Tab className="tenant_navigation" value="child-files" label={t('Files')} />
            ) : <></>
        }
      </TabList>
      <TabPanel className="mt-1" value="child-information">
        <Row>
          <Col className="p-0" md={3}>
            <Card>
              <CardBody>
                <Col md={12} className="d-flex gap-2 flex-column">
                  <Row className="d-flex justify-content-center">
                    <img
                      src={store.currentChild.image}
                      alt={t('Child')}
                      width="100%"
                      height="100%"
                      style={{
                        padding: 0, maxWidth: '170px', maxHeight: '170px', borderRadius: '5px',
                      }}
                    />
                  </Row>
                  <Row>
                    <h3 className="text-center mb-0">{`${store.currentChild.name} ${store.currentChild.surname}`}</h3>
                  </Row>
                  <RelatedParents parents={store.currentChild.parents} />
                  <Col xs={12} className="text-center">
                    {isManagePermissionActive(childPermissions, 'sendEmailToParents') ? (
                      <Button
                        color="primary"
                        style={{ margin: '8px 4px 0' }}
                        onClick={sendEmail}
                      >
                        {t('Send email') }
                      </Button>
                    ) : <></>}
                    {isManagePermissionActive(childPermissions, 'administrationSms') ? (
                      <Button
                        type="reset"
                        color="secondary"
                        outline
                        style={{ margin: '8px 4px 0' }}
                        onClick={() => dispatch(sendSms)}
                      >
                        {t('Send sms')}
                      </Button>
                    ) : <></>}
                  </Col>
                  <Row>
                    <Button
                      color="outline"
                      className="d-flex align-items-center justify-content-center py-0 gap-50"
                      onClick={() => {
                        navigate(`/children/${childId}/edit`);
                      }}
                    >
                      <Col md={2} style={{ width: 'fit-content' }}>
                        <Info />
                      </Col>
                      <Col md={10} style={{ width: 'fit-content' }}>
                        {t('Edit basic information')}
                      </Col>
                    </Button>
                  </Row>
                </Col>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardHeader style={{ paddingTop: 0 }}>
                  <CardTitle style={{ margin: '0 auto' }}>{t('Documents')}</CardTitle>
                </CardHeader>
                <Row>
                  {contractStore.allContracts.length ? contractStore.allContracts.map((item) => (
                    <div key={item.id} style={{ padding: '0 16px 8px' }}>
                      <ButtonWithIcon
                        IconComponent={FileDownload}
                        iconProps={{ size: 15 }}
                        handleClick={() => download({
                          downloadUrl: `contracts/download-file/${childId}/${item.id}`,
                          fileName: `${store.currentChild.name}-${store.currentChild.surname}-${item.title}.${extractFileExtension(item.filePath)}`,
                        })}
                        block
                        text={item.title}
                      />
                    </div>
                  )) : (
                    <p style={{ textAlign: 'center' }}>{t('No documents added')}</p>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={9}>
            <Card>
              <CardBody>
                <FinanceChart childId={Number(childId ?? 0)} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </TabPanel>
      {
        isViewPermissionActive(childPermissions, 'Notes')
          ? (
            <TabPanel value="child-notes">
              <NotesAccordion
                notes={notesStore.allNotes ?? []}
              />
            </TabPanel>
          ) : <></>
      }
      {
        isViewPermissionActive(childPermissions, 'Absences')
          ? (
            <TabPanel value="child-absences" className="px-0 mx-0">
              <AbsencesTab />
            </TabPanel>
          ) : <></>
      }
      {
        isViewPermissionActive(childPermissions, 'Invoices')
          ? (
            <TabPanel value="child-finances" className="px-0 mx-0">
              <FinancesTab />
            </TabPanel>
          ) : <></>
      }
      {
        isViewPermissionActive(childPermissions, 'Payments')
          ? (
            <TabPanel value="child-payments" className="px-0 mx-0">
              <PaymentsTab />
            </TabPanel>
          ) : <></>
      }
      {
        isViewPermissionActive(childPermissions, 'Evaluations')
          ? (
            <TabPanel value="child-evaluations" className="px-0 mx-0">
              <EvaluationsTab />
            </TabPanel>
          ) : <></>
      }
      {
        isViewPermissionActive(childPermissions, 'ChildFiles')
          ? (
            <TabPanel value="child-files" className="px-0 mx-0">
              <ChildrenFiles />
            </TabPanel>
          ) : <></>
      }
    </TabContext>
  );
};

export default ChildProfile;
