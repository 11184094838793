import SelectedOptions from '@src/types/SelectedOptions';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import * as yup from 'yup';

const packageValidationSchema = (
  selectedCurrency: SingleValue<SelectedOptions>,
) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    name: yup.string().required(t('Name is required')),
    price: yup.number().typeError(t('Price must be a number')).required(t('Price is required')),
    currencyId: yup.mixed().test('required', t('Currency is required'), () => !!selectedCurrency?.value),
  });
  return validation;
};
export default packageValidationSchema;
