import { TFunction } from 'react-i18next';
import * as yup from 'yup';

const welcomeEmailValidationSchema = (t: TFunction<'translation', undefined>) => {
  const validation = {
    welcomeEmailSubject: yup
      .string()
      .required(t('Subject is required')),
    welcomeEmailText: yup
      .string()
      .required(t('Email template is required')),
    welcomeEmailAppLoginPath: yup
      .string()
      .required(t('App login path is required')),
    welcomeHelpEmail: yup
      .string()
      .email(t('Enter the welcome email help email in a valid format'))
      .required(t('Help email is required')),
  };
  return validation;
};

export default welcomeEmailValidationSchema;
