import { yupResolver } from '@hookform/resolvers/yup';
import FormHeader from '@src/components/forms/FormHeader';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import FileType from '@src/components/file-uploader/types/FileType';
import {
  Button, Card, CardBody, Col, Row, Spinner,
} from 'reactstrap';
import FileUploader from '@src/components/file-uploader/FileUploader';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import RequestStatus from '@src/types/RequestStatus';
import { successToast } from '@src/components/wrappers/ToastMessages';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import addFilesValidation from '../validation/AddFilesValidation';
import { addNewFiles, getAlbumById } from '../store';

const AddFilesForm = () => {
  const { t } = useTranslation();
  const { albumId } = useParams();
  const [files, setFiles] = useState<FileType[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<{files: File[] }>({
    resolver: yupResolver(addFilesValidation(files)),
  });

  const submitHandler = () => {
    setIsSaving(true);
    if (albumId) {
      dispatch(addNewFiles({ albumId: +albumId, uploadFiles: newFiles })).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successToast(t('Successfully added files to album'));
          setIsSaving(false);
          dispatch(getAlbumById(+albumId));
          navigate(`/gallery/album/${albumId}`);
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <FormHeader title={t('Add files')} subtitle={t('Add new files to album')} />
      <Card className="mb-1">
        <CardBody>
          <Col md={12}>
            <FileUploader
              files={files}
              setFiles={setFiles}
              newFiles={newFiles}
              setNewFiles={setNewFiles}
              maxFiles={100}
              maxFileSize={100_000_000}
            />
            <CustomFormFeedback message={errors.files?.message} />
          </Col>
        </CardBody>
      </Card>
      <Row className="d-flex justify-content-center mb-2 mt-1">
        <Button className="mt-1" color="primary" type="submit" style={{ maxWidth: '200px' }} disabled={isSaving}>
          {isSaving && <Spinner size="sm" style={{ marginRight: '10px' }} />}
          <span>
            { t('Add') }
          </span>
        </Button>
      </Row>
    </form>
  );
};

export default AddFilesForm;
