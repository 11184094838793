import CustomPhoneRegex from '@src/components/forms/CustomPhoneReg';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const objectValidationSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    name: yup.string().required(t('Name is required')),
    address: yup.string().required(t('Address is required')),
    phoneNumber: yup
      .string()
      .required(t('Phone number is required'))
      .matches(
        CustomPhoneRegex,
        t('Please enter contact number in valid format'),
      ),
  });
  return validation;
};
export default objectValidationSchema;
