import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardBody,
  Col, Input, Row, Spinner,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CustomLabel from '@src/components/forms/CustomLabel';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';
import { useEffect, useMemo, useState } from 'react';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import FormHeader from '@src/components/forms/FormHeader';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { getAllTenantObjects } from '@src/views/tenantObjects/store';
import { getAllTenantGroups } from '@src/views/tenantGroups/store';
import { formatDate } from '@src/views/polls/components/PollsForm';
import SelectedOptions from '@src/types/SelectedOptions';
import TenantObjectState from '@src/views/tenantObjects/types/TenantObjectState';
import TenantGroupState from '@src/views/tenantGroups/types/TenantGroupState';
import Select, { MultiValue } from 'react-select';
import { selectThemeColors } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { useNavigate, useParams } from 'react-router-dom';
import FileUploader from '@src/components/file-uploader/FileUploader';
import FileType from '@src/components/file-uploader/types/FileType';
import albumValidation from '../validation/AlbumValidation';
import AlbumFormData from '../types/AlbumFormData';
import {
  addNewAlbum, clearCurrentAlbum, editAlbum, getAlbumById, setSelectedGroups,
} from '../store';
import AlbumState from '../types/AlbumState';
import '@styles/react/libs/react-select/_react-select.scss';
import Option from '../../posts/components/Option';
import Album from '../types/Album';

const AlbumForm = () => {
  const { t } = useTranslation();
  const store = useSelector((state: AlbumState) => state.albums);
  const groupsStore = useSelector((state: TenantGroupState) => state.tenantGroups);
  const objectStore = useSelector((state: TenantObjectState) => state.tenantObjects);
  const [files, setFiles] = useState<FileType[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [groups, setGroups] = useState<MultiValue<SelectedOptions>>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { albumId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const groupOptions = useMemo(() => store.selectedGroups
    .reduce((result: SelectedOptions[], current, index, array) => {
      if (index === 0 || current.objectId !== array[index - 1].objectId) {
        const object = objectStore.allTenantObjects.find((obj) => obj.id === current.objectId);

        if (object) {
          result.push({
            value: object.name,
            label: object.name,
            title: true,
          });
        }
      }
      result.push(current);
      return result;
    }, []), [store.selectedGroups]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AlbumFormData>({
    resolver: yupResolver(albumValidation(files, groups)),
  });

  const submitHandler = (data: AlbumFormData) => {
    if (albumId) {
      setIsSaving(true);
      if (albumId === 'new') {
        dispatch(addNewAlbum({
          ...data,
          description: data.description ? data.description : '',
          date: formatDate(new Date(data.date).toISOString()),
          uplaodFiles: newFiles,
          groupIds: groups.map((group) => group.value.toString()),
        })).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            successToast(t('Successfully added new album'));
            navigate('/gallery');
          }
        }).finally(() => setIsSaving(false));
      } else {
        dispatch(editAlbum({
          ...data,
          description: data.description ? data.description : '',
          id: +albumId,
          date: formatDate(new Date(data.date).toISOString()),
          groupIds: groups.map((group) => group.value.toString()),
        })).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            successToast(t('Successfully edited album'));
            navigate('/gallery');
          }
        }).finally(() => setIsSaving(false));
      }
    }
  };

  useEffect(() => {
    setGroups([]);
    dispatch(clearCurrentAlbum());
    dispatch(getAllTenantObjects());
    dispatch(getAllTenantGroups());

    if (albumId && albumId !== 'new') {
      dispatch(getAlbumById(+albumId)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          const { data } = res.payload as {data: Album};
          setValue('title', data?.title);
          setValue('date', data.date.toString());
          if (data.description !== 'undefined') {
            setValue('description', data.description ?? '');
          }
        }
      });
    }

    return () => {
      dispatch(clearCurrentAlbum());
    };
  }, []);

  useEffect(() => {
    dispatch(setSelectedGroups(groupsStore.allTenantGroups.map((group) => ({
      label: group.name, value: group.id, objectId: group.object.id,
    }))));
  }, [groupsStore.allTenantGroups]);

  useEffect(() => {
    if (store.currentAlbum && albumId !== 'new') {
      const albumGroupIds = store.currentAlbum.groups.map((group) => group.id);
      setGroups(groupsStore.allTenantGroups.filter(
        (group) => albumGroupIds.includes(group.id),
      ).map((group) => ({
        label: group.name, value: group.id, objectId: group.object.id,
      })));

      setFiles(store.currentAlbum.albumFiles.map(((file) => ({ ...file, name: file.path.split('/').pop() ?? '' }))));
    }
  }, [store.currentAlbum, groupsStore.allTenantGroups]);

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <FormHeader
        title={albumId === 'new' ? t('New Album') : t('Edit album')}
        subtitle={albumId === 'new' ? t('Please enter album details to complete') : t('Edit album details')}
      />
      <Card>
        <CardBody>
          <Row>
            <Col md={4}>
              <CustomLabel name={t('Title')} required />
              <Controller
                name="title"
                control={control}
                defaultValue=""
                disabled={isSaving}
                render={({ field }) => (
                  <Input
                    id="title"
                    maxLength={100}
                    invalid={errors.title !== undefined}
                    {...field}
                  />
                )}
              />
              <CustomFormFeedback message={errors.title?.message} />
            </Col>
            <Col md={4}>
              <CustomDatePicker
                label={t('Date')}
                name="date"
                control={control}
                error={errors?.date?.message}
                options={{ minDate: undefined, dateFormat: 'M Y' }}
                setValue={setValue}
                defaultValue={new Date().toISOString()}
                required
                isMonthSelect
              />
            </Col>
            <Col md={4}>
              <CustomLabel name={t('Groups')} required />
              <Select
                name="groupIds"
                className="react-select"
                classNamePrefix="select"
                theme={selectThemeColors}
                placeholder={t('Select')}
                isMulti
                options={groupOptions}
                isDisabled={isSaving}
                onChange={(val) => setGroups(val as MultiValue<SelectedOptions>)}
                value={groups}
                components={{ Option }}
                closeMenuOnSelect={false}
              />
              <CustomFormFeedback message={errors.groupIds?.message} />
            </Col>
            <Col md={12}>
              <CustomLabel name={t('Description')} />
              <Controller
                name="description"
                control={control}
                disabled={isSaving}
                render={({ field }) => (
                  <Input
                    type="textarea"
                    rows="4"
                    id="description"
                    maxLength={500}
                    invalid={errors.description !== undefined}
                    {...field}
                  />
                )}
              />
              <CustomFormFeedback message={errors.description?.message} />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {albumId === 'new' && (
      <Card>
        <CardBody>
          <Col md={12}>
            <FileUploader
              files={files}
              setFiles={setFiles}
              newFiles={newFiles}
              setNewFiles={setNewFiles}
              maxFiles={100}
            />
          </Col>
        </CardBody>
      </Card>
      )}
      <Row className="d-flex justify-content-center">
        <Button className="mt-1" color="primary" type="submit" style={{ maxWidth: '200px' }} disabled={isSaving}>
          {isSaving && <Spinner size="sm" style={{ marginRight: '10px' }} />}
          <span>
            {store.currentAlbum ? t('Save changes') : t('Add') }
          </span>
        </Button>
      </Row>
    </form>
  );
};

export default AlbumForm;
