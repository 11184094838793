/* eslint-disable max-len */
import {
  Button, Col, Input, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { X } from 'react-feather';
import CustomLabel from '@src/components/forms/CustomLabel';
import Package from '@src/views/packages/types/Package';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { useEffect, useMemo } from 'react';
import { Items } from './InvoiceItems';
import InvoiceState from '../types/InvoiceState';
import { selectedPackages } from '../store';

type Props = {
  item: Items,
  handleItemChange: any,
  packages: Package[],
}
const InvoiceItem = ({ item, handleItemChange, packages }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((sto: InvoiceState) => sto.invoice);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(selectedPackages(packages.map(
      (pack) => ({ label: pack.name, value: pack }),
    )));
  }, [packages]);

  const vatPrice = useMemo(() => ((+item.price * parseFloat(item.vat)) / 100), [item.price, item.vat]);

  useEffect(() => {
    const newPrice = (+item.price + vatPrice) * (1 - item.discount / 100);
    handleItemChange({ id: item.id, item: { ...item, finalPrice: parseFloat(`${newPrice}`).toFixed(2) } });
  }, [item.discount, item.price, item.vat]);

  return (
    <Col>
      <Row className="px-2 hide-md-down">
        <Col md={3}>
          <span>{t('Item')}</span>
        </Col>
        <Col md={3}>
          <span>{t('Price')}</span>
        </Col>
        <Col md={2}>
          <span>{t('VAT')} (%)</span>
        </Col>
        <Col md={2}>
          <span>{t('Disc')} (%)</span>
        </Col>
        <Col md={2}>
          <span>{t('Total')}</span>
        </Col>
      </Row>
      <Row className="border rounded p-2" style={{ position: 'relative' }}>
        <Button
          size="sm"
          color="transparent"
          className="btn btn-icon"
          style={{
            position: 'absolute', right: '0.5rem', top: '0.5rem', width: '15px', height: '15px', padding: 0,
          }}
          onClick={() => handleItemChange({ id: item.id, item: undefined })}
        >
          <X size={15} className="text-center" />
        </Button>
        <Col md={3}>
          <CustomLabel name={t('Item')} className="display-md-down" />
          <Select
            className="react-select"
            classNamePrefix="select"
            placeholder={t('Select')}
            options={store?.selectedPackages}
            value={store?.selectedPackages.find((it) => it.value.id === item.packageId)}
            onChange={(val) => handleItemChange({ id: item.id, item: { ...item, packageId: val?.value.id, price: val?.value.price ? parseFloat(val?.value.price) : 0 } })}
          />
        </Col>
        <Col md={3}>
          <CustomLabel name={t('Price')} className="display-md-down" />
          <Input value={item.price} type="number" onChange={(e) => handleItemChange({ id: item.id, item: { ...item, price: e.target.value } })} />
        </Col>
        <Col md={2}>
          <CustomLabel name={t('VAT')} className="display-md-down" />
          <Input style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'center' }} value={+item.vat} type="number" onChange={(e) => handleItemChange({ id: item.id, item: { ...item, vat: e.target.value ? parseInt(e.target.value, 10) : 0 } })} />
        </Col>
        <Col md={2}>
          <CustomLabel name={t('Disc')} className="display-md-down" />
          <Input max={100} type="number" style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'center' }} value={item.discount} onChange={(e) => handleItemChange({ id: item.id, item: { ...item, discount: e.target.value } })} />
        </Col>
        <Col md={2}>
          <CustomLabel name={t('Total')} className="display-md-down" />
          <Input value={item.finalPrice} disabled />
        </Col>
        <Row style={{ marginTop: '1rem' }}>
          <Col md={4}>
            <Input placeholder="Dodatna beleska" value={item.packageNote} onChange={(e) => handleItemChange({ id: item.id, item: { ...item, packageNote: e.target.value } })} />
          </Col>
          <Col md={4} />
          <Col md={2} />
          <Col md={2} />
        </Row>
      </Row>
    </Col>
  );
};

export default InvoiceItem;
