import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const validationSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    question: yup.string().required(t('Question is required')),
    answers: yup
      .array()
      .of(
        yup.object().shape({
          answer: yup.string().required(t('Answer is required')),
        }),
      )
      .min(2, t('Poll should have at least 2 answers')),
    startDate: yup.date().required(t('Start Date is required')),
    endDate: yup
      .date()
      .required(t('End Date is required'))
      .min(yup.ref('startDate'), t('End date must be after start date')),
  });
  return validation;
};
export default validationSchema;
