/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Button, Col } from 'reactstrap';
import InvoiceItem from './InvoiceItem';
import Package from '../../packages/types/Package';
import CreateInvoicePackage from '../types/CreateInvoicePackage';

export type Items = {
  price: number,
  packageNote: string,
  discount: number,
  vat: string,
  id: number;
  packageId?: number;
  finalPrice: string;
}

const InvoiceItems = ({
  packages, setValue, vat, defaultValue,
} : { packages: Package[], setValue: any, vat: string, defaultValue: CreateInvoicePackage[] }) => {
  const Item: Items = {
    id: 0,
    price: 0,
    packageNote: '',
    discount: 0,
    vat,
    finalPrice: '0',
  };

  const { t } = useTranslation();
  const [items, setItems] = useState<Items[]>([Item]);
  const [freeId, setFreeId] = useState(0);

  useEffect(() => {
    const potentialDefaultValue = defaultValue.length ? defaultValue.map((item, id) => ({ ...item, id })) : [];
    if (potentialDefaultValue.length === 0) {
      setItems([Item]);
      setFreeId(1);
    } else {
      setItems(potentialDefaultValue);
      setFreeId(potentialDefaultValue.length);
    }
  }, []);

  const handleItemChange = ({ id, item }
    : { id: number | undefined, item: Items | undefined }) => {
    if (id === undefined) {
      setItems([...items, { ...Item, id: freeId }]);
      setFreeId(freeId + 1);
      return;
    }

    if (!item) {
      setItems(items.filter((el) => el.id !== id));
      return;
    }

    const changedItemIndex = items.findIndex((el) => el.id === id);

    if (changedItemIndex !== undefined) {
      const newItems = [...items];
      newItems[changedItemIndex] = item;
      setItems(newItems);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const itemsToSet = items.map(({ id, ...rest }) => ({ ...rest, price: String(rest.price) }));
    setValue('packages', itemsToSet);
    const totalAmount = items.reduce((acc, val) => (acc + +val.finalPrice), 0);

    setValue('totalAmount', totalAmount);
  }, [items]);

  return (
    <Col>
      {
        items.map((item) => <InvoiceItem key={item.id} packages={packages} item={item} handleItemChange={handleItemChange} />)
      }
      <Button className="me-1 my-2" color="primary" type="button" onClick={() => handleItemChange({ item: Item, id: undefined })}>{t('Add Item')}</Button>
    </Col>
  );
};

export default InvoiceItems;
