import FilterValue from "@src/types/FilterValue";
import SelectedOptions from "@src/types/SelectedOptions";
import { useEffect, useState } from "react";
import { MultiValue, SingleValue } from "react-select";

const useFilter = () => {
  const [filters, setFilters] = useState<{ name: string, value: string | number; }[]>([]);
  const [query, setQuery] = useState<string>('');

  const setInputFilter = (filterIndex: number, name: string, value: string | number) => {
    if (filterIndex >= 0) {
      const filtersCopy = [...filters];
      filtersCopy[filterIndex] = { name, value };
      setFilters(filtersCopy);
    } else {
      setFilters(prevState => ([...prevState, { name, value }]))
    }
  }

  const setSingleSelectFilter = (filterIndex: number, name: string, value: SingleValue<SelectedOptions>) => {
    if (value) {
      if (filterIndex >= 0) {
        const filtersCopy = [...filters];
        filtersCopy[filterIndex] = { name, value: value.value };
        setFilters(filtersCopy);
      }
      else {
        setFilters(prevState => ([...prevState, { name, value: value.value }]))
      }
    }
  }

  const setMultiSelectFilter = (filterIndex: number, name: string, value: MultiValue<SelectedOptions>) => {
    const filterValue = value.map((val) => val.value.toString()).join(',');
    if (filterValue !== '') {
      if (filterIndex >= 0) {
        const filtersCopy = [...filters];
        filtersCopy[filterIndex] = { name, value: filterValue };
        setFilters(filtersCopy);
      }
      else {
        setFilters(prevState => ([...prevState, { name, value: filterValue }]))
      }
    } else {
      const filteredFilters = filters.filter((filter) => filter.name !== name);
      setFilters(filteredFilters);
    }
  }

  const onFilterChange = (name: string, value: FilterValue) => {
    if (value) {
      const filterIndex = filters.findIndex((filter) => filter.name === name);

      if (typeof value === 'string') {
        setInputFilter(filterIndex, name, value);
      } else if ('value' in value) {
        setSingleSelectFilter(filterIndex, name, value);
      } else if ('values' in value) {
        setMultiSelectFilter(filterIndex, name, value);
      }
    } else {
      const filteredFilters = filters.filter((filter) => filter.name !== name);
      setFilters(filteredFilters);
    }
  }

  useEffect(() => {
    const queryString = new URLSearchParams();

    filters.forEach((item) => {
      queryString.append(item.name, item.value.toString());
    });

    setQuery(queryString.toString());
  }, [filters]);

  return { query, filters, onFilterChange };
};

export default useFilter;