import { useEffect, useState } from 'react';
import {
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import EvaluationMetric from '../types/EvaluationMetric';
import EvaluationGrade from '../types/EvaluationGrade';

interface Props {
  metric: EvaluationMetric,
  initialValue: number,
  evaluationGrade: EvaluationGrade,
  setFormValue: (key: string, val: number) => void,
  isYearly: boolean,
}

const EvaluationGradingItem = ({
  metric, initialValue, setFormValue, evaluationGrade, isYearly = false,
} : Props) => {
  const [value, setValue] = useState(initialValue);
  const { t } = useTranslation();

  const handleChange = (val: number) => {
    setValue(val);
    setFormValue(String(metric.id), val);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div style={{ marginBottom: 0 }}>
      {metric.name}
      <Row>
        <Col>
          <FormGroup className="form-group">
            <Input
              id={`${metric.name}-six`}
              type="radio"
              value={6}
              onChange={(e) => handleChange(Number(e.target.value))}
              checked={value === 6}
            />
            {' '}
            <Label check htmlFor={`${metric.name}-six`}>
              {t('Not graded')}
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="form-group" check>
            <Input
              id={`${metric.name}-one`}
              type="radio"
              value={1}
              onChange={(e) => handleChange(Number(e.target.value))}
              checked={value === 1}
            />
            {' '}
            <Label check htmlFor={`${metric.name}-one`}>
              {isYearly ? t('P (present)') : evaluationGrade?.one}
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="form-group" check>
            <Input
              id={`${metric.name}-two`}
              type="radio"
              value={2}
              onChange={(e) => handleChange(Number(e.target.value))}
              checked={value === 2}
            />
            {' '}
            <Label check htmlFor={`${metric.name}-two`}>
              {isYearly ? t('R/0 (rare or absent)') : evaluationGrade?.two}
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="form-group" check>
            <Input
              id={`${metric.name}-three`}
              type="radio"
              value={3}
              onChange={(e) => handleChange(Number(e.target.value))}
              checked={value === 3}
            />
            {' '}
            <Label check htmlFor={`${metric.name}-three`}>
              {isYearly ? t('R (in development)') : evaluationGrade?.three}
            </Label>
          </FormGroup>
        </Col>
        {!isYearly
          ? (
            <>
              <Col>
                <FormGroup className="form-group" check>
                  <Input
                    id={`${metric.name}-four`}
                    type="radio"
                    value={4}
                    onChange={(e) => handleChange(Number(e.target.value))}
                    checked={value === 4}
                  />
                  {' '}
                  <Label check htmlFor={`${metric.name}-four`}>
                    {evaluationGrade?.four}
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group" check>
                  <Input
                    id={`${metric.name}-five`}
                    type="radio"
                    value={5}
                    onChange={(e) => handleChange(Number(e.target.value))}
                    checked={value === 5}
                  />
                  {' '}
                  <Label check htmlFor={`${metric.name}-five`}>
                    {evaluationGrade?.five}
                  </Label>
                </FormGroup>
              </Col>
            </>
          )
          : <></>}
      </Row>
    </div>
  );
};

export default EvaluationGradingItem;
