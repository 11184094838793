import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import SelectedOptions from '@src/types/SelectedOptions';
import { handleErrorMessage } from '@src/utility/Utils';
import Sms from '../types/Sms';

export const getAllSmses = createAsyncThunk('goKinder/getAllSmses', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/sms${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const smsesSlice = createSlice({
  name: 'sms',
  initialState: {
    allSmses: [] as Sms[],
    selectedUsers: [] as SelectedOptions[],
    selectedParents: [] as SelectedOptions[],
  },
  reducers: {
    setAllSmses: (state, action) => {
      state.allSmses = action.payload;
    },
    setSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload;
    },
    setSelectedParents: (state, action) => {
      state.selectedParents = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSmses.fulfilled, (state, action) => {
      state.allSmses = action.payload.data;
    });
  },
});

export const {
  setAllSmses,
  setSelectedUsers,
  setSelectedParents,
} = smsesSlice.actions;

export default smsesSlice.reducer;
