import { Action, Permission, Status } from "@src/types/Permission";

const isPermissionActive = (
  permissions: Permission[],
  permissionName: string,
  action: Action,
) => permissions?.find(
  (p: Permission) => p.name === permissionName
  && p.action === action
  && p.status === Status.CAN,
) !== undefined;
  
export const isViewPermissionActive = (
  permissions: Permission[],
  permissionName: string,
) => isPermissionActive(permissions, permissionName, Action.VIEW);
  
export const isManagePermissionActive = (
  permissions: Permission[],
  permissionName: string,
) => isPermissionActive(permissions, permissionName, Action.MANAGE);

export const isDeletePermissionActive = (
  permissions: Permission[],
  permissionName: string,
) => isPermissionActive(permissions, permissionName, Action.DELETE);
