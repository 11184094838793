import Parent from '@src/views/parents/types/Parent';
import { AppDispatch } from '@store/store';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { CircleMinus } from 'tabler-icons-react';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { removeParentRelationShip } from '../store';

type Props = {
  parents?: Parent[];
}

const RelatedParents: React.FC<Props> = ({ parents }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { childId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRemoveDeleteParentRelationship = (parent: Parent) => {
    if (childId) {
      dispatch(
        openModal({
          Component: ConfirmationModalWrapper,
          title: t('Are you sure?'),
          subtitle: `${t('Relationship with')} "${parent.name} ${parent.surname}" ${t('will be deleted.')}`,
          open: true,
          componentProps: {
            handleConfirm: async () => {
              try {
                if (parent.id) {
                  await dispatch(
                    removeParentRelationShip({ childId: +childId, parentId: parent.id }),
                  );
                }
              } finally {
                dispatch(closeModal());
              }
            },
          },
        }),
      );
    }
  };

  return (
    <Row className="text-center">
      {parents?.map(
        (parent) => (
          <Col sm={12} className="d-flex justify-content-center gap-50" key={parent.id}>
            <Link to={`/parent/${parent.id}/edit`} className="text-left">{parent.name} {parent.surname}</Link>
            <Button color="outline" className="p-0" onClick={() => { if (parent.id) handleRemoveDeleteParentRelationship(parent); }}>
              <CircleMinus width={16} height={16} />
            </Button>
          </Col>
        ),
      )}

      {parents && parents?.length < 2 && (
        <Button
          color="outline"
          className="d-flex justify-content-center align-items-center py-0 gap-50 mt-50"
          onClick={() => { navigate('parent-relationship/add'); }}
        >
          <Col md={2} style={{ width: 'fit-content' }}>
            <Plus width={16} height={16} />
          </Col>
          <Col md={10} className="d-flex justify-content-start" style={{ width: 'fit-content' }}>
            {t('Add parent info')}
          </Col>
        </Button>
      )}
    </Row>
  );
};

export default RelatedParents;
