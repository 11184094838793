import { useState } from 'react';
import CustomLabel from '@src/components/forms/CustomLabel';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import EvaluationMetric from '../types/EvaluationMetric';
import EvaluationMetricForm from './EvaluationMetricForm';

const EvaluationMetricItem = ({ evaluationMetricItem, handleDelete } : {
   evaluationMetricItem: EvaluationMetric, handleDelete: (id: number) => void }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      {isEditing ? (
        <EvaluationMetricForm
          evaluationGroupId={String(evaluationMetricItem.evaluationGroupId)}
          evaluationMetricItem={evaluationMetricItem}
          setIsEditing={setIsEditing}
        />
      ) : (
        <Row>
          <Col md={4}>
            <CustomLabel name={t('Name')} />
            <p>{evaluationMetricItem.name}</p>
          </Col>
          <Col md={4}>
            <CustomLabel name={t('Sort')} />
            <p>{evaluationMetricItem.sort}</p>
          </Col>
          <Col md={2} />
          <Col
            md={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Button color="primary" onClick={() => setIsEditing(true)}>{t('Edit')}</Button>
            <Button color="red" onClick={() => handleDelete(evaluationMetricItem.id)}>{t('Delete')}</Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default EvaluationMetricItem;
