import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import RequestStatus from '@src/types/RequestStatus';
import toast from 'react-hot-toast';
import { Button } from 'reactstrap';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import Faq from '../types/Faq';
import { deleteFaq, getFaqCategoriesById } from '../store';
import FaqItem from './FaqItem';
import FaqForm from './FaqForm';

const FaqList = ({ items, categoryId } : { items: Faq[], categoryId: string }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isAdding, setIsAdding] = useState(false);
  const { t } = useTranslation();

  const handleDelete = (id: number) => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('FAQ will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            const res = await dispatch(deleteFaq(id));

            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              dispatch(getFaqCategoriesById(categoryId));
              toast.success(t('FAQ successfully deleted'), { position: 'top-right', duration: 3000 });
            }
          } finally {
            dispatch(closeModal());
          }
        },
      },
    }));
  };

  return (
    <div>
      <div style={{ margin: '14px 0' }}>
        <hr />
        {items.map((item) => <FaqItem key={item.id} faqItem={item} handleDelete={handleDelete} />)}
      </div>
      {isAdding ? (
        <FaqForm categoryId={categoryId} setIsEditing={setIsAdding} />
      ) : <Button color="primary" onClick={() => setIsAdding(true)}>{t('Add FAQ')}</Button>}
    </div>
  );
};

export default FaqList;
