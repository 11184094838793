import { useEffect, useMemo } from 'react';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { AppDispatch } from '@store/store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import Select from 'react-select';
import CustomLabel from '@src/components/forms/CustomLabel';
import PackageState from '@src/views/packages/types/PackageState';
import { errorToast } from '@src/components/wrappers/ToastMessages';
import { getAllPackages } from '@src/views/packages/store';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';
import { formatDateToShortDate, handleApiErrorResponse } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAllTenants, getTenantInfo } from '@src/views/tenants/store';
import { closeModal } from '@store/modal';
import {
  createMassInovice,
  getInvoiceNumbers,
  getInvoiceTypes,
  getYears,
  selectedInvoiceType,
  selectedYears,
} from '../store';
import InvoiceItems from './InvoiceItems';
import InvoiceState from '../types/InvoiceState';
import CreateInvoicePackage from '../types/CreateInvoicePackage';
import { invoiceValidationSchema } from '../validation/InvoiceValidation';

type SubmitData = {
  dateIssued: string;
  servicePeriodFrom: string;
  servicePeriodTo: string;
  dueDate: string;
  totalAmount: string;
  note?: string;
  invoiceNumber: number;
  model: number;
  invoiceType: string,
  yearId: number,
  packages: CreateInvoicePackage[];
}

const InvoiceMassAddition = ({ childrenIds }: { childrenIds: number[] }) => {
  const currentDate = useMemo(() => new Date(new Date().toDateString()), []);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const invoiceStore = useSelector((store: InvoiceState) => store.invoice);
  const tenantStore = useSelector((store: any) => store.tenants);
  const packageStore = useSelector((store: PackageState) => store.packages);

  const modelEnum = useMemo(
    () => ([{ label: '97', value: 97 }, { label: '0', value: 0 }]), [],
  );

  const defaultValues = {
    dateIssued: currentDate.toISOString(),
    servicePeriodFrom: new Date(new Date().setDate(1)).toISOString(),
    servicePeriodTo: new Date(new Date(new Date().setMonth(currentDate.getMonth() + 1))
      .setDate(0)).toISOString(),
    dueDate: currentDate.toISOString(),
    totalAmount: '0',
    note: '',
    invoiceNumber: 0,
    model: 0,
    invoiceType: '',
    packages: [],
  };

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm<SubmitData>({
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(
      invoiceValidationSchema(),
    ),
  });

  useEffect(() => {
    if (!childrenIds.length) {
      dispatch(closeModal());
      errorToast(t('Please select at least one child'));
    }
  }, []);

  useEffect(() => {
    dispatch(getAllPackages());
    dispatch(getInvoiceTypes());
    dispatch(getYears());
    dispatch(getAllTenants());
    dispatch(getTenantInfo());
    dispatch(getInvoiceNumbers());
  }, []);

  const getNumDaysAhead = (date: string | Date, num: number) => {
    const baseDate = new Date(date);
    const newDate = new Date(baseDate);

    newDate.setDate(baseDate.getDate() + num);

    return newDate;
  };

  const handleSuccessfulSubmit: SubmitHandler<any> = async (values: SubmitData) => {
    try {
      const newInvoices = {
        ...values,
        dateIssued: formatDateToShortDate(getValues('dateIssued')),
        dueDate: formatDateToShortDate(getValues('dueDate')),
        servicePeriodFrom: formatDateToShortDate(getValues('servicePeriodFrom')),
        servicePeriodTo: formatDateToShortDate(getValues('servicePeriodTo')),
        totalAmount: String(values.totalAmount),
        invoiceNumber: Number(values.invoiceNumber),
        childrenIds,
      };

      await dispatch(createMassInovice(newInvoices)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          toast.success(t('Invoices successfully added'), { position: 'top-right', duration: 3000 });
          dispatch(closeModal());
        }
      });
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  useEffect(() => {
    dispatch(selectedInvoiceType(invoiceStore.invoiceTypes.map(
      (type) => ({ label: t(`${type}`), value: type }),
    )));
  }, [invoiceStore.invoiceTypes]);

  useEffect(() => {
    if (invoiceStore.invoiceNumbers.length) setValue('invoiceNumber', Number(invoiceStore.invoiceNumbers[0].invoiceNumber) + 1);
  }, [invoiceStore.invoiceNumbers]);

  useEffect(() => {
    if (tenantStore.allTenants.length) {
      const newDueDate = getNumDaysAhead(getValues('dateIssued'), tenantStore.allTenants[0].invoiceDueDateByNumber || 1);
      setValue('dueDate', newDueDate.toISOString());
    }
  }, [watch('dateIssued'), tenantStore.allTenants]);

  useEffect(() => {
    dispatch(selectedYears(invoiceStore.years.map(
      (year) => ({ label: String(year.value), value: year.id }),
    )));
  }, [invoiceStore.years]);

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={6} className="mb-1">
          <CustomDatePicker<SubmitData>
            control={control}
            required
            label={t('Date Issued')}
            error={errors?.dateIssued?.message}
            setValue={setValue}
            name="dateIssued"
            options={{ dateFormat: 'd M Y' }}
            defaultValue={getValues('dateIssued')}
          />
          {errors?.dateIssued?.message
          && <CustomFormFeedback message={errors.dateIssued.message} />}
          <Col md={3} />
        </Col>
        <Col md={6} className="mb-1">
          <CustomDatePicker<SubmitData>
            control={control}
            required
            label={t('Due Date')}
            error={errors?.dueDate?.message}
            setValue={setValue}
            name="dueDate"
            options={{ minDate: getValues('dateIssued'), dateFormat: 'd M Y' }}
            defaultValue={getValues('dueDate')}
          />
          {errors?.dueDate?.message
          && <CustomFormFeedback message={errors.dueDate.message} />}
          <Col md={3} />
        </Col>
        <Col md={6} className="mb-1">
          <CustomDatePicker<SubmitData>
            control={control}
            name="servicePeriodFrom"
            options={{ maxDate: new Date(getValues('servicePeriodTo')), dateFormat: 'd M Y' }}
            defaultValue={getValues('servicePeriodFrom')}
            required
            label={t('Service Period From')}
            error={errors?.servicePeriodFrom?.message}
            setValue={setValue}
          />
          {errors?.servicePeriodFrom?.message
          && <CustomFormFeedback message={errors.servicePeriodFrom.message} />}
          <Col md={3} />
        </Col>
        <Col md={6} className="mb-1">
          <CustomDatePicker<SubmitData>
            control={control}
            required
            error={errors?.servicePeriodTo?.message}
            setValue={setValue}
            options={{ minDate: new Date(new Date(getValues('servicePeriodFrom')).toDateString()), dateFormat: 'd M Y' }}
            defaultValue={getValues('servicePeriodTo')}
            name="servicePeriodTo"
            label={t('Service Period To')}
          />
          {errors?.servicePeriodTo?.message
          && <CustomFormFeedback message={errors.servicePeriodTo.message} />}
          <Col md={3} />
        </Col>
        <Col md={3} className="mb-1">
          <CustomLabel name={t('Invoice number')} required />
          <Controller
            control={control}
            name="invoiceNumber"
            render={({ field }) => (
              <Input id="invoiceNumber" type="number" invalid={!!errors.invoiceNumber} {...field} />
            )}
          />
          {errors?.invoiceNumber?.message
          && <CustomFormFeedback message={errors.invoiceNumber.message} />}
          <Col md={3} />
        </Col>
        <Col md={3}>
          <CustomLabel name={t('Invoice year')} required />
          <Select
            options={invoiceStore.selectedYears}
            value={invoiceStore.selectedYears
              ?.find((opt) => opt.value === watch('yearId'))}
            onChange={(val) => setValue('yearId', Number(val?.value))}
            name="object"
            className="react-select"
            classNamePrefix="select"
          />
          {errors?.yearId?.message
          && <CustomFormFeedback message={errors.yearId.message} />}
        </Col>
        <Col md={3}>
          <CustomLabel name={t('Invoice type')} required />
          <Select
            options={invoiceStore.selectedInvoiceType}
            value={invoiceStore.selectedInvoiceType
              .find((opt) => opt.value === watch('invoiceType'))}
            onChange={(val) => setValue('invoiceType', String(val?.value) || '')}
            name="object"
            className="react-select"
            classNamePrefix="select"
          />
          {errors?.invoiceType?.message
          && <CustomFormFeedback message={errors.invoiceType.message} />}
        </Col>
        <Col md={3}>
          <CustomLabel name={t('Model')} required />
          <Select
            options={modelEnum}
            value={modelEnum.find((opt) => opt.value === watch('model'))}
            onChange={(val) => setValue('model', val?.value || 0)}
            name="object"
            className="react-select"
            classNamePrefix="select"
          />
          {errors?.model?.message
          && <CustomFormFeedback message={errors.model.message} />}
        </Col>
        <Col md={12} className="mb-1">
          <Col className="d-flex align-items-center md-align-items-end">
            <span className="fw-normal">{t('Invoice note')}</span>
          </Col>
          <Col>
            <Controller
              control={control}
              name="note"
              render={({ field }) => (
                <Input id="note" type="textarea" invalid={!!errors.note} {...field} />
              )}
            />
            {errors?.note?.message
            && <CustomFormFeedback message={errors.note.message} />}
          </Col>
          <Col md={3} />
        </Col>
      </Row>
      <hr />
      <Row style={{ margin: '0px' }}>
        <InvoiceItems
          packages={packageStore.allPackages}
          vat={String(tenantStore.currentTenant?.vat ?? 0)}
          defaultValue={getValues('packages') || []}
          setValue={setValue}
        />
        {errors?.packages?.message
          && <CustomFormFeedback message={errors.packages.message} />}
      </Row>
      <Col className="m-sm-4">
        <p style={{ textAlign: 'end' }}><span style={{ fontWeight: 'bold' }}>{t('Total')}:</span> {watch('totalAmount')}</p>
      </Col>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          color="primary"
          disabled={false}
          style={{ minWidth: '250px' }}
          type="submit"
          className="me-1"
        >
          {t('Add')}
        </Button>
      </div>
    </Form>
  );
};

export default InvoiceMassAddition;
