import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { Button } from 'reactstrap';
import { Plus } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import RequestStatus from '@src/types/RequestStatus';
import { useEffect, useState } from 'react';
import FaqCategoriesColumns from '../components/FaqCategoriesColumns';
import {
  deleteFaqCategory,
  getAllFaqCategories,
  setAllFaqCategories,
} from '../store';
import FaqState from '../types/FaqState';

const EvaluationGroups = () => {
  const dispatch = useDispatch<AppDispatch>();
  const faqStore = useSelector((store: FaqState) => store.faq);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const faqPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [dataLoaded, setDataLoaded] = useState(false);

  const handleDeleteClick = (id: number) => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('FAQ category will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            const res = await dispatch(deleteFaqCategory(id));

            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              const filteredEvaluationGroups = faqStore
                .allFaqCategories.filter((item) => item.id !== id);
              dispatch(setAllFaqCategories(filteredEvaluationGroups));
              toast.success(t('FAQ category successfully deleted'), { position: 'top-right', duration: 3000 });
            }
          } finally {
            dispatch(closeModal());
          }
        },
      },
    }));
  };

  useEffect(() => {
    dispatch(getAllFaqCategories());
    setDataLoaded(true);
  }, []);

  return (
    <div>
      <DataTableWrapper
        dataSource={faqStore.allFaqCategories}
        title={t('FAQ categories')}
        subtitle={t('See, manage and filter FAQ categories table')}
        columns={FaqCategoriesColumns({ handleDeleteClick })}
        showSearch={false}
        filters={[]}
        dataLoaded={dataLoaded}
        functionButton={(
          isManagePermissionActive(faqPermission, 'AdministrationFaqCategories')
            ? (
              <Button
                color="primary"
                className="text-nowrap mb-1 mt-2"
                outline
                onClick={() => { navigate('/faq-categories/new'); }}
              >
                <div className="d-flex justify-content-center">
                  <Plus size={15} />
                  &nbsp;
                  <span>{t('Add new category')}</span>
                </div>
              </Button>
            ) : <></>
        )}
      />
    </div>
  );
};

export default EvaluationGroups;
