import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Control, Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Input } from 'reactstrap';
import CustomPhoneTooltip from '@src/components/forms/CustomPhoneTooltip';

type Props = {
  control: Control<any>,
  errors: UseFormReturn['formState']['errors']
}

const EditableFields: React.FC<Props> = ({ control, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col md={4}>
        <CustomLabel required name={t('Name')} for="name" />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              invalid={errors?.name !== undefined}
              {...field}
            />
          )}
        />
        <CustomFormFeedback
          message={errors?.name?.message?.toString()}
        />
      </Col>
      <Col md={4}>
        <CustomLabel required name={t('Surname')} for="surname" />
        <Controller
          name="surname"
          control={control}
          render={({ field }) => (
            <Input
              invalid={errors?.surname !== undefined}
              {...field}
            />
          )}
        />
        <CustomFormFeedback
          message={errors?.surname?.message?.toString()}
        />
      </Col>
      <Col md={4}>
        <CustomLabel required name={t('Email')} for="email" />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              invalid={errors?.email !== undefined}
              {...field}
            />
          )}
        />
        <CustomFormFeedback
          message={errors?.email?.message?.toString()}
        />
      </Col>
      <Col md={4}>
        <CustomLabel name={t('EMBG')} for="embg" />
        <Controller
          name="embg"
          control={control}
          render={({ field }) => (
            <Input
              invalid={errors?.embg !== undefined}
              {...field}
            />
          )}
        />
        <CustomFormFeedback
          message={errors?.embg?.message?.toString()}
        />
      </Col>
      <Col md={4}>
        <CustomLabel name={t('Contract number')} for="contractNumber" />
        <Controller
          name="contractNumber"
          control={control}
          render={({ field }) => (
            <Input
              invalid={errors?.contractNumber !== undefined}
              {...field}
            />
          )}
        />
        <CustomFormFeedback
          message={errors?.contractNumber?.message?.toString()}
        />
      </Col>
      <Col md={4}>
        <CustomLabel name={t('Bank account number')} for="bankAccountNumber" />
        <Controller
          name="bankAccountNumber"
          control={control}
          render={({ field }) => (
            <Input
              invalid={errors?.bankAccountNumber !== undefined}
              {...field}
            />
          )}
        />
        <CustomFormFeedback
          message={errors?.bankAccountNumber?.message?.toString()}
        />
      </Col>
      <Col md={4}>
        <CustomPhoneTooltip isRequired={false} />
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <Input
              invalid={errors?.phoneNumber !== undefined}
              {...field}
            />
          )}
        />
        <CustomFormFeedback
          message={errors?.phoneNumber?.message?.toString()}
        />
      </Col>
      <Col md={4}>
        <CustomLabel name={t('Address')} for="address" />
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <Input
              invalid={errors?.address !== undefined}
              {...field}
            />
          )}
        />
        <CustomFormFeedback
          message={errors?.address?.message?.toString()}
        />
      </Col>
      <Col md={4}>
        <CustomLabel name={t('City')} for="city" />
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <Input
              invalid={errors?.city !== undefined}
              {...field}
            />
          )}
        />
        <CustomFormFeedback
          message={errors?.city?.message?.toString()}
        />
      </Col>
    </>
  );
};

export default EditableFields;
