import SelectedOptions from '@src/types/SelectedOptions';
import { useSkin } from '@src/utility/hooks/useSkin';
import { OptionProps, components } from 'react-select';

const Option: React.FC<OptionProps<SelectedOptions>> = ({ data, ...props }) => {
  const { skin } = useSkin();

  if (data.title) {
    return <div className={`tag-dropdown-title ${skin}`}>{data.label}</div>;
  }
  if (data.subtitle) {
    return <div className={`tag-dropdown-subtitle ${skin}`}>{data.label}</div>;
  }
  return <components.Option {...props} label={data.label} data={data} />;
};

export default Option;
