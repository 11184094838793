// ** Icons Import
import { useTranslation } from 'react-i18next';
import {version} from '../../../../../package.json';

const Footer = () => {
  const { t } = useTranslation();

  return (
  <p className="clearfix mb-0">
    <span className="float-md-start d-block d-md-inline-block mt-25">
      {t('COPYRIGHT')} © {new Date().getFullYear()}{' '}
      <a href="https://gokinder.com/" target="_blank" rel="noopener noreferrer">
        GoKinder
      </a>
      <span className="d-none d-sm-inline-block">, {t('All rights Reserved')}</span>
    </span>
    <span className="float-md-end d-none d-md-block" style={{color: '#7DCBEC', fontWeight: '600'}}>
       v{version}
    </span>
  </p>
  )
};

export default Footer;
