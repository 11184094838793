import SelectedOptions from '@src/types/SelectedOptions';
import Child from '@src/views/children/types/Child';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import * as yup from 'yup';

const noteValidationSchema = (
  selectedChild: SingleValue<SelectedOptions> | undefined,
  noteCategory: SingleValue<SelectedOptions> | undefined,
  note: string,
  currentChild?: Child,
) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    note: yup.mixed().test('required', t('Note is required'), () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(note, 'text/html');
      const cleanTitle = doc.body.textContent ?? '';
      return cleanTitle.trim().length !== 0;
    }),
    date: yup.string().required(t('Date is required')),
    noteCategoryId: yup.mixed().test('required', t('Note category is required'), () => !!noteCategory?.value),
    childId: yup.mixed().test('required', t('Child is required'), () => {
      if (currentChild) {
        return true;
      }

      if (!currentChild && !!selectedChild?.value) {
        return true;
      }

      return false;
    }),
  });
  return validation;
};
export default noteValidationSchema;
