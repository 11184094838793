/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Trash } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';
import InvoiceBulk from '../types/InvoiceBulk';

const InvoiceBulkColumns = ({ handleDeleteClick, deletedChildren }:
  { handleDeleteClick: (invoiceId: number) => void, deletedChildren: Set<string> }) => {
  const { t } = useTranslation();

  const columns: TableColumn<InvoiceBulk>[] = [
    {
      name: t('Child name'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.referenceNumber}>{`${row.childNameAndSurname}`}</p>
      ),
    },
    {
      name: t('Object'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.referenceNumber}>{`${row.objectName}`}</p>
      ),
    },
    {
      name: t('Invoice'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.referenceNumber}>{`${row.referenceNumber}`}</p>
      ),
    },
    {
      name: t('Package'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.referenceNumber}>{`${row.packageName}`}</p>
      ),
    },
    {
      name: t('Package price'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.referenceNumber}>{`${row.packagePrice}rsd`}</p>
      ),
    },
    {
      name: t('Total amount'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.referenceNumber}>{`${row.amountWithDiscountAndVat.toFixed(2)}rsd`}</p>
      ),
    },
    {
      name: t('Contract holder'),
      minWidth: '200px',
      cell: (row) => (
        <p className="m-0" key={row.referenceNumber}>
          {row.contractHolder && <span style={{ color: 'Green' }}>{t('Has')}</span>}
          {!row.contractHolder && <span style={{ color: 'Red' }}>{t('Doesnt have')}</span>}
        </p>
      ),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          <Button
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => { handleDeleteClick(row.childId); }}
          >
            <Trash className="text-center" size={15} color={deletedChildren.has(String(row.childId)) ? 'red' : 'gray'} />
          </Button>
        </div>
      ),
    },
  ];

  return columns;
};

export default InvoiceBulkColumns;
