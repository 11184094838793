import moment from 'moment';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import {
  Edit, Delete,
  File,
} from 'react-feather';
import {
  CalendarTime, Heading, Notes, User,
} from 'tabler-icons-react';
import { extractFileName, getUserAccessTokenData } from '@src/utility/Utils';
import { useSkin } from '@src/utility/hooks/useSkin';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { successToast } from '@src/components/wrappers/ToastMessages';

import AnnouncementModal from '@src/views/announcements/components/AnnouncementModal';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { deleteContract, getAllContracts } from '../store';
import ContractForm from './ContractForm';
import Contract from '../types/Contract';

const ContractCard = ({ details }: { details: Contract }) => {
  const dispatch = useDispatch<AppDispatch>();
  const userTokenData = getUserAccessTokenData();
  const { t } = useTranslation();
  const { skin } = useSkin();
  const contractPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const handleDeleteClick = (announcementsId: number) => {
    dispatch(
      openModal({
        Component: ConfirmationModalWrapper,
        title: t('Are you sure?'),
        subtitle: t('Contract will be deleted'),
        open: true,
        componentProps: {
          handleConfirm: async () => {
            try {
              await dispatch(deleteContract(announcementsId));
            } finally {
              successToast(t('Contract successfully deleted'));
              dispatch(getAllContracts());
              dispatch(closeModal());
            }
          },
        },
      }),
    );
  };

  const handleContentClick = (content: string) => {
    dispatch(openModal({
      Component: AnnouncementModal,
      componentProps: { content },
      title: t('Content'),
      open: true,
    }));
  };

  const disabledColor = skin === 'dark' ? '#444' : '#cccccc';
  const canEdit = userTokenData.role === 'superAdmin' || userTokenData.sub === details.createdById;

  return (
    <Col md={4}>
      <Card>
        <CardBody>
          <div>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><User size={20} />&nbsp;</span>
              {t('Created by')}:&nbsp;
              {`${details.createdBy.name}${details.createdBy.surname}`}
            </p>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><CalendarTime size={20} />&nbsp;</span>
              {t('Created at')}:&nbsp;
              {moment(new Date(details.createdAt)).format('DD. MMM Y. HH:mm')}
            </p>
            <div>
              <Button
                outline
                color="outline"
                block
                onClick={() => handleContentClick(details.title)}
                style={{
                  margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
                }}
              >
                <p
                  className="cursor-pointer"
                  style={{
                    whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'start', marginBottom: '0.5rem',
                  }}
                >
                  <span style={{ color: '#7DCBEC' }}><Heading size={20} /></span>
                  {t('Title')}:&nbsp;{details.title}
                </p>
              </Button>
            </div>
            <div>
              <Button
                outline
                color="outline"
                onClick={() => handleContentClick(details.note)}
                block
                style={{
                  margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
                }}
              >
                <p
                  className="cursor-pointer"
                  style={{
                    whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'start', marginBottom: '0.5rem',
                  }}
                >
                  <span style={{ color: '#7DCBEC' }}><Notes size={20} /></span>
                  {t('Note')}:&nbsp;{details.note}
                </p>
              </Button>
            </div>
          </div>
          <hr />
          <a
            href={details.filePath}
            download
            target="_blank"
            style={{ gap: '10px', width: 'fit-content' }}
            className="d-flex align-items-center"
            rel="noreferrer"
          >
            <File width={15} height={15} />{extractFileName(details.filePath)}
          </a>
          <hr />
          <div className="w-full d-flex justify-content-between">
            <div>
              {isManagePermissionActive(contractPermission, 'AdministrationContracts')
                ? (
                  <Button
                    color="outline m-0 p-0"
                    style={{ border: 'none', color: !canEdit ? disabledColor : '' }}
                    disabled={!canEdit}
                    onClick={() => {
                      dispatch(openModal({
                        Component: ContractForm,
                        componentProps: { contract: details },
                        title: t('Edit contract'),
                        open: true,
                        modalProps: { size: 'lg' },
                      }));
                    }}
                  ><Edit />
                  </Button>
                ) : <></>}
            </div>
            {isDeletePermissionActive(contractPermission, 'AdministrationContracts')
              ? (
                <Button
                  style={{ border: 'none', color: !canEdit ? disabledColor : '' }}
                  disabled={!canEdit}
                  color="outline m-0 p-0"
                  onClick={() => handleDeleteClick(details.id)}
                ><Delete color="red" />
                </Button>
              ) : <></>}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ContractCard;
