import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const addContractValidationSchema = (newFiles: File[]) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    title: yup.string().required(t('Title is required')),
    file: yup.mixed().test('required', t('File is required'), () => !!newFiles.length),
  });
  return validation;
};

const editContractValidationSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    title: yup.string().required(t('Title is required')),
  });
  return validation;
};

export { addContractValidationSchema, editContractValidationSchema };
