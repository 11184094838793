import FileType from '@src/components/file-uploader/types/FileType';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const addFilesValidation = (
  files: FileType[],
) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    files: yup.mixed().test('required', t('Files are required'), () => files.length > 0),
  });
  return validation;
};
export default addFilesValidation;
