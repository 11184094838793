import { Button } from 'reactstrap';

import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { closeModal } from '@store/modal';
import { useTranslation } from 'react-i18next';

interface ConfirmationModalProps {
  handleConfirm: () => void;
  confirmButtonText?: string;
  closeButtonText?: string;
}

const ConfirmationModalWrapper = ({
  handleConfirm,
  confirmButtonText = 'Delete',
  closeButtonText = 'Close',
}: ConfirmationModalProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleCloseClick = () => {
    dispatch(closeModal());
  };

  const { t } = useTranslation();

  return (
    <div>
      <div className="text-center mt-2">
        <Button
          type="submit"
          className="me-1"
          color="primary"
          onClick={handleConfirm}
        >
          {t(confirmButtonText)}
        </Button>
        <Button color="secondary" outline onClick={handleCloseClick}>
          {t(closeButtonText)}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationModalWrapper;
