import {
  Button, CardHeader, CardSubtitle, CardTitle,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Plus } from 'tabler-icons-react';
import { AppDispatch } from '@store/store';
import { openModal } from '@store/modal';
import ChildrenFileForm from './ChildrenFileForm';

const NewFileCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <CardHeader className="border-bottom d-flex">
      <div className="mt-2">
        <CardTitle tag="h4">{t('Child\'s files overview')}</CardTitle>
        <CardSubtitle className="mt-1">{t('View, add and edit files')}</CardSubtitle>
      </div>
      <Button
        color="primary"
        className="text-nowrap mb-1 mt-2"
        outline
        onClick={() => {
          dispatch(openModal({
            Component: ChildrenFileForm,
            title: t('Add new file'),
            open: true,
            modalProps: { size: 'lg' },
          }));
        }}
      >
        <div className="d-flex justify-content-center">
          <Plus size={15} />
          &nbsp;
          <span>{t('Add file')}</span>
        </div>
      </Button>
    </CardHeader>
  );
};

export default NewFileCard;
