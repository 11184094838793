import { useEffect, useState } from 'react';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import ShowModalButton from '@src/components/buttons/ShowModalButton';
import { AppDispatch } from '@store/store';
import toast from 'react-hot-toast';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import TenantObjectsColumns from './components/TenantObjectsColumns';
import TenantObjectForm from './components/TenantObjectForm';
import TenantObject from './types/TenantObject';
import { deleteTenantObject, getAllTenantObjects } from './store';
import TenantObjectState from './types/TenantObjectState';

const TenantObjects: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const tenantObjectPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const { t } = useTranslation();
  const tenantObjects = useSelector(
    (state: TenantObjectState) => state.tenantObjects.allTenantObjects,
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleDeleteClick = (tenantObject: TenantObject) => {
    dispatch(
      openModal({
        Component: ConfirmationModalWrapper,
        title: t('Are you sure?'),
        subtitle: `${t('Object')} "${tenantObject.name}" ${t('will be deleted.')}`,
        open: true,
        componentProps: {
          handleConfirm: async () => {
            setLoading(true);
            try {
              await dispatch(deleteTenantObject(tenantObject.id));
            } finally {
              setLoading(false);
              dispatch(closeModal());
            }
          },
        },
      }),
    );
  };

  useEffect(() => {
    setLoading(false);
    dispatch(getAllTenantObjects())
      .catch(() => { toast.error('Error while fetching objects'); })
      .finally(() => { setLoading(false); });
  }, []);

  return (
    <DataTableWrapper
      dataSource={tenantObjects}
      title={t('Objects')}
      subtitle={t('See, manage and filter objects table')}
      columns={TenantObjectsColumns({ handleDeleteClick })}
      dataLoaded={!loading}
      functionButton={(
        isManagePermissionActive(tenantObjectPermission, 'AdministrationObjects')
          ? (
            <ShowModalButton
              modalProps={{
                Component: TenantObjectForm,
                title: t('Add new object'),
                open: true,
              }}
              title={t('Add Object')}
            />
          ) : <></>
      )}
    />
  );
};

export default TenantObjects;
