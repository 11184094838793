// ** Vertical Menu Components
import {
  canViewMenuItem,
  canViewMenuGroup,
  resolveVerticalNavMenuItemComponent as resolveNavItemComponent,
} from '@layouts/utils';
import VerticalNavMenuLink from './VerticalNavMenuLink';
import VerticalNavMenuGroup from './VerticalNavMenuGroup';
import VerticalNavMenuSectionHeader from './VerticalNavMenuSectionHeader';
import { isViewPermissionActive } from '@src/utility/context/ActivePermissions';

// ** Utils

const VerticalMenuNavItems = (props) => {
  // ** Components Object
  const Components = {
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    VerticalNavMenuSectionHeader,
  };

  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)];
    const userPermissions = JSON.parse(localStorage.getItem('userData') || '{}').permissions;

    if (item.children) {
      if(item.children.some((child) => (isViewPermissionActive(userPermissions, child.permissionName || !child.permissionName)))){
        return canViewMenuGroup(item) && <TagName item={item} index={index} key={item.id} {...props} />;
      }
      return false;
    }
    return canViewMenuItem(item) 
    && (isViewPermissionActive(userPermissions, item.permissionName) || !item.permissionName)
    && <TagName key={item.id || item.header} item={item} 
    {...props} />;
  });

  return RenderNavItems;
};

export default VerticalMenuNavItems;
