/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const verifyToken = async (token: string | undefined) => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/auth/reset-password/${token}`);
  return {
    data: response.data,
  };
};

export const updatePassword = async (password: string, confirmPassword: string, token: string) => {
  const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/auth/reset-password/${token}`,
    { password, confirmPassword });
  return {
    data: response.data,
  };
};

export const callSso = async (token: string) => {
  const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/auth/sso/${token}`);

  return {
    data: response.data,
  };
};

export const changePassword = async (old_password: string,
  password: string, confirm_password: string) => {
  const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/auth/change-password`,
    { password, confirm_password, old_password });
  return {
    data: response.data,
  };
};

export const universitiesSlice = createSlice({
  name: 'passwordManagement',
  initialState: {},
  reducers: {},
});

export default universitiesSlice.reducer;
