/* eslint-disable no-restricted-syntax */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import RequestStatus from '@src/types/RequestStatus';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { useNavigate, useParams } from 'react-router-dom';
import CustomLabel from '@src/components/forms/CustomLabel';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';
import { useForm } from 'react-hook-form';
import { ChevronLeft } from 'react-feather';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Row,
} from 'reactstrap';
import SelectedOptions from '@src/types/SelectedOptions';
import {
  clearCurrentEvaluation,
  createNewEvaluation,
  editEvaluation,
  getAllEvaluationGroups,
  getEvaluationById,
  getEvaluationTypes,
  setSelectedEvaluationTypes,
} from '../store';
import EvaluationState from '../types/EvaluationState';
import EvaluationGradingItem from '../components/EvaluationGradingItem';
import EvaluationGrade from '../types/EvaluationGrade';
import EvaluationSubmitData from '../types/EvaluationSubmitData';
import EvaluationDate from '../components/EvaluationDate';

type FormData = {
  [key: string]: any;
};

const EvaluationsManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const evaluationsStore = useSelector((store: EvaluationState) => store.evaluations);
  const { t } = useTranslation();
  const { childId, evaluationId } = useParams();
  const [evaluationType, setEvaluationType] = useState<SingleValue<SelectedOptions>>({ label: t('DAILY_EVALUATIONS'), value: 'DAILY_EVALUATIONS' });
  const [initialValue, setInitialValue] = useState<FormData>({});
  const [evaluationDates, setEvaluationDates] = useState<{
    startDate: string, endDate: string, snapshot: number }>({
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      snapshot: 0,
    });
  const navigate = useNavigate();

  const {
    handleSubmit,
    reset,
    setValue,
  } = useForm<FormData>();

  useEffect(() => {
    if (evaluationId) dispatch(getEvaluationById(evaluationId));
    dispatch(getEvaluationTypes());
  }, []);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(clearCurrentEvaluation());
    };
  }, []);

  useEffect(() => {
    if (childId) dispatch(getAllEvaluationGroups(`childId=${childId}&evaluationType=${evaluationType?.value}`));
    if (evaluationsStore.currentEvaluation.childId) dispatch(getAllEvaluationGroups(`childId=${evaluationsStore.currentEvaluation.childId}&evaluationType=${evaluationType?.value}`));
  }, [evaluationType, evaluationsStore.currentEvaluation]);

  useEffect(() => {
    if (evaluationsStore.currentEvaluation.evaluationType !== evaluationType?.value
      && (evaluationsStore.currentEvaluation.evaluationType)) {
      setEvaluationType({ label: t(`${evaluationsStore.currentEvaluation.evaluationType}`), value: evaluationsStore.currentEvaluation.evaluationType });
    }
    if (evaluationsStore.currentEvaluation.id) {
      setEvaluationDates({
        startDate: evaluationsStore.currentEvaluation.dateFrom,
        endDate: evaluationsStore.currentEvaluation.dateTo,
        snapshot: evaluationsStore.currentEvaluation.snapshot,
      });
    }
  }, [evaluationsStore.currentEvaluation]);

  useEffect(() => {
    const metrics: FormData = {};
    for (const group of evaluationsStore.allEvaluationGroups) {
      for (const evaluationMetric of group.evaluationMetrics) {
        if (evaluationsStore.currentEvaluation.id) {
          const evaluationMetricGrade = evaluationsStore.currentEvaluation.evaluationMetricGrades
            .find((item) => item.evaluationMetric.id === evaluationMetric.id);
          if (evaluationMetricGrade) {
            metrics[String(evaluationMetric.id)] = evaluationMetricGrade?.grade;
          } else {
            metrics[String(evaluationMetric.id)] = 6;
          }
        } else {
          metrics[String(evaluationMetric.id)] = 6;
        }
      }
    }
    reset(metrics);
    reset(metrics);
    setInitialValue(metrics);
  }, [evaluationsStore.allEvaluationGroups, evaluationsStore.currentEvaluation]);

  useEffect(() => {
    dispatch(setSelectedEvaluationTypes(evaluationsStore.evaluationTypes.map(
      (item) => ({ label: t(String(item)), value: item }),
    )));
  }, [evaluationsStore.evaluationTypes]);

  const defaultGrade: EvaluationGrade = useMemo(() => (
    {
      id: 0,
      evaluationGroupId: 0,
      one: t('One'),
      two: t('Two'),
      three: t('Three'),
      four: t('Four'),
      five: t('Five'),
    }
  ), []);

  const handleSuccessfulSubmit = async (data: any) => {
    const items = Object.keys(data);
    const grades = items.map((item) => ({ evaluationMetricId: Number(item), grade: data[item] }));
    const submitData: EvaluationSubmitData = {
      childId: Number(childId),
      dateFrom: evaluationDates.startDate,
      dateTo: evaluationDates.endDate,
      evaluationType: String(evaluationType?.value),
      snapshot: evaluationDates.snapshot,
      evaluationMetricGrades: grades,
    };

    if (evaluationId) {
      submitData.id = Number(evaluationId);
      submitData.childId = Number(evaluationsStore.currentEvaluation.childId);
      dispatch(editEvaluation(
        { id: Number(evaluationId), data: submitData },
      )).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successToast(t('Evaluation successfully edited'));
          navigate(`/children/${evaluationsStore.currentEvaluation.childId}?tab=child-evaluations`);
        }
      });
    } else {
      dispatch(createNewEvaluation(submitData)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successToast(t('Evaluation successfully added'));
          navigate(`/children/${childId}?tab=child-evaluations`);
        }
      });
    }
  };

  return (
    <Card className="mt-1">
      <CardHeader>
        <div className="mt-2" style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            color="outline"
            outline
            style={{
              margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
            }}
            onClick={() => navigate(-1)}
          >
            <ChevronLeft size={21} />
          </Button>
          <CardTitle>{childId ? t('Add new evaluation') : t('Edit evaluation')}</CardTitle>
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={4}>
            <CustomLabel name={t('Type')} required />
            <Select
              className="react-select"
              classNamePrefix="select"
              placeholder={t('Select')}
              options={evaluationsStore.selectedEvaluationTypes}
              value={evaluationType}
              onChange={setEvaluationType}
            />
          </Col>
          <EvaluationDate
            evaluationType={String(evaluationType?.value)}
            evaluationDates={evaluationDates}
            setEvaluationDates={setEvaluationDates}
          />
        </Row>
        <Form onSubmit={handleSubmit(handleSuccessfulSubmit)} style={{ paddingTop: '16px' }}>
          {evaluationsStore.allEvaluationGroups.map((group) => (
            <Col key={group.id} md={12} style={{ marginBottom: '16px' }}>
              <h4>{group.name}</h4>
              {group.evaluationMetrics.map((metric) => (
                <EvaluationGradingItem
                  isYearly={evaluationType?.value === 'YEARLY_EVALUATIONS'}
                  key={metric.id}
                  metric={metric}
                  initialValue={initialValue ? initialValue[String(metric.id)] : 6}
                  setFormValue={setValue}
                  evaluationGrade={group?.evaluationGrade || defaultGrade}
                />
              ))}
            </Col>
          ))}
          {!Object.keys(initialValue).length
            ? (
              <h5 style={{ textAlign: 'center' }}>{`${t('There is no group evaluations for')} ${t(String(evaluationType?.value)).toLocaleLowerCase()}! ${t('Please add them in administration')}`}</h5>
            ) : <></>}
          <Row md={12} style={{ marginTop: '32px' }}>
            <Col md={4} />
            <Col md={4}>
              <Button
                type="submit"
                color="primary"
                disabled={!Object.keys(initialValue).length}
                style={{ minWidth: '100%' }}
              >
                {evaluationId ? t('Save') : t('Add')}
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default EvaluationsManagement;
